export const OPEN_LEFT_NAVBAR = 'OPEN_LEFT_NAVBAR';
export const CLOSE_LEFT_NAVBAR = 'CLOSE_LEFT_NAVBAR';

export const openLeftNavbar = () => ({
    type: OPEN_LEFT_NAVBAR,
});

export const onClickMenu = ({link}) => {
    return (dispatch) => {
        if (!link){
            return;
        }
        dispatch(closeLeftNavbar());
    }
}

export const closeLeftNavbar = () => ({
    type: CLOSE_LEFT_NAVBAR,
});