import React from 'react';
import Input from "../../Components/Input";
import LineBreak from "../../Components/LineBreak";

const SectionContainer = (props) => {
    const {headerText, content, lineColor} = props;
    const useHeader = props.useHeader ? props.useHeader : true;
    return(
        <div>
            {useHeader &&
                <SectionHeader text={headerText}/>
            }
            <div className={"d-flex flex-column"}>
                {content}
            </div>
            <LineBreak color={`${lineColor}`}/>
        </div>
    );
}

const SectionHeader = (props)=>{
    return (
        <div className={"section-header"}>
            {props.text}
        </div>
    );
}

export default SectionContainer;
