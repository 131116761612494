export const SUPPLIER_STATUS = {
    ACTIVE: "Active",
    INACTIVE: "Inactive"
}

export const DELIVERY_DAY_OPTIONS = [
    {value: 0, label: 'Setiap Hari'},
    {value: 1, label: 'Senin'},
    {value: 2, label: 'Selasa'},
    {value: 3, label: 'Rabu'},
    {value: 4, label: 'Kamis'},
    {value: 5, label: 'Jumat'},
    {value: 6, label: 'Sabtu'},
    {value: 7, label: 'Minggu'}
];

export const SUPPLIER_TYPES = [
    "Category",
    "Consumables",
    "Upstream",
    "Packaging"
];

export const SKU_CATEGORIES = [
    'Sayuran',
    'Buah Lokal',
    'Buah Impor',
    'Daging',
    'Seafood',
    'Poultry',
    'Protein',
    'Dry Goods',
    '4R',
    'Rescue',
    'Asset'
]