import React from 'react'
import {Modal} from "react-bootstrap";

const guidelineImage = 'https://s3.ap-southeast-1.amazonaws.com/assets.segari.id/internal-tools-frontend-category-group-guideline-image.jpeg';
class GuidelineModal extends React.PureComponent {
    render(){
        return <Modal show={this.props.show} size='xl'>
            <Modal.Header>
            <button type="button" class="close" aria-label="Close" onClick={this.props.onClose}>
                <span aria-hidden="true">&times;</span>
            </button>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-2'>
                    <img src={guidelineImage} alt="guideline"/>
                </div>
            </Modal.Body>
        </Modal>
    }
}

export default GuidelineModal