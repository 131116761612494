import React from 'react'

class Pagination extends React.PureComponent {
    render() {
        const {loadingData, currentPage, totalPages, first, last, numberOfElements, totalElements, goToPage, custom, currentPagePagination, isFloating} = this.props;

        const floatingPaginationClassname = "d-flex justify-content-end align-items-center fixed-bottom mb-1 mr-1";
        const normalPaginationClassname = "d-flex justify-content-between align-items-center";

        const floatingPaginationStyle = {borderRadius: '10px', border: '2px solid gray', backgroundColor: '#ADC4CE'};

        if (!!numberOfElements && !!currentPage) {
            return (
                <div className={!!isFloating ? floatingPaginationClassname : normalPaginationClassname} style={{fontSize: '1.2em'}}>
                    {
                        !custom &&
                        <div className={!!isFloating ? 'p-1 mr-1' : ''} style={!!isFloating ? floatingPaginationStyle : null}>
                            Showing {(currentPage < 1) ? 0 : (Math.min(totalElements, (currentPage-1) * numberOfElements + 1))}-{Math.min(totalElements, currentPage * numberOfElements)} from {totalElements} {totalElements > 1 ? 'records' : 'record'}
                        </div>
                    }
                    <div className={!!isFloating ? 'p-1' : ''} style={!!isFloating ? floatingPaginationStyle : null}>
                        Page
                        <button type='button' disabled={first || loadingData} onClick={() => goToPage(1)}> {'<<'} </button>
                        <button type='button' disabled={first || loadingData} onClick={() => goToPage(currentPage-1)}> {'<'} </button>
                        {totalPages ? Math.min(totalPages, currentPage) : currentPage} {totalPages ? `of ${totalPages}` : ""}
                        <button type='button' disabled={last || loadingData} onClick={() => goToPage(currentPage+1)}> {'>'} </button>
                        <button type='button' disabled={last || loadingData || !totalPages} onClick={() => goToPage(totalPages)}> {'>>'} </button>
                    </div>
                </div>
            )
        }

        return (
            <div></div>
        )
    }
}

export default Pagination;
