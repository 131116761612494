import React, { useEffect, useState } from 'react';
import {connect, useDispatch} from 'react-redux'
import styles from './NewRecipeTags.module.css'
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck"
import { Button, Spinner } from 'react-bootstrap';
import { displayAlert } from '../../redux/actions/notif';
import Pagination from '../../components/Pagination/Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import * as recipeTagApis from "../../apis/recipeTag";
import * as s from "../../constants/scope";

const RecipeTagInfr = () => {

    const location = useLocation()
    const id = location.state.id
    const name = location.state.name
    
    const loadRecipeTags = async (page) => {
        setIsRecipeTagLoading(true)
        const param =  Object.assign({}, {name}, {page: page - 1, size: recipePaginationState.numberOfElements})

        try {
            const response = await recipeTagApis.getAllRecipeTags(param)
            const recipeTags = response.data?.data?.data[0].recipeTagRecipes

            setRecipePaginationState(
                {   
                    ...recipePaginationState,
                    totalElements : response.data.data.totalElements,
                    totalPages : response.data.data.totalPages,
                    currentPage : page,
                    first : (page === 1),
                    last : (page === response.data.data.totalPages),
                    loadingData : false,
                }
            )

            let recipeOptions = [];
            if (Array.isArray(recipeTags) && recipeTags.length) {
                recipeTags.forEach(recipeTag => {
                    recipeOptions.push({ value: recipeTag.id, label: recipeTag.recipe?.name, priority: recipeTag.priority });
                })
            }

            setRecipes(recipeOptions)

        } catch(e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        } finally {
            setIsRecipeTagLoading(false)
        }
    }


    function initialPaginationState (goToPage, numberOfElements) {
        return {
            loadingData: false,
            currentPage: 0,
            totalPages: 0,
            first: false,
            last: false,
            numberOfElements,
            totalElements: 0,
            goToPage,
            custom: false,
            currentPagePagination: 0
        };
    }

    const [recipes, setRecipes] = useState([])
    const [isRecipeTagLoading, setIsRecipeTagLoading] = useState(false)
    const [recipePaginationState, setRecipePaginationState] = useState(initialPaginationState(loadRecipeTags, 10))

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        loadRecipeTags(1)
    }, [])

    return <PageAuthCheck allowedScopes={[s.MANAGE_RECIPE]}>
        {(isRecipeTagLoading) ? <div className={styles.overlaySpinner}><Spinner animation="border" size="lg" /></div>: <></>}
        <div className="body-div">
            <div className="internal-body-div">
                <div className={styles.recipeTagInfo}>
                    <h4>Recipe Tag Information</h4>
                    <div className='d-flex'>
                        <Button variant="primary" onClick={e => history.push("/recipe/tags/update", {id: id, name})}>Edit</Button>
                        <Button onClick={() => history.push('/recipe/tags')} className={styles.customButton} variant="secondary">Back</Button>
                    </div>
                </div>
                <div className='d-flex' style={{alignItems: "center", marginBottom: 16}}>
                    <p>Recipe Tag Name:</p>
                    <input style={{marginLeft: 24,padding: 4, width: "30%"}} disabled={true} value={name} size="lg"/>
                </div>

                <>
                    <table style={{width:"100%", marginTop: 0, marginBottom: 8}}>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Recipe</th>
                                <th>Priority</th>
                            </tr>
                        </thead>
                        <tbody >
                            {recipes.map((data, index) => {
                                const displayedNumber = index+1+((recipePaginationState.currentPage-1) * recipePaginationState.numberOfElements)
                                return <tr key={index} >
                                    <td style={{width:"20%"}}>{displayedNumber}</td>
                                    <td>{data.label}</td>
                                    <td>{data.priority}</td>
                                </tr>
                            })}
                        </tbody>
                    </table> 
                    <p hidden={recipes.length > 0} className='text-center'>There is No Recipes on This Recipe Tag</p>

                    <Pagination {...recipePaginationState}/> 
                </> 
        </div>
    </div>
    </PageAuthCheck>
}

export function DisplayJson({ jsonString }) {
    try {
      const jsonObject = JSON.parse(jsonString);
      
      return <div>
        <>{"{"}</><br/>
        {Object.keys(jsonObject).map((key) => 
            <>
                <>&nbsp;&nbsp;{`"${key}": `}</>
                {jsonObject[key].includes("https") ? <a href={jsonObject[key]}>{jsonObject[key]}</a> : <>{`${jsonObject[key]}`},</>}
                <br/>
            </>
        )}
       
        <>{"}"}</>
    </div>
    } catch (error) {
      return <div></div>;
    }
  }

export default connect()(RecipeTagInfr)