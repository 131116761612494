import customAxios from "./axios";
import { FRONTEND_CATEGORY_GROUP_V1 } from "../constants/api";

export function getAll() {
    return customAxios.get(FRONTEND_CATEGORY_GROUP_V1);
}

export function getAllPagination(page, pageSize) {
    return customAxios.get(FRONTEND_CATEGORY_GROUP_V1 + '/paginate', {params : 
        {
            page: page-1,
            pageSize: pageSize
        }
    });
}

export function getByName(name, page, pageSize) {
    return customAxios.get(FRONTEND_CATEGORY_GROUP_V1 + '/name', {params : 
        {
            name: name,
            page: page-1,
            pageSize: pageSize
        }
    });
}

export function getById(id) {
    return customAxios.get(FRONTEND_CATEGORY_GROUP_V1 + "/" + id);
}

export function getLogs(page, pageSize) {
    return customAxios.get(FRONTEND_CATEGORY_GROUP_V1 + "/logs", {params :
        {
            page: page-1,
            pageSize: pageSize
        }
    })
}

export function getByFrontendCategoryId(fcId){
    return customAxios.get(FRONTEND_CATEGORY_GROUP_V1 + "/frontend-category/" + fcId);
}

export function create(body) {
    return customAxios.post(FRONTEND_CATEGORY_GROUP_V1, body);
}

export function update(id, body) {
    return customAxios.patch(FRONTEND_CATEGORY_GROUP_V1 + "/" + id, body);
}

export function deleteOne(id) {
    return customAxios.delete(FRONTEND_CATEGORY_GROUP_V1 + "/" + id);
}
