import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import GitInfo from "react-git-info/macro";
import {history} from "./utils/history";

export default async function initializeSentry() {
    try {
        const gitInfo = GitInfo();
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
                new BrowserTracing({
                    tracingOrigins: ["localhost", "local.segari.id:8080", "api-v2.segari.id", /^\//],
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                }),
                new Sentry.Replay({
                    blockAllMedia: false,
                    maskAllInputs: false,
                    maskAllText: false,
                }),
            ],
            release: gitInfo.commit.hash,
            beforeSend: addTagsToEvent,
            beforeSendTransaction: addTagsToEvent
        });
    } catch (e) {
        console.error(e);
    }
}

function addTagsToEvent(event) {
    if (!event.tags) event.tags = {};
    const activeTransaction = Sentry.getActiveTransaction()
    const phoneNumber = localStorage.getItem('phoneNumber');
    event.tags.phoneNumber = phoneNumber;
    if (activeTransaction && activeTransaction.traceId) {
        event.tags.traceId = activeTransaction.traceId
    }

    return event;
}