export const CODE_REFERRAL_FREEBIE_ATL = "REFERRAL_FRUIT_ATL";
export const CODE_REFERRAL_FREEBIE_REG_OR_SUB = "REFERRAL_FRUIT_REG_AND_SUB";
export const CODE_NEW_AGENT_FREE_MINYAK = "NEW_AGENT_FREE_OIL";

export function codeFreebieOptions() {
    return [
        { value: CODE_REFERRAL_FREEBIE_ATL, label: `${CODE_REFERRAL_FREEBIE_ATL}` },
        { value: CODE_REFERRAL_FREEBIE_REG_OR_SUB, label: `${CODE_REFERRAL_FREEBIE_REG_OR_SUB}` },
        { value: CODE_NEW_AGENT_FREE_MINYAK, label: `${CODE_NEW_AGENT_FREE_MINYAK}` }
    ]
}