import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as manpowerApi from "../../../../apis/ManpowerDatabase";

const BulkUploadModal = (props) => {
    const { title, isShown, toggleModal, csvUrl, submit, ErrorList, keepModal } = props;

    const [isSubmitting, setSubmitting] = useState(false);
    const [bulkCreateAndUpdateErrors, setBulkCreateAndUpdateErrors] = useState({});


    const processData = async (values) => {
        setSubmitting(true);
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            formData.append(key, values[key])
        });
        setBulkCreateAndUpdateErrors(await submit(formData));
        setSubmitting(false);
        if (!keepModal) {
            toggleModal(false);
        }
    }

    return (
        <Modal size='lg' show={isShown} onHide={() => {toggleModal(false)}} >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Formik onSubmit={processData} initialValues={{}}>
                    {
                        (formikProps) => {
                            const { setFieldValue, values } = formikProps;
                            return (
                                <Form>
                                    <div className={'form-group'}>
                                        <label>CSV File</label>
                                        <input name='csv' type='file' onChange={e => setFieldValue('csv', e.currentTarget.files[0])} className={'form-control'} />
                                        <div className="form-text text-muted">CSV example can be viewed <a href={`${csvUrl}`} target="_blank">here</a></div>
                                    </div>
                                    <div className={'form-group'}>
                                        <button type={'submit'} className={'btn btn-primary'} disabled={isSubmitting}>{isSubmitting && <Spinner animation="border" variant="light" size="sm" />} Upload</button>
                                    </div>
                                </Form>
                            );
                        }
                    }
                </Formik>
                <div className="overflow-auto">
                    <ErrorList bulkCreateAndUpdateErrors={bulkCreateAndUpdateErrors}/>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default BulkUploadModal;