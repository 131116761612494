import React from "react";

const BorderedBox = (props)=>{
    const width = props.width ? props.width : 50;
    const bgColor = props.bgColor ? props.bgColor : '#E6E6E6';
    const color = props.textColor ? props.textColor : "#000000";
    const borderColor = props.borderColor ? props.borderColor : bgColor;
    const action = props.action ? props.action : ()=>{};

    return(
        <div style={{width: `${width}%`, fontSize: "1em", border: `2px solid ${borderColor}`, borderRadius: "5px"
            ,paddingLeft: "5px", backgroundColor: `${bgColor}`, color: `${color}`}} onClick={()=>{action()}}>
            {props.content}
        </div>
    );
}

export default BorderedBox;