import customAxios from "./axios";
import {ROLES_V1, ROLES_V1_ID, CREATE_ROLES_V1, DELETE_ROLES_V1, ROLE_BY_DIRECTORIES_V1, ROLE_DIRECTORIES_V1} from "../constants/api";

export function getRoles({params}){
    return customAxios.get(ROLES_V1,{params})
}

export function getRoleDetail({id,params}){
    return customAxios.get(ROLES_V1_ID({id}),{params})
}

export function updateRoles({id,data}){
    return customAxios.patch(ROLES_V1_ID({id}),data)
}

export function createNewRole(params){
    return customAxios.post(CREATE_ROLES_V1,params)
}

export function deleteRole(roleId){
    return customAxios.delete(DELETE_ROLES_V1({roleId}))
}

export function roleGroupedByDirectories(){
    return customAxios.get(ROLE_BY_DIRECTORIES_V1)
}

export function getRoleDirectories(){
    return customAxios.get(ROLE_DIRECTORIES_V1)
}