import React from 'react';
import {Modal} from "react-bootstrap";

const BulkErrorModal = (props)=>{
    if (props.bulkErrors == null) {
        return <></>
    }
    return <Modal show={props.showModal} backdrop="static" size='xl'>
        <Modal.Header>
            <Modal.Title>
                Error List Csv
            </Modal.Title>
            <button type="button" class="close" onClick={() => {props.setShowModal(false)}}>
                <span>&times;</span>
            </button>
        </Modal.Header>
        <Modal.Body style={{overflowX: 'scroll'}}>
            <table className={"table w-100"}>
                <thead className={"thead"}>
                    <th scope={"col"}>Row No</th>
                    <th scope={"col"}>General Error</th>
                    <th scope={"col"}>Action</th>
                    <th scope={"col"}>User ID</th>
                    <th scope={"col"}>Nomor Induk Karyawan</th>
                    <th scope={"col"}>Name</th>
                    <th scope={"col"}>Email</th>
                    <th scope={"col"}>Kontrak Kerja</th>
                    <th scope={"col"}>Username</th>
                    <th scope={"col"}>Password</th>
                    <th scope={"col"}>Status Internal Akun</th>
                    <th scope={"col"}>Catatan Internal</th>
                    <th scope={"col"}>Role</th>
                    <th scope={"col"}>Home Office</th>
                    <th scope={"col"}>Directory</th>
                </thead>
                <tbody>
                    {/*Do loop in here*/}
                    {props.bulkErrors?.map((item)=>{
                        return (
                            <tr>
                                <td>{item.row}</td>
                                <td>{item.errors.generalError}</td>
                                <td>{item.errors.action}</td>
                                <td>{item.errors.userId}</td>
                                <td>{item.errors.employeeId}</td>
                                <td>{item.errors.name}</td>
                                <td>{item.errors.email}</td>
                                <td>{item.errors.contractType}</td>
                                <td>{item.errors.username}</td>
                                <td>{item.errors.password}</td>
                                <td>{item.errors.accountStatus}</td>
                                <td>{item.errors.internalNotes}</td>
                                <td>{item.errors.roles}</td>
                                <td>{item.errors.homeOfficeId}</td>
                                <td>{item.errors.directoryId}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
    </Modal>
}


export default BulkErrorModal;
