import {
    ROLES_V1,
    USERS_V1,
    USER_BY_ID_V1,
    AGENT_DASHBOARD_ACCESS_V1,
    USER_USER_ROLE_DROPDOWN,
    USER_ROLE_SCOPE_DROPDOWN,
    DEACTIVATE_USER,
    ACTIVATE_USER,
    USER_DEVICES_DROPDOWN_V1,
    CREATE_INTERNAL_USER_V1,
    INTERNAL_USER_V1,
    UPDATE_INTERNAL_USER_V1,
    USER_SDD_COURIER_FEE_RULE_CONFIG_V1,
    BULK_INTERNAL_USER_V1, USERS_PROFILE
} from "../constants/api";
import customAxios from "./axios";
import * as qs from "query-string";

export function createUser({data}){
    return customAxios.post(USERS_V1,data)
}

export function getUsers({params}){
    return customAxios.get(USERS_V1,{
        params, 
        paramsSerializer: params => {
            return qs.stringify(params, {
                arrayFormat: 'comma',
            })
        },
    })
}

export function searchEmployeeId(query, {params}){
    return customAxios.get(`${USERS_V1}/search-employeeid/${query}`,{params})
}

export function getUserById({id,params}){
    return customAxios.get(USER_BY_ID_V1(id),{params})
}

export function updateUserById({id,data}){
    return customAxios.patch(USER_BY_ID_V1(id),data)
}

export function getRoles({params}){
    return customAxios.get(ROLES_V1,{params})
}

export function getAgentDashboardAccess({agentId, params}) {
    return customAxios.get(AGENT_DASHBOARD_ACCESS_V1(agentId),{params})
}

export function getUsersByUserRoleDropdown({params}){
    return customAxios.get(USER_USER_ROLE_DROPDOWN,{params})
}

export function getUsersByRoleScope({params}){
    return customAxios.get(USER_ROLE_SCOPE_DROPDOWN,{params})
}
export function deactivateUser({id, data}){
    return customAxios.post(DEACTIVATE_USER(id), data)
}

export function activateUser({id, data}){
    return customAxios.post(ACTIVATE_USER(id), data)
}

export function getUserDevicesDropdown({params}){
    return customAxios.get(USER_DEVICES_DROPDOWN_V1, {params});
}

export function getAllInternalUser(){
    return customAxios.get(INTERNAL_USER_V1);
}

export function getAllInternalUserV24(){
    return customAxios.get(`${INTERNAL_USER_V1}/v24`);
}

export function createInternalUser(request){
    return customAxios.post(CREATE_INTERNAL_USER_V1, request);
}

export function updateInternalUser(request) {
    return customAxios.put(UPDATE_INTERNAL_USER_V1, request);
}

export function bulkInternalUser({data}){
    return customAxios.post(BULK_INTERNAL_USER_V1, data);
}

export function getSddCourierFeeRuleConfigUser(){
    return customAxios.get(USER_SDD_COURIER_FEE_RULE_CONFIG_V1)
}

export function getProfileData(id){
    return customAxios.get(USERS_PROFILE, id);
}

export function getInternalUserByUserType(type){
    return customAxios.get(`${INTERNAL_USER_V1}/${type}`)
}