import React from 'react';
import {displayAlert} from "../../redux/actions/notif";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck"
import * as apiReferral from "../../apis/referral";
import * as styles from "./ManageReferralAndProgramBannerPage.module.css"
import CreateAndEditBannerModal from './EditBannerModal';

class ManageReferralBannerPage extends React.Component {

    state = {
        isLoading: false,
        banners: [],
        showCreateAndEditBannerModal: false,
        isNewData: true,
        bannerId:'',
        isSubmitting: false,
    }

    componentDidMount = async () => {
        this.setState({
            isLoading: true
        });

        const responseBanners = (await apiReferral.getAllReferralBanners());

        this.setState({
            banners: responseBanners.data.data,
            isLoading: false
        });
    }

    renderNavBar() {
        return <div>
            <h5>Manage Referral Banners</h5>
        </div>
    }

    renderTable = () => {
        return <div className="container">
            <div className="row">
                <div className="col-12">
                    <table className='table_banner table-bordered'>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Preview</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.banners.map((b, idx) => (
                            <tr key={idx}>
                                <td>{b.id}</td>
                                <td>
                                    <img
                                        src={b.imgUrl}
                                        alt={'No Image Uploaded'}
                                        className={styles.imagePreview}
                                    />
                                </td>
                                <td>
                                    {this.renderActionButton(b)}
                                </td>
                            </tr>))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }

    renderActionButton = (banner) => {
        let actions = [];
        actions.push(
            <button type="button" className='btn btn-primary btn-block' disabled={this.state.isSubmitting} onClick={() => this.onClickShowCreateAndEditBannerModal(banner.id)}>Edit</button>
        );
        if (banner.id !== 'REFERRAL_MAIN_BANNER' && banner.id !== 'REFERRAL_POPUP_LANDING_PAGE_BANNER' && banner.imgUrl)
        actions.push(
            <button type="button" className='btn btn-danger btn-block' disabled={this.state.isSubmitting} onClick={() => this.deleteBanner(banner)}>Delete</button>
        );

        return actions;
    }

    deleteBanner = async (banner) => {
        this.setState({
            isSubmitting: true
        });
        try{
            const response = await apiReferral.deleteReferralBanner({id: banner.id});
            this.props.displayAlert({message: 'Success', type: 'success'});
            this.refreshBanners();
        } catch(e){
            this.props.displayAlert({message: e.message, type:'error'});
        }

        this.setState({
            isSubmitting: false
        });
    }

    refreshBanners = async () => {
        this.setState({
            isLoading: true
        });

        try{
            const res = (await apiReferral.getAllReferralBanners());
            this.setState({
                banners: res.data.data
            })
        }catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error'});
        }

        this.setState({
            isLoading: false
        });
    }

    renderBody() {
        return <div>
            {this.renderTable()}
            <CreateAndEditBannerModal
                show={this.state.showCreateAndEditBannerModal}
                onClose={this.onCloseShowCreateAndEditBannerModal}
                bannerId={this.state.bannerId}
            />
        </div>
    }

    onClickShowCreateAndEditBannerModal = (bannerId) => {
        this.setState({
            showCreateAndEditBannerModal: true,
            bannerId: bannerId
        });
    }

    onCloseShowCreateAndEditBannerModal = () => {
        this.refreshBanners();
        this.setState({
            showCreateAndEditBannerModal: false,
            bannerId: '',
        });
    }

    render() {
        return <PageAuthCheck skipPermission={true}>
            <div className="body-div">
                <div className={'container'}>
                    {this.renderNavBar()}
                    {this.renderBody()}
                </div>
            </div>
        </PageAuthCheck>
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({message,type}) => displayAlert({message,type})
    },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(ManageReferralBannerPage)