import React from 'react'
import {Link, Redirect, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import * as authApi from "../../apis/auth";
import * as auth from "../../redux/actions/auth";
import * as notif from "../../redux/actions/notif";
import PropTypes from 'prop-types'
import styles from './AuthCheck.module.scss'
import {
    NEXT_DAY_COURIER,
    OPS_LOGISTIC_DRIVER,
    SAME_DAY_COURIER,
    SDD_TRIAL_RIDER,
    SDD_AUTO_ASSIGN_RIDER,
    VENDOR_IDX_DELIVERY,
    VENDOR_RARA_DELIVERY
} from '../../constants/role';



class AuthCheck extends React.PureComponent {
    state = {
        attemptedLogin: false
    }

    async componentDidMount() {
        const {dispatch} = this.props
        const {attemptedLogin} = this.state
        if (!attemptedLogin){
            await this.doLogin(dispatch)
        }
    }

    notAllowedMessage = () => {
        const {user, allowedRoles, allowedScopes} = this.props
        if (!!user){
            return <div>
                <h5>Anda tidak berhak mengakses halaman ini</h5>
                <p>Jika anda merasa berhak mengakses halaman ini, silakan hubungi tim produk dengan mengcopy paste informasi berikut</p>
                <pre>
                    User ID: {user.id}<br/>
                    User Email: {user.email}<br/>
                    User Phone: {user.phone}<br/>
                    Roles: {user.roles.map((d) => (d.id)).join(', ')}<br/>
                    Scopes: {user.scopes.map((d) => (d.id)).join(', ')}<br/>
                    Required Roles: {(allowedRoles || []).join(', ')}<br/>
                    Required Scopes: {(allowedScopes || []).join(', ')}<br/>
                </pre>
            </div>
        } else {
            return <h5>Anda tidak berhak mengakses halaman ini</h5>
        }
    }

    notLoginItoolsAccountMessage = () => {
        return <div>
                <h5>Anda tidak berhak mengakses halaman ini</h5>
                <p>Harap login dengan akun itools</p>
                <span><Link to={'/logout'}>Logout</Link></span>
            </div>
    }

    render(){
        const {user, location, allowedRoles, noMessage, allowedScopes, showUser} = this.props
        const returnUrl = location.pathname
        if (!this.state.attemptedLogin){
            return <div>Loading Auth...</div>
        } else if (!user){
            return <Redirect to={"/login?redirect="+returnUrl}/>
        } else if (user?.roles === null || user?.roles?.length === 0) {
            return <div>
                {this.notLoginItoolsAccountMessage()}
            </div>
            
        } else if (!this.allowed(user, location.pathname, allowedRoles, allowedScopes)){
            return <div>
                {noMessage ? '' : this.notAllowedMessage()}
            </div>
        } else if (this.allowedCourierDashboardOnly(user) && returnUrl !== "/couriers/dashboard"){
            return <Redirect to={"/couriers/dashboard"}/>
        }else if (this.allowed3plVendorOnly(user) && returnUrl !== "/orders/order-delivery"){
            return <Redirect to={"/orders/order-delivery"}/>
        } else {
            if (showUser){
                return <div className={styles.container}>
                    <div className={styles.navbar}>
                        <span><Link to={'/help'}>/help</Link></span>
                        <span>
                            <span>You are logged in as {this.identity(user)}, {this.roles(user)}</span>
                            <span className={'ml-5'}><Link to={'/logout'}>Logout</Link></span>
                        </span>
                    </div>
                    <div className={styles.content}>
                        {this.props.children}
                    </div>
                </div>
            } else {
                return this.props.children
            }
        }
    }

    identity = (user) => {
        if (!!user.name) return user.name
        if (!!user.email) return user.email
        if (!!user.username) return user.username
        return user.phone
    }

    roles = (user) => {
        // Filter out duplicate roles
        const removeDuplicates = (arr, idKey) => {
            if (!arr) return [];
            const uniqueIds = new Set();
            return arr.filter(obj => {
                if (!uniqueIds.has(obj[idKey])) {
                    uniqueIds.add(obj[idKey]);
                    return true;
                }
                return false;
            });
        }
        const cleanRoles = removeDuplicates(user.roles, 'id')

        return (cleanRoles || []).map(r=>r.name).join()
    }

    allowed = (user, url, allowedRoles, allowedScopes) => {
        if (this.props.skipPermission){
            return true
        }
        if (user.roles.length){
            for(let i in user.roles){
                const role = user.roles[i]
                if (role.id === 'admin') return true;
                if (allowedRoles && allowedRoles.includes(role.id)) return true;
            }
        }
        if ((user.scopes || []).length){
            for(let i in user.scopes){
                const scope = user.scopes[i]
                if (allowedScopes && allowedScopes.includes(scope.id)) return true;
            }
        }
        return false
    }

    allowedCourierDashboardOnly = (user) => {
        if (user.roles.length === 0) return false;
        for (const role of user.roles){
            if (
                role.id !== OPS_LOGISTIC_DRIVER
                && role.id !== SAME_DAY_COURIER
                && role.id !== NEXT_DAY_COURIER
                && role.id !== SDD_TRIAL_RIDER
                && role.id !== SDD_AUTO_ASSIGN_RIDER
            ){
                return false;
            }
        }
        return true;
    }

    allowed3plVendorOnly = (user) => {
        if (user.roles.length === 1){
            if (user.roles[0].id === VENDOR_IDX_DELIVERY) return true;
            if (user.roles[0].id === VENDOR_RARA_DELIVERY) return true;
        }
        return false;
    }

    doLogin = async (dispatch) => {
        try {
            let user = undefined
            if (this.props.cached){
                user = this.props.user
            }
            if (!user){
                const response = await authApi.me()
                user = response.data.data
            }
            dispatch(auth.setUser({user}))
        } catch (e) {
            dispatch(notif.displayAlert({message: e.message, type:'error'}))
            
            if (e.response?.status === 401) {
                // if response code is AUTH-0003(token expired) or AUTH-0006(user deactivated), log out user
                if (e.response?.code === "AUTH-0003" || e.response?.code === "AUTH-0006") {
                    dispatch(auth.setUser({user: null}))
                }
            }
        } finally {
            this.setState({
                attemptedLogin: true
            })
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

AuthCheck.propTypes = {
    //determine to check local storage first prior to api call
    cached: PropTypes.bool,
    //if noMessage = true, hide the inside content. otherwise, show 'Not Allowed'
    noMessage: PropTypes.bool,
    allowedRoles: PropTypes.array,
    allowedScopes: PropTypes.array,
    //if this is true, we will skip permission check of the user
    //this is intended if we want to only load user data
    skipPermission: PropTypes.bool,
    showUser: PropTypes.bool
}

AuthCheck.defaultProps = {
    cached: false,
    noMessage: false,
    allowedRoles: [],
    allowedScopes: []
}

export default withRouter(connect(mapStateToProps)(AuthCheck))

