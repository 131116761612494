import React from "react";
import BorderedBox from "../../Components/BorderedBox";
import {Spinner} from "react-bootstrap";

const UploadFileComponent = (props)=>{
    const BorderedBoxContent = (props)=>{
        return(
            <>
                <input className="form-control-file"
                    type="file"
                    name="csv"
                    accept=".csv"
                    onClick={e => e.target.value = ''}
                    onChange={e => {
                        props.setFile(e.target.files[0])
                    }}
                />
            </>
        );
    }

    return(
        <div className={"w-50"}>
            <BorderedBox bgColor={"#FFFFFF"} borderColor={"#E6E6E6"} content={BorderedBoxContent({"setFile": props.setFile})} width={"100%"}/>
            <div className={"d-flex mt-2 justify-content-end"}>
                {/*On action tembak request*/}
                <button type="button" className={"btn btn-sm"} style={{backgroundColor:"#DA0063", color: "#FFFFFF", width: "40%"}}
                        onClick={()=>{props.sendBulkRequest()}} disabled={props.isSubmitting}>
                    {props.isSubmitting && <Spinner animation="border" variant="dark" size="sm"/>}
                    Execute
                </button>
            </div>
        </div>
    );
}

export default UploadFileComponent;