import customAxios from "./axios";
import {
    FRONTEND_SUBCATEGORY_V1,
    FRONTEND_SUBCATEGORY_DETAIL_V1,
    FRONTEND_SUBCATEGORY_SEO_V1,
    FRONTEND_SUBCATEGORY_RESET_SEO_V1,
    FRONTEND_SUBCATEGORY_LOGS_V1
} from "../constants/api";

export function getAll() {
    return customAxios.get(FRONTEND_SUBCATEGORY_V1);
}

export function getById({params}) {
    return customAxios.get(FRONTEND_SUBCATEGORY_V1, {params});
}

export function updateFrontendSubCategoriesSeo(body) {
    return customAxios.put(FRONTEND_SUBCATEGORY_SEO_V1, body);
}

export function getAllWithProductTagsAndFrontendCategories() {
    return customAxios.get(FRONTEND_SUBCATEGORY_DETAIL_V1);
}

export function getByIdWithProductTagsAndFrontendCategories(id) {
    return customAxios.get(FRONTEND_SUBCATEGORY_DETAIL_V1 + '/id/' + id);
}

export function getByNameWithProductTagsAndFrontendCategories(name) {
    return customAxios.get(FRONTEND_SUBCATEGORY_DETAIL_V1) + '/name/' + name;
}

export function create(data) {
    return customAxios.post(FRONTEND_SUBCATEGORY_V1, data);
}

export function update(id, data) {
    return customAxios.put(FRONTEND_SUBCATEGORY_V1 + '/' + id, data);
}

export function deletes(id) {
    return customAxios.delete(FRONTEND_SUBCATEGORY_V1 + '/' + id);
}

export function resetFrontendSubCategorySeoById(id){
    return customAxios.put(FRONTEND_SUBCATEGORY_RESET_SEO_V1(id))
}

export function getFrontendSubcategoriesLogs(params) {
    return customAxios.get(FRONTEND_SUBCATEGORY_LOGS_V1, {params});
}