import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";

function UploadCSVButton(props) {
    const {isMultiple = false, onChange = (files) => {}, isLoading = false, csvFile} = props;
    const [uploadedFileName, setUploadedFileName] = useState(null);
    const inputRef = useRef(null);

    const handleUpload = (e) => {
        e.preventDefault();
        const clicked = inputRef.current?.click();
        return;
    };


    const handleInputChange = () => {
        const files = inputRef?.current?.files;
        if (files && files.length > 0){
            let fileDisplay = '';
            if (isMultiple){
                fileDisplay = files.map(file => file?.name).filter(name => !!name).join(', ');
            } else {
                const file = files[0];
                if (file) fileDisplay = file?.name ?? '';
            }
            setUploadedFileName(fileDisplay);
            onChange(files);
        }
    };

    return (
        <div>
            <input
                ref={inputRef}
                onChange={handleInputChange}
                className="d-none"
                type="file"
            />
            <Button
                disabled={isLoading}
                variant="outline-success"
                onClick={handleUpload}
            >
                Upload CSV
            </Button>
            {!!csvFile && !!uploadedFileName && <label className="ml-2 font-weight-bold">{uploadedFileName}</label>}
        </div>
    );
}

export default UploadCSVButton;