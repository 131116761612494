export function getLabelFromValue(value, enumOptions) {
  if (!enumOptions) { return null; }
  const matchingOption = enumOptions.find(option => option.value === value);
  return matchingOption ? matchingOption.label : 'Label Not Found';
}

export function getOptionsFromValue(value, enumOptions) {
  if (!enumOptions) { return null; }
  const matchingOption = enumOptions.find(option => option.value === value);
  return matchingOption ? matchingOption : null;
}

const CREATE = "CREATE";
const VIEW = "VIEW";
const DELETE = "DELETE";
const UPDATE = "UPDATE";

export const ACTION = { CREATE, VIEW, DELETE, UPDATE };
export const MODAL_TITLE = {
  [ACTION.CREATE]: "Create Warehouse Tag Group",
  [ACTION.UPDATE]: "Update Warehouse Tag Group",
  [ACTION.VIEW]: "",
};

export const INITIAL_FORM = {
  id: null,
  name: "",
  whTagIds: [],
};
