const { OPEN_LEFT_NAVBAR, CLOSE_LEFT_NAVBAR } = require("../actions/kulaLeftNavbar");

/**
 * Please register the proper icon in the MenuRow.js
 */
const menuRows = {
    "help": {
        "label": "Home",
        "link": "/help",
        "icon": "home",
        "priority": 1
    },

    "membership": {
        "label": "Membership",
        "icon": "membership",
        "priority": 2,
        "subMenuItems": [{
                "label": "Member List",
                "link": "/pos/membership",
                "icon": "membership",
                "priority": 1
            },{
                "label": "Benefits",
                "link": "/pos/membership/benefit",
                "icon": "membership",
                "priority": 2
            },
        ]
    },
    "cash-mutation": {
        "label": "Kas Masuk & Keluar",
        "link": "/pos/cash-mutation",
        "icon": "cash-mutation",
        "priority": 3
    },
}

const initialState = {
    isOpen: false,
    menuRows: menuRows,
};

const kulaLeftNavbar = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_LEFT_NAVBAR:
            return {
                ...state,
                isOpen: true,
            };
        case CLOSE_LEFT_NAVBAR:
            return {
                ...state,
                isOpen: false,
            };
        default:
            return state;
    }
}

export default kulaLeftNavbar;