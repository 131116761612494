import { generateTitleCase } from "../utils/Formatter";
import { ON_DEMAND, SCHEDULED } from "./deliveryTimeType";
import {
    EXTENSION,
    INSTAN,
    PRIORITY_30,
    PRIORITY_45, PRIORITY_60, PRIORITY_75,
    TURBO
} from './sameDayDeliveryVariant';

export const NEXT_DAY_DELIVERY = 'NEXT_DAY_DELIVERY';
export const SAME_DAY_DELIVERY = 'SAME_DAY_DELIVERY';

export function isValidDeliveryServiceType(input) {
    return (input === NEXT_DAY_DELIVERY) || (input === SAME_DAY_DELIVERY);
}

export function getAliasDeliveryServiceType(type) {
    return type === SAME_DAY_DELIVERY ? "SDD" : "NDD";
}

export const convertToDeliveryServiceType = (deliveryServiceType) => {
    if (deliveryServiceType === NEXT_DAY_DELIVERY) {
        return NEXT_DAY_DELIVERY;
    }
    return SAME_DAY_DELIVERY;
}

export const convertToFormattedDeliveryServiceType = (deliveryServiceType) => {
    const formattedDeliveryServiceType = {
        [NEXT_DAY_DELIVERY]: 'Next Day Delivery',
        [SAME_DAY_DELIVERY]: 'Same Day Delivery'
    };

    return formattedDeliveryServiceType[deliveryServiceType] || '-';
}

export const deliveryServiceTypeOptions = [
    { value: { serviceType: null, timeType: null, sddVariant: null }, label: 'Semua' },
    { value: { serviceType: NEXT_DAY_DELIVERY, timeType: null, sddVariant: null }, label: 'Next Day Delivery' },
    { value: { serviceType: SAME_DAY_DELIVERY, timeType: null, sddVariant: null }, label: 'Same Day Delivery (Semua)' },
    { value: { serviceType: NEXT_DAY_DELIVERY, timeType: SCHEDULED, sddVariant: null }, label: 'NDDS' },
    { value: { serviceType: SAME_DAY_DELIVERY, timeType: SCHEDULED, sddVariant: null }, label: 'SDDS' },
    { value: { serviceType: SAME_DAY_DELIVERY, timeType: ON_DEMAND, sddVariant: null }, label: 'SDDO' },
    { value: { serviceType: SAME_DAY_DELIVERY, timeType: ON_DEMAND, sddVariant: PRIORITY_30 }, label: generateTitleCase(PRIORITY_30) },
    { value: { serviceType: SAME_DAY_DELIVERY, timeType: ON_DEMAND, sddVariant: PRIORITY_45 }, label: generateTitleCase(PRIORITY_45) },
    { value: { serviceType: SAME_DAY_DELIVERY, timeType: ON_DEMAND, sddVariant: PRIORITY_60 }, label: generateTitleCase(PRIORITY_60) },
    { value: { serviceType: SAME_DAY_DELIVERY, timeType: ON_DEMAND, sddVariant: PRIORITY_75 }, label: generateTitleCase(PRIORITY_75) },
    { value: { serviceType: null, timeType: SCHEDULED, sddVariant: EXTENSION }, label: 'Segari Extension' },
];