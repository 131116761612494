import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import styles from "./LoaderModal.module.css";
import logo from "../../icons/logo.png";

/**
 * Returns LoadingModalV2
 *
 * @param {Boolean} centered - Set True to make the modal centered.
 * @param {Boolean} show - Set True to show the modal and false to hide it.
 * @param {String} title - Give the title of the loading modal.
 * @param {String} content - Give the message of the loading modal.
 * @param {blinkMessage} blinkMessage - Set true to make the message have blinking effect.
 * @return {React.Component}
 */

const LoaderModalV2 = ({
  centered = true,
  show = false,
  title,
  content,
  blinkMessage = false,
}) => {
  title = !!title ? title : "Please Wait";
  content = !!content ? content : "Processing the data...";

  return (
    <Modal animation={false} centered={centered} show={show} className={styles.loaderModal}>
      <Modal.Body className={styles.loaderModalBody}>
        <img src={logo} alt="Logo" height={25} className="logo-image" />
        <div className="text-center">
          <Spinner animation="border" role="status" variant="primary" />
          {title && <h5 className={styles.loaderModalTitle}>{title}</h5>}
          {content && (
            <div
              className={`${styles.loaderModalMessage} ${
                blinkMessage ? styles.blinking : ""
              }`}
            >
              {content}
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoaderModalV2;
