import {Form} from "react-bootstrap";
import Select from "react-select";
import React, {memo} from "react";
import {CREATE_PURCHASE_ORDER_METHOD, CREATE_PURCHASE_ORDER_METHOD_MAP} from "../../../../constants/purchaseOrder";

const CREATE_METHOD_OPTIONS = [
    {
        label: CREATE_PURCHASE_ORDER_METHOD_MAP.SINGLE,
        value: CREATE_PURCHASE_ORDER_METHOD.SINGLE
    },
    {
        label: CREATE_PURCHASE_ORDER_METHOD_MAP.MULTIPLE,
        value: CREATE_PURCHASE_ORDER_METHOD.MULTIPLE
    },
]

function MethodCard(props) {
    const {createMethod, setCreateMethod} = props;
    const selectedOption = CREATE_METHOD_OPTIONS.find(e => e.value === createMethod);

    return <div className='internal-body-div mb-5'>
        <h5>Metode</h5>
        <hr/>
        <div>
            <Form.Group>
                <Form.Text className="text-muted mb-3">
                    Pilih metode “Single PO” untuk upload satuan; “Bulk Multiple PO” untuk upload banyak sekaligus.
                </Form.Text>
                <Form.Label>
                    Metode
                </Form.Label>
                <div style={{maxWidth: '300px'}}>
                    <Select
                        name='method'
                        value={selectedOption}
                        placeholder={'Pick Method'}
                        options={CREATE_METHOD_OPTIONS}
                        components={{IndicatorSeparator: () => null}}
                        onChange={(selected) => setCreateMethod(selected.value)}
                    />
                </div>
            </Form.Group>
        </div>
    </div>
}

export default memo(MethodCard);