import AuthCheck from "./AuthCheck";
import React from "react";
import PropTypes from "prop-types";

/**
 * Use this as a "root" container of a page
 * Behaviour:
 * - not cached (if user privilege changes, it will be immediately applied)
 * - show message (if user permission is denied, show 'not authorized' message
 * @param props
 * @returns {*}
 * @constructor
 */
const PageAuthCheck = (props) => {
    const { children, ...rest } = props
    return <AuthCheck showUser={props.showUser ?? true} {...rest}>
        {children}
    </AuthCheck>
}

export default PageAuthCheck

PageAuthCheck.propTypes = {
    allowedRoles: PropTypes.array,
    allowedScopes: PropTypes.array,
    showUser: PropTypes.bool
}