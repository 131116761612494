import React from "react";
import SelectFormik from "../SelectFormik/SelectFormik";
import * as warehousesApi from "../../apis/warehouses";
import { Typeahead } from 'react-bootstrap-typeahead';
import { displayAlert } from "../../redux/actions/notif";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class SelectWarehouse extends React.Component {
    state = {
        warehouses: []
    };

    componentDidMount = async() => {
        try {
            const response = await warehousesApi.getWarehouses({sort: 'id,asc'});
            let warehouses = [];

            warehouses.push({
                label: this.props.defaultValue !== undefined ? this.props.defaultValue : 'All',
                value: ''
            });
            response.data.data.forEach((warehouse) => {
                warehouses.push({
                    id: warehouse.id,
                    label: warehouse.id + " - " + warehouse.name,
                    value: warehouse.id
                });
            });
            this.setState({
                warehouses: warehouses
            });
        } catch(e) {
            this.props.displayAlert({type: 'error', message: 'Error while fetching warehouses: ' + e});
        }
    };

    render() {
        const { className, name, value, onChange, disabled, multiple = false, typeahead, placeholder } = this.props;

        if (multiple || typeahead) {
            return <Typeahead
                id={name}
                maxResults={20}
                onChange={(selected) => {
                    onChange(selected)
                }}
                options={this.state.warehouses}
                multiple={multiple}
                placeholder={placeholder}
                clearButton={true}
            >
            </Typeahead>
        }

        return <SelectFormik
            className={className}
            name={name}
            onChange={onChange}
            value={value}
            options={this.state.warehouses}
            disabled={disabled}
        />;
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({ message, type }) => displayAlert({ message, type })
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps) (SelectWarehouse);