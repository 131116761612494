import React from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import AuthCheck from "../../components/AuthCheck/AuthCheck";
import TableBodyResponse from "../../components/TableBodyResponse";
import { CHECK_LOCATION_COVERED_BY_SHIPPING_POINT } from "../../constants/scope";
import * as apiShippingPoints from "../../apis/shippingPoints";
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";
import MapsPoint from "../../components/Maps/MapsPoint";
import MapsDeliveryArea from "../../components/Maps/MapsDeliveryArea"

class CheckLocationCoveredByShippingPoint extends React.Component {
    render() {
        return <PageAuthCheck allowedScopes={[CHECK_LOCATION_COVERED_BY_SHIPPING_POINT]}>
            <div>
                <Title />
                <Body />
            </div>
        </PageAuthCheck>
    }

    componentDidMount() {
        document.title = "Check Location Covers By Shipping Point";
    }
}

function Title() {
    return <div className="title-div"><h1 className="title-text">Check Location Covers By Shipping Point</h1></div>;
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            useMap: true,
            longitude: 106.7541634,
            latitude: -6.1958759,
            response: "",
        }
    }

    handleChange(fieldName, value) {
        this.setState({
            [fieldName]: value
        })
    }

    handleNumberChange(fieldName, value) {
        if (!value) return
        this.setState({
            [fieldName]: parseFloat(value)
        })
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ isSubmitting: true });

        try {
            const params = { latitude: this.state.latitude, longitude: this.state.longitude }

            const response = await apiShippingPoints.getShippingPointsCovering({ ...params });
            this.setState({
                isSubmitting: false,
                response: response.data,
            });
        } catch (error) {
            this.setState({
                isSubmitting: false,
                response: JSON.stringify(error),
            })
        }
    }

    deliveryAreaComponent = (deliveryArea, componentId) => {
        if (!deliveryArea) { return ""; }

        return <MapsDeliveryArea
            id={componentId}
            fullscreenControl={true}
            style={{ width: '400px', height: '300px' }}
            zoom={14}
            viewOnly={true}
            showSearchBar={false}
            deliveryAreaCoordinates={deliveryArea.coordinates[0]}
            onLoad={(val) => { }}
            onChange={(val) => { }}
        />
    }

    getInputField() {
        if (this.state.useMap) {
            return <Form.Group>
                <MapsPoint
                    id="mapsPoint"
                    fullscreenControl={false}
                    style={{ width: '100%', height: '550px' }}
                    zoom={14}
                    onLoad={(val) => { }}
                    fallbackLatlng={{ "lat": this.state.latitude, "lng": this.state.longitude }}
                    onChange={(lat, lng) => {
                        this.handleChange("latitude", lat); this.handleChange("longitude", lng);
                    }}
                />
            </Form.Group>
        }
        return <Form.Group>
            <Form.Label column sm='2'>Longitude :</Form.Label>
            <Col sm='10'>
                <Form.Control className="form-ctr"
                    value={this.state.longitude}
                    type="number"
                    onChange={e => this.handleNumberChange("longitude", e.target.value)} />
            </Col>
            <Form.Label column sm='2'>Latitude :</Form.Label>
            <Col sm='10'>
                <Form.Control className="form-ctr"
                    value={this.state.latitude}
                    type="number"
                    onChange={e => this.handleNumberChange("latitude", e.target.value)} />
            </Col>
        </Form.Group>
    }

    render() {
        let titleRow = [];

        let contentRows = [];
        if (this.state.response) {
            if (this.state.response.data) {
                titleRow = [
                    "ID", "Name", "Coverage", "DocumentId", "Warehouse Name", "Point Map Delivery Area"
                ];
                this.state.response.data.forEach((row) => {
                    let contentRow = [
                        row.id,
                        row.name,
                        row.coverage,
                        row.documentId,
                        row.warehouse.name,
                        this.deliveryAreaComponent(row.deliveryArea, row.id),
                    ];

                    contentRows.push(contentRow);
                });
            }
        }
        return <div className="body-div">
            <div className="internal-body-div">
                <Form >
                    <Form.Text className="text-muted">
                        Check a Map Point In Any Shipping Point
                    </Form.Text>
                    <Form.Group as={Row} className="form-prop">
                        <Col sm='12'>
                            <div className="form-ctr">
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    style={{ margin: "auto" }}
                                    checked={this.state.useMap}
                                    onChange={e => this.handleChange("useMap", e.target.checked)}
                                    label="Use Map"
                                />
                            </div>
                            <Form.Text className="text-muted">
                            </Form.Text>
                        </Col>
                    </Form.Group>
                    {this.getInputField()}
                    <Button onClick={(e) => { this.handleSubmit(e) }}
                        variant="primary">
                        Get Shipping Point Covering
                    </Button>
                </Form>
            </div>
            <TableBodyResponse
                isSubmitting={this.state.isSubmitting & this.state.response}
                titleRow={titleRow}
                contentRows={contentRows}
            />
        </div>;
    }
}

export default CheckLocationCoveredByShippingPoint;