import a from 'axios'
import { getDeviceUuid } from "../utils/deviceUuid";

const customAxios =  a.create({
    withCredentials: true
})

customAxios.interceptors.request.use(function (config){
    config.headers['X-Segari-Platform'] = 'web-internal-tools';
    config.headers['X-Segari-Device-Id'] = getDeviceUuid();
    return config;
});

customAxios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {

    console.log(error);

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (!!error.response && !!error.response.data && !!error.response.data.message){
        error.message = error.response.data.message
        error.code = error.response.data.code
    }
    return Promise.reject(error);
});

export default customAxios