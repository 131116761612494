import {
    SHOW_POPUP_CHECKER,
    SHOW_POPUP_CREATE_OUTBOUND,
    SHOW_POPUP_OPEN_PDF
} from "../constants/warehouseTransfer";

const initialState = {
    showPopupChecker: false,
    showPopupCreateOutbound: false,
    showPopupOpenPDF: false
}

export default (state = initialState, action) => {
    const {type, payload} = action

    switch (type) {
        case SHOW_POPUP_CHECKER:
            return {...state, showPopupChecker: payload.showPopupChecker}
        case SHOW_POPUP_CREATE_OUTBOUND:
            return {...state, showPopupCreateOutbound: payload.showPopupCreateOutbound}
        case SHOW_POPUP_OPEN_PDF:
            return {...state, showPopupOpenPDF: payload.showPopupOpenPDF}
        default: {
            return state
        }
    }
}