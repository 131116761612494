import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from "react-bootstrap";
import styles from "./style.module.scss";
import { displayAlert } from '../../../../redux/actions/notif.js';
import * as apiProductTag from "../../../../apis/productTags.js";
import * as warehouseTagGroupApi from "../../../../apis/warehouseTagGroup.js";
import * as shipmentApi from "../../../../apis/shipment.js";
import ShipmentSearchBar from './ShipmentSearchBar.js';
import ShipmentTable from './ShipmentTable.js';
import ShipmentListPage from './ShipmentListPage.js';
import ShipmentDetailPage from './ShipmentDetailPage.js';
import ShipmentDetailWarehouseGroupConfigurationModal from './ShipmentDetailWarehouseGroupConfigurationModal.js';

const _ = require('lodash');
const ShipmentPage = (props) => {
    const [warehouseTagGroupData, setWarehouseTagGroupData] = useState([]);
    const [shipmentInbounds, setShipmentInbounds] = useState([]);
    const [warehouseGroupFunctionalityEnumOptions, setWarehouseGroupFunctionalityEnumOptions] = useState([]);
    const [selectedShipmentId, setSelectedShipmentId] = useState(null);
    const [selectedShipmentName, setSelectedShipmentName] = useState(null);

    useEffect(() => {
        const fetchWarehouseGroupTag = async () => {
            try {
                warehouseTagGroupApi.getAll().then((result) => {
                    setWarehouseTagGroupData(result?.data?.data)
                }).catch(err => {
                    throw err;
                });
            } catch (e) {
                this.props.displayAlert({ message: e.message, type: "error" });
            }
        }
        
        setWarehouseTagGroupData([]);
        if (warehouseTagGroupData.length === 0) {
            fetchWarehouseGroupTag();
        }
    }, []);

    return (selectedShipmentId != null) ?
            <ShipmentDetailPage
                displayAlert={props.displayAlert}
                setSelectedShipmentId={setSelectedShipmentId}
                selectedShipmentId={selectedShipmentId}
                selectedShipmentName={selectedShipmentName}
            />
        :
            <ShipmentListPage
                displayAlert={props.displayAlert}
                setSelectedShipmentId={setSelectedShipmentId}
                setSelectedShipmentName={setSelectedShipmentName}
            />
}


export default ShipmentPage;