import React from "react";
import JsonBodyForm from "../../components/JsonBodyForm";
import * as recipeTagApis from "../../apis/recipeTag";
import {displayAlert} from "../../redux/actions/notif";
import {connect} from 'react-redux'
import { MANAGE_RECIPE } from '../../constants/scope';
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";
import styles from './NewRecipeTags.module.css'
import { Button, Modal, Spinner } from "react-bootstrap";
import RecipeTagTable from "./RecipeTagTable";
import * as recipeApis from '../../apis/recipe';

class CreateRecipeTagsPage extends React.Component {
    render() {
        return <PageAuthCheck allowedScopes={[MANAGE_RECIPE]}><div>
            <Body
                dispatch={this.props.dispatch}
                history={this.props.history}
            />
        </div></PageAuthCheck>
    }

    componentDidMount() {
        document.title = "Create Recipe Tag";
    }
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipes: [],
            name: "",
            response: "",
            recipeTags: [],
            recipeIds: [{ recipeId: -1, label: "Select...", priority: 10000 }],
            isSubmitFailed: false,
            showModal: false,
            loadingText: "",
            isLoading: false,
            isSubmitting: false,
        }
    }

    async componentDidMount() {
        const { dispatch } = this.props;
        this.setState({
            isLoading: true,
            loadingText: "Getting Recipe Options..."
        })
        try {
            const recipesResponse = await recipeApis.getAllRecipes({
                params: {
                    size: 10_000
                }
            })
            const recipes = recipesResponse?.data?.data?.data;
            let recipeOptions = [];
            if (Array.isArray(recipes) && recipes.length) {
                recipes.forEach(recipe => {
                        recipeOptions.push({ recipeId: recipe.id, label: recipe.name });
                })
            }
            this.setState({
                recipes: recipeOptions,
            });
        } catch(e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        } finally {
            this.setState({
                isLoading: false,
            })
        }
    }

    handleChangeJsonForm(fieldName, value) {
        this.setState({
            [fieldName]: value
        })
    }

    handleChange = (value, index, fieldName) => {
        const newRows = [...this.state.recipeIds];
        newRows[index][fieldName] = fieldName == "recipeId" ? parseInt(value) : value;

        this.setState({ 
            recipeIds: newRows,
        });
      };
    
    addRow = () => {
        this.setState({ 
            recipeIds: [...this.state.recipeIds, { recipeId: -1, label: "Select...", priority: 10000 }],
        });

    };

    removeRecipeItem = (index) => {
        let newData = [...this.state.recipeIds];
        newData.splice(index, 1);
        this.setState({
            recipeIds: [...newData]
        })
    }
    

    handleSubmit = async (e) => {
        const { dispatch } = this.props;
        e.preventDefault();
        e.stopPropagation();
        let data = {};
        if (this.state.name) {
            data.name = this.state.name;
        }

        if (Array.isArray(this.state.recipeIds) && this.state.recipeIds.length) {
            let recipeTag = [];
            this.state.recipeIds.forEach(data => {
                const {recipeId, priority} = data
                recipeTag.push({recipeId, priority});
            });
            data.recipeTagRecipes = recipeTag
        }


        this.setState({ isSubmitting: true, loadingText: "Submitting..." });
        try {
            const response =  await recipeTagApis.create(data);
            dispatch(displayAlert({ message: `Succeed Create New Recipe Tag ${this.state.name}`, type: 'success' }));
            const recipeTagId = response.data.data.id;
            this.props.history.push(
                "/recipe/tags/info", 
                { 
                    id: recipeTagId,
                    name: this.state.name,
                }
            )
        } catch(e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
            this.setState({
                isSubmitFailed: true
            })
        } finally {
            this.setState({ isSubmitting: false });
        }
    };

    render() {

        let rowInfos = [
            {
                fieldName: "name",
                label: "Name",
                infoText: "All Recipe Tags must have unique name",
            },
        ];

        return <div className="body-div">
            <Modal show={this.state.showModal} className={'d-flex align-items-center'}>   
                <Modal.Body>
                    <p className={'d-flex justify-content-center'}>Are you sure want to Cancel create new Recipe Tag ?</p>
                    <div className={styles.centeredButtonGroup}>
                        <Button 
                            size='sm' 
                            className={'mr-4 pl-4 pr-4'} 
                            variant="primary" type='submit'
                            onClick={ () => {
                                this.props.history.goBack()
                                this.setState({
                                    showModal: false
                                })
                            }}
                        >OK</Button>    
                        <Button 
                            className={'pl-4 pr-4'} 
                            size='sm'
                            variant="danger" 
                            onClick={e =>  this.setState({showModal:false})}
                        >Cancel</Button>    
                    </div>
                </Modal.Body>
            </Modal>
        { this.state.isSubmitting || this.state.isLoading ? <div className={styles.overlaySpinner}>
            <Spinner animation="border" size="lg" />
            <p className='ml-2'>{this.state.loadingText}</p>
        </div>: <></>}
        <div className="internal-body-div">
            <div className={`title-div ${styles.recipeTagFormHeader}`}>
                <h4>Create New Recipe Tag</h4>
                <div className="d-flex">
                    <Button onClick={(e) => this.handleSubmit(e)} className={styles.customButton} variant="primary">Submit</Button>
                    <Button 
                        onClick={()=>{this.setState({
                            showModal: true
                        })}} 
                        className={styles.customButton} 
                        variant="secondary"
                    >Cancel</Button>
                </div>
            </div>
                <JsonBodyForm
                    onRowChange={(fn, v) => this.handleChangeJsonForm(fn, v)}
                    rowInfos={rowInfos}
                    disableSubmit={true}
                />
            
           
                <RecipeTagTable 
                    addRow={this.addRow}
                    recipeIds={this.state.recipeIds}
                    recipes={this.state.recipes}
                    selectedOptions={this.state.selectedOptions}
                    handleChange={this.handleChange}
                    removeRecipeItem={this.removeRecipeItem}
                    isSubmitFailed={this.state.isSubmitFailed}
                />
            </div>
        </div>;
    }
}

export default connect()(CreateRecipeTagsPage);