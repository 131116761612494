import React from "react";
import { Nav, Navbar } from "react-bootstrap";
import { withRouter } from "react-router";
import logo from '../../../../icons/logo_v4.png';
import styles from './WarehouseTransferNavigationSidebar.module.css'
import _ from 'lodash';
import { classNameJoin } from "../../Utils/stylingUtils";

const Side = props => {
    const { activeLink, links, linksWithSubheaders, onClick, hideCollapseToggle } = props;
    return (
        <>
            <Navbar defaultExpanded expand="xs" style={{ width: '16vw',height: '100%', backgroundColor: 'white', borderRight: "2px solid lightgrey" }} className={classNameJoin(["d-block", styles.sidebar])}>
                {!hideCollapseToggle && <Navbar.Toggle aria-controls="responsive-navbar-nav" />}
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav
                        activeKey={activeLink}
                        variant="pills"
                    >
                        <div className="sidebar-sticky"></div>
                        <div className={styles.linkStyle}>
                            <Nav.Item>
                                <Nav.Link  className={styles.logo} href="/help">
                                    <img className={styles.logoStyle} src={logo} alt="Logo" />
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className={styles.logoSubtitle}>
                                    WAREHOUSE TRANSFER
                                </Nav.Link>
                            </Nav.Item>
                            <div className={styles.spacer}/>
                            {
                                !_.isEmpty(links) && links.map(e =>
                                    <Nav.Item>
                                        {
                                            <Nav.Link
                                                className={activeLink === e.link ? styles.navLinkStyleActive : styles.navLinkStyle}
                                                active={activeLink === e.link}
                                                href={_.isNil(onClick) ? e.link : '#'}
                                                onClick={_.isNil(onClick) ? null : () => onClick(e.link, e.label)}>
                                                {e.icon}&nbsp;&nbsp;{e.label}
                                            </Nav.Link>
                                        }
                                    </Nav.Item>
                                )
                            }
                        </div>
                        <div className={styles.linkStyle}>
                            {
                                !_.isEmpty(linksWithSubheaders) && linksWithSubheaders.map(header =>
                                    <Nav.Item key={header.label}>
                                        <h5>{header.label}</h5>
                                        {
                                            !_.isEmpty(header.links) && header.links.map(headerLink =>
                                                <Nav.Link
                                                    className={activeLink === headerLink.link ? styles.navLinkStyleActive : styles.navLinkStyle}
                                                    active={activeLink === headerLink.link}
                                                    key={headerLink.link}
                                                    href={_.isNil(onClick) ? headerLink.link : '#'}
                                                    onClick={
                                                        _.isNil(onClick) ?
                                                            null
                                                            :
                                                            () => onClick(headerLink.link, headerLink.label)}>
                                                    {headerLink.icon}&nbsp;&nbsp;{headerLink.label}
                                                </Nav.Link>)
                                        }
                                    </Nav.Item>
                                )
                            }
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
};

const WarehouseTransferNavigationSidebar = withRouter(Side);
export default WarehouseTransferNavigationSidebar;