export function mapOptions({products}) {
    let productOpts = [];
    if (!!products) {
        products.map((item) => {
            productOpts.push({
                value: item.id,
                label: `${item.skuId} - ${item.name}`
            });
        });
    }
    return {productOpts};
}

export function mapPromoLabelOptions({promoLabels}) {
    let promoLabelOpts = [];
    if (!!promoLabels) {
        promoLabels.map((item) => {
            promoLabelOpts.push({
                value: item.id,
                label: `${item.name}`
            });
        });
    }
    return {promoLabelOpts};
}
