import customAxios from "./axios";
import {
    SUPPLIERS_V1,
    SUPPLIERS_V1_ID,
    SUPPLIERS_DOWNLOAD_V1,
} from "../constants/api";
import fileDownload from "js-file-download";

export function getSuppliers({...params} = {}) {
    return customAxios.get(SUPPLIERS_V1, {params: params});
}

export function createSupplier(data) {
    return customAxios.post(SUPPLIERS_V1, data);
}

export function updateSupplier({id, data}) {
    return customAxios.put(SUPPLIERS_V1_ID({id: id}), data);
}

export function downloadSupplierCsv() {
    return customAxios.get(SUPPLIERS_DOWNLOAD_V1).then((response) => {
        fileDownload(response.data, `Suppliers-${new Date().toLocaleString()}.csv`);
    });
}