import React, { useState } from 'react';
import styles from "./style.module.scss";
import './style.module.scss'
import * as shipmentApi from "../../../../apis/shipment.js";
import Select from "react-select";
import { HiOutlineDownload } from "react-icons/hi";
import fileDownload from 'js-file-download';
import { DATE_TIME_FORMAT, TIMEZONE } from '../../../../constants/time.js';
import moment from 'moment';

const _ = require('lodash');
const ShipmentOrderPicklistSearchAndDownloadBar = (props) => {
    const { fetchShipmentOrderPicklist, selectedShipmentOrderId, selectedShipmentName, warehouses } = props;
    const warehouseOptions = !_.isEmpty(warehouses) ? warehouses.map(e => ({value: e.id, label: `[${e.documentId}] ${e.name}`})) : [];
    const [ selectedWarehouseId, setSelectedWarehouseId ] = useState(null);

    const downloadCsv = () => {
        const param = {};
        if (selectedWarehouseId != null) {
            param.warehouseId = selectedWarehouseId;
        }

        shipmentApi.exportAndGetPicklistCsv(selectedShipmentOrderId, param).then((response) => {
            fileDownload(response.data, `${selectedShipmentName}__${selectedShipmentOrderId}__${moment().tz(TIMEZONE).format(DATE_TIME_FORMAT)}.csv`)
        })
    }

    return (
        <div className='d-flex justify-content-end pt-3'>
            <div style={{width: '30%'}}>
                <Select
                    onChange={(v, a) => {
                        fetchShipmentOrderPicklist(v?.value);

                        setSelectedWarehouseId(v?.value);
                    }}
                    isMulti={false}
                    isClearable={true}
                    options={warehouseOptions}
                    placeholder={"Pilih warehouse..."}
                    styles={{
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                    }}
                />
            </div>

            <button
                className={[styles.btnOutlineOk, 'btn ml-3'].join(' ')} 
                type='button'
                onClick={() => {
                    downloadCsv();
                }}
            >
                <HiOutlineDownload/>
            </button>

        </div>
    )
}

export default ShipmentOrderPicklistSearchAndDownloadBar;