import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Form, Formik, useFormikContext } from "formik";
import { displayAlert } from "../../../../redux/actions/notif";

import * as apiPurchaseOrder from "../../../../apis/purchaseOrders";
import UploadCard from "./UploadCard";
import { Button } from "react-bootstrap";
import LoaderModalV2 from "../../../../components/LoaderModalV2/LoaderModalV2";

function MultiplePOCreate(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [csvFile, setCsvFile] = useState(null);
  const [verified, setVerified] = useState(false);
  const [fileLogs, setFileLogs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMessage, setLoadMessage] = useState(null);

  useEffect(() => {
    document.title = "Bulk Multiple PO - Segari Internal";
  });

  const onSubmit = async () => {
    const formData = new FormData();
    formData.append("csvFile", csvFile);

    try {
      setIsLoading(true);
      setLoadMessage("Creating Purchase Order in Bulk...");
      const result = await apiPurchaseOrder.bulkCreateV2({ data: formData });
      if (result.status === 200) {
        let purchaseOrderIds = "PO ID: ";
        const purchaseOrders = result?.data?.data;
        if (!!purchaseOrders) {
          for (const po of purchaseOrders) {
            purchaseOrderIds += `${po.id}, `;
          }
          purchaseOrderIds = purchaseOrderIds.substring(
            0,
            purchaseOrderIds.length - 2
          );
        }

        dispatch(
          displayAlert({
            type: "success",
            duration: 10000,
            message: `${result.data.data.length} PO berhasil dibuat! ${purchaseOrderIds}`,
          })
        );
        const delay = new Promise((resolve) => setTimeout(resolve, 1000));
        await delay;
        return history?.push("/purchase-orders/list");
      }
    } catch (e) {
      dispatch(displayAlert({ message: e.message, type: "error" }));
    } finally {
      setIsLoading(false);
      setLoadMessage(null);
    }
  };

  const onVerify = async () => {
    const formData = new FormData();
    formData.append("csvFile", csvFile);

    try {
      setIsLoading(true);
      setLoadMessage("Verifying Purchase Orders CSV...");
      const { data } = await apiPurchaseOrder.verifyBulkCreateV2({
        data: formData,
      });
      const result = data.data;
      if (!result.verified) {
        setCsvFile(null);
        setFileLogs(result.fileLogs);
      }
      setVerified(result.verified);
    } catch (e) {
      dispatch(displayAlert({ message: e.message, type: "error" }));
    } finally {
      setIsLoading(false);
      setLoadMessage(null);
    }
  };

  return (
    <div className="internal-body-div">
      <LoaderModalV2
        content={loadMessage}
        blinkMessage={true}
        show={isLoading}
      />
      <UploadCard
        csvFile={csvFile}
        setCsvFile={setCsvFile}
        verified={verified}
        fileLogs={fileLogs}
        isLoading={isLoading}
        setFileLogs={setFileLogs}
        setVerified={setVerified}
      />
      <SubmitButton
        onSubmit={onSubmit}
        onVerify={onVerify}
        verified={verified}
        csvFile={csvFile}
        isLoading={isLoading}
      />
    </div>
  );
}

const SubmitButton = (props) => {
  const { onSubmit, onVerify, verified, csvFile, isLoading } = props;

  return (
    <div className="mt-3">
      <Button
        className="mr-2"
        onClick={onVerify}
        disabled={!csvFile || verified || isLoading}
        variant="primary"
      >
        Verify PO
      </Button>
      <Button
        onClick={onSubmit}
        disabled={!verified || isLoading}
        variant="success"
      >
        Buat PO
      </Button>
    </div>
  );
};

export default memo(MultiplePOCreate);
