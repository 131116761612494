import React from 'react';
import styles from './CategoryDisplayGroupPage.module.css'
import ReactSelectForm from '../../components/ReactSelectForm/ReactSelectForm';
import { PlusLg, Trash, ArrowsMove } from "react-bootstrap-icons";
import _ from "lodash"
import { MIN_CATEGORY_ITEM_WITHOUT_THEMATIC, MIN_CATEGORY_ITEM_WITH_THEMATIC, THEMATIC_CATEGORY_COUNT } from '../../constants/thematicCategory';
import { Field } from 'formik';
class CDGDragNDropItem extends React.PureComponent {

    deleteFromValues = (values, id) => {
        let key = "categoryOptions_" + id;
        if (values[key]) delete values[key]
        return values;
    }

    render() {
        let { 
            setFieldValue, 
            values, 
            onClickDelete, 
            onClickAdd, 
            data, 
            allData, 
            optionData, 
            initialValues, 
            removeSelectedItemFromOptions, 
            dragHandleProps,
            isThematicItem,
            isThematicActive,
            isBackup,
            hidePlusAndDeleteButton
        } = this.props;

        let valueCategoryOptions = [];
        const options = optionData;
        let key = (isBackup ? "backupOptions_" : "categoryOptions_") + data.id;

        options.forEach(item => {
            if( values[key] && values[key] == item.value){
                valueCategoryOptions[data.id] = item
            }
            else if ( !values[key] ) {
                if( initialValues && initialValues[key] == item.value){
                    valueCategoryOptions[data.id] = item
                }
            }
        })

        return (
            <div className={` border ${styles.dnd_item_row_style}`}>
                <div className='px-2 pt-1 border-right mr-2 w-25'> {isBackup ? "Backup Category" : isThematicItem && isThematicActive ? data.priority - (allData.length - THEMATIC_CATEGORY_COUNT) : data.priority}</div>
                <div className='mr-2 w-100'>
                    {hidePlusAndDeleteButton ? 
                        <Field 
                            fieldName={(isBackup ? "backupOptions_" : "categoryOptions_") + data.id}
                            value={valueCategoryOptions[data.id].label}
                            className={`${styles.field_style}`}
                        />
                        :
                        <ReactSelectForm
                            fieldName={(isBackup ? "backupOptions_" : "categoryOptions_") + data.id}
                            options={options}
                            setFieldValue={setFieldValue}
                            value={valueCategoryOptions[data.id]}
                            onChangeFunction={this.props.removeSelectedItemFromOptions}
                            isDisabled={hidePlusAndDeleteButton}
                        />
                }
                    
                </div>

                {(isThematicItem && isThematicActive) || isBackup || hidePlusAndDeleteButton ? <></> : <><div className='mr-2 mt-1'> <PlusLg onClick={onClickAdd}/></div>
                <div className='mr-2 mt-1'
                    style={{visibility: allData.length > MIN_CATEGORY_ITEM_WITH_THEMATIC || (!isThematicActive && allData.length > MIN_CATEGORY_ITEM_WITHOUT_THEMATIC) || hidePlusAndDeleteButton ? 'visible' : 'hidden'}}
                > <Trash onClick={ () => {
                    onClickDelete(data ,values[key], initialValues[key])
                    values = this.deleteFromValues(values, data.id)
                    }}/>
                </div></>}
                {isBackup ? <></> : <div className='mr-2 mt-1'
                      style={{visibility: allData.length > 1 ? 'visible' : 'hidden'}}
                >
                    <div {...dragHandleProps}>
                        <ArrowsMove/>
                    </div>
                </div>}
            </div>
        );
    }

}

export default CDGDragNDropItem;
