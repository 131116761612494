import axios from "./axios";
import {PROMO_LABELS_CHANGE_ACTIVITY_V1, PROMO_LABELS_V1} from "../constants/api";

export function getAllPromoLabels({params}) {
    return axios.get(PROMO_LABELS_V1,{params});
}

export function createPromoLabel({promoLabel}) {
    return axios.put(PROMO_LABELS_V1, promoLabel);
}

export function changeActivity({data}) {
    return axios.put(PROMO_LABELS_CHANGE_ACTIVITY_V1, data);
}