import React, { useEffect, useState } from "react";
import NavigationSidebar from "../../../components/NavigationSidebar/NavigationSidebar";
import { bindActionCreators } from "redux";
import { displayAlert } from "../../../redux/actions/notif";
import { connect } from "react-redux";
import { ACTIVE_LINKS, LINKS_WITH_SUBHEADERS } from "./navigation-link";
import PageAuthCheck from "../../../components/AuthCheck/PageAuthCheck";
import PageWithSideNavbar from "../../../components/PageWithSideNavbar/PageWithSideNavbar";
import { GENERAL_ACCESS } from "../../../constants/role";
import Employment from "./Employment";
import RoleList from "./RoleList";
import Personal from "./Personal";
import * as workplaceDepartmentApi from "../../../apis/workplaceDepartment";
import * as warehouseApi from "../../../apis/warehouses";
import * as userApi from "../../../apis/users";
import * as warehouseWorkplaceApi from "../../../apis/warehouseWorkplaceDepartment";
import "./style.css";
import * as WD_TYPE from './Constant/workplace_department';
import { MANPOWER_VIEW, MANPOWER_WHITELIST, MANPOWER_WRITE_UPDATE } from "../../../constants/scope";

const ManpowerDatabase = (props) => {
    const WAREHOUSE_USER = "WAREHOUSE";
    const { displayAlert } = props;
    const [activeLink, setActiveLink] = useState(props.location?.pathname);
    const [workplaceDepartments, setWorkplaceDepartments] = useState([]);
    const [users, setUsers] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [warehouseMap, setWarehouseMap] = useState(new Map());
    const [wdNameToWdIdMap, setwdNameToWdIdMap] = useState(new Map());
    const [workplaceDepartmentMap, setWorkplaceDepartmentMap] = useState(new Map());
    const [warehouseWorkplaceDepartmentRelationMap, setWarehouseWorkplaceRelationMap] = useState(new Map());

    useEffect(() => {
        const initializePage = async () => {
            const workplaceDepartmentsResponse = await workplaceDepartmentApi.getAllWorkplaceDepartment();
            const warehouseResponse = await warehouseApi.getWarehouses();
            const userResponse = await userApi.getInternalUserByUserType(WAREHOUSE_USER);
            const warehouseWorkplaceDepartmentResponse = await warehouseWorkplaceApi.getAllWarehouseWorkplaceDepartment();

            const newWhMap = new Map();
            warehouseResponse.data.data.forEach((wh) => {
                newWhMap.set(wh.id, wh);
            });

            const newWdNameToWdIdMap = new Map();
            const newWorkplaceDepartmentMap = new Map();

            workplaceDepartmentsResponse.data.data.forEach((wd) => {
                newWdNameToWdIdMap.set(wd.value, wd.id);
                newWorkplaceDepartmentMap.set(wd.id, wd);
            });

            const newWarehouseWorkplaceDepartmentMap = new Map();
            warehouseWorkplaceDepartmentResponse.data.data.forEach(
                (wwd) => {
                    let mapValue = new Map(); // Temp Name
                    mapValue = newWarehouseWorkplaceDepartmentMap.get(wwd.warehouseId);
                    if (!mapValue || mapValue === undefined) {
                        mapValue = new Map();
                    }
                    mapValue.set(wwd.workplaceDepartmentId, wwd);
                    newWarehouseWorkplaceDepartmentMap.set(wwd.warehouseId, mapValue);
                }
            );

            setWarehouseMap(newWhMap);
            setwdNameToWdIdMap(newWdNameToWdIdMap);
            setWorkplaceDepartmentMap(newWorkplaceDepartmentMap);
            setWarehouses(warehouseResponse.data.data);
            setUsers(userResponse.data.data);
            setWorkplaceDepartments(workplaceDepartmentsResponse.data.data);
            setWarehouseWorkplaceRelationMap(newWarehouseWorkplaceDepartmentMap);
        }
        initializePage();
    }, []);

    const changePage = (link, title) => {
        window.history.replaceState(null, title, link);
        setActiveLink(link);
    }

    const navBar = <NavigationSidebar
        activeLink={activeLink}
        linksWithSubheaders={LINKS_WITH_SUBHEADERS}
        onClick={changePage}
    />

    const manageContent = () => {
        const options = generateOptions();
        let content = <></>;
        if (activeLink === ACTIVE_LINKS.EMPLOYMENT) {
            content = <Employment options={options} displayAlert={displayAlert} warehouseMap={warehouseMap} wdNameToWdIdMap={wdNameToWdIdMap} workplaceDepartmentMap={workplaceDepartmentMap} warehouseWorkplaceDepartmentMap={warehouseWorkplaceDepartmentRelationMap} workplaceDepartments={workplaceDepartments} />;
        } else if (activeLink === ACTIVE_LINKS.PERSONAL) {
            content = <Personal options={options} displayAlert={displayAlert} />;
        } else if (activeLink === ACTIVE_LINKS.ROLES) {
            content = <RoleList workplaceDepartments={workplaceDepartments} warehouses={warehouses} displayAlert={displayAlert} />;
        }
        return content;
    }

    const generateOptions = () => {
        const warehouseOptions = warehouses.map(
            (warehouse) => {
                return { value: `${warehouse.id}`, label: `${warehouse.id} - ${warehouse.name}` }
            }
        );
        let employeeIdOptions = [];
        let userNameOptions = [];
        users.forEach(
            (user) => {
                employeeIdOptions.push({
                    value: `${user.userId}`,
                    label: `${user.employeeId ? user.employeeId : user.id}`
                });
                userNameOptions.push({ value: `${user.userId}`, label: `${user.name ? user.name : user.username}` });
            }
        )

        let statusOptions = [];
        statusOptions.push({
            value: 'active', label: `Active`
        });
        statusOptions.push({
            value: 'inactive', label: `Inactive`
        });
        statusOptions.push({
            value: 'blacklist', label: `Blacklist`
        });

        let employmentOptions = [];
        employmentOptions.push({
            value: `PKWTT`, label: `PKWTT`
        });
        employmentOptions.push({
            value: `PKWT`, label: `PKWT`
        });
        employmentOptions.push({
            value: `PKHL`, label: `PKHL`
        });
        employmentOptions.push({
            value: `OUTSOURCING`, label: `OUTSOURCING`
        });
        employmentOptions.push({
            value: `VENDOR`, label: `VENDOR`
        });

        let divisionOptions = [];
        let tierOptions = [];
        let roleOptions = [];
        let departmentOptions = [];
        let teamOptions = [];
        let positionOptions = [];
        let resignReasonOptions = [];
        workplaceDepartments.forEach((wd) => {
            const type = wd.type ? wd.type : "";
            if (type === WD_TYPE.DIVISION) {
                divisionOptions.push({ value: wd.id, label: wd.value });
            } else if (type === WD_TYPE.TIER) {
                tierOptions.push({ value: wd.id, label: wd.value });
            } else if (type === WD_TYPE.ROLE) {
                roleOptions.push({ value: wd.id, label: wd.value });
            } else if (type === WD_TYPE.DEPARTMENT) {
                departmentOptions.push({ value: wd.id, label: wd.value });
            } else if (type === WD_TYPE.TEAM) {
                teamOptions.push({ value: wd.id, label: wd.value });
            } else if (type === WD_TYPE.POSITION) {
                positionOptions.push({ value: wd.id, label: wd.value });
            } else if (type === WD_TYPE.RESIGN_REASON) {
                resignReasonOptions.push({ value: wd.id, label: wd.value });
            }

        });

        const options = {
            warehouseOptions: warehouseOptions,
            nameOptions: userNameOptions,
            employeeIdOptions: employeeIdOptions,
            statusOptions: statusOptions,
            employmentOptions: employmentOptions,
            divisionOptions: divisionOptions,
            tierOptions: tierOptions,
            roleOptions: roleOptions,
            departmentOptions: departmentOptions,
            teamOptions: teamOptions,
            positionOptions: positionOptions,
            resignReasonOptions: resignReasonOptions
        };
        return options;
    }

    return (
        <PageAuthCheck allowedScopes={[MANPOWER_VIEW, MANPOWER_WRITE_UPDATE, MANPOWER_WHITELIST]}>
            <PageWithSideNavbar navbar={navBar} content={manageContent()} />
        </PageAuthCheck>
    );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({ message, type }) => displayAlert({ message, type })
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ManpowerDatabase);