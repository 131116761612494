import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { displayAlert } from "../../redux/actions/notif";
import Spinner from 'react-bootstrap/Spinner';
import { Formik, Field, Form, FieldArray } from 'formik';
import Modal from 'react-bootstrap/Modal';
import * as apiFreebie from '../../apis/freebieProducts';
import TableBodyResponse from "../../components/TableBodyResponse";
import SelectFormik from "../../components/SelectFormik/SelectFormik";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";
import MainContainer from "../../components/MainContainer/MainContainer";
import { CODE_REFERRAL_FREEBIE_ATL, CODE_REFERRAL_FREEBIE_REG_OR_SUB, 
    codeFreebieOptions } 
from '../../constants/freebieProducts';
import { MANAGE_FREEBIE_PRODUCT } from '../../constants/scope';

const CreateOrUpdateForm = ({values, isSubmitting, dirty, onClose}) => {
    return <Form>
        <b>Create or Update Freebie Product - Code: {values.code}</b>
        <div className='form-group'>
            <label>Code</label>
            <Field name='code' className='form-control' disabled={true}/>
        </div>
        <div className='h6 small mb-3'>to see products sku id, open <a href="/products/list" target="_blank">this link</a></div>
        <div className='form-group'>
            <b>Saved freebie product</b>
            <FieldArray name="freebieProducts" 
                render={ arrayHelpers => (
                    <div>
                        { values.freebieProducts.map((freebieProduct, index) => 
                            freebieProduct.id && <div className="lg-12" key={index}>
                                <label className="mr-1">Sku Id</label>
                                <Field className="mr-2" name={`freebieProducts.${index}.product.skuId`} placeholder="Sku Id"/>
                                <label className="mr-1">Priority</label>
                                <Field className="mr-2" name={`freebieProducts.${index}.priority`} type="number" placeholder="Priority"/>
                            </div> )
                        }
                        <div className='h6 small'>don't add 2 or more same sku id for one code</div>
                    </div>
                )}
            />
            <hr />
            <b>New freebie product</b>
            <FieldArray name="freebieProducts" 
                render={ arrayHelpers => (
                    <div>
                        { values.freebieProducts.map((freebieProduct, index) => 
                            !freebieProduct.id && <div key={index}>
                                <label className="mr-1">Sku Id</label>
                                <Field className="mr-2" name={`freebieProducts.${index}.product.skuId`} placeholder="Sku Id"/>
                                <label className="mr-1">Priority</label>
                                <Field className="mr-2" name={`freebieProducts.${index}.priority`} type="number" placeholder="Priority"/>
                                <button className='btn btn-danger' type="button" onClick={() => arrayHelpers.remove(index)}>Remove</button>
                            </div> )
                        }
                        <div className='h6 small'>don't add 2 or more same sku id for one code</div>
                        <button type="button" className="btn btn-success" onClick={() => arrayHelpers.push({id: null, skuId: 0, priority: 0})}>
                            Add A Freebie Product
                        </button>
                    </div>
                )}
            />
        </div>
        <div className={'d-flex justify-content-end'} style={{width:'100%'}}>
            <button className='btn btn-danger mr-2' type="button" onClick={onClose}>Close</button>
            <button className='btn btn-primary' type='submit' disabled={isSubmitting || !dirty}>Save</button>
        </div>
    </Form>
}

class ManageFreebieProductsPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showCreateUpdateModal: false,
            showDeleteModal: false,
            dataReady: false,
            isLoading: false,
            freebieCode: CODE_REFERRAL_FREEBIE_ATL,
            freebieIdToDelete: {},
            freebieProducts: [],
        }
    }
    
    componentDidMount = async() => {
        await this.getByCode();
    }

    initialFormValue() {
        return {
            code: this.state.freebieCode,
            freebieProducts: this.state.freebieProducts
        }
    }

    onChangeCode = (e) => {
        if (!e.target.value) {
            this.setState({ freebieCode: "", dataReady: false });
            return;
        }
        this.setState({ freebieCode: e.target.value, isLoading: true }, this.getByCode );
    }

    getByCode = async () => {
        try {
            const responseApi = await apiFreebie.getFreebieProductsByCode(this.state.freebieCode);
            this.setState({
                freebieProducts: responseApi.data.data
            })
        } catch (e) {
            this.props.displayAlert({message: e.message, type: 'error'});
        }
        this.setState({
            dataReady: true,
            isLoading: false,
            response: ""
        })
    }

    onClickDelete = (id) => {
        this.setState({
            showDeleteModal: true,
            freebieIdToDelete: id
        })
    }
    
    confirmDelete = async () => {
        try {
            const response = await apiFreebie.deleteFreebieProduct(this.state.freebieIdToDelete);
            this.setState({ showDeleteModal: false }, this.getByCode)
            this.props.displayAlert({message: `Success to delete. id: ${response.data.data.id}`, type: 'success'});
        } catch (e) {
            this.props.displayAlert({message: e.message, type: 'error'});
        }
    }

    titleRow = ["id", "code", "product sku id", "priority", "action"]
    getTableContent() {
        const freebieProducts = this.state.freebieProducts
        let rows = [];
        for (let i = 0; i < freebieProducts.length; i++) {
            let removeBtn = <button className='btn btn-danger' type="button" onClick={() => this.onClickDelete(freebieProducts[i].id)}> Remove </button>
            let row = [freebieProducts[i].id, freebieProducts[i].code, freebieProducts[i].product.skuId, freebieProducts[i].priority, removeBtn];
            rows.push(row);
        }
        return rows;
    }

    closeModalCreateUpdate = () => {
        this.setState({
            showCreateUpdateModal: false
        }, this.getByCode)
    }

    render() {
        return <PageAuthCheck allowedScopes={[MANAGE_FREEBIE_PRODUCT]}><MainContainer title='Manage Freebie Products'>
            <ConfirmDeleteModal show={this.state.showDeleteModal}
                message={`Are you sure want to delete freebie product with id ${this.state.freebieIdToDelete}.`}
                onCancel={() => this.setState({ showDeleteModal:false })} 
                onConfirm={() => this.confirmDelete()}/>
            <ModalCreateUpdate show={this.state.showCreateUpdateModal}
                initialValue={this.initialFormValue()}
                onClose={this.closeModalCreateUpdate}
                displayAlert={this.props.displayAlert} />
            <div className="p-1"> Select freebie product code</div>
            { this.state.isLoading? <Spinner animation="border" size="lg" /> : <><SelectFormik
                name={"Code Freebie Product"}
                onChange={this.onChangeCode}
                value={this.state.freebieCode}
                options={codeFreebieOptions()}
            /> <button className='btn btn-primary mt-3' type="button" onClick={() => this.setState({ showCreateUpdateModal: true })}>
                Create or Update Freebie Product
            </button>
            </>}
            <hr/>
            { this.state.dataReady && <TableBodyResponse
                isSubmitting={this.state.isSubmitting}
                titleRow={this.titleRow}
                contentRows={this.getTableContent()}
            /> }
        </MainContainer></PageAuthCheck>
    }
}

class ModalCreateUpdate extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    toRequestBody(values) {
        let body = { code: values.code }
        let details = [];
        for (let i = 0; i < values.freebieProducts.length; i++) {
            let detail = { productSkuId: values.freebieProducts[i].product.skuId, priority: values.freebieProducts[i].priority };
            if (values.freebieProducts[i].id) {
                detail["freebieProductId"] = values.freebieProducts[i].id;
            }
            details.push(detail);
        }
        body["freebieProducts"] = details;
        console.log(body);
        return body;
    }

    submitCreateOrUpdate = async (values, {setSubmitting}) => {
        setSubmitting(true)
        try {
            const bodyInput = this.toRequestBody(values);
            const response = await apiFreebie.createOrUpdateFreebieProducts(bodyInput);
            this.props.displayAlert({message: `Data successfully saved. Total item saved: ${response.data.data.length}`, type: 'success'});
        } catch(e) {
            this.props.displayAlert({message: e.message, type: 'error'});
        }
        setSubmitting(false);
        this.props.onClose();
    }

    render() {
        return <Modal show={this.props.show} size="lg">
            <Modal.Body>
                <Formik
                    initialValues={this.props.initialValue}
                    enableReinitialize={true}
                    onSubmit={this.submitCreateOrUpdate}
                    render={formikProps => (
                        <CreateOrUpdateForm {...formikProps} onClose={this.props.onClose}/>
                    )}
                />
            </Modal.Body>
        </Modal>
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({message, type}) => displayAlert({message, type})
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageFreebieProductsPage);