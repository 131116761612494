import customAxios from "./axios";
import {INVOICE_DELIVERY_NOTIFY, INVOICE_LIST, INVOICE_QUALITY_SURVEY} from "../constants/api";

export function getInvoices({params}){
    return customAxios.get(INVOICE_LIST,{params})
}

export function notifyDelivery({agentId,deliveryDate,allAgents,deliveryServiceType}){
    return customAxios.post(INVOICE_DELIVERY_NOTIFY,{
        agentId,
        deliveryDate,
        allAgents,
        deliveryServiceType
    })
}

export function qualitySurvey({deliveryDate}){
    return customAxios.post(INVOICE_QUALITY_SURVEY,{deliveryDate})
}