import { 
    SPECIAL_PRICE_PROMO_ID_V1, 
    SPECIAL_PRICE_PROMO_UPDATE_ACTIVE_STATUS_V1, 
    SPECIAL_PRICE_PROMO_TYPE_V1
} from "../constants/api";
import { SPECIAL_PRICE_PROMO_TYPE } from "../constants/specialPricePromo";
import customAxios from "./axios";

export function getNegativePriceList() {
    return customAxios.get(SPECIAL_PRICE_PROMO_TYPE_V1(SPECIAL_PRICE_PROMO_TYPE.NEGATIVE_PRICE));
}

export function getNegativePriceDetail(id) {
    return customAxios.get(SPECIAL_PRICE_PROMO_ID_V1(id));
}

export function changeNegativePriceActiveStatus(data) {
    return customAxios.post(SPECIAL_PRICE_PROMO_UPDATE_ACTIVE_STATUS_V1(SPECIAL_PRICE_PROMO_TYPE.NEGATIVE_PRICE), data);
}

export function createOrUpdateNegativePricePromo(data) {
    return customAxios.post(SPECIAL_PRICE_PROMO_TYPE_V1(SPECIAL_PRICE_PROMO_TYPE.NEGATIVE_PRICE), data);
}