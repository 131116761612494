import React, { useState } from 'react';
import { Form, Formik } from "formik";
import ButtonV2 from "../../../../components/ButtonV2/ButtonV2";
import { Modal, Table } from "react-bootstrap";
import "./style.css";
import Select from "react-select";
import * as manpowerApi from "../../../../apis/ManpowerDatabase";
import DatePicker from "react-datepicker";
import ContentWithSideContent from "../Component/ContentWithSideContent";
import BulkUploadModal from "../Component/BulkUploadModal";
import * as employeeRecordApi from "../../../../apis/employeeRecord";
import moment from "moment";
import Pagination from '../../../../components/Pagination/Pagination';
import ConfirmationModal from '../Component/ConfirmationModal';
import * as WD_TYPE from '../Constant/workplace_department';
import MenuAuthCheck from "../../../../components/AuthCheck/MenuAuthCheck";
import { MANPOWER_VIEW, MANPOWER_WHITELIST, MANPOWER_WRITE_UPDATE } from "../../../../constants/scope";
import GeneratePayRollModalComponent from '../Component/GeneratePayrollModal';

const _ = require('lodash');
const Employment = (props) => {
    const SIZE = 100;
    const DATE_FORMAT = 'YYYY-MM-DD';
    const { options, displayAlert, warehouseMap, wdNameToWdIdMap, workplaceDepartmentMap, warehouseWorkplaceDepartmentMap, workplaceDepartments } = props;
    const [formData, setFormData] = useState({
        startDate: null,
        endDate: null,
        employeeIds: [],
        names: "",
        contractTypes: [],
        warehouses: [],
        departments: null,
        roles: null,
        teams: null,
        positions: null,
        tiers: null,
        divisions: null,
        status: "",
    });

    const [hideResult, setHideResult] = useState({
        division: false,
        employment: false,
        tier: false,
        warehouse: false,
        jabatan: false,
        department: false,
        team: false,
        position: false,
        role: false
    });
    const [isSubmit, setIsSubmit] = useState(false);
    const [responseData, setResponseData] = useState([]);

    const [pagination, setPagination] = useState({
        currentPage: 0,
        totalPages: 0,
        numberOfElements: _.max([responseData.length, SIZE]),
        totalElements: 0,
    });

    const [showBulkUploadEmployeeRecordModal, setShowBulkUploadEmployeeRecordModal] = useState(false);
    const [employeeRecordErrors, setEmployeRecordErrors] = useState([]);

    const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);

    const [sideType, setSideType] = useState("");

    const [showSide, setShowSide] = useState(false);
    const [employeeRecords, setEmployeeRecords] = useState([]);
    const [employeeRecordCategory, setEmployeeRecordCategory] = useState("LATEST")
    const [userData, setUserData] = useState(null);
    const [employeeRecordToBeDeleted, setEmployeeRecordToBeDeleted] = useState(null);
    const [showDeleteEmployeeRecordConfirmationModal, setShowDeleteEmployeeRecordConfirmationModal] = useState(false);

    const [selectedTimeFilter, setSelectedTimeFilter] = useState(new Date());
    const [selectedDuration, setSelectedDuration] = useState({ value: -6, label: "6 Bulan Terakhir" });
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [tempUser, setTempUser] = useState(null);
    const [userWarehouse, setUserWarehouse] = useState(null);
    const [updateUserWorkplaceDepartmentOptions, setUpdateUserWorkplaceDepartmentOptions] = useState(new Map());

    // Generate payroll modal state
    const [showGeneratePayrollModal, setShowGeneratePayrollModal] = useState(false);

    const getEmploymentData = async (page, values) => {
        const actualPage = !_.isNil(pagination.totalPages) && pagination.totalPages != 0 ? (page - 1) % pagination.totalPages : (page - 1);
        const offsetPage = actualPage + 1;
        try {
            const requestData = generateRequest(values, actualPage);
            const fetchEmploymentData = await manpowerApi.fetchEmploymentData(requestData);
            setResponseData(fetchEmploymentData.data.data.data);
            let newPagination = {
                currentPage: offsetPage,
                numberOfElements: _.max([responseData.length, SIZE]),
                totalElements: fetchEmploymentData.data.data.totalElements,
                totalPages: fetchEmploymentData.data.data.totalPages,
            };
            setPagination(newPagination);
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' })
        }
    }

    const SearchForm = () => {
        return (
            <Formik className={"d-flex"} initialValues={formData} onSubmit={onSubmit}>
                {(formikProps) =>
                    <Form>
                        <SearchFormInputGroup {...formikProps} options={options} />
                        <ButtonCollections />
                    </Form>
                }
            </Formik>
        );
    }

    const SearchFormInputGroup = (props) => {
        const { values, setFieldValue, options } = props;
        return (
            <div className={"d-flex flex-column"}>
                <div className={"row"}>
                    <div className={'col'}>
                        <label>Start Date</label>
                        <DatePicker
                            selected={values.startDate}
                            onChange={date => { setFieldValue('startDate', date) }}
                            className='form-control'
                            placeholderText='yyyy-mm-dd'
                            dateFormat='yyyy-MM-dd'
                            excludeTimes={true}
                        />
                    </div>
                    <div className={'col'}>
                        <label>End Date</label>
                        <DatePicker
                            selected={values.endDate}
                            onChange={date => { setFieldValue('endDate', date) }}
                            className='form-control'
                            placeholderText='yyyy-mm-dd'
                            dateFormat='yyyy-MM-dd'
                        />
                    </div>
                    <div className={'col'}>
                        <label>Employee Id</label>
                        <Select
                            value={values.employeeIds}
                            options={options.employeeIdOptions}
                            onChange={(selected) => {
                                setFieldValue('employeeIds', selected);
                            }}
                            selected={values.employeeIds}
                            isClearable={true}
                            isMulti={true}
                        />
                    </div>

                    <div className={'col'}>
                        <label>Name</label>
                        <input
                            name='name'
                            className='form-control' 
                            value={values.name}
                            onChange={(value)=>{setFieldValue("name", value.target.value)}}
                        />
                    </div>

                    <div className={'col'}>
                        <label>Department</label>
                        <Select
                            value={values.departments}
                            options={options.departmentOptions}
                            onChange={(selected) => {
                                setFieldValue('departments', selected);
                            }}
                            selected={values.departments}
                            isClearable={true}
                        />
                    </div>

                    <div className={'col'}>
                        <label>Employment</label>
                        <Select
                            value={values.contractTypes}
                            options={options.employmentOptions}
                            onChange={(selected) => {
                                setFieldValue('contractTypes', selected);
                            }}
                            selected={values.contractTypes}
                            isClearable={true}
                            isMulti={true}
                        />
                    </div>
                </div >

                <div className={"d-flex justify-content-between"}>
                    <div className={'col'}>
                        <label>Status</label>
                        <Select
                            value={values.status}
                            options={options.statusOptions}
                            onChange={(selected) => {
                                setFieldValue('status', selected);
                            }}
                            selected={values.status}
                            isClearable={true}
                        />
                    </div>
                    <div className={'col'}>
                        <label>Warehouse</label>
                        <Select
                            value={values.warehouses}
                            options={options.warehouseOptions}
                            onChange={(selected) => {
                                setFieldValue('warehouses', selected);
                            }}
                            selected={values.warehouses}
                            isClearable={true}
                            isMulti={true}
                        />
                    </div>
                    <div className={'col'}>
                        <label>Jabatan</label>
                        <Select
                            value={values.roles}
                            options={options.roleOptions}
                            onChange={(selected) => {
                                setFieldValue('roles', selected);
                            }}
                            selected={values.roles}
                            isClearable={true}
                        />
                    </div>
                    <div className={'col'}>
                        <label>Division</label>
                        <Select
                            value={values.divisions}
                            options={options.divisionOptions}
                            onChange={(selected) => {
                                setFieldValue('divisions', selected);
                            }}
                            selected={values.divisions}
                            isClearable={true}
                        />
                    </div>

                    <div className={'col'}>
                        <label>Team</label>
                        <Select
                            value={values.teams}
                            options={options.teamOptions}
                            onChange={(selected) => {
                                setFieldValue('teams', selected);
                            }}
                            selected={values.warehouses}
                            isClearable={true}
                        />
                    </div>

                    <div className={'col'}>
                        <label>Position</label>
                        <Select
                            value={values.positions}
                            options={options.positionOptions}
                            onChange={(selected) => {
                                setFieldValue('positions', selected);
                            }}
                            selected={values.positions}
                            isClearable={true}
                        />
                    </div>

                    <div className={'col'}>
                        <label>Tier</label>
                        <Select
                            value={values.tiers}
                            options={options.tierOptions}
                            onChange={(selected) => {
                                setFieldValue('tiers', selected);
                            }}
                            selected={values.tiers}
                            isClearable={true}
                        />
                    </div>
                </div>
            </div>
        );
    }

    const generateRequest = (values, page) => {
        let requestData = {}
        if(values.name){
            requestData.name = values.name;
        }
        if (values.startDate) {
            requestData.joinStart = moment(values.startDate).format(DATE_FORMAT);
        }
        if (values.endDate) {
            requestData.joinEnd = moment(values.endDate).format(DATE_FORMAT);
        }
        if (values.warehouses) {
            requestData.warehouseIds = values.warehouses.map((warehouse) => warehouse.value);
        }
        if (values.contractTypes) {
            requestData.contractTypes = values.contractTypes.map((employment) => employment.value);
        }

        if (values.status) {
            requestData.status = values.status.value;
        }

        const workplaceDepartments = generateWorkplaceDeparmentRequest(values);
        if (workplaceDepartments) {
            requestData.workplaceDepartmentIds = workplaceDepartments;
        }

        const internalUserIds = generateUserIdRequest(values);
        if (internalUserIds) {
            requestData.internalUserIds = internalUserIds;
        }
        
        requestData.size = SIZE;
        requestData.sort = "id,asc";
        requestData.page = page;
        return requestData;
    }

    const generateWorkplaceDeparmentRequest = (values) => {
        let workplaceDepartmentIds = [];
        if (values.positions) {
            workplaceDepartmentIds.push(values.positions.value);
        }
        if (values.departments) {
            workplaceDepartmentIds.push(values.departments.value);
        }
        if (values.roles) {
            workplaceDepartmentIds.push(values.roles.value);
        }
        if (values.teams) {
            workplaceDepartmentIds.push(values.teams.value);
        }
        if (values.divisions) {
            workplaceDepartmentIds.push(values.divisions.value);
        }
        if (values.tiers) {
            workplaceDepartmentIds.push(values.tiers.value);
        }
        return workplaceDepartmentIds;
    }

    const generateUserIdRequest = (values) => {
        let internalUserIds = new Set();
        values.employeeIds.forEach((user) => {
            internalUserIds.add(user.value);
        });
        
        return Array.from(internalUserIds);
    }

    const onSubmit = async (values) => {
        setFormData(values);
        getEmploymentData(1, values);
    }

    const ButtonCollections = () => {
        return (
            <div className={"d-flex mt-3"}>
                <MenuAuthCheck allowedScopes={[MANPOWER_VIEW]}>
                    <ButtonV2 text={"Search"} variant={"fill"} type={"submit"} style={{ marginRight: "5px" }} />
                </MenuAuthCheck>
                <MenuAuthCheck allowedScopes={[MANPOWER_WHITELIST, MANPOWER_WRITE_UPDATE]}>
                    <>
                        <ButtonV2 text={"Bulk Changes"} variant={"fill"} onClick={() => { setShowUpdateUserModal(true) }} style={{ marginRight: "5px" }} />
                        <ButtonV2 text={"Create Account"} variant={"fill"} onClick={() => { setShowCreateUserModal(true) }} style={{ marginRight: "5px" }} />
                        <ButtonV2 text={"Upload Employee Record Data"} variant={"fill"} onClick={() => { setShowBulkUploadEmployeeRecordModal(true) }} style={{ marginRight: "5px" }} />
                        <ButtonV2 text={"Generate Payroll"} variant={"fill"} onClick={() => { setShowGeneratePayrollModal(true) }} style={{ marginRight: "5px" }} />
                    </>
                </MenuAuthCheck>
            </div>
        );
    }

    const ResultTables = (props) => {
        const { data, hideResult } = props;
        return (
            <div>
                <div className={"hide-collection mt-3 mb-2 border-top d-flex flex-column"}>
                    <div className={"row mb-1 mt-1"}>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("division", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.division} onChange={(e) => { onChangeHideResult("division", e) }} />
                            Divisi
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("tier", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.tier} onChange={(e) => { onChangeHideResult("tier", e) }} />
                            Tier
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("role", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.role} onChange={(e) => { onChangeHideResult("role", e) }} />
                            Jabatan
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("team", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.team} onChange={(e) => { onChangeHideResult("team", e) }} />
                            Tim
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("employment", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.employment} onChange={(e) => { onChangeHideResult("employment", e) }} />
                            Employment
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("warehouse", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.warehouse} onChange={(e) => { onChangeHideResult("warehouse", e) }} />
                            Warehouse
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("department", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.department} onChange={(e) => { onChangeHideResult("department", e) }} />
                            Department
                        </div>
                        <div className={"col form-check hover-cursor text-wrap"} onClick={(e) => { onChangeHideResult("position", e) }}>
                            <input className={"mr-1"} type='checkbox' checked={hideResult.position} onChange={(e) => { onChangeHideResult("position", e) }} />
                            Position
                        </div>
                    </div>
                </div>

                <div>
                    <Pagination {...pagination} goToPage={(page) => getEmploymentData(page, formData)} />
                </div>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Employee ID</th>
                            <th>Nama lengkap</th>
                            <th>Check</th>
                            {hideResult && hideResult.division === false ? <th>Divisi</th> : <></>}
                            {hideResult && hideResult.employment === false ? <th>Employment</th> : <></>}
                            {hideResult && hideResult.tier === false ? <th>Tier</th> : <></>}
                            {hideResult && hideResult.warehouse === false ? <th>Warehouse</th> : <></>}
                            {hideResult && hideResult.role === false ? <th>Jabatan</th> : <></>}
                            {hideResult && hideResult.department === false ? <th>Dept</th> : <></>}
                            {hideResult && hideResult.team === false ? <th>Tim</th> : <></>}
                            {hideResult && hideResult.position === false ? <th>Posisi</th> : <></>}
                            <th>Tanggal Join</th>
                            <th>Status</th>
                            <th>Lama Durasi Bekerja</th>
                            <th>Payroll System</th>
                            <th>ID Finger</th>
                            <th>Email Aktif</th>
                            <th>KPI</th>
                            <th>Tanggal Kontrak Mulai</th>
                            <th>Tanggal Kontrak Selesai</th>
                            <th>Historical Promotion</th>
                            <th>Historical Gaji</th>
                            <th>Inventory Charge</th>
                            <th>Tanggal Resign</th>
                            <th>Alasan Resign</th>
                            <th>Sanksi</th>
                            <th>Notes</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {responseData && responseData.length > 0 ?
                            <>
                                {buildResultData()}
                            </>
                            : <></>}

                    </tbody>
                </Table>
            </div>
        );
    }

    const onChangeHideResult = (propertyName, e) => {
        let newHideResult = { ...hideResult };
        newHideResult[propertyName] = !newHideResult[propertyName];
        setHideResult(newHideResult);
    }

    const buildResultData = () => {
        return (
            <>
                {
                    responseData.map(
                        (data) => {
                            return <tr key={`${data.employeeId}#${data.name}`}>
                                <td>{getTextContent(data, "employeeId")}</td>
                                <td>{getTextContent(data, "name")}</td>
                                <td>{getTextContent(data, "nameCount")}</td>

                                {hideResult && hideResult.division === false ? <td>{getTextContent(data, "division")}</td> : <></>}
                                {hideResult && hideResult.employment === false ? <td>{getTextContent(data, "employment")}</td> : <></>}
                                {hideResult && hideResult.tier === false ? <td>{getTextContent(data, "tier")}</td> : <></>}
                                {hideResult && hideResult.warehouse === false ? <td>{getTextContent(data, "warehouseId")}</td> : <></>}
                                {hideResult && hideResult.role === false ? <td>{getTextContent(data, "role")}</td> : <></>}
                                {hideResult && hideResult.department === false ? <td>{getTextContent(data, "department")}</td> : <></>}
                                {hideResult && hideResult.team === false ? <td>{getTextContent(data, "team")}</td> : <></>}
                                {hideResult && hideResult.position === false ? <td>{getTextContent(data, "position")}</td> : <></>}

                                <td>{getTextContent(data, "joinDate")}</td>
                                <td>{getTextContent(data, "isActive")}</td>
                                <td>{getTextContent(data, "workDuration")}</td>
                                <td>{getTextContent(data, "payrollSystem")}</td>
                                <td>{getTextContent(data, "fingerId")}</td>
                                <td>{getTextContent(data, "email")}</td>
                                <td className={"hover-cursor"} colSpan={6} onClick={() => { onClickViewEmployeeRecord(data) }}>View Employee Record</td>
                                <td>{getTextContent(data, "resignDate")}</td>
                                <td>{getTextContent(data, "resignReason")}</td>
                                <td>{getTextContent(data, "penalty")}</td>
                                <td>{getTextContent(data, "notes")}</td>
                                <td>
                                    <MenuAuthCheck allowedScopes={[MANPOWER_WRITE_UPDATE]}>
                                        <button onClick={() => { onClickUpdateData(data); }} className='btn btn-primary' disabled={showSide ? true : false}>Update</button>
                                    </MenuAuthCheck>
                                </td>
                            </tr>
                        }
                    )
                }
            </>
        );
    }

    const onClickViewEmployeeRecord = async (data) => {
        const internalUserId = data.internalUserId;

        let requestData = {};
        requestData.internalUserId = internalUserId;
        requestData.type = "LATEST";

        try {
            const getEmployeeRecord = await employeeRecordApi.getEmployeeRecordByInternalUserId(requestData);
            setUserData(data);
            setEmployeeRecords(getEmployeeRecord.data.data);
            setShowSide(true);
            setSideType("EMPLOYEE_RECORD");
            setEmployeeRecordCategory("LATEST");
        } catch (e) {
            displayAlert({ message: e.message, type: "error" });
        }
    }

    const generateEmployeeRecord = () => {
        const options = [
            { value: -3, label: "3 Bulan Terakhir" },
            { value: -6, label: "6 Bulan Terakhir" },
            { value: -9, label: "9 Bulan Terakhir" },
            { value: -12, label: "12 Bulan Terakhir" },
        ]
        return (
            <div className={"w-100 p-1"}>
                <div className={"title d-flex justify-content-center"}>Employee Record</div>
                <div className={"pt-2"}>
                    <div className='side-row w-100 d-flex'>
                        {employeeRecordCategory === "ALL" || employeeRecordCategory === "LATEST" ?
                            <div>
                                <DatePicker
                                    selected={selectedTimeFilter}
                                    onChange={(date) => {
                                        setSelectedTimeFilter(date);
                                        changeEmployeeRecordData("ALL", date);
                                    }}
                                    showMonthYearPicker={true}
                                    className='form-control'
                                    placeholderText='MM YYYY'
                                    dateFormat='MMMM yyyy'
                                    excludeTimes={true}
                                />
                                <button className='ml-5 btn btn-primary' onClick={() => { changeEmployeeRecordData("LATEST") }}>View latest</button>
                            </div>
                            :
                            <Select
                                options={options}
                                onChange={(selected) => {
                                    const selectedValue = selected.value;
                                    setSelectedDuration(selectedValue);
                                    changeEmployeeRecordData(employeeRecordCategory, selectedValue);
                                }}
                            />
                        }
                    </div>

                    <div className='side-row d-flex flex-column'>
                        <div className={"d-flex justify-content-between"}>
                            <div className='w-25'>Nik Karyawan</div>
                            <div className='w-75'>{getTextContent(userData, "employeeId")}</div>
                        </div>

                        <div className={"d-flex justify-content-between mt-2"}>
                            <div className='w-25'>Nama</div>
                            <div className='w-75'>{getTextContent(userData, "name")}</div>
                        </div>
                    </div>
                    <div className='employee-record-result'>
                        {
                            (employeeRecordCategory === "ALL" || employeeRecordCategory === "LATEST") ?
                                AllEmployeeRecordData() :
                                CategorizedEmployeeRecordData(userData)
                        }
                    </div>
                </div>
            </div>
        )
    }

    const changeEmployeeRecordData = async (category, value) => {
        const internalUserId = userData.internalUserId;
        let startDate = new Date();
        let endDate = new Date();
        let requestData = {};
        let startDateIso = null;
        let endDateIso = null;

        if (category !== "LATEST") {
            if (category === "ALL") {
                startDate = new Date(Date.UTC(value.getFullYear(), value.getMonth(), 1));
                endDate = new Date(Date.UTC(value.getFullYear(), value.getMonth() + 1, 1 - 1));
            } else {
                startDate.setMonth(startDate.getMonth() + value);
                requestData.type = category;
            }
            startDateIso = startDate.toISOString().split('T')[0];
            endDateIso = endDate.toISOString().split('T')[0];
        } else {
            requestData.type = category;
        }
        
        requestData.internalUserId = internalUserId;
        requestData.dateStart = startDateIso;
        requestData.dateEnd = endDateIso;

        try {
            const getEmployeeRecord = await employeeRecordApi.getEmployeeRecordByInternalUserId(requestData);
            setEmployeeRecords(getEmployeeRecord.data.data);
            setEmployeeRecordCategory(category);
            setShowSide(true);
            setSideType("EMPLOYEE_RECORD");
        } catch (e) {
            displayAlert({ message: e.message, type: "error" });
        }
    }

    const AllEmployeeRecordData = () => {
        return (
            <>
                <div className="side-row">
                    <div className='font-weight-bold' onClick={() => { setEmployeeRecordCategory("KPI") }}>KPI</div>
                    <div className=''>
                        {
                            generateEmployeeRecordData("KPI")
                        }
                    </div>
                </div>

                <div className="side-row">
                    <div className='font-weight-bold' onClick={() => { setEmployeeRecordCategory("CONTRACT_START_DATE") }}>Informasi Kontrak</div>
                    <div>
                        START
                        {
                            generateEmployeeRecordData("CONTRACT_START_DATE")
                        }
                    </div>
                    <div>
                        END
                        {
                            generateEmployeeRecordData("CONTRACT_END_DATE")
                        }
                    </div>

                </div>

                <div className="side-row">
                    <div className='font-weight-bold' onClick={() => { setEmployeeRecordCategory("PROMOTION_HISTORY") }}>Riwayat Promosi</div>
                    <div>
                        {
                            generateEmployeeRecordData("PROMOTION_HISTORY")
                        }
                    </div>
                </div>

                <div className="side-row">
                    <div className='font-weight-bold' onClick={() => { setEmployeeRecordCategory("SALARY_HISTORY") }}>Riwayat Gaji</div>
                    <div>
                        {
                            generateEmployeeRecordData("SALARY_HISTORY")
                        }
                    </div>
                </div>

                <div className="side-row">
                    <div className='font-weight-bold' onClick={() => { setEmployeeRecordCategory("INVENTORY_CHARGE") }}>Inventory Charge</div>
                    <div>
                        {
                            generateEmployeeRecordData("INVENTORY_CHARGE")
                        }
                    </div>
                </div>
            </>
        );
    }

    const CategorizedEmployeeRecordData = (data) => {
        return (
            <>
                <div onClick={() => { onClickViewEmployeeRecord(data) }}>{`${'<'} Back`}</div>
                <div className="side-row">
                    <div className='font-weight-bold'>{employeeRecordCategory}</div>
                    <div className=''>
                        {
                            generateEmployeeRecordData(employeeRecordCategory)
                        }
                    </div>
                </div>
            </>
        );
    }

    const deleteRecordData = async (recordId) => {
        try {
            await employeeRecordApi.deleteEmployeeRecordDataById(recordId);

            var newEmployeeRecords = {...employeeRecords};
            Object.keys(newEmployeeRecords).forEach(propertyName => {
                newEmployeeRecords[propertyName].forEach((record, index) => {
                    if (record.id == recordId) {
                        newEmployeeRecords[propertyName].splice(index, 1);
                        
                        if (newEmployeeRecords[propertyName].length == 0) {
                            delete newEmployeeRecords[propertyName];
                        }
                    }
                });
            });
            await setEmployeeRecords(newEmployeeRecords);

            displayAlert({ message: "Record berhasil dihapus", type: "success" });
        } catch (e) {
            displayAlert({ message: e.message, type: "error" });
        }
    }

    const generateEmployeeRecordData = (propertyName) => {
        let records = employeeRecords[propertyName] ? employeeRecords[propertyName] : [];
        let index = 1;
        return (
            <div>
                {
                    records.map(
                        (record) => {
                            let recordDate = new Date(record.date).toLocaleString("id-ID", { month: "long", year: "numeric" });
                            return (
                                <div className='row mx-0 mb-1' key={record.id}>
                                    {
                                        record.type === "CONTRACT_START_DATE" ?
                                            <>
                                                <div className='col-4'>{index}</div>
                                                <div className='col-4'>{record.content}</div>
                                            </>
                                            :
                                            <>
                                                <div className='col-4'>{recordDate}</div>
                                                <div className='col-4'>{record.content}</div>
                                            </>
                                    }
                                    <button className='btn btn-sm btn-danger col-2' onClick={() => {
                                        setEmployeeRecordToBeDeleted(record);
                                        setShowDeleteEmployeeRecordConfirmationModal(true);
                                    }}>Hapus</button>
                                </div>
                            )
                        }
                    )
                }
            </div>
        );
    }
    const generateEmployeeRecordDetail = () => {
        return (
            <div className={"w-100"}>
                <div>Employee Record Data</div>
            </div>
        )
    }

    const generateEmployeeRecordErrors = () => {
        let iteration = 0;
        return (
            <Table>
                <thead>
                    <tr>
                        <th>
                            Row
                        </th>
                        <th>
                            EmployeeId
                        </th>
                        <th>
                            KPI
                        </th>
                        <th>
                            Salary
                        </th>
                        <th>
                            Contract
                        </th>
                        <th>
                            Inventory Charge
                        </th>
                        <th>
                            Type
                        </th>
                        <th>
                            Content
                        </th>
                        <th>
                            Date
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        generateEmployeeRecordErrorRows()
                    }
                </tbody>
            </Table>
        );
    }

    const generateEmployeeRecordErrorRows = () => {
        let keys = Object.keys(employeeRecordErrors);
        let errors = []
        keys.forEach(
            (key) => {
                let error = employeeRecordErrors[key];
                let row = parseInt(key) + 1
                errors.push(
                    <tr>
                        <td>{row}</td>
                        <td>{getTextContent(error, "employeeId")}</td>
                        <td>{getTextContent(error, "kpi")}</td>
                        <td>{getTextContent(error, "salary")}</td>
                        <td>{getTextContent(error, "contract")}</td>
                        <td>{getTextContent(error, "inventoryCharge")}</td>
                        <td>{getTextContent(error, "type")}</td>
                        <td>{getTextContent(error, "content")}</td>
                        <td>{getTextContent(error, "date")}</td>
                        <td>{getTextContent(error, "action")}</td>
                    </tr>
                )
            }
        );
        return errors;
    }
    const generateMain = () => {
        return (
            <div className={"overflow-auto"}>
                <ConfirmationModal
                    show={showConfirmationModal}
                    toggleModal={setShowConfirmationModal}
                    submit={updateEmploymentData}
                    user={tempUser}
                />
                <GeneratePayRollModalComponent
                    isShown={showGeneratePayrollModal}
                    toggleModal={setShowGeneratePayrollModal}
                />
                <BulkUploadEmployeeRecordModal />
                <DeleteEmployeeRecordConfirmationModal/>
                <BulkCreateUserModal />
                <BulkUpdateUserModal />
                <SearchForm />
                <ResultTables hideResult={hideResult} />
            </div>
        );
    }
    const BulkUploadEmployeeRecordModal = () => {
        const TITLE = "Bulk Insert Employee Record";
        const URL = 'https://docs.google.com/spreadsheets/u/1/d/1XEODIsZRawjP0YFqYd0YyPAfsXS2QfeNpUG1t3MxGnU/edit#gid=0';
        return (
            <BulkUploadModal
                title={TITLE}
                isShown={showBulkUploadEmployeeRecordModal}
                toggleModal={toggleEmployeeRecord}
                csvUrl={URL}
                isSubmitting={isSubmit}
                setSubmitting={setIsSubmit}
                submit={uploadEmployeeRecord}
                ErrorList={generateEmployeeRecordErrors}
                keepModal={true}
            />
        );
    }

    const DeleteEmployeeRecordConfirmationModal = () => {
        return (
            <Modal size='md' show={showDeleteEmployeeRecordConfirmationModal}>
                <Modal.Header>
                    <Modal.Title>Yakin hapus record ini?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {employeeRecordToBeDeleted != null && 
                        <>
                            <div className='row'>
                                <div className='col-6'>
                                    Employee ID
                                </div>
                                <div className='col-6'>
                                    {getTextContent(userData, "employeeId")}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    Nama Karyawan
                                </div>
                                <div className='col-6'>
                                    {getTextContent(userData, "name")}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    Date
                                </div>
                                <div className='col-6'>
                                    {employeeRecordToBeDeleted.date}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    Type
                                </div>
                                <div className='col-6'>
                                    {employeeRecordToBeDeleted.type}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    Content
                                </div>
                                <div className='col-6'>
                                    {employeeRecordToBeDeleted.content}
                                </div>
                            </div>
                        </>
                    }
                    <br></br>
                    Data yang sudah dihapus tidak akan ditampilkan lagi
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" class="btn btn-primary" onClick={() => {
                        setShowDeleteEmployeeRecordConfirmationModal(false);
                    }}>
                        Batal
                    </button>
                    <button type="button" class="btn btn-danger" onClick={() => {
                        setShowDeleteEmployeeRecordConfirmationModal(false);
                        deleteRecordData(employeeRecordToBeDeleted.id);
                    }}>
                        Hapus
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

    const onClickUpdateData = (user) => {
        const newUserData = { ...user };

        const newUpdateUserOptions = generateWorkplaceDepartmentOptions(user);
        newUserData.division = getWdDropdownDefaultValue(newUserData.divisionId);
        newUserData.department = getWdDropdownDefaultValue(newUserData.departmentId);
        newUserData.tier = getWdDropdownDefaultValue(newUserData.tierId);
        newUserData.team = getWdDropdownDefaultValue(newUserData.teamId);
        newUserData.role = getWdDropdownDefaultValue(newUserData.roleId);
        newUserData.resignReason = getWdDropdownDefaultValue(newUserData.resignReasonId);
        newUserData.position = getWdDropdownDefaultValue(newUserData.positionId);

        setUserData(newUserData);
        setSideType("UPDATE_USER");
        setShowSide(true);
        setUpdateUserWorkplaceDepartmentOptions(newUpdateUserOptions);
    }

    const generateWorkplaceDepartmentOptions = (user) => {
        let divisionOptions = [];
        let tierOptions = [];
        let roleOptions = [];
        let departmentOptions = [];
        let teamOptions = [];
        let positionOptions = [];
        let resignReasonOptions = [];

        workplaceDepartments.forEach(
            (wd) => {
                const type = wd.type ? wd.type : "";
                const option = generateOption(wd, user);
                if (type === WD_TYPE.DIVISION && option) {
                    divisionOptions.push(option);
                }
                if (type === WD_TYPE.TIER && option) {
                    tierOptions.push(option);
                }
                if (type === WD_TYPE.ROLE && option) {
                    roleOptions.push(option);
                }
                if (type === WD_TYPE.DEPARTMENT && option) {
                    departmentOptions.push(option);
                }
                if (type === WD_TYPE.TEAM && option) {
                    teamOptions.push(option);
                }
                if (type === WD_TYPE.POSITION && option) {
                    positionOptions.push(option);
                }
                if (type === WD_TYPE.RESIGN_REASON && option) {
                    resignReasonOptions.push(option);
                }
            }
        );

        return {
            divisionOptions: divisionOptions,
            tierOptions: tierOptions,
            roleOptions: roleOptions,
            departmentOptions: departmentOptions,
            teamOptions: teamOptions,
            positionOptions: positionOptions,
            resignReasonOptions: resignReasonOptions
        };
    }

    const generateOption = (workplaceDepartment, user) => {
        let optionData = null;
        const relations = warehouseWorkplaceDepartmentMap.get(parseInt(user.warehouseId));
        if (relations && relations.has(workplaceDepartment.id) && workplaceDepartment.active) {
            optionData = { value: workplaceDepartment.id, label: workplaceDepartment.value };
        }
        return optionData;
    }

    const generateUpdateUserComponent = () => {
        return (
            <Formik initialValues={userData}>
                {
                    (formikProps) => {
                        return (
                            <Form className='mr-2 ml-2'>
                                <UpdateEmploymentDataForm {...formikProps} />
                            </Form>
                        );
                    }
                }
            </Formik>
        );
    }

    const UpdateEmploymentDataForm = (props) => {
        const { values, setFieldValue } = props;
        const payrollOptions = [
            { value: 'time-based', label: 'time-based' },
            { value: 'performance-based', label: 'performance-based' },
        ];
        const statusOptions = [
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
            { value: 'blacklist', label: 'Blacklist' },
        ];
        return (
            <>
                <div>
                    <label>Nik Karyawan</label>
                    <input className='form-control' value={values.employeeId} disabled />
                </div>
                <div>
                    <label>Nama</label>
                    <input className='form-control' value={values.name} onChange={(input) => { setFieldValue("name", input.target.value) }} />
                </div>
                <div>
                    <label>Warehouse</label>
                    <Select
                        options={options.warehouseOptions}
                        value={{ value: values.warehouseId, label: getLabel(values, "warehouseId") }}
                        onChange={
                            (selected) => {
                                setFieldValue("warehouseId", selected.value);
                                const workplaceDepartmentOptions = generateWorkplaceDepartmentOptions({ warehouseId: selected.value });
                                setUpdateUserWorkplaceDepartmentOptions(workplaceDepartmentOptions);
                            }
                        }
                    />
                </div>
                <div>
                    <label>Employment</label>
                    <Select
                        options={options.employmentOptions}
                        defaultValue={{ value: values.employment, label: values.employment }}
                        onChange={(selected) => { setFieldValue("employment", selected.value); }}
                    />
                </div>
                <div>
                    <label>Tanggal Join</label>
                    <DatePicker
                        selected={values.joinDate ? new Date(values.joinDate) : null}
                        onChange={date => { setFieldValue('joinDate', date) }}
                        className='form-control'
                        placeholderText='yyyy-mm-dd'
                        dateFormat='yyyy-MM-dd'
                        excludeTimes={true}
                    />
                </div>
                <div>
                    <label>Payroll System</label>
                    <Select
                        options={payrollOptions}
                        defaultValue={{ value: values.payrollSystem, label: values.payrollSystem }}
                        onChange={(selected) => { setFieldValue("payrollSystem", selected.value) }}
                    />
                </div>

                <div>
                    <label>Status</label>
                    <Select
                        options={statusOptions}
                        defaultValue={getStatusValue(values)}
                        onChange={(selected) => { setStatusValue(setFieldValue, selected, values) }}
                    />
                </div>
                <div>
                    <label>Id Finger</label>
                    <input className='form-control' value={values.fingerId} onChange={(input) => { setFieldValue("fingerId", input.target.value) }} />
                </div>
                <div>
                    <label>Email</label>
                    <input className='form-control' value={values.email} onChange={(input) => { setFieldValue("email", input.target.value) }} type='email' />
                </div>
                <div>
                    <label>Sanksi</label>
                    <input className='form-control' value={values.penalty ? values.penalty : ""} onChange={(input) => { setFieldValue("penalty", input.target.value) }} />
                </div>
                <div>
                    <label>Notes</label>
                    <input className='form-control' value={values.notes ? values.notes : ""} onChange={(input) => { setFieldValue("notes", input.target.value) }} />
                </div>

                <div>
                    <label>Divisi</label>
                    <Select
                        options={updateUserWorkplaceDepartmentOptions.divisionOptions}
                        value={values.division}
                        onChange={(selected) => { setFieldValue("division", selected); }}
                    />
                </div>
                <div>
                    <label>Tier</label>
                    <Select
                        options={updateUserWorkplaceDepartmentOptions.tierOptions}
                        value={values.tier}
                        onChange={(selected) => { setFieldValue("tier", selected) }}
                    />
                </div>
                <div>
                    <label>Jabatan</label>
                    <Select
                        options={updateUserWorkplaceDepartmentOptions.roleOptions}
                        value={values.role}
                        onChange={(selected) => { setFieldValue("role", selected) }}
                    />
                </div>
                <div>
                    <label>Department</label>
                    <Select
                        options={updateUserWorkplaceDepartmentOptions.departmentOptions}
                        value={values.department}
                        onChange={(selected) => { setFieldValue("department", selected) }}
                    />
                </div>
                <div>
                    <label>Tim</label>
                    <Select
                        options={updateUserWorkplaceDepartmentOptions.teamOptions}
                        value={values.team}
                        onChange={(selected) => { setFieldValue("team", selected) }}
                    />
                </div>
                <div>
                    <label>Posisi</label>
                    <Select
                        options={updateUserWorkplaceDepartmentOptions.positionOptions}
                        value={values.position}
                        onChange={(selected) => { setFieldValue("position", selected) }}
                    />
                </div>
                <div>
                    <label>Tanggal Resign</label>
                    <DatePicker
                        selected={values.resignDate ? new Date(values.resignDate) : null}
                        onChange={date => { setFieldValue('resignDate', date) }}
                        className='form-control'
                        placeholderText='yyyy-mm-dd'
                        dateFormat='yyyy-MM-dd'
                        excludeTimes={true}
                    />
                </div>
                <div>
                    <label>Alasan Resign</label>
                    <Select
                        options={updateUserWorkplaceDepartmentOptions.resignReasonOptions}
                        value={values.resignReason}
                        onChange={(selected) => { setFieldValue("resignReason", selected) }}
                    />
                </div>

                <button className="ml-2 mt-2 w-50 btn btn-primary" type="button" onClick={() => { setTempUser(values); setShowConfirmationModal(true) }}>Update Data</button>
            </>
        );
    }

    const getWdDropdownDefaultValue = (wdId) => {
        const workplaceDepartment = workplaceDepartmentMap.get(wdId);
        let result = { value: null, label: null };
        if (workplaceDepartment) {
            result.value = workplaceDepartment.id;
            result.label = workplaceDepartment.value;
        }
        return result;
    }

    const updateEmploymentData = async (values) => {
        const requestData = {
            userId: userData.userId,
            internalUserId: userData.internalUserId,
            employeeId: userData.employeeId,
            name: compareInput(values, "name"),
            division: compareInput(values, "division"),
            employment: compareInput(values, "employment"),
            tier: compareInput(values, "tier"),
            warehouseId: compareInput(values, "warehouseId"),
            role: compareInput(values, "role"),
            department: compareInput(values, "department"),
            team: compareInput(values, "team"),
            position: compareInput(values, "position"),
            joinDate: compareInput(values, "joinDate"),
            payrollSystem: compareInput(values, "payrollSystem"),
            status: compareInput(values, "status"),
            isBlacklisted: compareInput(values, "isBlacklisted"),
            fingerId: compareInput(values, "fingerId"),
            email: compareInput(values, "email"),
            resignDate: compareInput(values, "resignDate"),
            resignReason: compareInput(values, "resignReason"),
            penalty: compareInput(values, "penalty"),
            notes: compareInput(values, "notes")
        };
        try {
            const response = await manpowerApi.updateEmploymentData(requestData);
            displayAlert({ type: "success", message: "Success Update Data" });
            setSideType("");
            setShowSide(false);
            getEmploymentData(pagination.currentPage, formData);
        } catch (e) {
            displayAlert({ type: "error", message: e.message });
        }
    }

    const compareInput = (values, propertyName) => {
        let inputtedData = values[propertyName];
        if (propertyName === 'isBlacklisted') {
            inputtedData = values.isBlacklisted ? values.isBlacklisted : false;
        }
        if (propertyName === "joinDate" || propertyName === "resignDate") {
            inputtedData = values[propertyName] ? moment(values[propertyName]).format(DATE_FORMAT) : null;
        }
        if (propertyName == "division" || propertyName == "department" || propertyName == "position" || propertyName == "tier" || propertyName == "team" || propertyName == "role" || propertyName == "resignReason") {
            inputtedData = values[propertyName] ? values[propertyName].value : "";
        }
        return inputtedData != userData[propertyName] ? inputtedData : null;
    }

    const getLabel = (values, propertyName) => {
        let whId = values[propertyName];
        let whName = "";
        if (whId && whId !== undefined) {
            whId = parseInt(whId);
            const warehouse = warehouseMap.get(whId);
            whName = warehouse.name;
        }
        return whName;
    }

    const generateSide = () => {
        let sideContent = <></>
        switch (sideType) {
            case "EMPLOYEE_RECORD":
                sideContent = generateEmployeeRecord();
                break;
            case "EMPLOYEE_RECORD_DETAIL":
                sideContent = generateEmployeeRecordDetail();
                break;
            case "UPDATE_USER":
                sideContent = generateUpdateUserComponent();
                break;
            default: sideContent = <></>
        }
        return (
            <div>
                {sideContent}
            </div>
        );
    }

    const setStatusValue = (setFieldValue, selected, values) => {
        if (selected.value === "active") {
            setFieldValue("isActive", true);
            setFieldValue("isBlacklisted", false);
        } else {
            setFieldValue("isActive", false);
            selected.value === "blacklisted" ? setFieldValue("isBlacklisted", true) : setFieldValue("isBlacklisted", false);
        }
        setFieldValue("status", selected.value);
    }

    const getStatusValue = (values) => {
        let value = {};
        if (values.isActive === true || values.isActive === "active") {
            value = { value: "active", label: "Active" }
        } else if (values.isActive === false && values.isBlacklisted === false || values.isActive === "inactive") {
            value = { value: "inactive", label: "Inactive" }
        } else {
            value = { value: "blacklisted", label: "Blacklisted" }
        }
        return value;
    }

    const uploadEmployeeRecord = async (formData) => {
        try {
            const response = await employeeRecordApi.bulkCreateEmployeeHistory({ data: formData })
            const responseData = response.data.data;
            if (responseData.error === true) {
                setEmployeRecordErrors(responseData.errors);
            } else {
                displayAlert({ message: 'Success upload employee record data ', type: 'success' });
                setShowBulkUploadEmployeeRecordModal(false);
            }
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' })
        }
    }

    const bulkUpdateUser = async (formData) => {
        try {
            const response = await manpowerApi.bulkUpdateEmploymentData({ data: formData });
            const responseData = response.data.data;
            if (responseData.error === true) {
                return responseData.errors;
            } else {
                displayAlert({ message: 'Success', type: 'success' })
                setShowUpdateUserModal(false);
            }
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' })
        }
    }

    const bulkCreateAndUpdateErrorList = ({ bulkCreateAndUpdateErrors }) => {
        return (
            <Table bordered>
                <thead>
                    <tr>
                        <th>Row</th>
                        <th>Username</th>
                        <th>Role Itools  </th>
                        <th>Nik Karyawan</th>
                        <th>Nama lengkap</th>
                        <th>Divisi</th>
                        <th>Employment</th>
                        <th>Tier</th>
                        <th>Warehouse </th>
                        <th>Jabatan</th>
                        <th>Dept</th>
                        <th>Tim</th>
                        <th>Posisi</th>
                        <th>Tanggal Join</th>
                        <th>Payroll System</th>
                        <th>Status</th>
                        <th>Tanggal Resign</th>
                        <th>Alasan Resign</th>
                        <th>Gender</th>
                        <th>Id Finger</th>
                        <th>Nik Ktp</th>
                        <th>Alamat</th>
                        <th>No Hp</th>
                        <th>Email aktif</th>
                        <th>Bank Name</th>
                        <th>Bank Account</th>
                        <th>Account Name</th>
                        <th>Tempat lahir</th>
                        <th>Tanggal Lahir </th>
                        <th>Golongan Darah</th>
                        <th>Nama Ibu Kandung</th>
                        <th>Agama</th>
                        <th>Status Pernikahan</th>
                        <th>Npwp</th>
                        <th>Skck</th>
                        <th>Bpjs kesehatan</th>
                        <th>BPJS Tenaga Kerja</th>
                        <th>Pajak Status</th>
                        <th>Sanksi</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        bulkCreateAndUpdateErrors ? generateBulkUploadUserRow(bulkCreateAndUpdateErrors) : <></>
                    }
                </tbody>
            </Table>
        );
    }
    const generateBulkUploadUserRow = (bulkCreateAndUpdateErrors) => {
        let keys = Object.keys(bulkCreateAndUpdateErrors);
        let errors = [];
        keys.forEach(
            (key) => {
                let error = bulkCreateAndUpdateErrors[key];
                let row = parseInt(key) + 1
                errors.push(
                    <tr>
                        <td>{row}</td>
                        <td>{getErrorText(error, "username")}</td>
                        <td>{getErrorText(error, "itoolsRoles")}</td>
                        <td>{getErrorText(error, "employeeId")}</td>
                        <td>{getErrorText(error, "name")}</td>
                        <td>{getErrorText(error, "division")}</td>
                        <td>{getErrorText(error, "employment")}</td>
                        <td>{getErrorText(error, "tier")}</td>
                        <td>{getErrorText(error, "warehouse")}</td>
                        <td>{getErrorText(error, "roles")}</td>
                        <td>{getErrorText(error, "department")}</td>
                        <td>{getErrorText(error, "team")}</td>
                        <td>{getErrorText(error, "position")}</td>
                        <td>{getErrorText(error, "joinDate")}</td>
                        <td>{getErrorText(error, "payrollSystem")}</td>
                        <td>{getErrorText(error, "status")}</td>
                        <td>{getErrorText(error, "resignDate")}</td>
                        <td>{getErrorText(error, "resignReason")}</td>
                        <td>{getErrorText(error, "gender")}</td>
                        <td>{getErrorText(error, "fingerId")}</td>
                        <td>{getErrorText(error, "nikKtp")}</td>
                        <td>{getErrorText(error, "address")}</td>
                        <td>{getErrorText(error, "phone")}</td>
                        <td>{getErrorText(error, "email")}</td>
                        <td>{getErrorText(error, "bankName")}</td>
                        <td>{getErrorText(error, "bankAccount")}</td>
                        <td>{getErrorText(error, "bankAccountName")}</td>
                        <td>{getErrorText(error, "birthPlace")}</td>
                        <td>{getErrorText(error, "birthDate")}</td>
                        <td>{getErrorText(error, "bloodType")}</td>
                        <td>{getErrorText(error, "motherName")}</td>
                        <td>{getErrorText(error, "religion")}</td>
                        <td>{getErrorText(error, "maritalStatus")}</td>
                        <td>{getErrorText(error, "npwp")}</td>
                        <td>{getErrorText(error, "skck")}</td>
                        <td>{getErrorText(error, "bpjs")}</td>
                        <td>{getErrorText(error, "bpjstk")}</td>
                        <td>{getErrorText(error, "taxStatus")}</td>
                        <td>{getErrorText(error, "penalty")}</td>
                        <td>{getErrorText(error, "notes")}</td>
                    </tr>
                )
            }
        )
        return errors;
    };

    const getTextContent = (data, propertyName) => {
        let text = "";
        if (data && data[propertyName] !== null) {
            text = data[propertyName];
            if (propertyName === "warehouseId") {
                const warehouse = warehouseMap.get(data[propertyName]);
                text = warehouse ? warehouse.name : "-";
            }
            if (propertyName == "isActive") {
                const isActive = data[propertyName];
                const isBlacklisted = data["isBlacklisted"];

                if (!isActive && isBlacklisted) {
                    text = "Blacklisted";
                } else if (!isActive && !isBlacklisted) {
                    text = "Inactive";
                } else {
                    text = "Active";
                }
            }
        }
        return text;
    };

    const getErrorText = (data, propertyName) => {
        let text = "";
        if (data && data[propertyName] !== null) {
            text = data[propertyName];
        }
        return text;
    }
    const BulkUpdateUserModal = () => {
        const TITLE = "Bulk Update Employment Data";
        const CSV = "https://docs.google.com/spreadsheets/d/19ievmQNg1sCUXQxM_cLC0JLM0YT1ey2nbtU0k07kXxU/edit?usp=sharing";

        return (
            <BulkUploadModal
                title={TITLE}
                csvUrl={CSV}
                isShown={showUpdateUserModal}
                toggleModal={toggleUpdateUploadUserModal}
                isSubmitting={isSubmit}
                setSubmitting={setIsSubmit}
                submit={bulkUpdateUser}
                ErrorList={bulkCreateAndUpdateErrorList}
                keepModal={true}
            />
        );
    }

    const uploadBulkCreateUser = async (formData) => {
        try {
            const response = await manpowerApi.bulkCreateManpowerUser({ data: formData });
            const responseData = response.data.data;
            let errors = null;
            if (responseData.error === true) {
                displayAlert({ message: 'Input error, cek tabel untuk info lengkap', type: 'error' })
                errors = responseData.errors;
            } else {
                displayAlert({ message: 'Success', type: 'success' })
                setShowCreateUserModal(false);
            }
            return errors;
        } catch (e) {
            displayAlert({ message: e.message, type: 'error' })
        }
    }

    const BulkCreateUserModal = () => {
        const TITLE = "Bulk Create User";
        const URL = "https://docs.google.com/spreadsheets/d/19ievmQNg1sCUXQxM_cLC0JLM0YT1ey2nbtU0k07kXxU/edit?usp=sharing";

        return (
            <BulkUploadModal
                title={TITLE}
                isShown={showCreateUserModal}
                toggleModal={toggleCreateUploadUserModal}
                csvUrl={URL}
                isSubmitting={isSubmit}
                setSubmitting={setIsSubmit}
                submit={uploadBulkCreateUser}
                ErrorList={bulkCreateAndUpdateErrorList}
                keepModal={true}
            />
        );
    }

    const toggleCreateUploadUserModal = (state) => {
        const newState = state;
        setShowCreateUserModal(newState);

    }
    const toggleUpdateUploadUserModal = (state) => {
        const newState = state;
        setShowUpdateUserModal(newState);
    }

    const toggleEmployeeRecord = (state) => {
        const newState = state;
        setShowBulkUploadEmployeeRecordModal(newState);
        setEmployeRecordErrors([]);
    }

    return (
        <ContentWithSideContent Main={generateMain()} Side={generateSide()} show={showSide} hideSideContent={setShowSide} />
    );
}


export default Employment;