import React from 'react'
import { connect } from 'react-redux'
import styles from './Maps.module.scss'
import PropTypes from 'prop-types'
import { addDebounceToAutocompleteElement, generateAutocompleteElementId } from '../../utils/GoogleMaps'

const determineInitialLocation = async ({ fallbackLatlng }) => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                resolve({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            }, (e) => {
                resolve(fallbackLatlng)
                console.log('Client is not allowing location access ')
            })
        } else {
            resolve(fallbackLatlng)
            console.log('Browser does not support geolocation')
        }
    })
}

const convertGmapPointsToArray = (mvcArray) => {
    return mvcArray.map(p => ({ lat: p.lat(), lng: p.lng() }))
}

class MapsDeliveryArea extends React.PureComponent {
    static RANDOM_ID_COUNTER = 0;

    static getRandomId() {
        this.RANDOM_ID_COUNTER++;
        return `map-selector-${this.RANDOM_ID_COUNTER}`
    }

    autocomplete = null;
    autocompleteElementId = generateAutocompleteElementId();
    existingPolygons = [];

    state = {
        isLoading: true,
        address: '',
        search: '',
    }

    componentDidMount() {
        if (this.props.mapReady) {
            this.loadMaps();
        }
    }

    //https://stackoverflow.com/questions/50692160/react-componentwillreceiveprops-alternative
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mapReady && !prevProps.mapReady) {
            this.loadMaps();
        }

        if (JSON.stringify(this.props.deliveryAreaCoordinates) != JSON.stringify(prevProps.deliveryAreaCoordinates)) {
            this.polygon = undefined;
            this.loadMaps();
        }
    }

    // It's based on PostGIS data x=longitude y=latitude
    convertCoordinatesToLatLong = (coordinates) => {
        if (!coordinates) { return []; }
        return coordinates.map(p => ({ lat: p[1], lng: p[0] }));
    }

    loadMaps = async () => {
        const { id, zoom, fallbackLatlng, streetViewControl, mapTypeControl, fullscreenControl } = this.props
        const google = window.google;
        const deliveryAreaCoordinates = this.convertCoordinatesToLatLong(this.props.deliveryAreaCoordinates);
        let initialLatlng;
        if ((deliveryAreaCoordinates || []).length > 0) {
            initialLatlng = deliveryAreaCoordinates[0];
        } else {
            initialLatlng = await determineInitialLocation({ fallbackLatlng });
        }
        
        if (!this.map) {
            this.map = new google.maps.Map(document.getElementById(id), {
                zoom, center: initialLatlng, mapId: 'd37a665b83c8be30',
                streetViewControl, mapTypeControl, fullscreenControl
            });
            this.map.addListener('dragend', async () => {
                const pos = this.map.getCenter()
            });
        } else {
            this.map.setCenter(initialLatlng)
            this.clearExistingPolygons()
        }

        if (this.props.showSearchBar && !this.autocomplete) {
            const searchBoxDom = document.createElement('div')
            searchBoxDom.style = {
                width: '100%',
                height: '60px'
            }
            const autocompleteElement = document.getElementById(this.autocompleteElementId);
            addDebounceToAutocompleteElement(autocompleteElement);
            this.autocomplete = new google.maps.places.Autocomplete(autocompleteElement, {
                fields: ['geometry']
            });
            this.autocomplete.bindTo("bounds", this.map);
            this.autocomplete.addListener('place_changed', () => {
                let place = this.autocomplete.getPlace();
    
                if (!place.geometry) {
                    // User did not select a prediction
                    alert("Please Choose from Option");
                    return;
                }
    
                if (place.geometry.viewport) {
                    this.map.fitBounds(place.geometry.viewport);
                    this.map.setZoom(15);
                } else {
                    this.map.setCenter(place.geometry.location);
                    this.map.setZoom(15);
                }
            });
        }

        if ((deliveryAreaCoordinates || []).length > 0) {
            this.polygon = new google.maps.Polygon({
                paths: deliveryAreaCoordinates.slice(0, deliveryAreaCoordinates.length - 1),
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FF0000",
                fillOpacity: 0.35,
                editable: !this.props.viewOnly,
                draggable: !this.props.viewOnly
            });

            this.polygon.getPaths().forEach((path, index) => {
                google.maps.event.addListener(path, 'insert_at', () => { this.handleChangePolygon(path) });
                google.maps.event.addListener(path, 'remove_at', () => { this.handleChangePolygon(path) });
                google.maps.event.addListener(path, 'set_at', () => { this.handleChangePolygon(path) });
            });
            google.maps.event.addListener(this.polygon, 'dragend', () => { this.handleChangePolygon(this.polygon.getPath()) });
            this.polygon.setMap(this.map);
        }

        this.props.onLoad({ latlng: initialLatlng })
        this.setState({
            isLoading: false
        });
    }

    clearExistingPolygons = () => {
        if (this.existingPolygons) {
            for(const item of this.existingPolygons) {
                item.setMap(null)
            }
        }
        this.existingPolygons = [];
    }

    handleChangePolygon = (path) => {
        this.props.onChange(convertGmapPointsToArray(path.getArray()))
    }

    onClickNewPolygon = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const google = window.google;
        if (this.polygon) { return; }
        const pos = { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() };
        const initialPath = [
            { lat: pos.lat - 0.0005, lng: pos.lng + 0.0005 },
            { lat: pos.lat + 0.001, lng: pos.lng + 0.001 },
            { lat: pos.lat - 0.001, lng: pos.lng - 0.001 }
        ];
        this.polygon = new google.maps.Polygon({
            paths: initialPath,
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
            editable: !this.props.viewOnly,
            draggable: !this.props.viewOnly
        })

        this.polygon.getPaths().forEach((path, index) => {

            google.maps.event.addListener(path, 'insert_at', () => { this.handleChangePolygon(path) });

            google.maps.event.addListener(path, 'remove_at', () => { this.handleChangePolygon(path) });

            google.maps.event.addListener(path, 'set_at', () => { this.handleChangePolygon(path) });

        });

        google.maps.event.addListener(this.polygon, 'dragend', () => { this.handleChangePolygon(this.polygon.getPath()) });
        this.props.onChange(initialPath.map(p => ({ lat: p.lat, lng: p.lng })));
        this.polygon.setMap(this.map);
    }

    locationSearchBar(show) {
        if (!show) { return "" }
        return <div className={styles['search-div']}>
            <input id={this.autocompleteElementId} className={`${styles['search-box']} ${styles['search-input']} md-12`}
                onChange={e => this.setState({ search: e.target.value })}
                aria-describedby="basic-addon1" placeholder="Cari Lokasi" />
            <div className={`${styles['search-btn']}`} />
        </div>
    }

    initPolygonButton(viewOnly) {
        if (viewOnly) { return }
        return <div className={styles.newPolygon}>
            <span className={'btn btn-sm btn-primary'} onClick={(e) => this.onClickNewPolygon(e)}>New Delivery Area</span>
        </div>
    }

    render() {
        const mapStyle = (!this.state.isLoading) ? styles.map : styles.mapHidden;

        return <div style={this.props.style} className={'p-1'}>
            {this.locationSearchBar(this.props.showSearchBar)}
            <div className={`${styles.outerDiv}`}>
                {this.initPolygonButton(this.props.viewOnly)}
                <div id={this.props.id} className={mapStyle} />
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        mapReady: state.gmaps.mapReady
    }
}

const LatLngPropTypes = PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
})

MapsDeliveryArea.propTypes = {
    //wrapper for map selector
    className: PropTypes.string,
    //initial location of marker
    initLatLng: LatLngPropTypes,
    //callback when map is changed (after user ends dragging)
    onChange: PropTypes.func,
    //callback when map is loaded and user location is determined (or default fallback if user denies location access)
    onLoad: PropTypes.func,
    //initial zoom value of map
    zoom: PropTypes.number,
    //fallback location if user denies location access
    fallbackLatlng: LatLngPropTypes,
    //show maptype control for google maps (type is satellite, terrain, street)
    mapTypeControl: PropTypes.bool,
    //show streetview option for googlemaps
    streetViewControl: PropTypes.bool,
    //enable fullscreen button
    fullscreenControl: PropTypes.bool
}

MapsDeliveryArea.defaultProps = {
    id: MapsDeliveryArea.getRandomId(),
    zoom: 15,
    //Jakarta Barat, pertigaan jalan panjang yang ke arah meruya ilir
    fallbackLatlng: {
        lat: -6.1958759,
        lng: 106.7541634
    },
    onChange: () => { console.error('Please define onChange') },
    onLoad: () => { console.error('Please define onLoad') },
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
}

export default connect(mapStateToProps)(MapsDeliveryArea)