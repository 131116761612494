import customAxios from "./axios";
import {EMPLOYEE_RECORDS, GET_EMPLOYEE_RECORDS} from "../constants/api";
import * as qs from "query-string";

export const bulkCreateEmployeeHistory = ({data})=>{
    return customAxios.post(EMPLOYEE_RECORDS,data);
}

export const getEmployeeRecordByInternalUserId = (params)=>{
    return customAxios.get(GET_EMPLOYEE_RECORDS, {
        params: params, paramsSerializer: params => {
            return qs.stringify(params, {
                arrayFormat: 'comma',
            })
        }       
    });
}

export const deleteEmployeeRecordDataById = (recordId) => {
    return customAxios.delete(
        EMPLOYEE_RECORDS,
        {
            params: {recordId}
        }
    )
}