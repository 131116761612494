import React, {useEffect, useState} from "react";
import {Button, Modal, Spinner} from "react-bootstrap";
import {approveDraft} from "../../../../apis/products";
import "./Modal.css";
import {forEach} from "react-bootstrap/ElementChildren";


const ApproveItemDraftModal = ({show, handleClose, approveId, handleApprovedData, cancelId, handleCancelData, selectedItemDraft, isBulk, bulkAction, handleBulk, checkedItemDraft, errors, isLoading, isSuccess, setErrors, processErrorMessage}) => {
    const [isApproveLoading, setApproveLoading] = useState(false);
    const [isApproveSuccess, setIsApproveSuccess] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsApproveSuccess(false);
        setApproveLoading(false);
        setError(null);
    }, [])

    const handleCloseWrapper = () => {
        setIsApproveSuccess(false);
        setApproveLoading(false);
        setError(null);
        setErrors([]);
        handleClose()
    }

    function concatNonNullValues(obj) {
        let result = '';
        for (const key in obj) {
            if (obj[key] !== null) {
                result += obj[key] + ' ';
            }
        }
        return result.trim();
    }

    const handleConfirmation = async () => {
        if(isBulk){
            handleBulk();
        }
        else if(handleCancelData){
            handleCancelData(cancelId);
        }else{
            await handleApprove();
        }
    }

    const handleApprove = async () => {
        if (approveId) {
            try {
                setApproveLoading(true);
                const result = await approveDraft({draftIds: [approveId]});
                if (result.status === 200 && result.data.data?.success) {
                    handleApprovedData(result.data.data, approveId)
                    setIsApproveSuccess(true);
                    setTimeout(() => {
                        handleCloseWrapper()
                    }, 1000)
                } else {
                    setApproveLoading(false);
                    setIsApproveSuccess(false);
                    setErrors(processErrorMessage(result.data.data.errors));
                }
            } catch (e) {
                if (e?.response?.status === 400){
                    setError(e.response.data.message);
                } else{
                    setError(e.message);
                }
                setIsApproveSuccess(false)
            } finally {
                setApproveLoading(false);
            }
        }
    }

    const generateSuccessMessage = ()=>{
        if(isBulk){
            return `${bulkAction} berhasil`
        }

        if(handleCancelData){
            return 'Draft Cancel Berhasil';
        }
        return 'Approval Berhasil';
    }

    const renderBulkBody = () => {
        return(
            <>
                <p>
                    Anda akan {bulkAction === "CANCEL" ? "mengcancel" : "mengapprove"} {checkedItemDraft.size} Item Draft
                    <br/>
                    {bulkAction === "CANCEL" ?
                        "Item Draft yang di-cancel tidak bisa diubah atau diaktifkan lagi."
                        :
                        ""
                    }<br/>
                    Konfirmasi untuk melanjutkan !
                </p>
                <br/>
                <div className={'info-tab'}>
                    {
                        checkedItemDraft && checkedItemDraft.size > 0 ?
                            Array.from(checkedItemDraft.values()).map((productDraft, index) => {
                                return(
                                    <div>
                                        {index+1}. {productDraft.id} - {productDraft.itemName}
                                    </div>
                                )
                            }) : <></>
                    }
                </div>
            </>
        )
    }

    const handleShowError = () => {
        return handleShowBulkError();
    }

    const handleShowBulkError = ()=> {
        return(
            <>
                <div>
                    Error List
                </div>
                <div className={'text-danger info-tab'}>
                    {   errors && errors.length > 0 ?
                        errors.map(
                            (error) => {
                                return(
                                    <div>{error}</div>
                                );
                            }) :
                        <div>{error}</div>
                    }
                </div>
            </>
        )
    }

    return (
        <Modal show={show} onHide={handleCloseWrapper} animation={false} size={'md'}>
            <Modal.Header closeButton>
                <Modal.Title>{
                    bulkAction? `Bulk ${bulkAction} draft` : handleCancelData ? "Cancel Draft" : "Approve Draft"} ?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='container-fluid'>
                    {
                        isBulk ? renderBulkBody() :
                            handleCancelData ?
                        <p>Anda akan meng-cancel Item Draft <b>{selectedItemDraft?.itemName}</b>.<br/>
                            Item Draft yang di-cancel tidak bisa diubah atau diaktifkan lagi.<br/>
                            Konfirmasi untuk melanjutkan !
                        </p> :
                        <p>Apakah anda yakin untuk approve draft ini <b>{selectedItemDraft?.itemName}</b></p>
                    }
                    <br/>
                    {handleShowError()}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isApproveLoading || isApproveSuccess || isLoading || isSuccess}
                    variant="primary" onClick={() => {
                    handleCloseWrapper()
                }}>
                    Batal
                </Button>
                <Button
                    variant={isApproveSuccess ? 'info' : 'success'}
                    disabled={isApproveLoading || isApproveSuccess || isLoading || isSuccess}
                    onClick={handleConfirmation}
                >
                    {isApproveSuccess || isSuccess ?  generateSuccessMessage() : isApproveLoading || isLoading? <Spinner animation={'border'} size={'sm'} /> : `Ya, Konfirmasi`}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ApproveItemDraftModal;