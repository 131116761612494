
export function classNameJoin(classNames) {
    if (!classNames || !classNames.length) {
        return '';
    }
    
    if (Array.isArray(classNames)) {
        const filteredClasses = classNames.filter(Boolean);
        if (!filteredClasses.length) {
            return '';
        }
        return filteredClasses.join(' ');
    } else {
        return classNames;
    }
}

