import React from 'react'
import {Modal} from "react-bootstrap";

class ApprovalModal extends React.PureComponent {
    render(){
        return <Modal show={this.props.show}>
            <Modal.Header>
                <Modal.Title>Approve Action</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-2'>{this.props.message}</div>
            </Modal.Body>
            <Modal.Footer>
                <div className={'d-flex justify-content-between'} style={{width:'100%'}}>
                    <button className='btn btn-danger mr-1' onClick={this.props.onCancel}>Cancel</button>
                    <button className='btn btn-primary' onClick={this.props.onConfirm}>Confirm</button>
                </div>
            </Modal.Footer>
        </Modal>
    }
}

export default ApprovalModal