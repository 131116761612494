import {
    SET_MAP_READY
} from "../constants/gmaps";

const initialState = {
    mapReady: false
}

export default (state = initialState, action) => {
    const {type,payload} = action

    switch (type) {
        case SET_MAP_READY:
            return {...state, mapReady: payload.mapReady}
        default: {
            return state
        }
    }
}