import React, { memo, useState } from "react";
import UploadCSVButton from "../UploadCSVButton";
import { CSVLink } from "react-csv";
import moment from "moment";

const csvLink = ({ fileLogs, name = "Download" }) => {
  return (
    <CSVLink
      data={fileLogs}
      filename={`Bulk Create PO - Error Log - ${moment().format(
        "DD-MM-YYYY hh-mm"
      )}`}
    >
      {name}
    </CSVLink>
  );
};

const VerificationStatus = ({ isVisible, isVerified, fileLogs }) => {
  if (!isVisible) return <></>;

  const getVerificationText = () => {
    if (isVerified) return "Verifikasi data berhasil";
    if (!!fileLogs) {
      return (
        <p>
          Verifikasi data belum berhasil.{" "}
          <a href="#" class="alert-link">
            {csvLink({ fileLogs, name: "Download error log disini " })}
          </a>
          dan pastikan untuk memperbaiki error yang tertera pada kolom U
        </p>
      );
    }
    return "Lakukan verifikasi terlebih dulu";
  };

  const verificationTextClassName = `${!!fileLogs ? "col-9" : "col-6"} m-0 p-0`;

  return (
    <div className="mt-4">
      <div className="col-2 m-0 p-0">
        <b>Status Verifikasi</b>
      </div>
      <div className={verificationTextClassName}>
        <div
          className={`alert ${isVerified ? "alert-success" : "alert-danger"}`}
          role="alert"
        >
          {getVerificationText()}
        </div>
      </div>
    </div>
  );
};

function UploadCard(props) {
  const {
    csvFile,
    setCsvFile,
    verified,
    fileLogs,
    isLoading,
    setFileLogs,
    setVerified,
  } = props;

  return (
    <div>
      <small className="text-muted">
        Download template csv di &nbsp;
        <a
          href="https://docs.google.com/spreadsheets/d/1PEfxrxlzcHvG-mKlRU_yfFIfULhKRBZpVskOa4g5c7E/edit#gid=1450132534"
          target="_blank"
          rel="noopener noreferrer"
        >
          sini
        </a>
      </small>
      <UploadCSVButton
        csvFile={csvFile}
        isLoading={isLoading}
        onChange={(files) => {
          files[0] && setCsvFile(files[0]);
          setFileLogs(null);
          setVerified(false);
        }}
      />
      <VerificationStatus
        isVisible={!!csvFile || !!fileLogs}
        isVerified={verified}
        fileLogs={fileLogs}
      />
    </div>
  );
}

export default memo(UploadCard);
