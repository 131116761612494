import React, {useEffect, useState} from "react";
import {InputGroup, Form} from "react-bootstrap";

function CurrencyField(props){
    const {
        onChange,
        value,
        currency = 'Rp',
        disabled = false,
        separator = ',',
        required = false
    } = props;

    const [renderValueText, setRenderValueText] = useState('');
    useEffect(() => {
        const {value: renderValue} = processText(renderValueText, renderValueText, {separator});
        if (value !== renderValue){
            setRenderValueText(processValue(value, {separator}));
        }
    }, [value]);

    const handleChange = (event) => {
        const element = event.target
        const caret = element.selectionStart

        // Simple Fix to keep cursor not jumping
        // window.requestAnimationFrame(() => {
        //     element.selectionStart = caret
        //     element.selectionEnd = caret
        // })

        const inputValue = element.value;
        const {text, value} = processText(renderValueText, inputValue, {separator});
        setRenderValueText(text);
        onChange && onChange(value);
    }

    const handleOnBlur = (event) => {
        const inputValue = event.target.value;
        const {value} = processText(renderValueText, inputValue, {separator});
        setRenderValueText(processValue(value, {separator}));
    }

    return <InputGroup className="mb-3">
        <InputGroup.Text>{currency}</InputGroup.Text>
        <Form.Control
            inputMode='decimal'
            value={renderValueText}
            onChange={handleChange}
            onBlur={handleOnBlur}
            disabled={disabled}
            required={required}
        />
    </InputGroup>

}

const processValue = (value, {separator = ','}) => {
    if (isNaN(value) || value === null || value === undefined) return '';
    let stringValue =  String(value).replace(/\s/g,'');
    return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

const processText = (prevText, curText, {separator = ','}) => {
    const cleanCurText = curText.replaceAll(',', '');

    if (isNaN(cleanCurText)) {
        return {
            text: prevText,
            value: Number(prevText.replace(',', ''))
        };
    }

    return {
        text: cleanCurText.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        value: !!cleanCurText ? Number(cleanCurText) : null,
    }
}

export default CurrencyField;