import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import "./style.css";
import {Select} from "antd";
import InputLabel from "./Components/InputLabel";
import LineBreak from "./Components/LineBreak";
import * as userApi from "../../../apis/users";
import {roleGroupedByDirectories} from "../../../apis/roles";
import {getWarehouses} from "../../../apis/warehouses";
import BulkUploadContainer from "./Container/BulkUpload/BulkUploadContainer";
import SingleUploadContainer from "./Container/SingleUpload/SingleUploadContainer";
import {bindActionCreators} from "redux";
import {displayAlert} from "../../../redux/actions/notif";
import {connect} from "react-redux";
import PageAuthCheck from "../../../components/AuthCheck/PageAuthCheck";
import {Modal, Spinner} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import _ from "lodash";
import {USER_CREATE, USER_UPDATE} from "../../../constants/scope";
import BulkErrorModal from './Container/BulkUpload/BulkErrorModal';

function CreateOrUpdateInternalUserPage(props){
    const searchParams = useLocation().search;
    const modeFromQueryParam = new URLSearchParams(searchParams).get('mode');
    const userIdFromQueryParam = new URLSearchParams(searchParams).get('id');

    const COLOR = {
        WHITE: "#FFFFFF",
        LINE: "#E6E6E6",
        PRIMARY: "#414BB2",
        SECONDARY: "#DA0063",
        TERTIARY: "#0CA789",
    };

    const MODE_CREATE = "create";
    const MODE_UPDATE = "update";
    const [mode, setMode] = useState(modeFromQueryParam || null);

    const METHOD_SINGLE = "single";
    const METHOD_BULK = "bulk";
    const [method, setMethod] = useState(modeFromQueryParam != null ? METHOD_SINGLE : null);

    const [userDatas, setUserDatas] = useState(null);
    const [userData, setUserData] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const [isInitialize, setIsInitialize] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const modeOptions=[
        {value: null, label:"Pilih Mode"},
        {value: "create", label:"Create User"},
        {value: "update", label:"Update User"},
    ]
    const contractTypeOptions=[
        {value: null, label:"Pilih Tipe Kontrak"},
        {value: "PKWTT", label:"PKWTT"},
        {value: "PKWT", label: "PKWT"},
        {value: "PKHL", label: "PKHL"},
        {value: "OUTSOURCING", label: "Outsourcing"},
        {value: "VENDOR", label: "Vendor"}
    ];
    const accountStatusOptions=[
        {value: null, label:"Pilih Status"},
        {value: true, label:"Aktif"},
        {value: false, label: "Tidak Aktif"},
    ]

    const [employeeId, setEmployeeId] = useState(null);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [contractType, setContractType] = useState(null);

    const [userId, setUserId] = useState(null);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [accountStatus, setAccountStatus] = useState(null);
    const [accountNote, setAccountNote] = useState(null);
    const [homeOfficeId, setHomeOfficeId] = useState("HO");
    const [directoryId, setDirectoryId] = useState(null);
    const [file, setFile] = useState(null);

    const [roles, setRoles] = useState(new Map());
    const [currentRoles, setCurrentRoles] = useState(null);
    const [directoryOptions, setDirectoryOptions] = useState([]);
    const [warehouseOptions, setWarehouseOptions] = useState([]);

    const [errors, setErrors] = useState(null);
    const [bulkErrors, setBulkErrors] = useState(null);
    const [bulkSuccess, setBulkSuccess] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect( () => {
        const initializePage = async () => {
            try {
                const [userResponse, directoryResponse, warehouseResponse] = await Promise.all([
                    userApi.getAllInternalUser(),
                    roleGroupedByDirectories(),
                    getWarehouses()
                ])

                setUserDatas(userResponse.data.data);
                setDirectoryOptions(directoryResponse.data.data);
                setWarehouseOptions(warehouseResponse.data.data);

                if(!roles || !currentRoles){
                    initializeRoles();
                }

                if (userIdFromQueryParam != null) {
                    const selectedUserFromQueryParam = userResponse?.data?.data?.filter(userData => userData.userId == userIdFromQueryParam);
                    if (selectedUserFromQueryParam?.length == 1) {
                        setSelectedUser([{value: selectedUserFromQueryParam, label: generateUserSelectLabel(selectedUserFromQueryParam[0])}]);
                        fillUserDataToForm({value: selectedUserFromQueryParam[0]});
                    }
                }
            } catch (e) {
                props.displayAlert({message: e.message, type: 'error'})
            }
            setIsInitialize(false);
        }

        if (isInitialize) {
            initializePage();
        }
    }, []);


    const initializeRoles = async () => {
        const data = directoryOptions;

        let tempRoles = new Map();
        data.forEach(roleDirectory => {
            roleDirectory.roles.forEach(role => {
                tempRoles.set(role.id, false);
            });
        });

        setRoles(new Map(tempRoles));
        setCurrentRoles(new Map(tempRoles));
    }

    const getTitleName = () => {
        return mode === MODE_CREATE ? "Create User" : "Update User";
    }

    const handleCheckbox = (key)=>{
        let newRoles = new Map(roles);
        let value = newRoles.get(key);
        if(value !== null){
            value = !value;
            newRoles.set(key, value);
        }else{
            newRoles.set(key, true);
        }
        setRoles(new Map(newRoles));
    }

    const onSubmitForm = async () => {
        setIsSubmitting(true);
        try {
            const request = prepareRequestData();
            let response;
            if(mode === MODE_CREATE){
                 response = (await userApi.createInternalUser(request)).data;
            }else if(mode === MODE_UPDATE){
                 response = (await userApi.updateInternalUser(request)).data;
            }

            if(response.data.success){
                setMode(null);
                setMethod(null);
                setUserData(null);
                setSelectedUser(null);
                initializeCreateVariable();
                props.displayAlert({message:`Success ${mode} user`, type: 'success'});

                setIsInitialize(true);
                const userResponse = await userApi.getAllInternalUser();
                setUserDatas(userResponse.data.data);
                setIsInitialize(false);
            }else {
                setErrors(response.data.errors);
                const errors = response.data.errors == null ? "No error" : Object.values(response.data.errors).filter(error => error != null).toString();
                props.displayAlert({message:`${errors}`, type: 'error'});
            }
        } catch (e) {
            props.displayAlert({message: e.message, type: 'error'})
        }
        setIsSubmitting(false);
    }

    const prepareRequestData = () => {
        let request;
        if(mode === MODE_UPDATE){
            request = {
                'employeeId': !hasSameProperty(userData?.employeeId, employeeId) ? employeeId : null,
                'name': !hasSameProperty(userData?.name, name) ? name : null,
                'email': !hasSameProperty(userData?.email,email) ? email : null,
                'contractTypeString': !hasSameProperty(userData?.contractType, contractType) ? contractType : null,
                'userId': userId,
                'username': !hasSameProperty(userData?.username, username) ? username : null,
                'password': !hasSameProperty(userData?.password, password) ? password : null,
                'accountStatus': !hasSameProperty(userData?.accountStatus, accountStatus) ? accountStatus : null,
                'accountNote': !hasSameProperty(userData?.accountNote, accountNote) ? accountNote : null,
                'homeOfficeId': !hasSameProperty(userData?.homeOfficeId, homeOfficeId) ? homeOfficeId : null,
                'directoryId': !hasSameProperty(userData?.directoryId, directoryId) ? directoryId : null,
                'roles': !hasSameProperty(currentRoles, roles) ? buildRolesRequest() : null,
                'mode': mode,
            }
        }else {
            request = {
                'employeeId': employeeId,
                'name': name,
                'email': email,
                'contractTypeString': contractType,
                'userId': userId,
                'username': username,
                'password': password,
                'accountStatus': accountStatus,
                'accountNote': accountNote,
                'homeOfficeId': homeOfficeId,
                'directoryId': directoryId,
                'roles': buildRolesRequest(),
                'mode': mode,
            }
        }
        return request;
    }

    const buildRolesRequest = () => {
        let roleRequest = [];
        roles.forEach((value, key)=>{
            if (mode === MODE_CREATE && !value) {
                return;
            }

            if (mode === MODE_UPDATE) {
                const currentRoleValue = currentRoles.get(key);

                if (currentRoleValue == value) {
                    return;
                }

                // if currentRole has no key of this role and the value == false
                if (currentRoleValue === undefined && !value) {
                    return;
                }
            }

            let roleItem = {
                id: key,
                value: value
            }
            roleRequest.push(roleItem);
        });

        return roleRequest;
    }

    const buildUserOptions = () => {
        return userDatas.map((user)=>{
            return ({
                value:user, label: generateUserSelectLabel(user)
            });
        });
    }

    const generateUserSelectLabel = (user)=>(
        `${user.userId} - ${user.name?user.name : user.username}`
    );

    const fillUserDataToForm = (value)=>{
        initializeCreateVariable();
        
        if(value){
            const valueData = value.value;
            setUserData(valueData);
            setEmployeeId(valueData.employeeId? valueData.employeeId : null);
            setName(valueData.name ? valueData.name : null);
            setEmail(valueData.email ? valueData.email : null);
            setContractType(valueData.contractType ? valueData.contractType : null);
            setUserId(valueData.userId ? valueData.userId : null);
            setUsername(valueData.username ? valueData.username : null);
            setPassword(valueData.password ? valueData.password : null);
            setAccountNote(valueData.accountNote ? valueData.accountNote : null);
            setAccountStatus(valueData.accountStatus != null ? valueData.accountStatus : null);
            setDirectoryId(valueData.directoryId ? valueData.directoryId : null);
            setHomeOfficeId(valueData.homeOfficeId ? valueData.homeOfficeId : "HO");
            let userRoles = new Map(roles);
            if(valueData.roles){
                valueData.roles.forEach( role => {
                    userRoles.set(role, true);
                });
            }
            setRoles(new Map(userRoles));
            setCurrentRoles(new Map(userRoles));
        }
    }

    const hasSameProperty = (currentUserData, inputData)=>{
        return _.isEqual(currentUserData, inputData);
    }

    const initializeCreateVariable = () => {
        setEmployeeId("");
        setName("");
        setEmail("");
        setContractType(null);
        setUserId("");
        setUsername("");
        setPassword("");
        setAccountStatus(true);
        setAccountNote("");
        setErrors(null);
        initializeRoles();
        setDirectoryId(null);
        setHomeOfficeId("HO");
    }

    const deleteFile = ()=>{
        setFile(null);
    }

    const sendBulkRequest = async ()=>{
        setIsSubmitting(true);
        if(!file){
            props.displayAlert({message:`No file selected`, type: 'error'});
        }else{
            try {
                const formData = new FormData();
                formData.append('csv', file);
                let response;
                response = (await userApi.bulkInternalUser({data: formData})).data;
                setBulkSuccess(response.data.success);
                if(response.data.success){
                    props.displayAlert({message:`Success bulk action`, type: 'success'});
                    setMode(null);
                    setMethod(null);
                    
                    setIsInitialize(true);
                    const userResponse = await userApi.getAllInternalUser();
                    setUserDatas(userResponse.data.data);
                    setIsInitialize(false);
                }else if(response.data.errorRows){
                    setBulkErrors(response.data.errorRows);
                    if(response.data.errorRows) {
                        props.displayAlert({message:`Error on processing bulk request`, type: 'error'});
                    }
                }
            } catch (e) {
                props.displayAlert({message: e.message, type: 'error'})
            }
        }
        setIsSubmitting(false);
    }

    const ModeSelectionInput = ()=>{
        return (
            <div className={"pl-2 pt-3"}>
                <div>
                    <InputLabel text={"Pilih Mode"}/>
                    <Select
                        style={{ width: '15%' }}
                        value={mode}
                        onChange={(selectedValue)=>{
                            initializeCreateVariable();
                            setMode(selectedValue);
                            setMethod(null);
                            setBulkErrors(null);
                            setBulkSuccess(null);
                        }}
                        options={modeOptions}
                    />
                </div>
                <LineBreak/>
            </div>
        );
    }

    const MethodSelectionInput = ()=>{
        return (
                <div className="col-3">
                    <InputLabel text={"Pilih Metode"}/>
                    <Select
                        style={{ width: '100%' }}
                        value={method}
                        onChange={(selectedValue)=>{
                            initializeCreateVariable();
                            setMethod(selectedValue);
                            setBulkErrors(null);
                            setBulkSuccess(null);
                            if(mode === MODE_CREATE){
                                setUserData(null);
                                initializeCreateVariable();
                            }
                            if(mode === MODE_UPDATE){
                                setUserData(null);
                            }
                        }}
                        options={[
                            {value: null, label:"Select Method"},
                            {value: METHOD_SINGLE, label: "Singular"},
                            {value: METHOD_BULK, label: "Bulk"}
                        ]}
                    />
                </div>
        );
    }

    const UserSelectionInput = ()=>{
        return(
            <div className='col-6'>
                <InputLabel text={"Pilih User"}/>
                <Typeahead
                    maxResults={30}
                    id="selectedUser"
                    name="selectedUser"
                    style={{ width: '100%' }}
                    value={selectedUser}
                    onChange={(selectedValue)=>{
                        setSelectedUser(selectedValue)
                        fillUserDataToForm(selectedValue[0]);
                    }}
                    options={buildUserOptions()}
                    isLoading={userDatas.length === 0}
                    selected={selectedUser}
                    clearButton
                />
            </div>
        );
    }

    const MethodAndUserSelectContainer = ()=>{
        return(
            <div className={"pt-2"}>
                <div className='row w-100'>
                    <MethodSelectionInput/>
                    {mode === "update" && method === "single" && <UserSelectionInput/>}
                </div>
                <LineBreak/>
            </div>
        );
    }

    const Loading = ()=>{
        return(
            <div className={"d-flex pt-5 pl-2"}>
                <Spinner animation="border" variant="dark" size="md"/>
                <div className={"ml-2"}>Mohon Tunggu</div>
            </div>
        );
    }

    return(
        <PageAuthCheck allowedScopes={[USER_CREATE, USER_UPDATE]}>
            <BulkErrorModal showModal={showModal} bulkErrors={bulkErrors} setShowModal={setShowModal}/>
            {
                isInitialize || !userDatas ?
                    <Loading/>:
                    <form action={"/"} method={"POST"} onSubmit={(e)=>{e.preventDefault(); onSubmitForm()}} className="mx-3" autoComplete='off'>
                        <ModeSelectionInput/>
                        {
                            !mode || mode === "" ?
                                <div/> :
                                <div className="content-container pl-2">
                                    <div className="title d-flex align-items-center">{getTitleName()}</div>
                                    <MethodAndUserSelectContainer/>
                                    {
                                        !method || method === "" ? <div/> :
                                            method === METHOD_SINGLE ?
                                                <SingleUploadContainer employeeId={employeeId}
                                                                       setEmployeeId={setEmployeeId}
                                                                       name={name} setName={setName}
                                                                       email={email} setEmail={setEmail}
                                                                       contractType={contractType}
                                                                       setContractType={setContractType}
                                                                       userId={userId}
                                                                       setUserId={setUserId}
                                                                       username={username}
                                                                       setUsername={setUsername}
                                                                       password={password}
                                                                       setPassword={setPassword}
                                                                       accountStatus={accountStatus}
                                                                       setAccountStatus={setAccountStatus}
                                                                       accountNote={accountNote}
                                                                       setAccountNote={setAccountNote}
                                                                       contractTypeOptions={contractTypeOptions}
                                                                       handleCheckbox={handleCheckbox}
                                                                       color={COLOR}
                                                                       mode={mode}
                                                                       accountStatusOptions={accountStatusOptions}
                                                                       isSubmitting={isSubmitting}
                                                                       errors={errors}
                                                                       roles={roles}
                                                                       directoryOptions={directoryOptions}
                                                                       warehouseOptions={warehouseOptions}
                                                                       directoryId={directoryId}
                                                                       homeOfficeId={homeOfficeId}
                                                                       setDirectoryId={setDirectoryId}
                                                                       setHomeOfficeId={setHomeOfficeId}
                                                />
                                                :
                                                <BulkUploadContainer color={COLOR} isSubmitting={isSubmitting}
                                                    deleteFile={deleteFile} sendBulkRequest={sendBulkRequest}
                                                                     bulkErrors={bulkErrors} bulkSuccess={bulkSuccess}
                                                                     setFile={setFile} setShowModal={setShowModal}
                                                />
                                    }
                                </div>
                        }
                    </form>
            }
        </PageAuthCheck>
    );
}

const mapStateToProps = (state) => {
    return({user: state.auth.user});
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({message, type}) => displayAlert({message, type})
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrUpdateInternalUserPage);