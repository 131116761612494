import React from "react";
import { Modal } from "react-bootstrap";

import clsx from "clsx";

import ButtonV2 from "../../components/ButtonV2/ButtonV2";

import styles from "./ConfirmDeleteModalV2.module.css";

const ConfirmDeleteModalV2 = ({
    confirmationText,
    content,
    disableButtonCancel,
    disableButtonSubmit,
    handleCancel,
    handleSubmit,
    loadingButtonSubmit,
    open,
    title,
}) => {
    return (
        <Modal
            show={open}
            backdrop={"static"}
            onHide={handleCancel}
            dialogClassName={styles.modal}
        >
            <Modal.Header
                className={clsx(styles.modalHeader, "align-items-center")}
            >
                <span style={{ fontSize: "16px", fontWeight: 600 }}>
                    {title}
                </span>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column align-items-center">
                    <div>{confirmationText}</div>
                    {content}
                </div>
                <div className="mt-2 d-flex">
                    <div style={{ marginLeft: "auto" }}>
                        <ButtonV2
                            onClick={handleCancel}
                            customStyling="mr-2"
                            text="Cancel"
                            isDisabled={disableButtonCancel}
                        />
                        <ButtonV2
                            onClick={handleSubmit}
                            isDisabled={disableButtonSubmit}
                            variant="fill"
                            text="Sure"
                            isSpinnerShown={loadingButtonSubmit}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmDeleteModalV2;
