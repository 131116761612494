import customAxios from "./axios";
import {
    FLASH_SALE_WIDGET_V1_INT_TOOLS_CURRENT,
    FLASH_SALE_WIDGET_V1_INT_TOOLS_AWS_UPLOAD,
    FLASH_SALE_WIDGET_V1_INT_TOOLS,
    FLASH_SALE_WIDGET_WAREHOUSE_GROUP_V1_INT_TOOLS
} from "../constants/api";

export function createFlashSaleBanner(data) {
    return customAxios.post(FLASH_SALE_WIDGET_V1_INT_TOOLS, data);
}

export function getFlashSaleWidgetLogs() {
    return customAxios.get(FLASH_SALE_WIDGET_V1_INT_TOOLS);
}

export function getCurrentFlashSaleWidget() {
    return customAxios.get(FLASH_SALE_WIDGET_V1_INT_TOOLS_CURRENT);
}

export function getFlashSaleWidgetStatus() {
    return customAxios.get(FLASH_SALE_WIDGET_WAREHOUSE_GROUP_V1_INT_TOOLS);
}

export function updateFlashSaleWidgetStatus(warehouseId, {data}) {
    return customAxios.put(FLASH_SALE_WIDGET_WAREHOUSE_GROUP_V1_INT_TOOLS + `${warehouseId}`, data);
}

export function uploadBannerToAws(data) {
    return customAxios.post(FLASH_SALE_WIDGET_V1_INT_TOOLS_AWS_UPLOAD, data);
}