export const CREATE_PURCHASE_ORDER_METHOD = {
    SINGLE: 'SINGLE',
    MULTIPLE: 'MULTIPLE'
}

export const CREATE_PURCHASE_ORDER_METHOD_MAP = {
    SINGLE: 'Single PO',
    MULTIPLE: 'Bulk Multiple PO',
}

export const PURCHASE_ORDER_STATUS = {
    CLOSED: 'CLOSED',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    APPROVED: 'APPROVED',
}

export const PURCHASE_ORDER_TYPE = {
    REGULAR: 'REGULAR',
    RESCUE: 'RESCUE',
    FREEBIES: 'FREEBIES',
    COMBO: 'COMBO',
    ASSET: 'ASSET',
}

export const PURCHASE_ORDER_TYPE_LIST_OPTIONS = [
    {label: 'Regular', value: PURCHASE_ORDER_TYPE.REGULAR},
    {label: 'Rescue', value: PURCHASE_ORDER_TYPE.RESCUE},
    {label: 'Freebies', value: PURCHASE_ORDER_TYPE.FREEBIES},
    {label: 'Combo (Regular & Freebies)', value: PURCHASE_ORDER_TYPE.COMBO},
    {label: 'Asset', value: PURCHASE_ORDER_TYPE.ASSET},
]

export const PURCHASE_ORDER_TYPE_OPTIONS = [
    {label: 'Regular', value: PURCHASE_ORDER_TYPE.REGULAR},
    {label: 'Rescue', value: PURCHASE_ORDER_TYPE.RESCUE},
    {label: 'Combo (Regular & Freebies)', value: PURCHASE_ORDER_TYPE.COMBO},
    {label: 'Asset', value: PURCHASE_ORDER_TYPE.ASSET},
]