import React, { useEffect, useState } from 'react';
import {connect, useDispatch} from 'react-redux'
import ProductTagTable from "./ProductTagTable"
import styles from './NewProductTags.module.css'
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck"
import { Button, Spinner } from 'react-bootstrap';
import * as scope from "../../constants/scope";
import { getProductTagLog, getProductTagWithPaginationById } from '../../apis/productTags';
import { displayAlert } from '../../redux/actions/notif';
import Pagination from '../../components/Pagination/Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import { InsertDriveFile, Warning } from '@material-ui/icons';
import { NON_EDITABLE_SPECIAL_PRODUCT_TAG, SPECIAL_PRODUCT_TAG_NAMES } from './ProductTag';
import moment from "moment";

const ProductTagInfo = () => {

    const location = useLocation()
    const id = location.state.id
    const name = location.state.name
    //const totalSku = location.state.totalSku
    const type = location.state.type
    
    const loadProducts = async (page) => {
        setIsProductLoading(true)
        const param = {page: page - 1, size: productPaginationState.numberOfElements}
        try {
            const productResponse = await getProductTagWithPaginationById(id, param)
            const products = productResponse.data?.data?.data

            setProductPaginationState(
                {   
                    ...productPaginationState,
                    totalElements : productResponse.data.data.totalElements,
                    totalPages : productResponse.data.data.totalPages,
                    currentPage : page,
                    first : (page === 1),
                    last : (page === productResponse.data.data.totalPages),
                    loadingData : false,
                }
            )

            let productOptions = [];
            if (Array.isArray(products) && products.length) {
                products.forEach(product => {
                    productOptions.push({ value: product.product.id, label: product.product.skuId + " " + product.product.name, priority: product.priority });
                })
            }

            setProducts(productOptions)
            setTotalSku(productResponse.data.data.totalElements)

        } catch(e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        } finally {
            setIsProductLoading(false)
        }
    }

    const loadProductTagLog = async (page) => {
        setIsLogLoading(true)
        const param = {page: page - 1, size: logPaginationState.numberOfElements}
        try {
            const logResponse = await getProductTagLog(id, param)
            setLogPaginationState(
                {   
                    ...logPaginationState,
                    totalElements : logResponse.data.data.totalElements,
                    totalPages : logResponse.data.data.totalPages,
                    currentPage : page,
                    first : (page === 1),
                    last : (page === logResponse.data.data.totalPages),
                    loadingData : false,
                }
            )

            const logs = logResponse?.data?.data?.data;

            const logTempData = []

            if (Array.isArray(logs) && logs.length) {
                let lastUrlRow = 0
                logs.forEach((log,index) => {
                    if(index == lastUrlRow) {
                        const jsonObject = JSON.parse(log.jsonValue)
                        if (jsonObject.listOfProducts != null) {
                            setCsvUrl(jsonObject.listOfProducts)
                        } else {
                            lastUrlRow += 1
                        }
                    }
                    logTempData.push({ date: log.createDate, editor: log.lastEditorEmail ?? log.lastEditorUsername ?? log.lastEditorPhone, jsonValue: log.jsonValue});
                })
            }

            setLogData(logTempData)
        } catch(e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        } finally {
            setIsLogLoading(false)
        }
    }

    function initialPaginationState (goToPage, numberOfElements) {
        return {
            loadingData: false,
            currentPage: 0,
            totalPages: 0,
            first: false,
            last: false,
            numberOfElements,
            totalElements: 0,
            goToPage,
            custom: false,
            currentPagePagination: 0
        };
    }

    const [products, setProducts] = useState([])
    const [logData, setLogData] = useState([{}])
    const [isProductLoading, setIsProductLoading] = useState(false)
    const [isLogLoading, setIsLogLoading] = useState(false)
    const [csvUrl, setCsvUrl] = useState("")
    const [totalSku, setTotalSku] = useState(0)

    const [logPaginationState, setLogPaginationState] = useState(initialPaginationState(loadProductTagLog, 10))

    const [productPaginationState, setProductPaginationState] = useState(initialPaginationState(loadProducts, 10))

    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        loadProducts(1)
        loadProductTagLog(1)
    }, [])

    return <PageAuthCheck allowedScopes={[scope.PRODUCT_TAG_UPDATE]}>
        {(isLogLoading || isProductLoading) ? <div className={styles.overlaySpinner}><Spinner animation="border" size="lg" /></div>: <></>}
        <div className="body-div">
            <div className="internal-body-div">
                <div className={styles.productTagInfo}>
                    <h4>Product Tag Information</h4>
                    <div className='d-flex'>
                        <Button variant="primary" disabled={NON_EDITABLE_SPECIAL_PRODUCT_TAG.includes(name)} onClick={e => history.push("/product-tags/update", {id: id, totalSku, name})}>Edit</Button>
                        <Button onClick={() => history.push('/product-tags/list')} className={styles.customButton} variant="secondary">Back</Button>
                    </div>
                </div>
                <div className='d-flex' style={{alignItems: "center", marginBottom: 16}}>
                    <p>Product Tag Name:</p>
                    <input style={{marginLeft: 24,padding: 4, width: "30%"}} disabled={true} value={name} size="lg"/>
                </div>
                <div className='d-flex' style={{alignItems: "center", marginBottom: 16}}>
                    <p>Product Tag Type:</p>
                    <input style={{marginLeft: 24,padding: 4, width: "30%"}} disabled={true} value={type} size="lg"/>
                </div>

                <div className={styles.productTagInfo}>
                    <b>List of Products: {totalSku}</b>
                    <a hidden={totalSku == 0 || logData.length == 0} style={{color: 'white', textDecoration: "none"}} className={styles.exportCsvButton} href={csvUrl}><InsertDriveFile /> Export List Of Products (.csv)</a>   
                </div>
                {totalSku > 200 ? <p><Warning /> Manual table Unavailable for total skus above 200, can only be updated/edited by bulk upload</p> :
                <>
                    <table style={{width:"100%", marginTop: 0, marginBottom: 8}}>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Product</th>
                                <th>Priority</th>
                            </tr>
                        </thead>
                        <tbody >
                            {products.map((data, index) => {
                                const displayedNumber = index+1+((productPaginationState.currentPage-1) * productPaginationState.numberOfElements)
                                return <tr key={index} >
                                    <td style={{width:"20%"}}>{displayedNumber}</td>
                                    <td>{data.label}</td>
                                    <td>{data.priority}</td>
                                </tr>
                            })}
                        </tbody>
                    </table> 
                    <p hidden={totalSku > 0} className='text-center'>There is No Products on This Product Tag</p>

                    <Pagination {...productPaginationState}/> 
                </> }

                <p style={{marginTop: 32}}><b>Logs</b></p>

                <table className='table'>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Editor</th>
                            <th>Json</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logData.map((data, index) => {
                            return <tr key={index}>
                                <td>{moment(data.date).format("DD-MM-YYYY HH:mm:ss")}</td>
                                <td>{data.editor}</td>
                                <td className='text-left'>
                                    <DisplayJson jsonString={data.jsonValue}/>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
                <p hidden={logData.length > 0} className='text-center'>There is No Log Data</p>

            
            <Pagination {...logPaginationState}/> 
        </div>
    </div>
    </PageAuthCheck>
}

export function DisplayJson({ jsonString }) {
    try {
      const jsonObject = JSON.parse(jsonString);
      
      return <div>
        <>{"{"}</><br/>
        {Object.keys(jsonObject).map((key) => 
            <>
                <>&nbsp;&nbsp;{`"${key}": `}</>
                {jsonObject[key].includes("https") ? <a href={jsonObject[key]}>{jsonObject[key]}</a> : <>{`${jsonObject[key]}`},</>}
                <br/>
            </>
        )}
       
        <>{"}"}</>
    </div>
    } catch (error) {
      return <div></div>;
    }
  }

export default connect()(ProductTagInfo)