import {Col, Form} from "react-bootstrap";
import React, {memo, useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import {displayAlert} from "../../../../redux/actions/notif";
import {useDispatch} from "react-redux";
import {Field, useFormikContext} from "formik";
import * as apiWarehouse from "../../../../apis/warehouses";
import DatePicker from "react-datepicker";
import { Typeahead } from 'react-bootstrap-typeahead';
import SelectWarehouse from "../../../../components/SelectWarehouse/SelectWarehouse";

function InternalShipmentCard(props) {
    const {values, setFieldValue} = useFormikContext();
    const {internalShipment, readOnly} = values;

    // Redux Hook
    const dispatch = useDispatch();
    // fetchedData
    const [warehouses, setWarehouses] = useState([])

    useEffect(() => {
        function handleResponse(response) {
            setWarehouses(response.warehouses.data.data);
        }

        function handleProps() {
            setWarehouses(props.warehouses);
        }

        // handle props instead of fetch new warehouse
        if(props.warehouses !== undefined && props.warehouses?.length > 0) {
            handleProps();
            return;
        }

        const fetch = async () => {
            try {
                const warehousePromise = apiWarehouse.getWarehouses({sort: 'id,asc'});

                const [warehouseResponse] = await Promise.all([
                    warehousePromise,
                ]);

                handleResponse({
                    warehouses: warehouseResponse,
                });
            } catch (e) {
                dispatch(displayAlert({ message: e.message, type: 'error' }));
            }
        }
        fetch().then(() => {});
    }, []);

    return <div className='internal-body-div mb-5'>
        <h5>B. Internal Shipment</h5>
        <hr/>
        <div>
            <Row>
                <Col>
                    <WarehouseField
                        internalShipment={internalShipment}
                        warehouses={warehouses}
                        setFieldValue={setFieldValue}
                        disabled={readOnly}
                    />
                </Col>
                <Col>
                    <AddressField
                        disabled={readOnly}
                    />
                </Col>
            </Row>
            <hr/>
            <Row>
                <Col>
                    <ETAField
                        internalShipment={internalShipment}
                        setFieldValue={setFieldValue}
                        disabled={readOnly}
                    />
                </Col>
                <Col>
                    <ShipmentMethodField
                        disabled={readOnly}
                    />
                </Col>
                <Col>
                    <VehicleNumbersField
                        disabled={readOnly}
                    />
                </Col>
            </Row>
        </div>
    </div>
}

function WarehouseField(props){
    const {internalShipment, warehouses, setFieldValue, disabled} = props;
    const handleWarehouseChange = (selected) => {
        const warehouseId = selected[0] ? selected[0].id : "";
        const address = selected[0] ? selected[0].address : "";
        const warehouseName = selected[0] ? `${selected[0].id} - ${selected[0].name}` : "";
    
        setFieldValue('internalShipment.selectedWarehouseTypeAhead', selected[0] ?? "");
        setFieldValue('internalShipment.warehouseId', warehouseId);
        setFieldValue('internalShipment.address', address);
        setFieldValue('internalShipment.warehouseName', warehouseName);
    }

    let selectedWarehouse = internalShipment.selectedWarehouseTypeAhead;
    if (internalShipment) {
        selectedWarehouse = warehouses.find(w => w.id === internalShipment.warehouseId);
    }
    return <Form.Group>
        <Form.Label>Warehouse</Form.Label>
        <div>
        <Typeahead
            id='internalShipment-filter-id'
            labelKey={option => option.id + ' - ' + option.name}
            options={warehouses}
            onChange={(selected) => handleWarehouseChange(selected)}
            name='internalShipment.warehouseId'
            clearButton={true}
            disabled={disabled}
            selected={selectedWarehouse === undefined ? [] : [selectedWarehouse]}
            />
        </div>
    </Form.Group>
}

function AddressField(props){
    const {disabled} = props;
    return <Form.Group>
        <Form.Label>Alamat Warehouse</Form.Label>
        <div>
            <Field
                name="internalShipment.address"
                type="text"
                className="form-control"
                disabled={disabled}
            />
        </div>
    </Form.Group>
}

function ShipmentMethodField(props){
    const {disabled} = props;
    return <Form.Group>
        <Form.Label>Shipment Method</Form.Label>
        <div>
            <Field
                name="internalShipment.shipmentMethod"
                type="text"
                className="form-control"
                disabled={disabled}
            />
        </div>
    </Form.Group>
}

function VehicleNumbersField(props){
    const {disabled} = props;
    return <Form.Group>
        <Form.Label>Vehicle Numbers</Form.Label>
        <div>
            <Field
                name="internalShipment.vehicleNumbers"
                type="text"
                className="form-control"
                placeholder='B 0000 X, B 0001 X, ...'
                disabled={disabled}
            />
            <small className="form-text text-muted">Pakai koma ketika lebih dari 1 kendaraan</small>
        </div>
    </Form.Group>
}

function ETAField(props){
    const {internalShipment, setFieldValue, disabled} = props;
    return <Form.Group>
        <Form.Label>ETA</Form.Label>
        <div>
            <DatePicker
                selected={internalShipment.expectedArrivalAt}
                className='form-control'
                showTimeSelect
                onChange={(date) => setFieldValue('internalShipment.expectedArrivalAt', date)}
                placeholderText='dd-mm-yyyy hh:mm'
                timeFormat='HH:mm'
                dateFormat='dd-MM-yyyy HH:mm'
                disabled={disabled}
            />
        </div>
    </Form.Group>
}


export default memo(InternalShipmentCard);