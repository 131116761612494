export const INSTAN = 'SEGARI_INSTAN';
export const TURBO = 'SEGARI_TURBO';
export const EXTENSION = 'SEGARI_EXTENSION';
export const PRIORITY_30 = 'PRIORITY_30';
export const PRIORITY_45 = 'PRIORITY_45';
export const PRIORITY_60 = 'PRIORITY_60';
export const PRIORITY_75 = 'PRIORITY_75';
export const PRIORITY = 'PRIORITY'; // for order limit only
export const NON_PRIORITY = 'NON_PRIORITY';

export const isSDDVariant = (request) => {
    return new Set([INSTAN, TURBO]).has(request) || getPriorityVariant().has(request);
}

export function getAliasSameDayDeliveryVariantType(type) {
    switch (type) {
        case TURBO: return 'Turbo';
        case INSTAN: return 'Instan';
        case EXTENSION: return 'Extension';
        case PRIORITY_30: return 'Priority 30';
        case PRIORITY_45: return 'Priority 45';
        case PRIORITY_60: return 'Priority 60';
        case PRIORITY_75: return 'Priority 75';
    }
}

export const convertToFormattedShorthandSameDayDeliveryVariant = (sameDayDeliveryVariant) => {
    const formattedSameDayDeliveryVariant = {
        [INSTAN]: 'Instan',
        [TURBO]: 'Turbo',
        [EXTENSION]: 'Extension',
        [PRIORITY_30]: 'Priority 30',
        [PRIORITY_45]: 'Priority 45',
        [PRIORITY_60]: 'Priority 60',
        [PRIORITY_75]: 'Priority 75',
        [PRIORITY]: 'Priority',
        [NON_PRIORITY]: 'Non-Priority'
    };

    return formattedSameDayDeliveryVariant[sameDayDeliveryVariant] || '';
}

export const getSameDayDeliveryVariantOptions = () => {
    return [
        {value: TURBO, label: convertToFormattedShorthandSameDayDeliveryVariant(TURBO)},
        {value: INSTAN, label: convertToFormattedShorthandSameDayDeliveryVariant(INSTAN)},
        {value: EXTENSION, label: convertToFormattedShorthandSameDayDeliveryVariant(EXTENSION)},
        {value: PRIORITY_30, label: convertToFormattedShorthandSameDayDeliveryVariant(PRIORITY_30)},
        {value: PRIORITY_45, label: convertToFormattedShorthandSameDayDeliveryVariant(PRIORITY_45)},
        {value: PRIORITY_60, label: convertToFormattedShorthandSameDayDeliveryVariant(PRIORITY_60)},
        {value: PRIORITY_75, label: convertToFormattedShorthandSameDayDeliveryVariant(PRIORITY_75)}
    ]
}

export const getPriorityVariant = () =>{
    return new Set([PRIORITY_30, PRIORITY_45, PRIORITY_60, PRIORITY_75]);
}

export const isPriority = (sameDayDeliveryVariant) => {
    if (getPriorityVariant().has(sameDayDeliveryVariant)) {
        return true;
    }

    return false;
}