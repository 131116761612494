import React from 'react'
import { bindActionCreators } from 'redux';
import { displayAlert } from '../../redux/actions/notif'
import { connect } from 'react-redux'
import PageAuthCheck from '../../components/AuthCheck/PageAuthCheck';
import MainContainer from '../../components/MainContainer/MainContainer';
import { Col, Row, Spinner } from 'react-bootstrap';
import ApprovalModal from '../../components/ApprovalModal/ApprovalModal';
import { Field, Form, Formik } from 'formik';
import { Switch } from '@material-ui/core';
import DatePicker from 'react-datepicker';

import * as flashSaleWidgetApi from '../../apis/flashSaleWidget'
import * as authApi from '../../apis/auth'
import { MANAGE_FLASH_SALE_WIDGET } from '../../constants/scope';

class FlashSaleWidgetManagementPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            canUpload: false,
            creatorId: '',
            currentBanner: {
                name: '',
                startDate: '',
                endDate: '',
                url: '',
            },
            imgPlaceholder: 'http://via.placeholder.com/360x183',
            isFlashSaleWidgetOn: false,
            isLoaded: false,
            isLoading: true,
            isJadetabekOn: false,
            isSurabayaOn: false,
            logs: [],
            currLogsPage: 0,
            maxLogsPage: 0,
            logsItemPerPage: 15,
            newBanner: {
                name: '',
                startDate: '',
                endDate: '',
                url: '',
                awsUrl: '',
                id: '',
            },
            showModalNewBanner: false,
            showModalSwitch: false,
            showModalUploadBanner: false,
            tutorialUrl: 'https://docs.google.com/document/d/1aNvg9MX1fDUTHgycP1WQT0s65O7A_i_dZbDX8uzy7QY/edit',
            
        }
        this.handleSubmit.bind(this)
        this.handleUploadToAws.bind(this)
        this.getParamsForNewBanner.bind(this)
        this.loadCurrentBanner.bind(this)
        this.loadCurrentEditorId.bind(this)
        this.loadFswLogChanges.bind(this)
    }

    async componentDidMount() {
        let currBanner = ''
        let currEditorId = ''
        let logs = ''
        let fswStatus = ''
        try {
            currBanner = await this.loadCurrentBanner()
            currEditorId = await this.loadCurrentEditorId()
            logs = await this.loadFswLogChanges()
            fswStatus = await this.getFswWarehouseStatus()
        } catch(e) {
            alert(e)
        } finally {
            this.setState({
                logs: logs,
                maxLogsPage: Math.ceil(logs.length/this.state.logsItemPerPage),
                currentBanner: {
                    name: currBanner.bannerName,
                    startDate: currBanner.startDate,
                    endDate: currBanner.endDate,
                    url: currBanner.bannerUrl,
                },
                creatorId: currEditorId.creatorId,
                isJadetabekOn: fswStatus.warehouseJadetabek,
                isSurabayaOn: fswStatus.warehouseSurabaya,
                isLoaded: true,
                isLoading: false,
            })
        }
    }
    
    getParamsForNewBanner(bannerId, startDate, endDate, creatorId, bannerUrl, name) {
        return {
            id: bannerId,
            startDate: startDate,
            endDate: endDate,
            creatorId: creatorId,
            bannerUrl: bannerUrl,
            name: name,
        }
    }

    async getFswWarehouseStatus() {
        let warehouseJadetabek = false
        let warehouseSurabaya = false
        try {
            const response = await flashSaleWidgetApi.getFlashSaleWidgetStatus()
            if(response) {
                let statusArray = response.data.data
                statusArray.map((value, _) => {
                    if(value.warehouseGroupName == "Jadetabek") {
                        warehouseJadetabek = value.isActive
                    }
                    if(value.warehouseGroupName == "Surabaya") {
                        warehouseSurabaya = value.isActive
                    }
                })
            }
        } catch(e) {
            alert(e)
        } finally {
            return { warehouseJadetabek, warehouseSurabaya }
        }
    }

    async loadCurrentEditorId() {
        let creatorId = ''
        try {
            const currentEditor = await authApi.me()
            if(currentEditor.data != null) {
                creatorId = currentEditor.data.data.id
            }
        } catch(e) {
            alert(e)
        } finally {
            return { creatorId }
        }
    }

    async loadCurrentBanner() {
        let startDate = ''
        let endDate = ''
        let bannerUrl = ''
        let bannerName = ''
        try {
            const currentFsw = await flashSaleWidgetApi.getCurrentFlashSaleWidget()
            if(currentFsw.data != null) {
                startDate = new Date(currentFsw.data.data.startDate).toLocaleDateString('ID') + '  ' + new Date(currentFsw.data.data.startDate).toLocaleTimeString('en', {hour12: false})
                endDate = new Date(currentFsw.data.data.endDate).toLocaleDateString('ID') + '  ' + new Date(currentFsw.data.data.endDate).toLocaleTimeString('en', {hour12: false})
                bannerName = currentFsw.data.data.name
                bannerUrl = currentFsw.data.data.bannerUrl
            }
        } catch (error) {
            alert(error)
        } finally {
            return { startDate, endDate, bannerUrl, bannerName }
        }
    }

    async loadFswLogChanges() {
        let logs = []
        try {
            const response = await flashSaleWidgetApi.getFlashSaleWidgetLogs()
            if(response) {
                logs = response.data.data
            }
        } catch(error) {
            alert(error)
        } finally {
            return logs
        }
    }

    async handleSubmit(data) {
        this.setState({
            isLoading: true,
        })
        try {
            const response = await flashSaleWidgetApi.createFlashSaleBanner(data)
            if(response.status == 200) {
                alert(response.data.data.action + " " + response.data.data.name)
                window.location.reload()
            } else {
                alert(response.data)
            }
        } catch (error) {
            alert(error)
        } finally {
            this.setState({
                isLoading: false,
            })
        }
    }

    async handleUploadToAws(data) {
        this.setState({
            isLoading: true,
        })
        let bannerAwsUrl = ''
        let bannerId = ''
        try {
            const response = await flashSaleWidgetApi.uploadBannerToAws(data)
            if(response) {
                bannerAwsUrl = response.data.data.bannerUrl
                bannerId = response.data.data.id
                alert(`Image URL in AWS: ${bannerAwsUrl}`)
            }
        } catch (error) {
            alert(error)
        } finally {
            this.setState({ isLoading: false, showModalUploadBanner: false })
            return { bannerAwsUrl, bannerId }
        }
    }

    async handleFswStatusChange(value) {
        this.setState({
            isLoading: true,
        })
        let isSuccessChanging = ''
        let msgError = ''
        try {
            const response = await flashSaleWidgetApi.updateFlashSaleWidgetStatus(1, { data: { isActive: value } })
            if(response) {
                isSuccessChanging = response.data.data
                msgError = response.data.message
                this.setState({ 
                    isLoading: false,
                    isJadetabekOn: isSuccessChanging && value,
                })
            }
        } catch(e) {
            alert(e)
        } finally {
            return { msgError: msgError, isSuccessChanging }
        }
        
    }

    renderLogsInTable() {
        return (
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Creator Name</th>
                        {/* <th>Creator Email</th> */}
                        <th>Action</th>
                        <th>Creation Date</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Banner URL</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.logs.map((value, idx) => {
                            if(idx >= (this.state.currLogsPage*this.state.logsItemPerPage) && idx < (this.state.currLogsPage + 1)*this.state.logsItemPerPage) {
                                return <tr key={idx}>
                                    <td>{value.id}</td>
                                    <td>{value.name}</td>
                                    <td>{value.creatorName}</td>
                                    {/* <td>{value.creatorEmail}</td> */}
                                    <td>{value.action}</td>
                                    <td>{new Date(value.createdAt).toLocaleDateString('ID') + '  ' + new Date(value.createdAt).toLocaleTimeString('EN', {hour12: false})}</td>
                                    <td>{new Date(value.startDate).toLocaleDateString('ID') + '  ' + new Date(value.startDate).toLocaleTimeString('EN', {hour12: false})}</td>
                                    <td>{new Date(value.endDate).toLocaleDateString('ID') + '  ' + new Date(value.endDate).toLocaleTimeString('EN', {hour12: false})}</td>
                                    <td><a href={value.bannerUrl}>{value.bannerUrl}</a></td>
                                </tr>
                            }
                        })
                    }
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <PageAuthCheck allowedScopes={[MANAGE_FLASH_SALE_WIDGET]}>
                <MainContainer title='Manage Flash Sale Widget'>
                    {
                        this.state.isLoaded ?
                        <React.Fragment>
                        <Formik 
                        initialValues={{
                                        currentBannerUrl: this.state.currentBanner.url,
                                        currentName: this.state.currentBanner.name,
                                        currentStartDate: this.state.currentBanner.startDate,
                                        currentEndDate: this.state.currentBanner.endDate,
                                        isVisible: this.state.isJadetabekOn && 'Y', 
                                        visibilityInput: '',
                                        id: '',
                                        name: '', 
                                        startDate: '', 
                                        endDate: '', 
                                        url: '',
                                        awsUrl: '',
                                    }}
                        onSubmit={(values) => {
                            if(values.name == '' || values.startDate == '' || values.endDate == '' || values.url == '' || values.awsUrl == '' || values.id == '') {
                                alert('All fields should not be empty!')
                            } else if(!this.state.canUpload) {
                                alert("Banner URL can't be previewed. Please check 'How to upload an image?'.")
                            } else if(values.startDate >= values.endDate) {
                                alert("Start Date cannot be the same or later than End Date. Please check Start Date and End Date!")
                            } else {
                                this.setState({
                                    showModalNewBanner: true, 
                                    newBanner:  {
                                        id: values.id,
                                        name: values.name,
                                        startDate: new Date(values.startDate).toISOString(),
                                        endDate: new Date(values.endDate).toISOString(),
                                        url: values.url,
                                        awsUrl: values.awsUrl,
                                    }
                                })
                            }
                        }}>
                        {({values, setFieldValue}) => 
                            <React.Fragment>
                                <ApprovalModal 
                                    show={this.state.showModalSwitch}
                                    message={`Do you want to change widget to ${values.visibilityInput} in JADETABEK?`}
                                    onCancel={() => this.setState({ showModalSwitch: false })} 
                                    onConfirm={async () => {
                                        this.setState({
                                            showModalSwitch: false,
                                        })
                                        let data = await this.handleFswStatusChange(values.visibilityInput)
                                        if(data.msgError == '') {
                                            setFieldValue('isVisible', data.isSuccessChanging && values.visibilityInput && 'Y')
                                        }
                                    }}/>
                                <ApprovalModal 
                                    show={this.state.showModalNewBanner} 
                                    message={`Are you sure want to make new flash sale banner?`}
                                    onCancel={() => this.setState({ showModalNewBanner:false })} 
                                    onConfirm={async () => {
                                        this.setState({
                                            showModalNewBanner: false,
                                        })
                                        let params = this.getParamsForNewBanner(values.id, values.startDate, values.endDate, this.state.creatorId, values.awsUrl, values.name)
                                        await this.handleSubmit(params)
                                    }}/>
                                <ApprovalModal 
                                    show={this.state.showModalUploadBanner}
                                    message={`Are you sure want to upload Image from Banner URL to AWS?`}
                                    onCancel={() => this.setState({ showModalUploadBanner:false })} 
                                    onConfirm={async () => {
                                        this.setState({
                                            showModalUploadBanner: false,
                                        })
                                        const { bannerAwsUrl, bannerId } = await this.handleUploadToAws({
                                                                    name: values.name,
                                                                    bannerUrl: values.url,
                                                                })
                                        if(bannerAwsUrl && bannerId) {
                                            setFieldValue('id', bannerId)
                                            setFieldValue('awsUrl', bannerAwsUrl)
                                        }
                                    }}/>
                                <Form>
                                    <Row>
                                        <Col sm={1}>
                                            <div>JADETABEK</div>
                                        </Col>
                                        <Col sm={2}>
                                            <Switch 
                                                value='Y' 
                                                checked={values.isVisible == 'Y'} 
                                                onChange={(_, checked) => {
                                                    setFieldValue('visibilityInput', checked)
                                                    this.setState({
                                                        showModalSwitch: true,
                                                    })
                                                }}
                                                color='primary'/>
                                        </Col>
                                        {/* <Col sm={1}>
                                            <div>SURABAYA</div>
                                        </Col>
                                        <Col sm={2}>
                                            <Switch 
                                                name='isVisible' 
                                                value='Y' 
                                                checked={values.isVisible == 'Y'} 
                                                onChange={(event, checked) => {
                                                    this.setState({showModalSwitch: true})
                                                    // setFieldValue('isVisible', checked ? 'Y' : 'N');
                                                }}
                                                color='primary'/>
                                        </Col> */}
                                        {/* in the next development will involve jadetabek and surabaya switch */}
                                    </Row>
                                    <br/>
                                    <h4>Current Flash Sale Banner</h4>
                                    <br/>
                                    <Row>
                                        <Col sm={5}>
                                            <Row sm={2}>
                                                <Col sm={3}>
                                                    <div>Name*</div>
                                                </Col>
                                                <Col sm={7}>
                                                    <Field name='currentName' className='form-control' disabled/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row sm={2}>
                                                <Col sm={3}>
                                                    <div>Start Date*</div>
                                                </Col>
                                                <Col sm={7}>
                                                    <Field name='currentStartDate' className='form-control' disabled/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row sm={2}>
                                                <Col sm={3}>
                                                    <div>End Date*</div>
                                                </Col>
                                                <Col sm={7}>
                                                    <Field name='currentEndDate' className='form-control' disabled/>
                                                </Col>
                                            </Row>
                                            <br/>
                                        </Col>
                                        <Col sm={5}>
                                            <img 
                                                width={380}
                                                height={183}
                                                src={values.currentBannerUrl}
                                                style={{backgroundImage: `url(${this.state.imgPlaceholder})`}} />
                                        </Col>
                                    </Row>
                                    <br/>
                                    <h4>Upload New Banner</h4>
                                    <br/>
                                    <Row>
                                        <Col sm={5}>
                                            <Row sm={2}>
                                                <Col sm={3}>
                                                    <div>ID Banner*</div>
                                                </Col>
                                                <Col sm={7}>
                                                    <Field name='id' className='form-control' placeholder='Autofilled after Upload to AWS' disabled/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row sm={2}>
                                                <Col sm={3}>
                                                    <div>Name*</div>
                                                </Col>
                                                <Col sm={7}>
                                                    <Field name='name' className='form-control'/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row sm={2}>
                                                <Col sm={3}>
                                                    <div>Start Date*</div>
                                                </Col>
                                                <Col sm={7}>
                                                    <DatePicker
                                                        selected={values.startDate}
                                                        onChange={date => setFieldValue('startDate', date)}
                                                        className='form-control'
                                                        showTimeInput
                                                        placeholderText='dd/MM/yyyy  hh:mm'
                                                        dateFormat='dd/MM/yyyy  HH:mm'
                                                        minDate={new Date()}
                                                        maxDate={values.endDate}
                                                        endDate={values.endDate}
                                                        selectsStart
                                                        isClearable
                                                    />
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row sm={2}>
                                                <Col sm={3}>
                                                    <div>End Date*</div>
                                                </Col>
                                                <Col sm={7}>
                                                    <DatePicker
                                                        selected={values.endDate}
                                                        onChange={date => setFieldValue('endDate', date)}
                                                        className='form-control'
                                                        showTimeInput
                                                        placeholderText='dd/MM/yyyy  hh:mm'
                                                        dateFormat='dd/MM/yyyy  HH:mm'
                                                        minDate={values.startDate == '' ? new Date() : values.startDate}
                                                        startDate={values.startDate}
                                                        selectsEnd
                                                        isClearable
                                                    />
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row sm={2}>
                                                <Col sm={3}>
                                                    <div>Banner URL*</div>
                                                </Col>
                                                <Col sm={7}>
                                                    <Field name='url' className='form-control'/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row sm={2}>
                                                <Col sm={3}>
                                                    <div>AWS Banner URL*</div>
                                                </Col>
                                                <Col sm={7}>
                                                    <Field name='awsUrl' className='form-control' placeholder='Autofilled after Upload to AWS' disabled/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <Row sm={2}>
                                                <Col>
                                                    <a 
                                                        target='_blank'
                                                        href={this.state.tutorialUrl} >How to upload an image? (mandatory)</a>
                                                </Col>
                                                <Col>
                                                    <button 
                                                        type='button' 
                                                        onClick={() => {
                                                            if(values.name == '' || values.url == '') {
                                                                alert(`Please fill Name or Banner URL Field!`)
                                                            } else {
                                                                this.setState({
                                                                    showModalUploadBanner: true,
                                                                })
                                                            }
                                                        }} 
                                                        className='btn btn-secondary'
                                                        disabled={this.state.isLoading}>
                                                        <span>{this.state.isLoading && <Spinner animation='border' size='sm' />}</span> Upload to AWS
                                                    </button>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <button 
                                                type='submit' 
                                                className='btn btn-primary'
                                                disabled={this.state.isLoading}>
                                                <span>{this.state.isLoading && <Spinner animation='border' size='sm' />}</span> Submit
                                            </button>
                                        </Col>
                                        <Col sm={5}>
                                            <div>Preview from AWS URL</div>
                                            <img
                                                width={380}
                                                height={183}
                                                src={values.awsUrl}
                                                onError={() => this.setState({canUpload: false})}
                                                onLoad={() => this.setState({canUpload: true})}
                                                loading='lazy'
                                                style={{backgroundImage: `url(${this.state.imgPlaceholder})`}} />
                                        </Col>
                                    </Row>
                                </Form>
                            </React.Fragment>
                        }
                    </Formik>
                    <br/>
                    <Row xs="auto">
                        <Col>
                            {
                                Array.from(
                                    Array(this.state.maxLogsPage), 
                                    (e, i) =>   <button 
                                                    key={i} 
                                                    style={{marginLeft: "10px", float: "left"}} 
                                                    onClick={() => this.setState({currLogsPage: i})} 
                                                    className='btn btn-primary'>
                                                        {i + 1}
                                                </button>)
                            }
                        </Col>
                    </Row>
                    {this.renderLogsInTable()}
                    </React.Fragment> :
                    <Spinner animation='border' size='md' />
                    }
                </MainContainer>
            </PageAuthCheck>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({message, type}) => displayAlert({message, type})
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashSaleWidgetManagementPage);

