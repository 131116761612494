import React, {useEffect, useState} from 'react';
import {Typeahead} from "react-bootstrap-typeahead";
import {v1 as uuid} from 'uuid';
import {CREATE_UNIVERSAL_BARCODE, UPDATE_UNIVERSAL_BARCODE} from "../../../constants/scope";

const UniversalBarcodeForm = props => {
    const {universalBarcodes, onUniversalBarcodeDelete, onNewUniversalBarcodeAdded, skuId} = props;
    const [newUniversalBarcodes, setNewUniversalBarcodes] = useState([]);
    const [deletedUniversalBarcodes, setDeletedUniversalBarcodes] = useState(new Set());

    useEffect(() => {
        setNewUniversalBarcodes([]);
        setDeletedUniversalBarcodes(new Set());
    }, [skuId, universalBarcodes]);

    const handleExistingUniversalBarcodeDeletion = (ub) => {
        const newSet = new Set(deletedUniversalBarcodes);
        newSet.add(ub.serialNumber);
        setDeletedUniversalBarcodes(newSet);
        onUniversalBarcodeDelete(Array.from(newSet));
    }

    const handleExistingUniversalBarcodeDeletionRestore = (ub) => {
        const newSet = new Set(deletedUniversalBarcodes);
        newSet.delete(ub.serialNumber);
        setDeletedUniversalBarcodes(newSet);
        onUniversalBarcodeDelete(Array.from(newSet));
    }

    const mapExistingUniversalBarcodes = (dub, idx) =>
        <div key={dub.serialNumber} className='row p-2'
             style={deletedUniversalBarcodes.has(dub.serialNumber) ? {'backgroundColor': 'rgba(255, 0, 0, 0.15)'} : {}}>
            <div className='col-3' key={`existing-${idx}`}>
                <input className='form-control' disabled={props.isDisabled(CREATE_UNIVERSAL_BARCODE, UPDATE_UNIVERSAL_BARCODE)} value={dub.serialNumber}/>
            </div>
            <div className='col-2'>
                <input className='form-control' disabled={props.isDisabled(CREATE_UNIVERSAL_BARCODE, UPDATE_UNIVERSAL_BARCODE)} value={dub.type}/>
            </div>
            <div className='col-2'>
                <input className='form-control' disabled={props.isDisabled(CREATE_UNIVERSAL_BARCODE, UPDATE_UNIVERSAL_BARCODE)} value={dub.quantityMultiplier}/>
            </div>
            <div className='col-1'>
                {!deletedUniversalBarcodes.has(dub.serialNumber) &&
                    <i className='icon-trash fa-xl' onClick={() => handleExistingUniversalBarcodeDeletion(dub)}/>}
                {deletedUniversalBarcodes.has(dub.serialNumber) && <i className='icon-refresh fa-xl'
                                                                      onClick={() => handleExistingUniversalBarcodeDeletionRestore(dub)}/>}
            </div>
        </div>;

    function handleNewUniversalBarcodeUpdate(idx, fieldName, val) {
        newUniversalBarcodes[idx][fieldName] = val;
        setNewUniversalBarcodes([...newUniversalBarcodes]);
        onNewUniversalBarcodeAdded([...newUniversalBarcodes]);
    }

    const mapNewUniversalBarcodes = (dub, idx) => <div key={`n-${idx}`} className='row p-2'>
        <div className='col-3'>
            <input className='form-control' placeholder='Serial Number...'
                   value={dub.serialNumber} onChange={evt => {
                handleNewUniversalBarcodeUpdate(idx, 'serialNumber', evt.target.value);
            }
            }
                disabled={props.isDisabled(CREATE_UNIVERSAL_BARCODE, UPDATE_UNIVERSAL_BARCODE)}
            />
        </div>
        <div className='col-4'>
            <Typeahead id={`typ-${idx}`} options={['EAN', 'UPC', 'INTERNAL_SEGARI', 'OTHERS']} onChange={val => {
                handleNewUniversalBarcodeUpdate(idx, 'type', val[0]);
            }
            } placeholder='Type...'
                disabled={props.isDisabled(CREATE_UNIVERSAL_BARCODE, UPDATE_UNIVERSAL_BARCODE)}
            />
        </div>
        <div className='col-2'>
            <input type='number' className='form-control' placeholder='Quantity...'
                   value={dub.quantityMultiplier} onChange={evt => {
                handleNewUniversalBarcodeUpdate(idx, 'quantityMultiplier', evt.target.value);
            }}
                   disabled={props.isDisabled(CREATE_UNIVERSAL_BARCODE, UPDATE_UNIVERSAL_BARCODE)}
            />
        </div>
        <div className='col-1'>
            <i className='icon-trash fa-xl' onClick={() => removeNewUniversalBarcode(idx)}/>
        </div>
    </div>;

    const addNewUniversalBarcode = () => {
        if(!props.isDisabled(CREATE_UNIVERSAL_BARCODE, UPDATE_UNIVERSAL_BARCODE)){
            setNewUniversalBarcodes(prev => [...prev, {}]);
        }
    }

    const removeNewUniversalBarcode = (index) => {
        if(!props.isDisabled(CREATE_UNIVERSAL_BARCODE, UPDATE_UNIVERSAL_BARCODE))
        {
            setNewUniversalBarcodes(prev => {
                return prev.filter((_, idx) => idx !== index);
            });
        }
    }

    return <div>
        {
            universalBarcodes.map((dub, idx) => mapExistingUniversalBarcodes(dub, idx))
        }
        {
            newUniversalBarcodes.map((nub, idx) => mapNewUniversalBarcodes(nub, idx))
        }
        <div className='row'>
            <div className='ml-2 col-12' onClick={addNewUniversalBarcode}>
                <button className='btn' type='button'>
                    <i className='icon-plus'/> Add Universal Barcode
                </button>
            </div>
        </div>
    </div>
}

export default UniversalBarcodeForm;