import React from "react";

const RUN_STATUSES = {
    RUNNING: 'RUNNING',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
};

export const getRunStatusWidget = (runStatus) => {
    let color;
    let text;
    
    if (runStatus === RUN_STATUSES.RUNNING) {
        color = '#FFCC00';
        text = 'Running...';
    } else if (runStatus === RUN_STATUSES.COMPLETED) {
        color = '#60BE34';
        text = 'Completed';
    } else if (runStatus === RUN_STATUSES.FAILED) {
        color = '#BE3434';
        text = 'Aborted Due to Fatal Error';
    }

    return <div className="d-flex align-items-center">
        <div className='m-0 p-0 mr-2 d-flex align-items-center' style={{width: '0.6rem', height: '0.6rem', borderRadius: '50%', backgroundColor: color, display: 'inline-block'}}/>
        <div className='m-0 p-0 d-flex align-items-center' style={{display: 'inline-block'}}> { text } </div>
    </div>
}
