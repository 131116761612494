import React, { useEffect, useState } from 'react';
import ProductListFilter from "./components/ProductListFilter";
import {
    approveDraft,
    cancelDraft,
    deleteDraft,
    getAllCategories,
    getItemDraftList, getItemDraftSimplifiedList,
    getItoolsProductList,
    getProductDropdown
} from "../../apis/products";
import * as productTag from "../../apis/productTags";
import {Spinner} from "react-bootstrap";
import ProductTable from "./components/ProductTable";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./ProductListPage.css";
import moment from 'moment';
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";
import * as s from "../../constants/scope";
import MenuAuthCheck from "../../components/AuthCheck/MenuAuthCheck";
import {ITEM_DRAFT_CONFIGURE, ITEM_DRAFT_EXECUTE} from "../../constants/scope";
import {bindActionCreators} from "redux";
import {displayAlert} from "../../redux/actions/notif";
import {connect} from "react-redux";
import {NUMBER_VALUES} from "../items/constants";
import ApproveDraftModals from "./components/modals/ApproveDraftModals";
import {getAllInternalUser} from "../../apis/users";

const ProductListPage = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [filterValues, setFilterValues] = useState({});
    const [filterOptions, setFilterOptions] = useState({});
    const [fetchedItems, setFetchedItems] = useState([]);
    const [isFetching, setFetching] = useState(false);

    // Item Draft States
    const [isDraftLoading, setDraftLoading] = useState(false);
    const [draftFilterValues, setDraftFilterValues] = useState({});
    const [fetchedItemDrafts, setFetchedItemDrafts] = useState([]);
    const [isDraftFetching, setDraftFetching] = useState(false);
    const [isApproveLoading, setApproveLoading] = useState(false);
    const [approveId, setApproveId] = useState(null);
    const [approveModalShown, setApproveModalShown] = useState(false);
    const [cancelId, setCancelId] = useState(null);
    const [cancelModalShown, setCancelModalShown] = useState(false);
    const [selectedItemDraft, setSelectedItemDraft] = useState(null);

    const [isAllDraftSelected, setIsAllDraftSelected] = useState(false);
    const [checkedItemDraft, setCheckedItemDraft] = useState(new Map());
    const [isBulkActionLoading, setIsBulkActionLoading] = useState(false);
    const [bulkModalShow, setBulkModalShow] = useState(false);
    const [bulkAction, setBulkAction] = useState("");
    const [errors, setErrors] = useState([]);

    const onFilterChange = (fieldName, val) => {
        setFilterValues({...filterValues, [fieldName]: val});
    }

    const onDraftFilterChange = (fieldName, val) => {
        setDraftFilterValues({...draftFilterValues, [fieldName]: val});
    }

    const toggleApproveModal = (draftId) => {
        setApproveModalShown(!approveModalShown);
        const itemDraft = fetchedItemDrafts.find( draft => draft.id === draftId);
        setSelectedItemDraft(itemDraft);
    }

    const toggleCancelModal = (draftId)=>{
        setCancelModalShown(!cancelModalShown);
        const itemDraft = fetchedItemDrafts.find( draft => draft.id === draftId);
        setSelectedItemDraft(itemDraft);
    }

    const toggleBulkModal = ()=>{
        setBulkModalShow(!bulkModalShow);
        setErrors([]);
    }

    const onFilterReset = () => {
        // update each property to null
        var tempFilterValues = {};
        var filterValuesKeys = Object.keys(filterValues);

        for(let filterValuesKey of filterValuesKeys) {
            tempFilterValues[filterValuesKey] = null;
        }

        setFilterValues(tempFilterValues);
    }

    const onDraftFilterReset = () => {
        // update each property to null
        var tempFilterValues = {};
        var draftFilterValuesKeys = Object.keys(draftFilterValues);

        for(let key of draftFilterValuesKeys) {
            tempFilterValues[key] = null;
        }

        setDraftFilterValues(tempFilterValues);
    }

    const preloadData = () => {
        setLoading(true);
        setDraftLoading(true);
        const productDropdownPromise = getProductDropdown({size: 10_000});
        const productDraftPromise = getItemDraftSimplifiedList({
            size: 1000000
        })
        const productCategoriesPromise = getAllCategories();
        const productTagPromise = productTag.getAll();
        const userPromise = getAllInternalUser();
        Promise.all([productDropdownPromise, productDraftPromise, productCategoriesPromise, productTagPromise, userPromise]).then(value => {
            const [productDropdownResponse, productDraftResponse, categoriesResponse, productTagsResponse, userResponse] = value;
            const {data: {data: {data: productDropdown}}} = productDropdownResponse;
            const {data: {data: productDraft}} = productDraftResponse;
            const {data: {data: categories}} = categoriesResponse;
            const {data: {data: productTags}} = productTagsResponse;
            const {data: {data: users}} = userResponse;

            setFilterOptions({
                'products': productDropdown,
                'productDrafts': productDraft,
                'universalBarcodes': ['ADA', 'TIDAK ADA', 'ALL'],
                'categories': categories,
                'productTags': productTags,
                'status': ['ACTIVE', 'INACTIVE', 'ALL'],
                'labels': [...new Set(productDropdown.map(p => p.label).filter(l => l !== null))],
                'itemStates': ['INVENTORY', 'PURCHASE_INVENTORY', 'INVENTORY_SELLING', 'PURCHASE_INVENTORY_SELLING', 'ALL'],
                'saleStates': ['ADA DI PRICE GROUP, ADA DI POL', 'ADA DI PRICE GROUP, TIDAK ADA DI POL', 'TIDAK ADA DI PRICE GROUP, ADA DI POL', 'TIDAK ADA DI PRICE GROUP, TIDAK ADA DI POL'],
                'draftStatus': [{label: 'WAITING FOR APPROVAL', value: 'WAITING_FOR_APPROVAL'}, {label: 'SUBMITTED', value: 'SUBMITTED'}],
                'approvalStatus': [{label:'Approved', value: true}, {label:'Not Approved / Cancelled Yet', value: false}, {label: 'All', value: null}, {label: 'Cancelled', value: 'deletedAt'}],
                'users': users
            })
            setLoading(false);
            setDraftLoading(false);
        }).catch(e => {
            alert(e);
            setLoading(false);
            setDraftLoading(false);
        });
    }

    const mapInternalProductObjectData = (product, idx) => {
        const {itemDimension, isPurchasable, isStorable, isSellable} = product;
        return {
            no: idx,
            id : product.id,
            skuId: product.skuId,
            status: product.isActive ? 'ACTIVE' : 'INACTIVE',
            createdAt: moment(product.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            createdAtWithHour: moment(product.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            draftCreatedBy: product.draftCreatedBy ? product.draftCreatedBy?.name : '-',
            draftApprovedBy: product.draftApprovedBy? product.draftApprovedBy?.name : '-',
            approvedAt: product.approvedAt? moment(product.approvedAt).format('YYYY-MM-DD') : null,
            approvedAtWithHour: product.approvedAt != null ? moment(product.approvedAt).format('YYYY-MM-DD HH:mm:ss') : '-',
            itemName: product.name,
            categories: product.categoryName,
            productTag: product.productTags ? product.productTags?.map(pt => {if(pt)return pt.name; return ""}).filter(ptn => !ptn.startsWith('wh-')).join(', ') : "-",
            warehouseTag: product.productTags ? product.productTags?.map(pt => {if(pt)return pt.name; return ""}).filter(ptn => ptn.startsWith('wh-')).join(', ') : "-",
            label: product.label,
            notes: product.notes,
            description: product.description,
            weight:itemDimension?.weight ? itemDimension.weight + ' kg' : '-',
            dimension: itemDimension ? `width: ${itemDimension.width}\nheight: ${itemDimension.height}\nlength:${itemDimension.length}` : '-',
            internalNotes: product.internalNotes,
            state: `${isPurchasable ? 'PURCHASE' : ''} ${isStorable ? 'INVENTORY' : ''} ${isSellable ? 'SELLING' : ''}`.trim(),
            universalBarcodes : product.universalBarcodes,
            whitelistedPlanograms : product.whitelistedPlanograms,
            totalApgp: product.totalAgentPriceGroupProduct,
            pol: product.productLimiterCount,
            totalConversionTemplates : product.finalConversionTemplateCount,
            itemDraftId: product.itemDraftId,
            approvalCategory: product.categoryApprovedByUserName != null ? `${product.categoryApprovedByUserName} ${moment(product.categoryApprovedAt).format("DD-MM-YYYY HH:mm:ss")}` : null,
            approvalDownstream: product.downstreamApprovedByUserName != null ? `${product.downstreamApprovedByUserName} ${moment(product.downstreamApprovedAt).format("DD-MM-YYYY HH:mm:ss")}` : null,
            approvalMarketing: product.marketingApprovedByUserName != null ? `${product.marketingApprovedByUserName} ${moment(product.marketingApprovedAt).format("DD-MM-YYYY HH:mm:ss")}` : null,
            bestBeforeDateType: product?.bestBeforeDateType,
            shelfLife: convertShelfLifeToDays(product.shelfLifeInHour),
            sellingUnit: product?.sellingUnit,
            deletedAt: product.deletedAt? moment(product.deletedAt).format('YYYY-MM-DD') : null,
            deletedAtWithHour: product.deletedAt != null ? moment(product.deletedAt).format('YYYY-MM-DD HH:mm:ss') : '-',
            deletedBy: product.deletedBy? product.deletedBy?.name : '-',
            storageRoomType: product.storageRoomType ?? '-',
            packagingType: product.packagingType ?? '-',
        }
    }

    const convertShelfLifeToDays = (shelfLifeInHour) => {
        if (shelfLifeInHour == null || shelfLifeInHour < 0) return null;
        try {
            return Math.round(parseInt(shelfLifeInHour)/24);
        } catch (e) {
            return NUMBER_VALUES.EMPTY;
        }
    }

    const fetchProductList = async (params) => {
        setFetching(true);
        try {
            const {data: {data: products}} = await getItoolsProductList(params);
            setFetchedItems(products.map((p, idx) => mapInternalProductObjectData(p, idx + 1)));
        } finally {
            setFetching(false)
        }
    }

    const fetchItemDraftList = async (params) => {
        setDraftFetching(true);
        setCheckedItemDraft(new Map());
        setIsAllDraftSelected(false);
        try {
            const {data: {data: products}} = await getItemDraftList(params);
            setFetchedItemDrafts(products.map((p, idx) => mapInternalProductObjectData(p, idx + 1)));
        } finally {
            setDraftFetching(false)
        }
    }

    const handleApprovedData = (data, id) => {
        let approvedAt = new Date();
        let approvedBy = "-";
        if (data.actionAt != null || data.actionBy != null) {
            approvedAt = data?.actionAt;
            approvedBy = data?.actionBy;
            if (approvedAt) {
                props.displayAlert({
                    message: 'Approve data sukses',
                    type: 'success'
                })
            }
        }
        const newState = fetchedItemDrafts.map(el => {
            if (el.id === id) {
                el.approvedAt = approvedAt? moment(approvedAt).format('YYYY-MM-DD') : '-';
                el.approvedAtWithHour = approvedAt? moment(approvedAt).format('YYYY-MM-DD HH:mm:ss') : "-";
                el.draftApprovedBy = approvedBy;
            }
            return el;
        })
        setFetchedItemDrafts(newState);
        setCheckedItemDraft(new Map());
    }
    const processErrorMessage = (errors) => {
        let finalError = [];
        let keys = Object.keys(errors);
        if(keys == null){
            return finalError;
        }
        keys.forEach(key => {
            let title = <li>{key}</li>;
            let errorList = [];
            let error = errors[key];
            for(const property in error){
                if(error[property]){
                    errorList.push(<li>{error[property]}</li>);
                }
            }
            finalError.push(
                <ul>
                    {title}
                    <ul>
                        {errorList}
                    </ul>
                </ul>
            )
        });
        return finalError;
    }

    const handleCancelData = async (id)=>{
        if (id) {
            try {
                setApproveLoading(true);
                const response = await cancelDraft({draftIds: [id]});
                if(response.status !== 200){
                    props.displayAlert({
                        message: "Request Error",
                        type: "error"
                    });
                }
                if(response.status === 200){
                    const productDraftDataResponse = response.data.data;
                    if(!productDraftDataResponse.success){
                        setErrors(processErrorMessage(response.data.data.errors));
                    }else{
                        const deletedAt = productDraftDataResponse.actionAt ? productDraftDataResponse.actionAt : new Date();
                        const deletedBy = productDraftDataResponse.actionBy ? productDraftDataResponse.actionBy : "-";
                        const newFetchedItemDrafts = fetchedItemDrafts.map((draft) => {
                            if(draft.id === id){
                               draft.deletedAt = deletedAt? moment(deletedAt).format('YYYY-MM-DD') : '-';
                               draft.deletedAtWithHour = deletedAt ? moment(deletedAt).format('YYYY-MM-DD HH:mm:ss') : '-';
                               draft.deletedBy = deletedBy;
                            }
                            return draft;
                        });

                        props.displayAlert({
                            message: `Product Draft ${id} Cancelled`,
                            type: "success"
                        });
                        setFetchedItemDrafts(newFetchedItemDrafts);
                        setTimeout(() => {
                            setCancelModalShown(false)
                        }, 1000)
                    }
                    setApproveLoading(false);
                }
            } catch (e) {
                setApproveLoading(false);
                props.displayAlert({
                    message: e.message,
                    type: "error"
                });
            }
        }
        setCheckedItemDraft(new Map());
    }

    const handleItemDraftCheckbox = (value)=>{
        let tempCheckedItemDraft = new Map(checkedItemDraft);
        if(value === "all"){
            if(isAllDraftSelected){
                tempCheckedItemDraft.clear();
                setIsAllDraftSelected(!isAllDraftSelected);
            }else{
                let total = 0;
                const limit = 50;

                fetchedItemDrafts.forEach(
                    draft => {
                        if(!draft.deletedAt && !draft.approvedAt){
                            if (total < limit) {
                                tempCheckedItemDraft.set(draft.id, draft);
                                total++;
                            }
                        }
                    }
                )
                setIsAllDraftSelected(!isAllDraftSelected);
            }
        }else {
            const productDraft = fetchedItemDrafts.find((draft) => {return draft.id === value})
            let singleDraft = tempCheckedItemDraft.has(value);
            if(singleDraft){
                tempCheckedItemDraft.delete(value);
            }else{
                tempCheckedItemDraft.set(value, productDraft);
            }
        }
        setCheckedItemDraft(tempCheckedItemDraft);
    }

    const handleBulkAction = async () => {
        let idList = [];
        let response = null;
        setIsBulkActionLoading(true);
        setApproveLoading(true);
        setFetching(true);

        checkedItemDraft.forEach((value, key, map)=>{
            idList.push(key);
        });

        if(bulkAction === "CANCEL"){
            response = await handleBulkCancel(idList);
        }
        else if(bulkAction === "APPROVE"){
            response = await handleBulkApprove(idList);
        }
        if(response){
            const responseData = response.data.data;
            if(!responseData.success){
                if(responseData.errors){
                    setErrors(processErrorMessage(responseData.errors));
                }else{
                    props.displayAlert({
                            message: `Terjadi Kesalahan`,
                            type: "error"
                        }
                    );
                }
            }else{
                props.displayAlert({
                        message: `Aksi berhasil dilakukan untuk ${idList.length} product draft data`,
                        type: "success"
                    }
                );

                let updatedProductDrafts = [];
                const actionAt = responseData.actionAt ? responseData.actionAt : new Date();
                const actionBy = responseData.actionBy ? responseData.actionBy : "-";
                fetchedItemDrafts.forEach((draft) => {
                    checkedItemDraft.forEach((value,key) => {
                        if(key === draft.id){
                            if(bulkAction === "CANCEL"){
                                draft.deletedAt = actionAt? moment(actionAt).format('YYYY-MM-DD') : '-';
                                draft.deletedBy = actionBy;
                                draft.deletedAtWithHour = actionAt ? moment(actionAt).format('YYYY-MM-DD HH:mm:ss') : '-';
                            }else if (bulkAction === "APPROVE"){
                                draft.approvedAt = actionAt? moment(actionAt).format('YYYY-MM-DD') : '-';
                                draft.draftApprovedBy = actionBy;
                                draft.approvedAtWithHour = actionAt ? moment(actionAt).format('YYYY-MM-DD HH:mm:ss') : '-';
                            }
                        }
                    });
                    updatedProductDrafts.push(draft);
                });
                setFetchedItemDrafts(updatedProductDrafts);
                setTimeout(() => {
                    setBulkModalShow(false)
                }, 1000)
            }
        }
        setIsBulkActionLoading(false);
        setApproveLoading(false);
        setFetching(false);
    }

    const handleBulkApprove = async (idList) => {
        let response = null;
        try{
            response  = await approveDraft({draftIds: idList});
        }catch(e){
            props.displayAlert({
                message: e.message,
                type: "error"
            });
            setBulkModalShow(false);
        }
        return response;
    }

    const handleBulkCancel = async (idList) => {
        let response = null;
        try{
             response = await cancelDraft({draftIds: idList});
        }catch(e){
            props.displayAlert({
                message: e.message,
                type: "error"
            });
            setBulkModalShow(false);
        }
        return response;
    }

    const handleSetBulkAction = (action)=>{
        setBulkAction(action);
        setBulkModalShow(true);
    }

    useEffect(() => {
        preloadData();
    }, []);

    return <PageAuthCheck allowedScopes={[s.PRODUCT_LIST, s.ITEM_DRAFT_CONFIGURE, s.ITEM_DRAFT_EXECUTE]}>
        <Tabs
            defaultActiveKey="list-item"
            id="uncontrolled-tab-example"
            className="mb-3"
            variant='pills'
        >
            <Tab eventKey="list-item" title="List Item">
                <div className='row mx-0'>
                    <div className='col'>
                        {!isLoading && <ProductListFilter filterValues={filterValues}
                            onFilterChange={onFilterChange}
                            filterOptions={filterOptions}
                            onSearchHandler={fetchProductList}
                            onFilterReset={onFilterReset}
                            isFetching={isFetching}
                        />}
                        {isLoading && <Spinner animation="border" variant="dark" size="lg" />}
                        <ProductTable products={fetchedItems} type='PRODUCT'/>
                    </div>
                </div>
            </Tab>
            <Tab
                eventKey="list-draft" title="List Item Draft"
            >
                <div className='row mx-0'>
                    <div className='col'>
                        {!isDraftLoading && <ProductListFilter filterValues={draftFilterValues}
                                                               onFilterChange={onDraftFilterChange}
                                                               filterOptions={filterOptions}
                                                               onSearchHandler={fetchItemDraftList}
                                                               onFilterReset={onDraftFilterReset}
                                                               isFetching={isDraftFetching}
                                                               type='DRAFT'
                        />}
                        {isDraftLoading && <Spinner animation="border" variant="dark" size="lg" />}
                        <ProductTable
                            products={fetchedItemDrafts}
                            isApproveLoading={isApproveLoading}
                            type='DRAFT'
                            onApprove={(draftId) => {
                                setApproveId(draftId);
                                toggleApproveModal(draftId)
                            }}
                            onCancel={(draftId) => {
                                setCancelId(draftId);
                                toggleCancelModal(draftId);
                            }}
                            handleItemDraftCheckbox={handleItemDraftCheckbox}
                            checkedItemDraft={checkedItemDraft}
                            isAllDraftSelected={isAllDraftSelected}
                            isDraftLoading={isDraftLoading}
                            isBulkActionLoading={isBulkActionLoading}
                            handleBulkButtonAction={handleSetBulkAction}
                        />
                    </div>
                </div>
            </Tab>
        </Tabs>
        <ApproveDraftModals
            approveId={approveId}
            show={approveModalShown}
            handleClose={toggleApproveModal}
            handleApprovedData={handleApprovedData}
            selectedItemDraft={selectedItemDraft}
            errors={errors}
            setErrors={setErrors}
            processErrorMessage={processErrorMessage}
        />
        <ApproveDraftModals
            cancelId={cancelId}
            show={cancelModalShown}
            handleClose={toggleCancelModal}
            handleCancelData={handleCancelData}
            selectedItemDraft={selectedItemDraft}
            isLoading={isApproveLoading}
            setErrors={setErrors}
            errors={errors}
            processErrorMessage={processErrorMessage}
        />
        <ApproveDraftModals
            checkedItemDraft={checkedItemDraft}
            show={bulkModalShow}
            handleClose={toggleBulkModal}
            handleBulk={handleBulkAction}
            isBulk={true}
            bulkAction={bulkAction}
            isLoading={isBulkActionLoading}
            errors={errors}
            setErrors={setErrors}
            processErrorMessage={processErrorMessage}
        />
    </PageAuthCheck>
}

const mapStateToProps = (state) => ({
    user: state.auth.user
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({message, type}) => displayAlert({message, type})
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPage);