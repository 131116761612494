import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const ConfirmationModal = (props) => {
    const { show, toggleModal, submit, user } = props;
    const [isLoading, setIsLoading] = useState(false);
    const onConfirm = async () => {
        setIsLoading(true);
        await submit(user);
        setIsLoading(false);
        toggleModal(false);
    }
    return (
        <Modal size='m' show={show} onHide={() => { toggleModal(false) }} >
            <Modal.Header>
                <Modal.Title>Confirm Action ? </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Update user {user ? user.name : ""}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger" onClick={() => { toggleModal(false) }} disabled={isLoading}>No</button>
                <button className="btn btn-primary" onClick={() => { onConfirm() }} disabled={isLoading}>Yes</button>
            </Modal.Footer>
        </Modal>
    );
}

export default ConfirmationModal;