export const START_DISABLE_EDIT_INDEX = 1
export const START_DISABLE_DELETE_ACTION_INDEX = 0
export const MAX_SPECIAL_ACTION_INDEX = 4
export const BELI_LAGI_TAG_NAME = "Beli Lagi";
export const MOST_PURCHASED_TAG_NAME = "Most Purchased";
export const NON_EDITABLE_SPECIAL_PRODUCT_TAG = [
    "Price Sorting (H-L)",
    "Price Sorting (L-H)",
    "New Products",
    BELI_LAGI_TAG_NAME,
    MOST_PURCHASED_TAG_NAME,
]
export const SPECIAL_PRODUCT_TAG_NAMES = [
    ...NON_EDITABLE_SPECIAL_PRODUCT_TAG,
    "Promo Products",
    "Tech-Promo",
    "Tech-FlashSale",
    "merch_tritab_trending",
]
export const DEFAULT_PRODUCT_TAG_NAME = "Default Product Tag"
export const PROMO_PRODUCT_TAG_NAME = "Promo Products"
export const EXCLUDED_PRODUCT_IDS = "EXCLUDED_PRODUCT_IDS_FOR_INSERT_PRODUCT_TAGS"