import customAxios from "./axios";
import {
    WAREHOUSE_TAG_GROUP
} from "../constants/api";

export function getAll() {
    return customAxios.get(WAREHOUSE_TAG_GROUP);
}

export function getUnregisteredWhTags(params) {
    return customAxios.get(WAREHOUSE_TAG_GROUP + '/wh-tag', {params});
}

export function create(data) {
    return customAxios.post(WAREHOUSE_TAG_GROUP, data);
}

export function deleteById(id) {
    return customAxios.delete(WAREHOUSE_TAG_GROUP + `/${id}`);
}

export function getOne(id) {
    return customAxios.get(WAREHOUSE_TAG_GROUP + `/${id}`);
}

export function update(id, data) {
    return customAxios.put(WAREHOUSE_TAG_GROUP + `/${id}`, data);
}

export function getAllWarehouseGroupFunctionalityEnumTypes() {
    return customAxios.get(WAREHOUSE_TAG_GROUP + '/functionality-types');
}