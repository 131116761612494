import styles from './Container.module.css'
import React from 'react'

const MainContainer = ({title,children}) => {
    return <div className={styles.wrapper}>
        {!!title && <h1 className={styles.title}>{title}</h1>}
        <div className={styles.containerWrapper}>
            {children}
        </div>
    </div>
}

MainContainer.defaultProps = {
    title: ''
}

export default MainContainer