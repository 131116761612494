import React from 'react';
import JsonBodyForm from "../../components/JsonBodyForm";
import TableBodyResponse from "../../components/TableBodyResponse";
import AuthCheck from "../../components/AuthCheck";
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";
import {PRODUCT_LIST} from "../../constants/scope";

class ListProductsPage extends React.Component {
    render() {
        return <PageAuthCheck allowedScopes={[PRODUCT_LIST]}><div>
            <Title />
            <Body />
        </div></PageAuthCheck>
    }

    componentDidMount() {
        document.title = "List Products";
    }
}

function Title() {
    return <div className="title-div"><h1 className="title-text">List Products</h1></div>;
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            response: "",
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        let requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        this.setState({isSubmitting: true});
        fetch(process.env.REACT_APP_API_V2_URL + 'v1.1/products?size=1000000', requestOptions)
            .then(res => res.json())
            .then(res => {
                    this.setState({
                        isSubmitting: false,
                        response: res,
                    });
                },
                error => {
                    this.setState({
                        isSubmitting: false,
                        response: JSON.stringify(error),
                    })
                });
    }

    render() {
        let titleRow = [];

        let contentRows = [];
        if (this.state.response) {
            if (this.state.response.data && this.state.response.data.data) {
                titleRow = [
                    "ID", "Name", "Internal Name", "SKU ID", "Selling Unit", "Image URL", "Category", "Tags", "Label", "Notes", "Description"
                ];
                this.state.response.data.data.forEach((row) => {
                    const productTags = [];
                    if (row.productTags && row.productTags.length > 0) {
                        row.productTags.forEach(pt => {
                            productTags.push(pt.name)
                        })
                    }
                    let contentRow = [row.id, row.name, row.internalName, row.skuId, row.sellingUnit, row.imageUrl, row.categoryName, productTags.join(", "), row.label, row.notes, row.description];
                    contentRows.push(contentRow);
                });
            }
        }
        return <div className="body-div">
            <div className="internal-body-div">
                <JsonBodyForm
                    onSubmit={(e) => this.handleSubmit(e)}
                    submitText={"Get"}
                />
            </div>
            <TableBodyResponse
                isSubmitting={this.state.isSubmitting}
                titleRow={titleRow}
                contentRows={contentRows}
            />
        </div>;
    }
}

export default ListProductsPage;
