import React, {useEffect} from "react";
import Pagination from "react-bootstrap/Pagination";
import {scrollToTop} from "./ScrollToTop"
import styles from "./PaginationBootstrap.module.scss";

const PaginationBootstrap = (
    {
        itemsCount,
        itemsPerPage,
        currentPage,
        setCurrentPage,
        alwaysShown = true,
        scrollOnChangePage = true,
        styled = false,
    }
) => {
    const pagesCount = Math.ceil(itemsCount / itemsPerPage);
    const isPaginationShown = alwaysShown ? true : pagesCount > 1;
    const isCurrentPageFirst = currentPage === 1;
    const isCurrentPageLast = currentPage === pagesCount;

    const changePage = number => {
        if (currentPage === number) return;
        setCurrentPage(number);
        if (scrollOnChangePage) scrollToTop();
    };

    const onPageNumberClick = pageNumber => changePage(pageNumber);
    const onPreviousPageClick = () => changePage(currentPage - 1);
    const onNextPageClick = () => changePage(currentPage + 1);
    const onFirstPageClick = () => changePage(1);
    const onLastPageClick = () => changePage(pagesCount);

    const setLastPageAsCurrent = () => {
        if (currentPage > pagesCount) {
            setCurrentPage(pagesCount);
        }
    };

    const getPageNumbers = () => {
        const maxVisible = 5;
        let start = currentPage - Math.floor(maxVisible / 2);
        let end = currentPage + Math.floor(maxVisible / 2);

        if (start < 1) {
            start = 1;
            end = Math.min(pagesCount, maxVisible);
        }
        if (end > pagesCount) {
            end = pagesCount;
            start = Math.max(1, end - maxVisible + 1);
        }

        const pageNumbers = [];
        for (let i = start; i <= end; i++) {
            pageNumbers.push(
                <Pagination.Item
                    key={i}
                    onClick={() => onPageNumberClick(i)}
                    active={i === currentPage}
                >
                    {i}
                </Pagination.Item>
            );
        }

        return pageNumbers;
    };

    useEffect(setLastPageAsCurrent, [pagesCount]);

    return (
        <>
            {isPaginationShown && (
                <Pagination className={styled ? styles.pagination : null}>
                    {/* Jump to first page */}
                    {currentPage > 3 && (
                        <Pagination.First
                            onClick={onFirstPageClick}
                            disabled={isCurrentPageFirst}
                        />
                    )}
                    {/* Previous page */}
                    <Pagination.Prev
                        onClick={onPreviousPageClick}
                        disabled={isCurrentPageFirst}
                    />
                    {/* Page numbers */}
                    {getPageNumbers()}
                    {/* Next page */}
                    <Pagination.Next
                        onClick={onNextPageClick}
                        disabled={isCurrentPageLast}
                    />
                    {/* Jump to last page */}
                    {currentPage < pagesCount - 2 && (
                        <Pagination.Last
                            onClick={onLastPageClick}
                            disabled={isCurrentPageLast}
                        />
                    )}
                </Pagination>
            )}
        </>
    );
};

export default PaginationBootstrap;