import moment from 'moment';
import 'moment/locale/id';

export function getDateDifferenceFromToday(date) {
    const today  = moment().startOf('day')
    return moment(date).startOf('day').diff(today, 'days')
}

export const dateFormatted = (dateTime) => {
    if(!dateTime) return "";

    return moment(dateTime).locale("id").format("DD-MM-YYYY")
}

export const timeFormatted = (dateTime) => {
    if(!dateTime) return "";

    return moment(dateTime).locale("id").format("HH.mm")
}

export const dateTimeFormatted = (dateTime) => {
    if(!dateTime) return "";

    return moment(dateTime).locale("id").format("DD MMMM YYYY, HH:mm")
}

export const dayDateTimeFormatted = (dateTime) => {
    if(!dateTime || dateTime == 'null') return "";

    return moment(dateTime).locale("id").format("dddd DD MMMM YYYY, HH:mm")
}

export const dateDayTimeFormatted = (dateTime, format = "YYYY/MM/DD HH:mm (dddd)") => {
    if(!dateTime) return "";

    return moment(dateTime).locale("id").format(format)
}

export const convertToServerDate = (date) => {
    const ISO_DATE_TIME = "YYYY-MM-DD";
    const TIMEZONE = "Asia/Jakarta";
    return moment(date).tz(TIMEZONE).format(ISO_DATE_TIME);
}

export const getStartOfCurrentDate = () => {
    return moment().startOf('date').toDate();
}

export const convertPickerFormatToStartOfCurrentDate = (date) => {
    return moment(date).startOf('date').toDate()
}