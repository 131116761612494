import React from 'react';
import JsonBodyResponse from "../../components/JsonBodyResponse";
import JsonBodyForm from "../../components/JsonBodyForm";
import AuthCheck from "../../components/AuthCheck/AuthCheck";
import * as recipeTagApis from "../../apis/recipeTag";
import {displayAlert} from "../../redux/actions/notif";
import {connect} from 'react-redux'
import * as s from "../../constants/scope";
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";

class DeleteRecipeTagsPage extends React.Component {
    render() {
        return <PageAuthCheck allowedScopes={[s.MANAGE_RECIPE]}><div>
            <Title/>
            <Body
                dispatch={this.props.dispatch}
            />
        </div></PageAuthCheck>
    }

    componentDidMount() {
        document.title = "Delete Recipe Tag";
    }
}

function Title() {
    return <div className="title-div"><h1 className="title-text">Delete Recipe Tag</h1></div>;
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipeTags: [],
            selectedRecipeTagId: "disabled",
            response: ""
        }
    }

    async loadRecipeTags() {
        const { dispatch } = this.props;
        try {
            const recipeTagsResponse = await recipeTagApis.getAll();
            const recipeTags = recipeTagsResponse?.data?.data;
            let recipeTagOptions = [];
            recipeTagOptions.push(<option disabled value={'disabled'}>--pilih recipe tag--</option>);
            if (Array.isArray(recipeTags) && recipeTags.length) {
                recipeTags.forEach(recipeTag => {
                    recipeTagOptions.push(<option key={recipeTag.id} value={recipeTag.id}>{recipeTag.name}</option>);
                })
            }
            this.setState({
                recipeTags: recipeTagOptions
            });
        } catch(e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        }
    }

    async componentDidMount() {
        await this.loadRecipeTags()
    }

    handleChange(fieldName, value) {
        this.setState({
            [fieldName]: value
        });
    }

    handleSubmit = async(e) => {
        e.preventDefault();
        e.stopPropagation();

        const { dispatch } = this.props;
        this.setState({ isSubmitting: true });
        try {
            const response = await recipeTagApis.deleteRecipeTag(this.state.selectedRecipeTagId);
            this.setState({
                response: JSON.stringify(response.data)
            });
        } catch(e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        } finally {
            this.setState({ isSubmitting: false });
        }
    };

    render() {
        let rowInfos = [
            {
                fieldName: "selectedRecipeTagId",
                label: "Recipe Tag",
                customOptions: this.state.recipeTags,
                selectValue: this.state.selectedRecipeTagId,
            },
        ];
        return <div className="body-div">
            <div className="internal-body-div">
                <JsonBodyForm
                    onRowChange={(fn, v) => this.handleChange(fn, v)}
                    onSubmit={(e) => this.handleSubmit(e)}
                    disableSubmit={this.state.isSubmitting || !this.state.selectedRecipeTagId}
                    rowInfos={rowInfos}
                />
            </div>
            <JsonBodyResponse response={this.state.response} />
        </div>;
    }
}

export default connect()(DeleteRecipeTagsPage);