import { Col, Form } from "react-bootstrap";
import Select from "react-select";
import React, { memo, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import ReactSelectForm from "../../../../components/ReactSelectForm/ReactSelectForm";
import { getTOPOptions, TOP } from "../../../../constants/termsOfPayment";
import * as apiSupplier from "../../../../apis/suppliers";
import { displayAlert } from "../../../../redux/actions/notif";
import { useDispatch } from "react-redux";
import { Field, useFormikContext } from "formik";
import { SUPPLIER_STATUS } from "../../../../constants/supplier";
import { PURCHASE_ORDER_TYPE, PURCHASE_ORDER_TYPE_OPTIONS } from "../../../../constants/purchaseOrder";
import CurrencyField from "../../../../components/CustomInput/CurrencyField/CurrencyField";

import styles from '../../PurchaseOrder.module.scss'

function PurchaseOrderCard(props) {
    const { values, setFieldValue } = useFormikContext();
    const { purchaseOrder, isUpdate, readOnly } = values;
    const dispatch = useDispatch();
    const [suppliers, setSuppliers] = useState([]);

    useEffect(() => {
        function handleResponse(response) {
            setSuppliers(response.supplier.data.data);
        }

        function handleProps() {
            const activeSuppliers = props.suppliers.filter(s => s.supplierStatus === SUPPLIER_STATUS.ACTIVE);
            setSuppliers(activeSuppliers);
        }

        // handle props instead of fetch new suppliers
        if (props.suppliers !== undefined && props.suppliers?.length > 0) {
            handleProps();
            return;
        }

        const fetch = async () => {
            try {
                const suppliersPromise = apiSupplier.getSuppliers({ supplierStatus: SUPPLIER_STATUS.ACTIVE });

                const [supplierResponse] = await Promise.all([
                    suppliersPromise,
                ])

                handleResponse({
                    supplier: supplierResponse,
                })
            } catch (e) {
                dispatch(displayAlert({ message: e.message, type: 'error' }));
            }
        }
        fetch();
    }, []);

    return <div className='internal-body-div mb-5'>
        <h5>A. Purchase Order</h5>
        <hr />
        <div>
            <Row>
                <Col>
                    <FulfillField
                        purchaseOrder={purchaseOrder}
                        setFieldValue={setFieldValue}
                        disabled={readOnly} />
                </Col>
            </Row>
        </div>
        <div>
            <Row>
                <Col>
                    <SupplierField
                        purchaseOrder={purchaseOrder}
                        suppliers={suppliers}
                        setFieldValue={setFieldValue}
                        disabled={isUpdate || readOnly}
                    />
                </Col>
                <Col>
                    <TOPField
                        purchaseOrder={purchaseOrder}
                        setFieldValue={setFieldValue}
                        disabled={readOnly}
                    />
                </Col>
                {
                    purchaseOrder.termsOfPayment === TOP &&
                    <Col>
                        <TOPDaysField
                            disabled={readOnly}
                        />
                    </Col>
                }
                <Col>
                    <VATField
                        disabled={readOnly}
                    />
                </Col>
                <Col>
                    <TypeField
                        purchaseOrder={purchaseOrder}
                        setFieldValue={setFieldValue}
                        disabled={readOnly}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={purchaseOrder.termsOfPayment === TOP ? 6 : 8}>
                    <OtherCostField
                        purchaseOrder={purchaseOrder}
                        setFieldValue={setFieldValue}
                        disabled={readOnly}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={purchaseOrder.termsOfPayment === TOP ? 6 : 8}>
                    <NotesField
                        disabled={readOnly}
                    />
                </Col>
            </Row>
        </div>
    </div>
}

function FulfillField(props) {
    const { purchaseOrder, setFieldValue, disabled } = props;

    const handleRadioChange = (event) => {
        setFieldValue('purchaseOrder.domainType', event.target.value);
    };

    return (
        <Form.Group>
            <Form.Label>Fulfil For</Form.Label>
            <div role="group" aria-labelledby="my-radio-group" style={{ marginLeft: '20px', display: "flex" }}>
                <label style={{width:"120px"}}>
                    <Field
                        type="radio"
                        name="domainType"
                        className="form-check-input"
                        value="SEGARI"
                        checked={purchaseOrder.domainType === 'SEGARI'}
                        onChange={handleRadioChange}
                        style={{ backgroundColor: purchaseOrder.domainType === 'SEGARI' ? 'red' : 'white' }}
                    />
                    Segari
                </label>
                <label style={{width:"120px"}}>
                    <Field
                        type="radio"
                        name="domainType"
                        className="form-check-input"
                        value="KULA"
                        checked={purchaseOrder.domainType === 'KULA'}
                        onChange={handleRadioChange}
                        style={{ backgroundColor: purchaseOrder.domainType === 'KULA' ? 'red' : 'white' }}
                    />
                    KULA
                </label>
            </div>
        </Form.Group>
    );
}

function SupplierField(props) {
    const { purchaseOrder, suppliers, setFieldValue, disabled } = props;
    let supplierOpts = [];
    let valueSupplier;
    if (suppliers) {
        suppliers.forEach(s => {
            let item = { value: s.id, label: s.name };
            if (s.id === purchaseOrder.supplierId) {
                valueSupplier = item;
            }
            supplierOpts.push(item);
        })
    }

    const handleSupplierChange = (selected) => {
        const supplierObject = suppliers.find(supplier => supplier.id === selected.value);
        setFieldValue('purchaseOrder.supplierId', supplierObject.id);
        const topPaymentDays = supplierObject.paymentTerms === TOP ? supplierObject.topPaymentDays : '';
        setFieldValue('purchaseOrder.termsOfPayment', supplierObject.paymentTerms ?? '');
        setFieldValue('purchaseOrder.TOPDays', topPaymentDays ?? '');
    }

    return <Form.Group>
        <Form.Label>Supplier</Form.Label>
        <div>
            <Select
                name='purchaseOrder.supplierId'
                value={valueSupplier}
                placeholder={'Pick Supplier'}
                options={supplierOpts}
                styles={{
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                components={{ IndicatorSeparator: () => null }}
                onChange={(selected) => handleSupplierChange(selected)}
                isDisabled={disabled}
            />
        </div>
    </Form.Group>
}

const TOPOptions = getTOPOptions()
    .map(top => {
        return { value: top, label: top }
    });

function TOPField(props) {
    const { purchaseOrder, setFieldValue, disabled } = props;

    let valueTermsOfPayment;
    if (purchaseOrder.termsOfPayment) {
        valueTermsOfPayment = { value: purchaseOrder.termsOfPayment, label: purchaseOrder.termsOfPayment };
    }

    return <Form.Group>
        <Form.Label>Terms Of Payment</Form.Label>
        <div>
            <ReactSelectForm
                fieldName={'purchaseOrder.termsOfPayment'}
                value={valueTermsOfPayment}
                options={TOPOptions}
                setFieldValue={setFieldValue}
                isDisabled={disabled}
            />
        </div>
    </Form.Group>
}

function TOPDaysField(props) {
    const { disabled } = props;
    return <Form.Group>
        <Form.Label>Hari</Form.Label>
        <div>
            <Field
                id='terms-of-payment-days'
                name='purchaseOrder.TOPDays'
                type='input'
                className='form-control'
                disabled={disabled}
            />
        </div>
    </Form.Group>
}

function VATField(props) {
    const { disabled } = props;
    return <Form.Group>
        <Form.Label>
            VAT
        </Form.Label>
        <div className='ml-4'>
            <Field
                id="isUseVat"
                name="purchaseOrder.isUseVat"
                type="checkbox"
                className="form-check-input"
                disabled={disabled}
            />
        </div>
    </Form.Group>
}

function TypeField(props) {
    const { purchaseOrder, setFieldValue, disabled } = props;

    let type;
    if (purchaseOrder.purchaseOrderType) {
        type = PURCHASE_ORDER_TYPE_OPTIONS.find(e => e.value === purchaseOrder.purchaseOrderType);
    } else {
        type = PURCHASE_ORDER_TYPE_OPTIONS.find(e => e.value === PURCHASE_ORDER_TYPE.REGULAR);
        setFieldValue('purchaseOrder.purchaseOrderType', PURCHASE_ORDER_TYPE.REGULAR ?? '');
    }

    return <Form.Group>
        <Form.Label>Purchase Order Type</Form.Label>
        <div>
            <ReactSelectForm
                fieldName={'purchaseOrder.purchaseOrderType'}
                value={type}
                options={PURCHASE_ORDER_TYPE_OPTIONS}
                setFieldValue={setFieldValue}
                isDisabled={disabled}
            />
        </div>
    </Form.Group>
}

function OtherCostField(props) {
    const { disabled, purchaseOrder, setFieldValue } = props;
    return <Form.Group>
        <Form.Label>
            Other Cost
        </Form.Label>
        <div>
            <CurrencyField
                name={"purchaseOrder.otherCost"}
                value={purchaseOrder?.otherCost}
                onChange={(val) => {
                    setFieldValue("purchaseOrder.otherCost", val)
                }}
                disabled={disabled}
                separator=','
                currency='Rp'
                required={true}
            />
        </div>
    </Form.Group>
}

function NotesField(props) {
    const { disabled } = props;
    return <Form.Group>
        <Form.Label>
            Notes
        </Form.Label>
        <div>
            <Field
                name="purchaseOrder.notes"
                type="text"
                className="form-control"
                disabled={disabled}
            />
        </div>
    </Form.Group>
}


export default memo(PurchaseOrderCard);