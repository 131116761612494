import React, { useEffect, useState } from 'react';
import {connect, useDispatch} from 'react-redux'
import * as s from "../../constants/scope";
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import MainContainer from '../../components/MainContainer/MainContainer';
import Pagination from '../../components/Pagination/Pagination';
import moment from 'moment';
import styles from './RecipeTags.module.css'
import { deleteRecipeTag, getAllRecipeTags, bulkCreateOrUpdate } from '../../apis/recipeTag';
import {displayAlert} from "../../redux/actions/notif";


const RecipeTagsPage = () => {

    const pageHistory = useHistory()
    const [recipeTagFilterName, setRecipeTagFilterName] = useState("")
    const [selectedRecipeTagName, setSelectedRecipeTagName] = useState("")
    const [selectedRecipeTagId, setSelectedRecipeTagId] = useState("")


    const [isShowModal, setShowModal] = useState(false)
    const [isLoading, setLoadingStatus] = useState(false)
    const [showUploadCsvModal, setShowUploadCsvModal] = useState(false)
    const [displayedName, setDisplayedName] = useState("")
    const [paginationState, setPaginationState] = useState(initialPaginationState())
    const [recipeTagListData, setRecipeTagListData] = useState([])
    const [csv, setCsv] = useState(null)

    const dispatch = useDispatch()

    async function loadRecipeTagData (page, isReset = false) {
        setLoadingStatus(true)
        const name = isReset ? "" : recipeTagFilterName
        const param = Object.assign({}, {name}, {page: page - 1, size: paginationState.numberOfElements})

        try {
            const response = await getAllRecipeTags(param)
            setPaginationState(
                {   
                    ...paginationState,
                    totalElements : response.data.data.totalElements,
                    totalPages : response.data.data.totalPages,
                    currentPage : page,
                    first : (page === 1),
                    last : (page === response.data.data.totalPages),
                    loadingData : false,
                }
            )
            setRecipeTagListData(response.data.data.data)
            setDisplayedName(recipeTagFilterName)
        } catch (e) {
        } finally {
            setLoadingStatus(false)
        }
    }

    function initialPaginationState () {
        return {
            loadingData: false,
            currentPage: 0,
            totalPages: 0,
            first: false,
            last: false,
            numberOfElements: 50,
            totalElements: 0,
            goToPage: loadRecipeTagData,
            custom: false,
            currentPagePagination: 0
        };
    }

    const clearForm = async () => {
        setRecipeTagFilterName("")
        loadRecipeTagData(1, true)
    }

    const onDeleteButtonPressed = (id, name, idx) => {
        setSelectedRecipeTagName(name)
        setSelectedRecipeTagId(id)
        setShowModal(true)
    }

    const onCloseUploadCsv = () => {
        setShowUploadCsvModal(false)
    }

    const onSubmitCsv = async () => {
        try {
            const formData = new FormData();
                formData.append('csv', csv)

            await bulkCreateOrUpdate({data: formData})
            displayAlert({message:'Success',type:'success'})
            onCloseUploadCsv()
            loadRecipeTagData(paginationState.currentPage)
        } catch (e) {
            displayAlert({message:e.message,type:'error'})
        }
    }

    useEffect(() => {
        loadRecipeTagData(1)
    }, []);

    return <PageAuthCheck allowedScopes={[s.MANAGE_RECIPE]}>
        { isLoading ? <div className={styles.overlaySpinner}><Spinner animation="border" size="lg" /></div>: <></>}
         <MainContainer>
            <Modal show={isShowModal} className={'d-flex align-items-center'}>   
                <Modal.Body>
                    <p className={'d-flex justify-content-center'}>Are you sure want to delete {selectedRecipeTagName} ?</p>
                    <div className={styles.centeredButtonGroup}>
                        <Button 
                            size='sm' 
                            className={'mr-4 pl-4 pr-4'} 
                            variant="primary" type='submit'
                            onClick={ () => {
                                deleteRecipeTag(selectedRecipeTagId)
                                loadRecipeTagData(paginationState.currentPage)
                                setShowModal(false)
                            }}
                        >OK</Button>    
                        <Button 
                            className={'pl-4 pr-4'} 
                            size='sm'
                            variant="danger" 
                            onClick={e => setShowModal(false)}
                        >Cancel</Button>    
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showUploadCsvModal} onHide={onCloseUploadCsv}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                      
                        <div className={'form-group'}>
                            <label>CSV File</label>
                            <input name='file' type='file' className={'form-control'} onChange={e => setCsv(e.currentTarget.files[0])} accept=".csv" />
                            <small className="form-text text-muted">CSV example can be viewed here: <a href="https://docs.google.com/spreadsheets/d/1XYLlfZqynWTRF01nhysCIuObMit2lV2XgQMiW7x6Ac0/edit?usp=sharing" target="_blank" className={styles.url}>BulkAddRecipeTag.csv</a></small>
                        </div>
                        <div className={'form-group'}>
                            <button className={'btn btn-primary'}  onClick={()=> onSubmitCsv()}> Upload</button>
                        </div>
                </Modal.Body>
            </Modal>
            
            <div className={styles.recipeTagFilterWrapper}>
                <Form className={styles.formStyle}>
                    <div className={styles.horizontalWrapper}>
                        <p className='pt-2'>Recipe Tag Name:</p>
                        <Form.Group className="ml-1" controlId="formFilter">
                            <Form.Control 
                                className={styles.formControl}
                                value = {recipeTagFilterName}
                                onKeyDown = {(e) => e.key == "Enter" && loadRecipeTagData(1)}
                                onChange={e => setRecipeTagFilterName(e.target.value)}
                            />
                        </Form.Group>
                    </div>
                    <div className={styles.horizontalWrapper}>
                        <Button className={styles.filterButton} variant="dark" onClick={e => loadRecipeTagData(1)}>Search</Button>    
                        <Button className={styles.filterButton} variant="outline-secondary" onClick={e => clearForm()}>Reset</Button>
                    </div>    
                </Form>
            </div>
            <div className={styles.recipeTagList}> 
                <p><b>List Of Recipe Tag</b> </p>
                <div className={styles.horizontalWrapper}>
                <Button className={`btn btn-primary ${styles.recipeTagBtn}`} onClick={(e) => {e.preventDefault(); setShowUploadCsvModal(true)}}>Upload CSV Recipe Tag</Button>     
                <Button className='btn btn-primary' onClick={() => pageHistory.push("/recipe/tags/create")}>+ Create New Recipe Tag</Button>
                </div>
            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Recipe Tag Id</th>
                        <th>Total Recipe</th>
                        <th>Recipe Tag Name</th>
                        <th>Last Edit Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {!isLoading && recipeTagListData.map((data, idx) => recipeTagTableData(data, pageHistory, onDeleteButtonPressed))}
                </tbody>
            </table>
            
            <Pagination {...paginationState}/>
           
        </MainContainer>   
        
    </PageAuthCheck>
}

const recipeTagTableData = (data, history, onDeleteButtonPressed, idx) => {
    return <tr>
        <td>{data.id}</td>
        <td>{data.recipeTagRecipes.length}</td>
        <td>{data.name}</td>
        <td>{moment(data.updatedAt).format("DD-MM-YYYY HH:mm:ss")}</td>
        <td><Button 
                className="mt-1 pl-4 pr-4"
                size="sm" 
                variant="primary" 
                onClick={
                    e => history.push(
                        "/recipe/tags/info", 
                        { 
                            id: data.id,
                            name: data.name,
                        }
                )
            }>View</Button>
            <Button 
                className="mt-1 pl-4 pr-4 ml-2 mr-2"
                size="sm" 
                variant="dark" 
                onClick={
                    e => history.push(
                        "/recipe/tags/update", 
                        { 
                            id: data.id, 
                            name: data.name
                        }
                    )
            }>Edit</Button>
            <Button 
                className="mt-1 pl-4 pr-4"
                size="sm" 
                variant="outline-secondary" 
                onClick={() => onDeleteButtonPressed(data.id, data.name, idx)}
            >Delete</Button>
        </td>
    </tr>
}

export default connect()(RecipeTagsPage);

