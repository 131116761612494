import customAxios from "./axios";
import {
    PRODUCT_TAG_V1,
    PRODUCT_TAG_DETAIL_V1,
    PRODUCT_TAG_V1_PAGINATION,
    PRODUCT_TAG_PRODUCTS,
    PRODUCT_TAG_PRODUCTS_LOG,
    PRODUCT_TAG_OPTIONS,
    PRODUCT_TAG_LOG,
    PRODUCT_TAG_PRODUCTS_LIST,
    PRODUCT_TAG_SIMPLE_WITH_WH,
    PRODUCT_TAG_V1_PAGINATION_WITH_PRODUCT_TAG_PRODUCT,
    PRODUCT_TAG_TYPE_OPTIONS_V1,
} from "../constants/api";

export function getAll() {
    return customAxios.get(PRODUCT_TAG_V1);
}

export function getAllWithWhPrefix() {
    return customAxios.get(PRODUCT_TAG_SIMPLE_WITH_WH);
}

export function getAllOptions() {
    return customAxios.get(PRODUCT_TAG_OPTIONS);
}

export function getAllProductTags(param) {
    return customAxios.get(PRODUCT_TAG_V1_PAGINATION, {params: param});
}

export function getAllProductTagWithProductTagProducts(param) {
    return customAxios.get(PRODUCT_TAG_V1_PAGINATION_WITH_PRODUCT_TAG_PRODUCT, {params: param});
}

export function getAllWithProductsAndFrontendSubcategories() {
    return customAxios.get(PRODUCT_TAG_DETAIL_V1);
}

export function getByIdWithProductsAndFrontendSubcategories(id) {
    return customAxios.get(PRODUCT_TAG_DETAIL_V1 + '/id/' + id);
}


export function getByNameWithProductsAndFrontendSubcategories(name) {
    return customAxios.get(PRODUCT_TAG_DETAIL_V1 + '/name/' + name);
}

export function getByPrefixWithProductSkuId(prefixName) {
    return customAxios.get(PRODUCT_TAG_DETAIL_V1 + '/prefixName/' + prefixName);
}

export function getByPrefix(prefixName) {
    return customAxios.get(PRODUCT_TAG_V1 + '/prefixName/' + prefixName);
}

export function create(data) {
    return customAxios.post(PRODUCT_TAG_V1, data);
}

export function update(id, data) {
    return customAxios.put(PRODUCT_TAG_V1 + '/' + id, data);
}

export function deleteProductTag(id) {
    return customAxios.delete(PRODUCT_TAG_V1 + '/' + id);
}

export function getProductTagWithPaginationById(id, param) {
    return customAxios.get(PRODUCT_TAG_PRODUCTS(id), {params: param});
}

export function getProductTagsListById(id) {
    return customAxios.get(PRODUCT_TAG_PRODUCTS_LIST(id));
}

export function getProductTagLog(id, param) {
    return customAxios.get(PRODUCT_TAG_LOG(id), {params: param});
}

export function getProductTagTypeOptions() {
    return customAxios.get(PRODUCT_TAG_TYPE_OPTIONS_V1);
}