import React from "react";
import Input from "../../Components/Input";

const RoleSection = (props) => {
    const {handleCheckbox, roles, directoryOptions} = props;

    return(
        <>
            <div className={"d-flex mb-3"}>
                {
                    directoryOptions?.map((roleOption) => {
                        const contents = [];
                        roleOption.roles.forEach(role => {
                            contents.push(
                                <Input text={role.name} type={"checkbox"} handleInput={handleCheckbox} id={role.id} isChecked={roles.get(role.id)}/>
                            )
                        });

                        return (
                            <div className={"d-flex flex-column mr-5"}>
                                <h6>
                                    <u>{roleOption.name}</u>
                                </h6>
                                {contents}
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
}

export default RoleSection;