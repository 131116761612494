import React from "react";
import Spinner from 'react-bootstrap/Spinner';

function JsonBodyResponse(props) {
    if (props.isSubmitting) {
        return <Spinner animation="border" size="lg" />;
    }
    return <div className="body-div">
        <div>{props.response}</div>
    </div>;
}

export default JsonBodyResponse;