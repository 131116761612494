import React from 'react';
import InputLabel from "./InputLabel";
import {Select} from "antd";
import { Typeahead } from 'react-bootstrap-typeahead';

const Input = (props) =>{
    const type = props.type ? props.type : "text";
    const isDisabled = props.isDisabled ? props.isDisabled : false;
    const{value, handleInput, footnote, text, id, isChecked, error} = props;
    if(type === "text" || type === "password"){
        return(
            <div className={"pr-3"} key={id}>
                <InputLabel text={text}/>
                <input className='form-control'
                       key={id}
                       id={id}
                       name={id}
                       style={{width: '100%', display: 'inline-block'}}
                       type={type}
                       value={value}
                       onChange={(event)=>{
                           handleInput(event.target.value);
                       }}
                       disabled={isDisabled}
                />
                {footnote ? <FootNote text={footnote}/> : <div></div>}
                {error ? <ErrorNote text={error}/> : <div/>}
            </div>
        )
    } else if(type === "select"){
        return (
            <div className={"pr-3"} key={id}>
                <InputLabel text={text}/>
                <Select
                    key={id}
                    id={id}
                    style={{width: "100%"}}
                    className={"form-select"}
                    value={value}
                    onChange={(selectedValue)=>{
                        handleInput(selectedValue);
                    }}
                    options={props.options}
                    disabled={isDisabled}
                />
                {error ? <ErrorNote text={error}/> : <div/>}
            </div>
        );
    } else if(type === "typeahead"){
        return (
            <div className={"pr-3"} key={id}>
                <InputLabel text={text}/>
                <Typeahead
                    key={id}
                    id={id}
                    style={{width: "100%"}}
                    className={"form-select"}
                    clearButton
                    selected={props.options.filter(option => option.value == value)}
                    onChange={(selectedValue)=>{
                        if (selectedValue.length > 0) {
                            handleInput(selectedValue[0].value);
                        }
                        else {
                            handleInput("")
                        }
                    }}
                    options={props.options}
                    disabled={isDisabled}
                />
                {error ? <ErrorNote text={error}/> : <div/>}
            </div>
        );
    } else if(type === "checkbox"){
        return(
            <div className={"d-flex"} key={id}>
                <input key={id}
                       type={type}
                       value={id}
                       id={id}
                       onChange={()=>{
                           handleInput(id);
                       }}
                       disabled={isDisabled}
                       checked={isChecked}
                />
                <InputLabel text={text}/>
            </div>
        );
    }
}

const FootNote = (props)=>{
    return(
        <div style={{fontSize: `0.7em`, margin:'1px', color:"#808080"}}>
            {props.text}
        </div>
    );
}

const ErrorNote = (props)=>{
    return(
        <div style={{fontSize: `0.7em`, margin:'1px', color:"#bb3a3a"}}>
        {props.text}
    </div>);
}
export default Input;