import React from "react";
import SectionContainer from "./SectionContainer";
import GeneralInformationSection from "./GeneralInformationSection";
import AccountConfigurationSection from "./AccountConfigurationSection";
import RoleSection from "./RoleSection";

const SingleUploadContainer = (props) => {
    const {employeeId, name, email, contractType, contractTypeOptions, accountStatusOptions, directoryOptions, warehouseOptions, directoryId, homeOfficeId} = props;
    const {setEmployeeId, setName, setEmail, setContractType, setDirectoryId, setHomeOfficeId} = props;

    const {userId, username, password, accountStatus, accountNote, roles, } = props;
    const {setUserId, setUsername, setPassword, setAccountStatus, setAccountNote } = props;

    const {handleCheckbox, isSubmitting} = props;
    const {color, mode, errors} = props;

    return(
        <>
            <SectionContainer
                headerText={"A1. Informasi User"}
                content={GeneralInformationSection({
                    employeeId: employeeId,
                    setEmployeeId: setEmployeeId,
                    name: name,
                    setName: setName,
                    email: email,
                    setEmail: setEmail,
                    contractType: contractType,
                    setContractType: setContractType,
                    contractTypeOptions: contractTypeOptions,
                    mode: mode,
                    directoryOptions: directoryOptions,
                    warehouseOptions: warehouseOptions,
                    directoryId: directoryId,
                    homeOfficeId: homeOfficeId,
                    setDirectoryId: setDirectoryId,
                    setHomeOfficeId: setHomeOfficeId,
                    errors: errors
                })}
                lineColor={color.LINE}
            />

            <SectionContainer
                headerText={"A2. Pengaturan Akun"}
                content={AccountConfigurationSection({
                    userId: userId,
                    setUserId: setUserId,
                    username: username,
                    setUsername: setUsername,
                    password: password,
                    setPassword: setPassword,
                    accountStatus: accountStatus,
                    setAccountStatus: setAccountStatus,
                    accountNote: accountNote,
                    setAccountNote: setAccountNote,
                    mode: mode,
                    accountStatusOptions: accountStatusOptions,
                    errors: errors
                })}
                lineColor={color.LINE}
            />

            <SectionContainer
                headerText={"A3. Roles"}
                content={RoleSection({
                    handleCheckbox: handleCheckbox,
                    mode: mode,
                    roles: roles,
                    directoryOptions: directoryOptions
                })}

                lineColor={color.WHITE}
            />

            <div className={"d-flex flex-column align-items-end"}>
                <ErrorNote text={errors && errors.generalError ? errors.generalError : null}/>
                    <button type={"submit"} className={"btn btn-m"}
                            style={{backgroundColor: `${color.PRIMARY}`, color: `${color.WHITE}`,
                                width:`20%`, marginRight:`5%`, marginBottom: `5%`}}
                            disabled={isSubmitting}>Submit</button>
            </div>
        </>
    );
}

const ErrorNote = (props)=>{
    return(
        <div style={{fontSize: `0.7em`, margin:'1px', color:"#bb3a3a"}}>
            {props.text}
        </div>);
}
export default SingleUploadContainer;