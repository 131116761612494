import React, { useState, useEffect, useMemo } from 'react';
import styles from "./style.module.css";
import ConfirmDeleteModalV2 from "../../../../components/ConfirmDeleteModalV2/ConfirmDeleteModalV2.js";
import { ACTION, INITIAL_FORM, MODAL_TITLE, } from "./constant.js";
import { COLUMN_WH_TAG_GROUP_DASHBOARD } from "./AdjustmentPageTableOptions.js";
import { useDispatch } from "react-redux";
import { useReactSelectForm } from "../../../../components/ReactSelectForm/useReactSelectForm.js";
import ReactSelectForm from "../../../../components/ReactSelectForm/ReactSelectForm.js";
import LoaderModalV2 from "../../../../components/LoaderModalV2/LoaderModalV2.js";
import * as warehouseTagGroupApi from "../../../../apis/warehouseTagGroup.js";
import CustomTable from "../../../../components/CustomTable/CustomTable";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Formik, Field } from "formik";
import { classNameJoin } from '../../Utils/stylingUtils.js';
import { getOptionsFromValue } from './constant.js';
import { WAREHOUSE_TRANSFER_CATEGORY_CREATE, WAREHOUSE_TRANSFER_CATEGORY_ASSIGN } from '../../../../constants/scope.js';
import MenuAuthCheck from '../../../../components/AuthCheck/MenuAuthCheck.js';
import Select from "react-select";

const ErrorMessage = ({ error, touched }) => {
    return <>{error && touched && <div className="text-danger">{error}</div>}</>;
};

const MANDATORY_CREATE_FORM = new Set(["name", "functionality"]);

const validateForm = (values) => {
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
        if (
            MANDATORY_CREATE_FORM.has(key) &&
            (!value || (Array.isArray(value) && value.length === 0))
        ) {
            errors[key] = "* Wajib diisi";
        }
    });
    return errors;
};

const CategoryAdjustment = (props) => {
    const { show, warehouseTagGroupData, displayAlert, warehouseTagOptions, functionalityEnumOptions, fetchWarehouseGroupTag, user } = props;
    const [action, setAction] = useState(ACTION.VIEW);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFuncitonality, setSelectedFuncitonality] = useState(null);
    const dispatch = useDispatch();
    const sendDisplayAlert = ({ message, type }) => {
        return dispatch(displayAlert({ message, type }));
    };
    const userScopes = user && user.scopes && user.scopes.map(r => r.id) || [];
    const userRoles = user && user.roles && user.roles.map(r => r.id) || [];

    const columnWhTagGroupDashboard = useMemo(() => {
        return COLUMN_WH_TAG_GROUP_DASHBOARD({
            onClickEdit: (row) => onActionUpdate(row),
            onClickDelete: (row) => {
                setShowDeleteModal(true);
                setSelectedDeletedForm(row);
            },
        });
    }, [functionalityEnumOptions]);

    const [_, getValueFromOptionsMulti] = useReactSelectForm();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedDeletedForm, setSelectedDeletedForm] = useState({});
    const [form, setForm] = useState(INITIAL_FORM);

    const fetchDashboard = async () => {
        try {
            setIsLoading(true);
            await fetchWarehouseGroupTag();
        } catch (error) {
            sendDisplayAlert({ message: error.message, type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (show) {
            fetchDashboard();
        }
    }, [show]);

    const TableDashboard = () => {
        return (
            <div className={styles.tableTest}>
                <CustomTable
                    height="90vh"
                    columns={columnWhTagGroupDashboard}
                    data={warehouseTagGroupData.filter(wt => wt.functionality === selectedFuncitonality?.value)}
                    isLoading={isLoading}
                />
                <FooterAction />
            </div>
        );
    };

    const onSubmitData = async (values) => {
        try {
            setIsLoading(true);
            if (ACTION.CREATE == action) {
                await warehouseTagGroupApi.create({
                    ...values,
                    whTagIds: !!values.whTagIds ? values.whTagIds : [],
                });
            }

            if (ACTION.UPDATE == action) {
                await warehouseTagGroupApi.update(values.id, {
                    ...values,
                    whTagIds: !!values.whTagIds ? values.whTagIds : [],
                });
            }

            onBack();
        } catch (error) {
            setForm(values);
            sendDisplayAlert({ message: error.message, type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteById = async () => {
        try {
            setIsLoading(true);
            await warehouseTagGroupApi.deleteById(selectedDeletedForm.id);
            fetchDashboard();
            setShowDeleteModal(false);
            setSelectedDeletedForm({});
        } catch (error) {
            sendDisplayAlert({ message: error.message, type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const onActionUpdate = async ({ id }) => {
        try {
            setIsLoading(true);
            const { data } = await warehouseTagGroupApi.getOne(id);
            setAction(ACTION.UPDATE);
            setForm(data?.data);
        } catch (error) {
            sendDisplayAlert({ message: error.message, type: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const CreateOrUpdateView = () => {
        return (
            <Formik
                validate={validateForm}
                key={"create-form"}
                initialValues={form}
                onSubmit={onSubmitData}
            >
                {({ values, setFieldValue, errors, touched }) => (
                    <Form className={styles.formContent}>
                        <Container fluid>
                            <Row md={"auto"}>
                                <Col className={"form-group"}>
                                    <label>Group Name</label>
                                    <Field
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        placeholder="Masukan nama grup"
                                        disabled={
                                            !userScopes.includes(WAREHOUSE_TRANSFER_CATEGORY_CREATE) && 
                                            !userRoles.includes('admin')
                                        }
                                    />
                                    <ErrorMessage error={errors.name} touched={touched.name} />
                                </Col>
                            </Row>
                            <Row md={"auto"}>
                                <Col className={"form-group"}>
                                    <label>Functionality</label>
                                    <ReactSelectForm
                                        fieldName={"functionality"}
                                        setFieldValue={setFieldValue}
                                        placeholder={"Pilih"}
                                        options={functionalityEnumOptions}
                                        isMulti={false}
                                        isDisabled={
                                            isLoading || 
                                            (
                                                !userScopes.includes(WAREHOUSE_TRANSFER_CATEGORY_CREATE) && 
                                                !userRoles.includes('admin')
                                            )
                                        }
                                        value={getOptionsFromValue(values.functionality, functionalityEnumOptions)}
                                        hideOnSelect={true}
                                        isClearable={true}
                                    />
                                    <ErrorMessage
                                        error={errors.functionality}
                                        touched={touched.functionality}
                                    />
                                </Col>
                            </Row>
                            <Row md={"auto"}>
                                <Col className={"form-group"}>
                                    <label>Warehouse Tag List</label>
                                    <ReactSelectForm
                                        fieldName={"whTagIds"}
                                        setFieldValue={setFieldValue}
                                        placeholder={"Pilih"}
                                        options={warehouseTagOptions}
                                        isMulti={true}
                                        isDisabled={isLoading || 
                                            (
                                                !userScopes.includes(WAREHOUSE_TRANSFER_CATEGORY_ASSIGN) && 
                                                !userRoles.includes('admin')
                                            )
                                        }
                                        value={getValueFromOptionsMulti(
                                            values.whTagIds,
                                            warehouseTagOptions
                                        )}
                                        hideOnSelect={false}
                                        isClearable={true}
                                    />
                                    <ErrorMessage
                                        error={errors.whTagIds}
                                        touched={touched.whTagIds}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <FooterAction />
                    </Form>
                )}
            </Formik>
        );
    };

    const BodyAction = () => {
        switch (action) {
            case ACTION.UPDATE:
            case ACTION.CREATE:
                return <CreateOrUpdateView />;

            default:
                return <TableDashboard />;
        }
    };

    const onClickCreate = () => {
        setAction(ACTION.CREATE);
    };

    const TitleAction = () => {
        switch (action) {
            case ACTION.VIEW:
                return (<>
                    <div className={classNameJoin(["align-items-center", styles.adjustmentPageTitle])}>
                        {MODAL_TITLE[action]}
                    </div>
                    <div className={styles.spaceBetween}>
                        <div style={{ width: "300px" }}>
                            <Select
                                onChange={(v, a) => {
                                    setSelectedFuncitonality(v);
                                }}
                                value={selectedFuncitonality}
                                isMulti={false}
                                isClearable={true}
                                options={functionalityEnumOptions}
                                placeholder={"Pilih Functionality"}
                                styles={{
                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                }}
                            />
                        </div>

                        <MenuAuthCheck allowedScopes={[WAREHOUSE_TRANSFER_CATEGORY_CREATE]}>
                            <div>
                                <Button
                                    disabled={isLoading}
                                    className={
                                        classNameJoin(["d-flex flex-row btn align-items-center ml-4", styles.createButton])
                                    }
                                    onClick={() => onClickCreate()}
                                >
                                    <p className={"m-0"}>Buat category baru</p>
                                </Button>
                            </div>
                        </MenuAuthCheck>
                    </div>
                </>
                );

            default:
                return (
                    <div className={styles.spaceBetween}>
                        <div className={classNameJoin(["align-items-center", styles.adjustmentPageTitle])}>
                            {MODAL_TITLE[action]}
                        </div>
                    </div>
                );
        }
    };

    const onBack = () => {
        setAction(ACTION.VIEW);
        fetchDashboard();
        setForm(INITIAL_FORM);
    };

    const FooterAction = () => {
        switch (action) {
            case ACTION.CREATE:
            case ACTION.UPDATE:
                return (
                    <div style={{ padding: "0 15px" }}>
                        <Button variant="danger" onClick={() => onBack()}>
                            Back
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button type="submit" variant="success">
                            Simpan
                        </Button>
                    </div>
                );

            default:
                return (<></>);
        }
    };

    return (
        <>
            <TitleAction />
            <BodyAction />
            <LoaderModalV2 blinkMessage={true} show={isLoading} />
            <ConfirmDeleteModalV2
                open={showDeleteModal}
                title={`Confirm Delete Group: ${selectedDeletedForm?.name}`}
                handleCancel={() => {
                    setShowDeleteModal(false);
                    setSelectedDeletedForm({});
                }}
                handleSubmit={handleDeleteById}
            />
        </>
    );
}

export default CategoryAdjustment;