import React from "react";

const LineBreak = (props) =>{
    const thickness = props.thickness ? props.thickness : 0.8;
    const color = props.color ? props.color : "#000000";
    return(
        <hr style={{border: `${thickness}px solid ${color}`, marginRight:"2%", marginBottom: "5px"}}/>
    );
}

export default LineBreak;