import customAxios from "./axios";
import {
    RECIPE_TAG_V1,
    RECIPE_TAG_V1_DELETE,
    RECIPE_TAG_V1_PAGINATION,
    RECIPE_TAG_BULK_ADD_V1
} from "../constants/api";

export function getAll() {
    return customAxios.get(RECIPE_TAG_V1);
}

export function getAllRecipeTags(param) {
    return customAxios.get(RECIPE_TAG_V1_PAGINATION, {params: param});
}

export function create(data) {
    return customAxios.post(RECIPE_TAG_V1, data);
}

export function deleteRecipeTag(id) {
    return customAxios.delete(RECIPE_TAG_V1_DELETE(id));
}

export function bulkCreateOrUpdate({data}) {
    return customAxios.post(RECIPE_TAG_BULK_ADD_V1, data);
}




