import {Button, Modal, Spinner} from "react-bootstrap";
import { Form, Formik} from "formik";
import React from "react";
import ReactSelectForm from "../../../../components/ReactSelectForm/ReactSelectForm";
import commonStyles from "./style.module.scss";
import { displayAlert } from "../../../../redux/actions/notif";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as shipmentApi from "../../../../apis/shipment";
import { AiFillExclamationCircle } from "react-icons/ai";

const _ = require('lodash');
class RunShipmentModal extends React.PureComponent {
    state = {
        isShowRunShipmentPrompt: false,
        createShipmentOrderData: null,
        isSubmitting: false,
    }

    createShipmentOrder = async (values) => {
        const data = !!values ?
            {
                warehouseTransferIds: values.warehouseTransferIds,
            }
        :
            this.state.createShipmentOrderData;

        this.setState({
            isSubmitting: true,
            isShowRunShipmentPrompt: false,
        })

        try {
            await shipmentApi.createShipmentOrder(this.props.selectedShipmentId, data);

            this.props.closeModalAndRefreshOrders();

            this.setState({
                isSubmitting: false,
            })
        } catch (e) {
            this.props.displayAlert({message: e.message, type: 'error'})
        }
    }

    runShipment = async (values) => {
        const shipmentPickListParams = {
            warehouseTransferIds: values.warehouseTransferIds.join(','),
        }

        const isPicklistOpen = (await shipmentApi.getPicklistIsOpen(shipmentPickListParams)).data.data;
        
        if (isPicklistOpen) {
            this.createShipmentOrder(values);
        } else {
            this.setState( {
                isShowRunShipmentPrompt: true,
                createShipmentOrderData: {
                    warehouseTransferIds: values.warehouseTransferIds,
                }
            })
        }
    }

    render() {
        const {isShow, selectedShipmentName, onClose } = this.props

        return <>
            <Modal size="m" centered
                        show={isShow}
                        onHide={onClose}>
                <Modal.Header closeButton={true}>
                    <Modal.Title> <b> Buat Shipment Order Untuk {selectedShipmentName} </b> </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={{
                            warehouseTransferIds: [],
                        }}
                        enableReinitialize={true}
                        onSubmit={this.runShipment}
                    >
                        {(formikProps) => (
                            <ModalBody
                                isSubmitting={this.state.isSubmitting}
                                {...this.props}
                                {...formikProps}
                            />
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>

            <Modal size="m" centered
                        show={this.state.isShowRunShipmentPrompt}
                        onHide={() => this.setState( { isShowRunShipmentPrompt: false })}>
                <Modal.Header closeButton={true}/>
                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        <AiFillExclamationCircle size='18%' color='#FD8D25'/>
                    </div>
                    <div className='mt-2 mb-3' style={{textAlign: 'center'}}>
                        <b>Lanjut run shipment? </b> <br/> 
                        Picklist yang belum selesai akan di-close
                    </div>
                    <div className={'form-group d-flex justify-content-end'}>
                        <Button className={`btn mr-2 ${commonStyles.btnCancel}`} onClick={() => this.setState( { isShowRunShipmentPrompt: false })}>Batal</Button>
                        <Button type={'button'} className={`btn ${commonStyles.btnOk}`} onClick={() => this.createShipmentOrder()}>Simpan</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    }
}

class ModalBody extends React.Component {
    state = {
        warehouseTransferIds: [],
        warehouseTransferOptions: [],
    }

    componentDidMount() {
        this.fetchWarehouseTransferOptions();
    }

    getValueFromOption(value, option) {
        return option.filter(e => e.value === value);
    }

    fetchWarehouseTransferOptions = async () => {
        let warehouseTransfers = [];
        try {
            warehouseTransfers = (await shipmentApi.getWarehouseTransfersByShipmentId(this.props.selectedShipmentId)).data.data;
        } catch (e) {
            this.props.displayAlert({message: e.message, type: 'error'});
        }
        
        const warehouseTransferOptions = warehouseTransfers.map(e => ({value: e.value, label: e.label}));

        this.setState({
            warehouseTransferOptions: warehouseTransferOptions,
        });
    }

    getOptionFromValue = (value, options) => {
        if (value === undefined || options === undefined) return;
        return options.find((i) => i.value === value);
    }

    render() {
        const { values, isSubmitting, setFieldValue, onClose } = this.props;

        const createForm = () => {
            return <>
                <div className={"row"}>
                    <div className={`col`}>
                        <div className={"form-group"}>
                            <label style={{marginBottom: '4px'}}><b>Pilih Dokumen Warehouse Transfer</b></label> <br/>
                            <label style={{fontSize: '12px'}}>Dokumen warehouse transfer yang dipilih akan digunakan dalam pembagian picklist</label>
                            <ReactSelectForm
                                isMulti={true}
                                fieldName={'warehouseTransferIds'}
                                placeholder={'Pilih...'}
                                options={this.state.warehouseTransferOptions}
                                isClearable
                                setFieldValue={setFieldValue}/>
                        </div>
                    </div>
                </div>
            </>
        }

        return <Form>
            {createForm()}
            <div className={'form-group d-flex justify-content-end'}>
                <Button className={`btn mr-2 ${commonStyles.btnCancel}`} disabled={isSubmitting} onClick={onClose}>
                    {isSubmitting && <Spinner animation="border" variant="light" size="sm"/>}Batal</Button>
                <Button type={'submit'} className={`btn ${commonStyles.btnOk}`} disabled={isSubmitting || _.isEmpty(values.warehouseTransferIds)}>
                    {isSubmitting && <Spinner animation={"border"} variant={"light"} size={"sm"}/>}Simpan</Button>
            </div>
        </Form>
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({ message, type }) => displayAlert({ message, type })
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RunShipmentModal);