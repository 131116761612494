import React from 'react';
import JsonBodyForm from "../../components/JsonBodyForm";
import {displayAlert} from "../../redux/actions/notif";
import {connect} from 'react-redux'
import * as s from "../../constants/scope";
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";
import RecipeTagTable from './RecipeTagTable';
import styles from './NewRecipeTags.module.css'
import { Button, Modal, Spinner } from 'react-bootstrap';
import * as globalConfigApis from "../../apis/globalConfigs";
import * as recipeApis from '../../apis/recipe';
import * as recipeTagApis from "../../apis/recipeTag";

class UpdateRecipeTagsPage extends React.Component {
    render() {
        return <PageAuthCheck allowedScopes={[s.MANAGE_RECIPE]}><div>
            <Body
                dispatch={this.props.dispatch}
                recipeTagId={this.props.location.state.id}
                history={this.props.history}
                totalSku={this.props.location.state.totalSku}
                name={this.props.location.state.name}
            />
        </div></PageAuthCheck>
    }

    componentDidMount() {
        document.title = "Update Recipe Tag";
    }
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recipeTags: [],
            name: "",
            selectedRecipeTagId: "",
            selectedOptions: [], 
            recipeIds: [],
            response: "",
            uploadResult:[],
            isSubmitFailed: false,
            showModal: false,
            isSubmitting: false,
            isLoading: false,
            loadingText: ""
        }
    }

    async componentDidMount() {
        const { dispatch, recipeTagId, name } = this.props;
        this.setState({
            selectedRecipeTagId: recipeTagId,
        })
        this.setState({ 
            isLoading: true,
        });

        try {  
            await this.loadRecipes();
            await this.loadRecipeTagData(recipeTagId);
        } catch(e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        } finally {
            this.setState({ isLoading: false });
        }
    }

    loadRecipes = async () => {
        const { dispatch } = this.props;

        this.setState({ loadingText: "Getting Recipe Options..." });
        try {
            const recipesResponse = await recipeApis.getAllRecipes({
                params: {
                    size: 10_000
                }
            })
            const recipes = recipesResponse?.data?.data?.data;

            let recipeOptions = [];
            if (Array.isArray(recipes) && recipes.length) {
                recipes.forEach(recipe => {
                        recipeOptions.push({ recipeId: recipe.id, label: recipe.name });
                })
            }
            this.setState({
                recipes: recipeOptions,
            });
        } catch (e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        } 
    }

    loadRecipeTagData = async (id) => {
        const { dispatch, recipeTagId, name } = this.props;
        this.setState({ loadingText: " Load Recipe Tag Data..." });
        try {
            const param =  Object.assign({}, {name}, {page: 0, size: 10000})
            const response = await recipeTagApis.getAllRecipeTags(param)
            const recipeTag = response?.data?.data?.data;

            let recipeOptions = [];
            if (Array.isArray(recipeTag[0]?.recipeTagRecipes) && recipeTag[0]?.recipeTagRecipes.length) {
                recipeTag[0].recipeTagRecipes.forEach(tag => {
                    recipeOptions.push({ id: tag.id, label: tag.recipe.name, priority: tag.priority, recipeId: tag.recipe?.id });
                })
            }

            this.setState({
                name: name,
                recipeIds: recipeOptions,
            });
        } catch(e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        } finally {
            this.setState({ loadingText: " Getting Recipe Tag Options..." });
        }
    }

    handleChange = async(fieldName, value) => {
        this.setState({
            [fieldName]: value
        });
    }

    handleSubmit = async(e) => {
        const {recipeTagId, name} = this.props;
        e.preventDefault();
        e.stopPropagation();

        const { dispatch } = this.props;
        let data = {};
        if (this.state.name) {
            data.name = this.state.name;
        }
        if (this.state.selectedRecipeTagId) {
            data.id = this.state.selectedRecipeTagId;
        }
        if (Array.isArray(this.state.recipeIds) && this.state.recipeIds.length) {
            let recipeTag = [];
            this.state.recipeIds.forEach(data => {
                const {recipeId, priority, id} = data
                recipeTag.push({recipeId, priority, id});
            });
            data.recipeTagRecipes = recipeTag

        }


        this.setState({ 
            isSubmitting: true,
            loadingText: "Submitting..."
         });
         this.setState({ isSubmitting: true, loadingText: "Submitting..." });
         try {
             const response =  await recipeTagApis.create(data);
             dispatch(displayAlert({ message: `Succeed Update Recipe Tag ${this.state.name}`, type: 'success' }));
             const recipeTagId = response.data.data.id;
             this.props.history.push(
                 "/recipe/tags/info", 
                 { 
                     id: recipeTagId,
                     name: this.state.name,
                 }
             )
         } catch(e) {
             dispatch(displayAlert({ message: e.message, type: 'error' }));
             this.setState({
                 isSubmitFailed: true
             })
         } finally {
             this.setState({ isSubmitting: false });
         }
    };

    handleChangeJsonForm(fieldName, value) {
        this.setState({
            [fieldName]: value
        })
    }

    handleChange = (e, index, fieldName) => {
        const value = e;
        const newRows = [...this.state.recipeIds];
        newRows[index][fieldName] = fieldName == "recipeId" ? parseInt(value) : value;

        this.setState({ 
            recipeIds: newRows,
        });

      };
    
    addRow = () => {
        this.setState({ recipeIds: [...this.state.recipeIds, { id: null, label: "Select...", priority: 10000, recipeId:-1 }] });
    };

    removeRecipeItem = (index) => {
        let newData = [...this.state.recipeIds];
        newData.splice(index, 1);
        this.setState({
            recipeIds: [...newData]
        })
    }

    render() {
        const {name} = this.props;
        let rowInfos = [
            {
                fieldName: "name",
                label: "Name",
                infoText: "All Recipe Tags must have unique name",
                value: this.state.name,
            }        
        ];

        return <div className="body-div">
             <Modal show={this.state.showModal} className={'d-flex align-items-center'}>   
                <Modal.Body>
                    <p className={'d-flex justify-content-center'}>Are you sure want to Cancel Editing?</p>
                    <div className={styles.centeredButtonGroup}>
                        <Button 
                            size='sm' 
                            className={'mr-4 pl-4 pr-4'} 
                            variant="primary" type='submit'
                            onClick={ () => {
                                this.props.history.goBack()
                                this.setState({showModal:false})
                            }}
                        >OK</Button>    
                        <Button 
                            className={'pl-4 pr-4'} 
                            size='sm'
                            variant="danger" 
                            onClick={e =>  this.setState({showModal:false})}
                        >Cancel</Button>    
                    </div>
                </Modal.Body>
            </Modal>
            <div className="internal-body-div">
            { this.state.isSubmitting || this.state.isLoading ? <div className={styles.overlaySpinner}>
                <Spinner animation="border" size="lg" />
                <p className='ml-2'>{this.state.loadingText}</p>
            </div>: <></>}
            <div className={`title-div ${styles.recipeTagFormHeader}`}>
                <h4>Update Recipe Tag</h4>
                <div className="d-flex">
                    <Button onClick={(e) => this.handleSubmit(e)} className={styles.customButton} variant="primary">Submit</Button>
                    <Button onClick={()=>{this.setState({showModal:true})}} className={styles.customButton} variant="secondary">Cancel</Button>
                </div>
            </div>
                <JsonBodyForm
                    onRowChange={(fn, v) => this.handleChangeJsonForm(fn, v)}
                    rowInfos={rowInfos}
                    disableSubmit={true}
                />
                        <RecipeTagTable 
                            addRow={this.addRow}
                            recipeIds={this.state.recipeIds}
                            recipes={this.state.recipes}
                            selectedOptions={this.state.selectedOptions}
                            handleChange={this.handleChange}
                            removeRecipeItem={this.removeRecipeItem}
                            isSubmitFailed={this.state.isSubmitFailed}
                        />
            </div>
            
        </div>;
    }
}

export default connect()(UpdateRecipeTagsPage);