import Spinner from "react-bootstrap/Spinner";
import React, { useState } from "react";
import { dayDateTimeFormatted } from "../utils/Date";
import { array } from "prop-types";
import { PERIOD_COLUMN } from "../constants/landingPageConstant";

function TableBodyResponse(props) {
    const [sortColumn, setSortColumn] = useState(0);
    const [sortDirection, setSortDirection] = useState('desc');

    if (props.isSubmitting) {
        return <Spinner animation="border" size="lg" />;
    }

    if (!props.titleRow && !props.contentRows) {
        return <div></div>;
    }

    const shouldShowFilterButton = (title) => {
        if (props.sortableColumns && Array.isArray(props.sortableColumns)) {
            return props.sortableColumns.includes(title);
        }
    }

    const handleSort = (columnIndex) => {
        if (sortColumn === columnIndex) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(columnIndex);
            setSortDirection('asc');
        }
    };

    let titleRow = "";
    if (props.titleRow) {
        let titles = [];
        props.titleRow.forEach((title, index) => {
            titles.push(
                <th key={"title" + index} onClick={shouldShowFilterButton(title) ? () => handleSort(index) : null}>
                    {title}
                    {shouldShowFilterButton(title) && (
                        <span>
                            {sortColumn === index ? (sortDirection === 'asc' ? ' ▲' : ' ▼') : ' ☰'}
                        </span>
                    )}
                </th>
            );
        });
        titleRow = <tr>{titles}</tr>;
    }

    let contents = [];
    let tdStyle = props.multipleColumns ? {
        maxWidth: '320px', 
        padding: '0px 8px', 
        textOverflow: 'ellipsis', 
        overflow: 'hidden', } : {}
    if (props.contentRows) {
        let sortedRows = [...props.contentRows];
        if (sortColumn !== null) {
            sortedRows.sort((a, b) => {
                const aValue = a[sortColumn];
                const bValue = b[sortColumn];
                
                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
                } else {
                    const aString = String(aValue).toLowerCase();
                    const bString = String(bValue).toLowerCase();
                    if (aString < bString) return sortDirection === 'asc' ? -1 : 1;
                    if (aString > bString) return sortDirection === 'asc' ? 1 : -1;
                    return 0;
                }
            });
        }
        sortedRows.forEach((contentRow) => {
            let contentRowArr = [];
            contentRow.forEach((content, index) => {
                contentRowArr.push(
                    <td style={tdStyle}>{content}</td>
                );
            });
            contents.push(
                <tr>{contentRowArr}</tr>
            );
        });
    }
    return <div >
        <table className="res-table">
            <thead>
                {titleRow}
            </thead>
            <tbody>
                {contents}
            </tbody>
        </table>
    </div>;
}

export default TableBodyResponse;