import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { displayAlert } from '../../redux/actions/notif';
import PageAuthCheck from '../../components/AuthCheck/PageAuthCheck';
import ApprovalModal from '../../components/ApprovalModal/ApprovalModal';
import MainContainer from '../../components/MainContainer/MainContainer';
import TableBodyResponse from '../../components/TableBodyResponse';
import ModalCreateUpdateNegativePrices from './ModalCreateUpdateNegativePrices';
import { MANAGE_NEGATIVE_PRICE } from '../../constants/scope';

import * as negativePriceApi from '../../apis/negativePrice';

class ManageNegativePricesPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            negativePrices: [],
            showCreateUpdateModal: false,
            idToEditNegativePrice: null,
            showChangeActiveModal: false,
            idToChangeActiveStatus: 0,
            nameToChangeActiveStatus: "",
            activeStatusToSubmit: false
        }
    }
    
    componentDidMount = async () => {
        await this.searchNegativePrices();
    }

    searchNegativePrices = async () => {
        this.setState({
            isLoading: true
        })

        try {
            const responseApi = await negativePriceApi.getNegativePriceList();
            this.setState({
                negativePrices: responseApi.data.data.data
            })
        } catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error' });
        }

        this.setState({
            isLoading: false
        })
    }

    closeModalCreateUpdateAndRefreshTable = () => {
        this.closeModalCreateUpdate();
        this.searchNegativePrices();
    }

    closeModalCreateUpdate = () => {
        this.setState({
            idToEditNegativePrice: null,
            showCreateUpdateModal: false
        })
    }

    onClickUpdate = (negativePrice) => {
        this.setState({
            idToEditNegativePrice: negativePrice.id,
            showCreateUpdateModal: true
        })
    }

    changeIsActiveStatus = (negativePrice, value) => {
        this.setState({
            showChangeActiveModal: true,
            idToChangeActiveStatus: negativePrice.id,
            nameToChangeActiveStatus: negativePrice.name,
            activeStatusToSubmit: value
        })
    }

    confirmChangeActiveStatus = async () => {
        let params = {
            id: this.state.idToChangeActiveStatus,
            activeStatus: this.state.activeStatusToSubmit
        };

        try {
            const response = await negativePriceApi.changeNegativePriceActiveStatus(params);
            this.props.displayAlert({ message: `Berhasil mengganti status harga negatif!`, type: 'success' });
            this.setState({ showChangeActiveModal: false });
        } catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error' })
        }
        
        this.searchNegativePrices();
    }

    titleRow = ["Id", "Promo Name", "Edit", "Status"]
    getTableContent() {
        const negativePrices = this.state.negativePrices;
        let rows = [];

        for (let i = 0; i < negativePrices.length; i++) {
            const negativePrice = negativePrices[i];
            let activeBtn = <Form.Check
                type="switch"
                id={`switch-active-${i}`}
                style={{ margin: "auto" }}
                label={""}
                checked={negativePrice.isActive}
                onChange={e => this.changeIsActiveStatus(negativePrice, e.target.checked)} />
            let editBtn = <button className='btn btn-primary' type="button" onClick={() => this.onClickUpdate(negativePrice)}>Edit</button>
            let row = [negativePrice.id, negativePrice.name, editBtn, activeBtn];

            rows.push(row);
        }

        return rows;
    }

    render() {
        return <PageAuthCheck allowedScopes={[MANAGE_NEGATIVE_PRICE]}>
            <MainContainer title='Manage Harga Negatif'>
                { this.state.isLoading ? <Spinner animation="border" size="lg" /> : <React.Fragment>
                    <ApprovalModal show={this.state.showChangeActiveModal}
                        message={`Are you sure want to make harga negatif with name ${this.state.nameToChangeActiveStatus} and id ${this.state.idToChangeActiveStatus} become ${this.state.activeStatusToSubmit ? "ACTIVE" : "INACTIVE"}.`}
                        onCancel={() => this.setState({ showChangeActiveModal:false })} 
                        onConfirm={this.confirmChangeActiveStatus}/>
                    { this.state.showCreateUpdateModal ? <ModalCreateUpdateNegativePrices show={this.state.showCreateUpdateModal}
                        idToEditNegativePrice={this.state.idToEditNegativePrice}
                        displayAlert={this.props.displayAlert}
                        onClose={this.closeModalCreateUpdate}
                        closeModalCreateUpdateAndRefreshTable={this.closeModalCreateUpdateAndRefreshTable}/> : <div></div> }
                    <button className='btn btn-primary mt-3' type="button" onClick={() => this.setState({ showCreateUpdateModal: true, idToEditNegativePrice: null })}>
                        Create Harga Negatif
                    </button>
                    <hr/>
                    <button className='btn btn-primary mt-3' type="button" onClick={() => this.searchNegativePrices()}>
                        Refresh Table
                    </button>
                    <hr/>
                    <TableBodyResponse
                        isSubmitting={this.state.isLoading}
                        titleRow={this.titleRow}
                        contentRows={this.getTableContent()}
                    />
                </React.Fragment>
            }
            </MainContainer>
        </PageAuthCheck>
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({message, type}) => displayAlert({message, type})
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageNegativePricesPage);