import React from 'react';
import JsonBodyForm from "../../components/JsonBodyForm";
import TableBodyResponse from "../../components/TableBodyResponse";
import AuthCheck from "../../components/AuthCheck/AuthCheck";
import * as frontendCategoryApis from "../../apis/frontendCategories";
import {displayAlert} from "../../redux/actions/notif";
import {connect} from 'react-redux'
import * as s from "../../constants/scope";
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";

class ListFrontendCategoriesPage extends React.Component {
    render() {
        return <PageAuthCheck allowedScopes={[s.CATEGORIZATION_CONFIGURE]}><div>
            <Title />
            <Body
                dispatch={this.props.dispatch}
            />
        </div></PageAuthCheck>
    }

    componentDidMount() {
        document.title = "List Frontend Category";
    }
}

function Title() {
    return <div className="title-div"><h1 className="title-text">List Frontend Category</h1></div>;
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitting: false,
            response: "",
        }
    }

    handleSubmit = async(e) => {
        e.preventDefault();
        e.stopPropagation();

        const {dispatch} = this.props;
        this.setState({isSubmitting: true});
        try {
            const response = await frontendCategoryApis.getAllWithFrontendSubcategories();
            this.setState({
                response: response.data.data
            })
        } catch (e) {
            dispatch(displayAlert({message: e.message, type: 'error'}));
        } finally {
            this.setState({
                isSubmitting: false
            });
        }
    };

    handleChange(fieldName, value) {
        this.setState({
            [fieldName]: value
        })
    }

    render() {
        let titleRow = [];
        let bodyResponse = "";
        let contentRows = [];

        if (this.state.response) {
            titleRow = [
                "ID", "Name", "Frontend Subcategories", "Image URL"
            ];
            this.state.response.forEach((row) => {
                let frontendSubcategories = []; 
                row.frontendSubcategories.forEach((fs) => {
                    frontendSubcategories.push(<p>{fs.name}</p>);
                });

                let contentRow = [
                    row.frontendCategory.id, row.frontendCategory.name, frontendSubcategories, row.frontendCategory.imageUrl
                ];
                
                contentRows.push(contentRow);
            });

            bodyResponse = <TableBodyResponse
                isSubmitting={this.state.isSubmitting}
                titleRow={titleRow}
                contentRows={contentRows}
            />;
        }

        return <div className="body-div">
            <div className="internal-body-div">
                <JsonBodyForm
                    onRowChange={(fn, v) => this.handleChange(fn, v)}
                    onSubmit={(e) => this.handleSubmit(e)}
                    submitText={"Get"}
                    disableSubmit={this.state.isSubmitting}
                />
            </div>
            {bodyResponse}
        </div>;
    }
}

export default connect()(ListFrontendCategoriesPage);