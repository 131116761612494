import React from 'react'
import { Form } from "react-bootstrap";
import {ITEM_FIELDS} from "../../pages/items/constants";

class UploadFileInput extends React.PureComponent {

    constructor(props) {
        super(props);

        this.uploadInputRef = React.createRef();

        this.handleButtonUploadClick = this.handleButtonUploadClick.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    async handleUpload(e) {
        const file = e.target.files.length > 0 ? e.target.files[0] : null;

        if(file != null && file.size > 100000) {
            alert('File size exceeds the allowed limit (100 KB).');
        } else {
            this.props.onUploadFile(file);
        }
    }

    async handleReset(e) {
        e.preventDefault();
        this.props.onUploadFile(null);
    }

    handleButtonUploadClick(e) {
        e.preventDefault();
        this.uploadInputRef.current.click();
    }

    render(){
        return <div >
            <div >
                <Form.Group className="form-prop">
                    <div>
                        <div className='col-sm-9 align-items-center d-flex' style={{padding: '0 2vw 0 0'}}>
                            <input key={this.props.fileName} id="fileInput" ref={this.uploadInputRef} type="file" onChange={this.handleUpload} accept={this.props.fileType} style={{ display: 'none' }} />
                            {this.props.isOnlyChooseButton ? <></> :
                            <input className="form-control"
                                    type="text"
                                    value={this.props.fileName}
                                    placeholder="Choose file..."
                                    disabled={true}
                                    style={{width: '80%', marginRight: 10,  display: 'inline-block', height: '30px'}}
                            />}
                            <div style={{width: '40%', display: 'inline-block'}}>
                                <button className={'btn btn-primary btn-sm'} onClick={this.handleButtonUploadClick}>Browse</button>
                                {this.props.isOnlyChooseButton ? <></> : <button className={'btn btn-danger btn-sm mx-2'} onClick={(e) => this.handleReset(e)}>Reset</button>}
                            </div>
                        </div>
                    </div>
                </Form.Group>
            </div>
            {this.props.isOnlyChooseButton ? <></> :
            <div>
                <div className="row">
                    { this.props.fileSource ?
                        <img style={{maxWidth: 200, marginLeft: 16, marginRight: 10, height: 'auto'}} src={this.props.fileSource} alt=" Preview" />
                        : <div style={{maxWidth: 100, marginLeft: 16, marginRight: 10, height: 'auto'}}>Preview</div>
                    }
                    {(this.props.fileAwsUrl && this.props.fileSource == this.props.fileAwsUrl) && <a download style={{ color: 'blue', textDecoration: 'underline' }} href={this.props.fileAwsUrl} target="_blank">{this.props.fileAwsUrl}</a> }
                </div>
            </div>}
        </div>
    }
}

export default UploadFileInput