import React from 'react';
import * as s from "../../constants/scope";
import MasterDetail from "../../components/MasterDetail";
import PageAuthCheck from '../../components/AuthCheck/PageAuthCheck';
import MenuAuthCheck from "../../components/AuthCheck/MenuAuthCheck";

import * as frontendCategoryApis from "../../apis/frontendCategories";
import * as apiFrontendCategorySortings from '../../apis/frontendCategorySortings';

import { bindActionCreators } from "redux";
import { displayAlert } from "../../redux/actions/notif";
import { connect } from "react-redux";

import {Formik, Form} from 'formik';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import styles from "./SortFrontendCategoriesPage.module.css";
import queryString from "query-string";
import Select from "react-select";

import _ from "lodash"

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const START_DATE = moment(Date.now()).toDate()
const END_DATE = moment(Date.now()).toDate()
const ISO_FORMAT = "YYYY-MM-DD"

const NEW_CATEGORIZATION_ONE_LINE = 'new-categorization-one-line';
const NEW_CATEGORIZATION_TWO_LINE_WITH_AUTO_SCROLL = 'new-categorization-two-line-with-auto-scroll';
const NEW_CATEGORIZATION_TWO_LINE_WITHOUT_AUTO_SCROLL = 'new-categorization-two-line-without-auto-scroll';
const HOMEPAGE_NO_FLASH_SALE_WITH_PROMO_ENTRY_POINT_NEW_CATEGORY_ENTRY_POINT = 'homepage_no_flash_sale_with_promo_entry_point_new_category_entry_point';
const SHOW_ALL_3_COLS = '3_cols_show_all';
const SHOW_ALL_4_COLS = '4_cols_show_all';

const ALL_VARIANTS = [
    NEW_CATEGORIZATION_ONE_LINE,
    NEW_CATEGORIZATION_TWO_LINE_WITH_AUTO_SCROLL,
    NEW_CATEGORIZATION_TWO_LINE_WITHOUT_AUTO_SCROLL,
    HOMEPAGE_NO_FLASH_SALE_WITH_PROMO_ENTRY_POINT_NEW_CATEGORY_ENTRY_POINT,
    SHOW_ALL_3_COLS,
    SHOW_ALL_4_COLS
]

const NOT_LIMITED_CATEGORIES_VARIANT = [
    HOMEPAGE_NO_FLASH_SALE_WITH_PROMO_ENTRY_POINT_NEW_CATEGORY_ENTRY_POINT,
    SHOW_ALL_3_COLS,
    SHOW_ALL_4_COLS
]

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};
  
const grid = 8;
  
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    border: '1px black solid',
  
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "lightgrey",
  
    // styles we need to apply on draggables
    ...draggableStyle
});
  
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid,
    width: 400
});
  
class FrontendCategoriesSortingPage extends React.Component {
    state = {
        params: {
            experimentVariant: '',
        },
        frontendCategorySortings: [],
        oldFrontendCategorySortings: [],

        errors: [],
        showDetail: false,
        showInputModal: false,
        isLoadingSearchResult: false,
        isLoadingUpdateSorting: false,
        moreThanEight: false
    }

    componentDidMount = async () => {
        try {
            const {orderId}  = queryString.parse(this.props.location.search);
            const params = !orderId ? {...this.state.params} : {orderId: orderId};

            this.setState({
                params,
            })

        } catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error' })
        }
    }

    getParams = () => {
        const params = {
            ...this.state.params,
            shippingPointIds: this.state.params.shippingPointIds?.join(),
            deliveryTypes: this.state.params.deliveryTypes?.join(),
            agentIds: this.state.params.agentIds?.join(),
            size: 1_000
        }

        if (params.deliveryDate) {
            params.deliveryDate = moment(params.deliveryDate).format(ISO_FORMAT)
        }

        if (params.deliveryDateEnd) {
            params.deliveryDateEnd = moment(params.deliveryDateEnd).format(ISO_FORMAT)
        }

        return params;
    }

    onSubmitSearch = async (values) => {
        this.setState({
            isLoadingSearchResult: true
        });
        if(NOT_LIMITED_CATEGORIES_VARIANT.includes(values.experimentVariant)){
            this.setState({
                moreThanEight: true
            })
        }else{
            this.setState({
                moreThanEight: false
            })
        }
        const params = { ...values
        }

        try {
            const [ frontendCategorySortingsResponse] = await Promise.all([
                apiFrontendCategorySortings.searchIntools(params)
            ]);

            let fcsResponse = frontendCategorySortingsResponse.data.data;

            this.setState({
                frontendCategorySortings: fcsResponse,
                oldFrontendCategorySortings: fcsResponse,
            });
        } catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error' })
        }
        this.setState({
            isLoadingSearchResult: false
        });
    }

    handleUpdateSorting = async () => {
        this.setState({
            isLoadingUpdateSorting: true
        });
        
        const visibleItemCount = this.state.frontendCategorySortings.filter(e => e.visible).length
        /*if( !(visibleItemCount === 4 || visibleItemCount === 8) && !this.state.moreThanEight){
            this.props.displayAlert({ message: 'Error: The number of visible items should be exactly 4 or 8 except for variant homepage_no_flash_sale_with_promo_entry_point_new_category_entry_point', type: 'error' })
            return
        }*/

        const params = this.state.frontendCategorySortings.map(
            e=> ({
                id: e.id,
                frontendCategoryId: e.frontendCategory.id,
                priority: e.priority,
                experimentVariant: e.experimentVariant,
                visible: e.visible,
            })
        )

        //validation for L1 that has no L2 but set as visible.
        const response = await frontendCategoryApis.getAllWithFrontendSubcategories();
        const frontendCategory = response?.data?.data.map(
            e=> ({
                frontendCategoryId: e.frontendCategory.id,
                frontendSubcategories: e.frontendSubcategories                
            })
        )
        for(let i = 0; i < params.length; i++){
            for(let j = 0; j < frontendCategory.length; j++){
                if(params[i].frontendCategoryId == frontendCategory[j].frontendCategoryId && params[i].visible){
                    if(frontendCategory[j].frontendSubcategories == ""){
                        this.props.displayAlert({ message: "ERROR: Please insert frontend subcategory before activating the frontend category", type: 'error' })
                        return;
                    }
                }
            }
        }

        try {
            const [ frontendCategorySortingsResponse] = await Promise.all([
                apiFrontendCategorySortings.updateBulk(params)
            ]);

            let fcsResponse = frontendCategorySortingsResponse.data.data;

            this.setState({
                frontendCategorySortings: fcsResponse,
                oldFrontendCategorySortings: fcsResponse,
            });
            this.props.displayAlert({ message: 'success update sorting', type: 'success' })
        } catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error' })
        }
        this.setState({
            isLoadingUpdateSorting: false
        });
    }

    renderNavbar = () => {
        const {lockOrderDelivery} = this.state;
        return <div>
            <h2>Frontend Category Sorting</h2>
            <Formik
                initialValues={this.state.params}
                onSubmit={this.onSubmitSearch}
                enableReinitialize={true}
            >
                {(formikProps) =>
                    <SearchForm
                        {...formikProps}
                        isLoadingSearchResult={this.state.isLoadingSearchResult}
                    />}
            </Formik>
            <div className='mb-2'>
                <MenuAuthCheck allowedScopes={[s.CATEGORIZATION_CONFIGURE]}>
                    {/* <button className='btn btn-success' onClick={this.onClickUploadCsv}>
                        Add Category for Sort
                    </button>{' '} */}
                    <button className='btn btn-success' disabled={_.isEqual(this.state.frontendCategorySortings, this.state.oldFrontendCategorySortings)} onClick={this.handleUpdateSorting}>
                        Update Sorting
                    </button>
                </MenuAuthCheck>
            </div>
        </div>
    }


    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          this.state.frontendCategorySortings,
          result.source.index,
          result.destination.index
        ).map((item, index)=> 
            ({
                ...item, priority: index+1
            })
        )
    
        this.setState({
            frontendCategorySortings: items
        });
      }

    handleCheckboxVisible = (id, checked) => {
        let items = this.state.frontendCategorySortings.map(e => {
            if (e.id === id){
                return { ...e, visible: checked }
            }
            return e
        })

        this.setState({
            frontendCategorySortings: items
        });
    }

    renderMaster = () => {
        const {isLoadingSearchResult} = this.state;

        if (isLoadingSearchResult) {
            return  <Spinner className="mx-auto d-block" animation="border" variant="dark" size="xl" />
        }
        
        if(this.state.frontendCategorySortings.length === 0) {
            return <div>Pilih Varian Terlebih Dahulu</div>
        }

        return (
            this.state.frontendCategorySortings.length > 0 && <div>
                <p>Instruction</p>
                <ul>
                    <li>Drag to sort</li>
                    <li>If you do not set category as "visible", L1 category will not shown anywhere on FE.</li>
                    <li>Top 8 categories will be shown on Homepage, the rest can be found on category directory.</li>
                </ul>

                <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    >
                    {this.state.frontendCategorySortings.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            >
                                <span className={styles.categoryPriority}>{item?.priority}</span>{' '}
                                <img className={styles.categoryImage} src={item?.frontendCategory?.imageUrl}/>
                                <span className={styles.categoryName}>{item?.frontendCategory?.name}</span>
                                <label className={styles.categoryVisible}>Visible
                                    <input type='checkbox'
                                    checked={item.visible || false}
                                    onChange={(e) => {
                                        this.handleCheckboxVisible(item.id, e.target.checked)
                                    }}/>
                                </label>
                                
                            </div>
                        )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                    </div>
                )}
                </Droppable>
            </DragDropContext>
            </div>
        )
    }

    render() {
        return (
            <PageAuthCheck allowedScopes={[s.CATEGORIZATION_CONFIGURE]}>
                <MasterDetail
                    navbar={this.renderNavbar()}
                    master={this.renderMaster()}
                    detail={this.state.showDetail && this.renderDetail()}
                />
            </PageAuthCheck>
        )
    }
}

class SearchForm extends React.PureComponent {
    render() {
        const {values, setFieldValue, shippingPointOptions, deliveryTypeOptions,
            deliveryStatusOptions, agentOptions, agentTypeOptions, courierOptions } = this.props
        const options = ALL_VARIANTS.map((variant) => ({value: variant, label: variant}));

        return (
            <Form className='my-1' id="search-form">
                <div className="row">
                    <div className="col-3">
                        <div className='form-group'>
                            <div>Experiment Variant</div>
                            <Select
                                options={options}
                                onChange={(e) => setFieldValue("experimentVariant", e.value)}
                            />
                        </div>
                    </div>
                    <div className="col-1">
                        <button type='submit' className='btn btn-primary btn-block mt-4'>
                            Cari
                        </button>
                    </div>
                </div>
            </Form>
        );
    }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({ message, type }) => displayAlert({ message, type })
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontendCategoriesSortingPage)