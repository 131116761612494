import React from "react";
import styles from "./style.module.css";
import MenuAuthCheck from '../../../../components/AuthCheck/MenuAuthCheck.js';
import { WAREHOUSE_TRANSFER_CATEGORY_ASSIGN, WAREHOUSE_TRANSFER_CATEGORY_CREATE } from "../../../../constants/scope.js";

export const COLUMN_WH_TAG_GROUP_DASHBOARD = ({
  onClickEdit,
  onClickDelete
}) => {
  return [
    {
      Header: "Categories",
      accessor: "name",
      minWid: '15%',
      Cell: function WarehouseTagCell({ row }) {
        return <div>
          <div className={styles.tableCategoryNameHeader}>{row.original?.name}</div>
        </div>;
      },
    },
    {
      Header: "Warehouse Tag",
      accessor: "whTagNames",
      width: '75%',
      Cell: function WarehouseTagCell({ value }) {
        return <StringWithBadges productTags={value} />;
      },
    },
    {
      Header: "Action",
      width: '10%',
      Cell: function ActionCell({ row }) {
        return (
          <div style={{ textAlign: "left" }}>
            <React.Fragment>
              <MenuAuthCheck allowedScopes={[WAREHOUSE_TRANSFER_CATEGORY_ASSIGN, WAREHOUSE_TRANSFER_CATEGORY_CREATE]}>
                <div className="col" style={{padding:'0px'}}>
                  <button
                    onClick={() => onClickEdit(row?.original)}
                    className="btn btn-link"
                    style={{ textDecoration: "none", padding: 0, margin: 0 }}
                  >
                    <u>Edit</u>
                  </button>
                </div>
              </MenuAuthCheck>
              <MenuAuthCheck allowedScopes={[WAREHOUSE_TRANSFER_CATEGORY_CREATE]}>
                <div className="col" style={{padding:'0px'}}>
                  <button
                    onClick={() => onClickDelete(row?.original)}
                    className="btn btn-link text-danger"
                    style={{ textDecoration: "none", padding: 0, margin: 0 }}
                  >
                    <u>Delete</u>
                  </button>
                </div>
              </MenuAuthCheck>
            </React.Fragment>
            </div>
        );
      },
    },
  ];
};

function StringWithBadges({ productTags }) {
  if (!productTags) return "-";
  const tags = productTags.split(",");
  return (
    <div style={{ textAlign: "start" }}>
      {tags.map((tag, index) => {
        return (
          <span
            key={index}
            style={{ fontSize: "14px", textAlign: "start", backgroundColor: "#22790a", padding: "6px 8px" }}
            className={`badge badge-success ml-1 mt-1`}
          >
            {tag.trim()}
          </span>
        );
      })}
    </div>
  );
}