const ACTIVE_LINKS = {
    EMPLOYMENT: "/manpower/database/employment",
    PERSONAL: "/manpower/database/personal",
    ROLES: "/manpower/database/roles",
    TESTING: "/manpower/database/testing",
}

const LINKS_WITH_SUBHEADERS = [
    {
        label: "",
        links: [{link: ACTIVE_LINKS.EMPLOYMENT, label: 'Employment'}, {link: ACTIVE_LINKS.PERSONAL, label: 'Personal'}, {link: ACTIVE_LINKS.ROLES, label: 'Role list'}],
    }
]

export {ACTIVE_LINKS, LINKS_WITH_SUBHEADERS};