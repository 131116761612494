import {combineReducers} from 'redux'
import notifReducer from './notif'
import authReducer from './auth'
import gmapsReducer from './gmaps'
import warehouseTransfer from './warehouseTransfer'
import kulaLeftNavbar from './kulaLeftNavbar'

export default combineReducers({
    auth: authReducer,
    gmaps: gmapsReducer,
    notif: notifReducer,
    warehouseTransfer: warehouseTransfer,
    kulaLeftNavbar: kulaLeftNavbar,
})