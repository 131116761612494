import React from "react";

const LabelBox = (props)=>{
    return(
        <div className={"w-50"} style={{fontSize: "1em"}}>
            {props.text}
        </div>
    );
}

export default LabelBox;