import customAxios from "./axios";

import {
    INTERNAL_SHIPMENT_V1,
    INTERNAL_SHIPMENT_V1_ID,
    INTERNAL_SHIPMENT_GROUP_V1,
    INTERNAL_SHIPMENT_PURCHASE_RETURN_V1_ID,
} from "../constants/api";

export function getInternalShipment({ id }) {
    return customAxios.get(INTERNAL_SHIPMENT_V1_ID({ id: id }))
}
export function getInternalShipmentByPurchaseReturnId({ id }) {
    return customAxios.get(INTERNAL_SHIPMENT_PURCHASE_RETURN_V1_ID({ id: id }))
}
export function createInternalShipment(data) {
    return customAxios.post(INTERNAL_SHIPMENT_V1, data)
}

export function createGroupInternalShipment(data) {
    return customAxios.post(INTERNAL_SHIPMENT_GROUP_V1, data);
}

