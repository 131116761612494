import customAxios from "./axios";
import {
    PURCHASE_ORDER_UPLOAD_V1,
    PURCHASE_ORDER_V1_ID,
    PURCHASE_ORDER_V1_1,
    PURCHASE_ORDER_V1_1_ID_ITEMS,
    PURCHASE_ORDER_V1_1_ID,
    PURCHASE_ORDER_V2,
    PURCHASE_ORDER_V2_ID,
    PURCHASE_ORDER_V2_BULK_CREATE,
    PURCHASE_ORDER_V2_SEND_PO_PDF_EMAIL,
    PURCHASE_ORDER_DOWNLOAD_ZIP
} from "../constants/api";

import moment from "moment";
import * as qs from "query-string";
import fileDownload from "js-file-download";

export function uploadPO({data}) {
    return customAxios.post(PURCHASE_ORDER_UPLOAD_V1, data)
}

export function getPO({id}) {
    return customAxios.get(PURCHASE_ORDER_V1_1_ID({id}))
}


export function getPOItemById({id}) {
    return customAxios.get(PURCHASE_ORDER_V1_1_ID_ITEMS({id}))
}

export function deletePO({id}) {
    return customAxios.delete(PURCHASE_ORDER_V1_ID({id}))
}

export function getAllPO({...params}) {
    return customAxios.get(PURCHASE_ORDER_V1_1, {params: params})
}

export function getAllPOV2({...params}) {
    return customAxios.get(PURCHASE_ORDER_V2, {params: params})
}

export function create({data}) {
    return customAxios.post(PURCHASE_ORDER_V1_1, data)
}

export function createV2({data}) {
    return customAxios.post(PURCHASE_ORDER_V2, data)
}

export function bulkCreateV2({data}) {
    return customAxios.post(PURCHASE_ORDER_V2_BULK_CREATE, data, {headers: {'Content-Type': 'multipart/form-data'}})
}

export function verifyBulkCreateV2({data}) {
    return customAxios.post(`${PURCHASE_ORDER_V2_BULK_CREATE}/verify`, data, {headers: {'Content-Type': 'multipart/form-data'}})
}

export function update({id, data}) {
    return customAxios.put(PURCHASE_ORDER_V1_1_ID({id}), data)
}

export function updateV2({id, data}) {
    return customAxios.put(PURCHASE_ORDER_V2_ID({id}), data)
}

export function updateStatusV2({id, data}) {
    return customAxios.put(PURCHASE_ORDER_V2_ID({id}) + '/status', data)
}

export function bulkUpdateStatusV2({data}) {
    return customAxios.put(PURCHASE_ORDER_V2 + '/bulk-update-status', data)
}

export function sendPoPdfV2({data}) {
    return customAxios.post(PURCHASE_ORDER_V2_SEND_PO_PDF_EMAIL, data)
}

export function bulkDownload(params, batch) {
    return customAxios.get(PURCHASE_ORDER_DOWNLOAD_ZIP, {
        params,
        paramsSerializer: params => {
            return qs.stringify(params, {
                arrayFormat: 'comma',
            })
        },
        responseType: 'blob'
    }).then((response) => {
        let date = moment().format('YYYYMMDD_HHmmss')
        fileDownload(response.data, `PO_${date}_B${batch}.zip`);
    });
}