import {
    LOGIN_URL, ME_URL, PROFILE_V1,
} from '../constants/api'
import axios from './axios'

export function login({identifier,password}){
    return axios.post(LOGIN_URL, {identifier,password})
}

export function me(){
    return axios.get(ME_URL)
}

export function updateProfile({data}){
    return axios.patch(PROFILE_V1,data)
}