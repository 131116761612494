import React from 'react';

const ContentWithSideContent = (props)=>{
    let {show, hideSideContent} = props;
    const {Main, Side} = props;
    if(!show){
        show = false;
    }

    const closeSideBar = ()=>{
        if(hideSideContent){
            hideSideContent(false);
        }
    }
    
    const MainContent =
        <div className={`${show? 'w-50' : 'w-100'}`} >
            {Main? Main: <></>}
        </div>;

    const SideContent = show ?
            <div className={"w-50 border border-2 border-dark"}>
                <div className={"w-100 d-flex justify-content-end pr-2 pt-1 close-button text-light"}>
                    <div  onClick={()=>{closeSideBar()}}  className={"btn btn-danger text-center"}>
                        X
                    </div>
                </div>
                {Side? Side: <></>}
            </div> : <></>;

    return(
        <div className={"d-flex w-100 h-100 overflow-auto"}>
            {MainContent}
            {SideContent}
        </div>
    );
}

export default ContentWithSideContent;