import customAxios from "./axios";
import {
    FRONTEND_CATEGORY_V1,
    FRONTEND_CATEGORY_DETAIL_V1,
    FRONTEND_CATEGORY_SEO_V1,
    FRONTEND_CATEGORY_RESET_SEO_V1,
    FRONTEND_CATEGORY_LOGS_V1
} from "../constants/api";

export function getAll() {
    return customAxios.get(FRONTEND_CATEGORY_V1);
}

export function getById({params}){
    return customAxios.get(FRONTEND_CATEGORY_V1, {params});
}

export function updateFrontendCategorySeo(body){
    return customAxios.put(FRONTEND_CATEGORY_SEO_V1, body)
}

export function resetSeoFrontendCategoryById(id){
    return customAxios.put(FRONTEND_CATEGORY_RESET_SEO_V1(id))
}

export function getAllWithFrontendSubcategories() {
    return customAxios.get(FRONTEND_CATEGORY_DETAIL_V1);
}

export function getByIdWithFrontendSubcategories(id) {
    return customAxios.get(FRONTEND_CATEGORY_DETAIL_V1 + '/id/' + id);
}

export function getByNameWithFrontendSubcategories(name) {
    return customAxios.get(FRONTEND_CATEGORY_DETAIL_V1 + '/name/' + name);
}

export function create(data) {
    return customAxios.post(FRONTEND_CATEGORY_V1, data);
}

export function update(id, data) {
    return customAxios.put(FRONTEND_CATEGORY_V1 + '/' + id, data);
}

export function deletes(id) {
    return customAxios.delete(FRONTEND_CATEGORY_V1 + '/' + id);
}

export function getFrontendCategoriesLogs(params) {
    return customAxios.get(FRONTEND_CATEGORY_LOGS_V1, {params});
}
