import React, { useState, useEffect } from 'react';
import styles from "./style.module.scss";
import * as shipmentApi from "../../../../apis/shipment.js";
import moment from 'moment';
import { TIMEZONE } from '../../../../constants/time.js';
import ShipmentOrderPicklistSearchAndDownloadBar from './ShipmentOrderPicklistSearchAndDownloadBar.js';
import { Spinner } from 'react-bootstrap';

const _ = require('lodash');
const ShipmentOrdersPicklistsSection = (props) => {
    const { show, warehouses, selectedShipmentOrderId, selectedShipmentName } = props;
    const [ picklists, setPicklists ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);

    const fetchShipmentOrderPicklist = async (warehouseId) => {
        setIsLoading(true);

        const param = {
            warehouseDestinationId: warehouseId,
        }
        const data = (await shipmentApi.getShipmentOrderPicklists(selectedShipmentOrderId, param)).data.data;

        setPicklists(data);

        setIsLoading(false);
    }

    useEffect(() => {
        fetchShipmentOrderPicklist();
    }, [show])

    const generateRows = () => {
        return picklists.map(p => {
            return ShipmentDetailOrderRow(p);
        })
    }

    return (
        <div className='overflow-auto' style={{maxHeight: '60vh', minHeight: '60vh'}}>
            <ShipmentOrderPicklistSearchAndDownloadBar fetchShipmentOrderPicklist={fetchShipmentOrderPicklist} warehouses={warehouses} selectedShipmentOrderId={selectedShipmentOrderId} selectedShipmentName={selectedShipmentName}/>
            <table className={styles.borderlessTable}>
                <thead>
                    <tr>
                        <th>STATUS</th>
                        <th>PICKLIST ID</th>
                        <th>STARTED</th>
                        <th>LAST UPDATED</th>
                        <th>TUJUAN</th>
                        <th>ZONA PICKING</th>
                        <th>JUMLAH TERALOKASI</th>
                        <th>TELAH DIAMBIL</th>
                    </tr>
                </thead>
                <tbody>
                    { generateRows() }
                </tbody>
            </table>
            {
                isLoading && <Spinner className='mt-4 mx-auto d-block' animation="border" size="lg"/>
            }
        </div>
    )
}

const ShipmentDetailOrderRow = (picklist) => {
    const getRunStatusWidget = (runStatus) => {
        const RUN_STATUSES = {
            OPEN: 'OPEN',
            PENDING: 'PENDING',
            COMPLETED: 'COMPLETED',
            CLOSED: 'CLOSED',
        };

        let color;
        let text;
        
        if (runStatus === RUN_STATUSES.OPEN) {
            color = '#8E8E93';
            text = 'Open';
        } if (runStatus === RUN_STATUSES.PENDING) {
            color = '#007AFF';
            text = 'Ongoing';
        } else if (runStatus === RUN_STATUSES.COMPLETED) {
            color = '#60BE34';
            text = 'Completed';
        } else if (runStatus === RUN_STATUSES.CLOSED) {
            color = '#BE3434';
            text = 'Closed';
        }

        return <div className="d-flex align-items-center">
            <div className='m-0 p-0 mr-2 d-flex align-items-center' style={{width: '0.6rem', height: '0.6rem', borderRadius: '50%', backgroundColor: color, display: 'inline-block'}}/>
            <div className='m-0 p-0 d-flex align-items-center' style={{display: 'inline-block'}}> { text } </div>
        </div>
    }

    return <tr>
        <td>
            { getRunStatusWidget(picklist.status) }
        </td>
        <td>
            {  picklist.id }
        </td>
        <td>
            { moment(picklist.createdAt).tz(TIMEZONE).format('DD/MM/YYYY') + ' pada ' + moment(picklist.createdAt).tz(TIMEZONE).format('HH:mm') }
        </td>
        <td>
            { moment(picklist.updatedAt).tz(TIMEZONE).format('DD/MM/YYYY') + ' pada ' + moment(picklist.updatedAt).tz(TIMEZONE).format('HH:mm') }
        </td>
        <td>
            { picklist.warehouses }
        </td>
        <td>
            { picklist.zone }
        </td>
        <td>
            { picklist.itemRequestQuantity }
        </td>
        <td>
            { picklist.itemActualQuantity }
        </td>
    </tr>
} 

export default ShipmentOrdersPicklistsSection;