import {Field, useFormikContext} from "formik";
import React, {memo, useMemo} from "react";
import {Table} from "antd";
import styles from "../../PurchaseOrder.module.scss";
import Autocomplete from "react-autocomplete";
import {QuestionCircleFill, Trash} from "react-bootstrap-icons";
import 'antd/dist/antd.css';
import 'antd/es/style/themes/default.less'
import * as utils from "../../utils/utils";
import {calculatePredictedQuantity} from "../../utils/utils";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import CurrencyField from "../../../../components/CustomInput/CurrencyField/CurrencyField";

const innerPackQty = (predictedQuantity, customQuantity) => {
    if (Number.isNaN(predictedQuantity / customQuantity) || customQuantity == null || customQuantity == 0) return '-'
    return (predictedQuantity / customQuantity).toFixed(2)
}

function ItemTable(props){
    const {values, setFieldValue} = useFormikContext();
    const {items, readOnly} = values;

    const tableData = useMemo(
        () => Object.entries(items).map(([, value]) => value),
        [items]
    );

    const onUpdateTableData = (key, props, value) => {
        if (key in items){
            let newItem = {...items[key]};
            newItem[props] = value;
            if (["customPurchasingUnit", "customQuantity", "predictedQuantity"].includes(props)){
                newItem = calculatePredictedQuantity(newItem, props);
            }
            setFieldValue(`items.${key}`, newItem);
        }
    }

    const deleteTableRow = (key) => {
        if (key in items){
            const newItems = {...items}
            delete newItems[key];
            setFieldValue('items', newItems);
        }
    }

    let columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            align: 'left',
        },
        {
            title: 'SKU ID',
            dataIndex: 'sku',
            key: 'sku',
            align: 'left',
        },
        {
            title: 'Type',
            dataIndex: 'itemType',
            key: 'itemType',
            align: 'left',
        },
        {
            title: 'Qty',
            key: 'qty',
            render: (value) => {
                return <Field
                    name={`dynamic.${value.itemType}-${value.sku}.quantity`}
                    type="text"
                    onChange={(e) => onUpdateTableData(value.key, "quantity", e.target.value)}
                    className="form-control"
                    value={!value.isCustom ? value.quantity : ''}
                    disabled={value.isCustom || readOnly}
                />
            }
        },
        {
            title: 'Unit',
            key: 'unit',
            render: (value) => {
                return <Field
                    name={`dynamic.${value.itemType}-${value.sku}.unit`}
                    component='textarea'
                    className={`form-control ${!value.isCustom ? styles.readOnly : ''}`}
                    value={!value.isCustom ? value.unit : ''}
                    disabled={true}
                />
            }
        },
        {
            title: 'Price per Unit',
            key: 'pricePerUnit',
            width: 300,
            render: (value) => {
                return <CurrencyField
                    name={`dynamic.${value.itemType}-${value.sku}.pricePerUnit`}
                    value={!value.isCustom ? value.pricePerUnit : ''}
                    onChange={(val) => {
                        onUpdateTableData(value.key, "pricePerUnit", val)
                    }}
                    disabled={value.isCustom || readOnly}
                    separator=','
                    currency='Rp'
                    required={true}
                />
            }
        },
        {
            title: 'Custom?',
            key: 'custom',
            render: (value) => {
                return <Field
                    name={`dynamic.${value.itemType}-${value.sku}.custom`}
                    type="checkbox"
                    onClick={(e) => {onUpdateTableData(value.key, "isCustom", !value.isCustom)}}
                    className="form-check-item mx-auto d-block"
                    checked={value.isCustom}
                    disabled={readOnly}
                />

            }
        },
        {
            title: 'Purchase Qty',
            key: 'purchaseQty',
            render: (value) => {
                return <Field
                    name={`dynamic.${value.itemType}-${value.sku}.customQty`}
                    type="text"
                    onChange={(e) => onUpdateTableData(value.key, "customQuantity", e.target.value)}
                    className="form-control"
                    value={value.isCustom ? value.customQuantity : ''}
                    disabled={!value.isCustom || readOnly}>
                </Field>
            }
        },
        {
            title: 'Purchase Unit',
            key: 'purchaseUnit',
            render: (value) => {
                return <Autocomplete
                    items={value.uomSuggestion === undefined ? [] : value.uomSuggestion}
                    getItemValue={(item) => item.name}
                    renderItem={(item, isHighlighted) =>
                        <div className='react-autocomplete' style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                            {item.name}
                        </div>
                    }
                    onChange={(e) => onUpdateTableData(value.key, "customPurchasingUnit", e.target.value)}
                    onSelect={(val) => onUpdateTableData(value.key, "customPurchasingUnit", val)}
                    name={`dynamic.${value.itemType}-${value.sku}.customUnit`}
                    value={value.isCustom ? value.customPurchasingUnit ? value.customPurchasingUnit : '' : ''}
                    inputProps={{
                        disabled: !value.isCustom || readOnly,
                        className: "form-control",
                        type: "text",
                        name: `dynamic.${value.itemType}-${value.sku}.customUnit`,
                    }}
                    menuStyle={{ overflow: 'visible', zIndex: 10, position: 'absolute', left: 'auto', top: 'auto' }}
                    wrapperStyle={{ display: 'inline-block' }}
                />
            }
        },
        {
            title: 'Price per Purchase Unit',
            key: 'pricePerUnit',
            width: 300,
            render: (value) => {
                return <CurrencyField
                    name={`dynamic.${value.itemType}-${value.sku}.pricePerUnit`}
                    value={value.isCustom ? value.pricePerUnit : ''}
                    onChange={(val) => {
                        onUpdateTableData(value.key, "pricePerUnit", val)
                    }}
                    disabled={!value.isCustom || readOnly}
                    separator=','
                    currency='Rp'
                    required={true}
                />
            }
        },
        {
            title: () => {
                return <div>
                    <p>
                        Predicted Inventory Qty
                        <OverlayTrigger
                            placement="bottom"
                            overlay={(props) => {
                                return <Tooltip id="button-tooltip" {...props}>
                                    Jumlah perkiraan item yang datang ke gudang dalam unit inventory
                                </Tooltip>
                            }}
                        >
                            <QuestionCircleFill className='ml-1 mb-1'/>
                        </OverlayTrigger>
                    </p>
                </div>
            },
            key: 'predictedQuantity',
            render: (value) => {
                return <Field
                    name={`dynamic.${value.itemType}-${value.sku}.predictedQuantity`}
                    type="text"
                    onChange={(e) => onUpdateTableData(value.key, "predictedQuantity", e.target.value)}
                    className="form-control"
                    value={value.isCustom ? value.predictedQuantity : ''}
                    disabled={!value.isCustom || value.isSuggestedUOM || readOnly}>
                </Field>
            }
        },
        {
            // title: 'Innerpack Qty',
            title: () => {
                return <div>
                    <p>
                        Innerpack Qty
                        <OverlayTrigger
                            placement="bottom"
                            overlay={(props) => {
                                return <Tooltip id="button-tooltip" {...props}>
                                    Predicted Inventory Qty dibagi dengan Purchase Qty
                                </Tooltip>
                            }}
                        >
                            <QuestionCircleFill className='ml-1 mb-1'/>
                        </OverlayTrigger>
                    </p>
                </div>
            },
            key: 'innerpackQty',
            render: (value) => {
                return <Field
                    disabled={true}
                    value={!value.isCustom ? '-' : innerPackQty(value.predictedQuantity, value.customQuantity)}
                    className="form-control"
                />
            }
        },
        {
            title: 'Inventory Unit',
            key: 'Inventory Unit',
            render: (value) => {
                return <Field
                    name={`dynamic.${value.itemType}-${value.sku}.inventoryUnit`}
                    component='textarea'
                    className={`form-control ${value.isCustom ? styles.readOnly : ''}`}
                    value={value.isCustom ? value.unit : ''}
                    disabled={true}
                />
            }
        },
        {
            title: 'Gramation',
            key: 'gramation',
            render: (value) => {
                return <Field
                    name={`dynamic.${value.itemType}-${value.sku}.gramationPerQuantity`}
                    type="text"
                    onChange={(e) => onUpdateTableData(value.key, "gramationPerQuantity", e.target.value)}
                    className="form-control"
                    value={value.gramationPerQuantity}
                    disabled={readOnly}
                />
            }
        },
        {
            title: 'Action',
            key: 'action',
            render: (value) => {
                return <Button
                    variant='outline-danger'
                    style={{borderColor: "transparent",}}
                    disabled={readOnly}
                    onClick={() => deleteTableRow(value.key)}
                >
                    <Trash size='32'/>
                </Button>
            }
        },
    ]

    return items && !utils.isEmpty(items) ?
        <div className='mb-4'>
            <div>
                <h4>Tabel Item</h4>
            </div>
            <Table
                dataSource={tableData}
                columns={columns}
                pagination={false}
                scroll={{ x: 3000, y: 2000 }}
            />
        </div>
        : <div></div>
}

export default memo(ItemTable);