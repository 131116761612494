export const SCHEDULED = 'SCHEDULED';
export const ON_DEMAND = 'ON_DEMAND';
export const EMPTY = 'EMPTY';

export function getAliasDeliveryTimeType(type){
    return type === SCHEDULED ? "Scheduled " : "On-Demand ";
}

export const convertToFormattedDeliveryTimeType = (deliveryTimeType) => {
    const formattedDeliveryTimeType = {
        [SCHEDULED]: 'Scheduled',
        [ON_DEMAND]: 'On Demand'
    };

    return formattedDeliveryTimeType[deliveryTimeType] || '-';
}
