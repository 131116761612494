import React from "react";
import Input from "../../Components/Input";

const AccountConfigurationSection = (props)=>{
    const {userId, username, password, accountStatus, accountNote, mode, accountStatusOptions, errors} = props;
    const {setUserId, setUsername, setPassword, setAccountStatus, setAccountNote} = props;

    const isModeCreate = (mode)=>{
        return mode === "create";
    }

    return(
        <>
            <div className={"d-flex mb-3"}>
                 <Input text={"User Id"} value={userId} handleInput={setUserId}
                       isDisabled={true} error={errors ? errors.userId : null}/>
                <Input id={"user_name"} text={"User Name"} value={username} handleInput={setUsername} error={errors ? errors.username : null}/>
                <Input id={"user_password"} text={"Password"} value={password}
                       handleInput={setPassword} type={"password"} error={errors ? errors.password : null}/>
            </div>
            <div className={"d-flex mb-2"}>
                <Input text={"Status Internal Akun"} value={accountStatus} handleInput={setAccountStatus}
                       options={accountStatusOptions} type={"select"} isDisabled={isModeCreate(mode)} error={errors ? errors.accountStatus : null}/>
                <Input text={"Catatan Internal"} value={accountNote} handleInput={setAccountNote} error={errors ? errors.accountNote : null}/>
            </div>
        </>
    );
}

export default AccountConfigurationSection;