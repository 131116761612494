import debounce from 'lodash.debounce';

export function addDebounceToAutocompleteElement(autocompleteElement) {
    if (autocompleteElement.isDebounced) return;
    // Debounce autocomplete api call when user input address
    // Note: kinda hacky because need to override addEventListener to intercept google maps autocomplete onInput event
    // General reference: https://gist.github.com/pmuellr/854959
    const autocompleteAddEventListener = autocompleteElement.addEventListener;
    autocompleteElement.addEventListener = function (event, listener, useCapture) {
        if (typeof arguments[1] == "function" && event === "input") {
            arguments[1] = debounce(arguments[1], 1000);
        }
        autocompleteAddEventListener.apply(this, [].slice.call(arguments))
    }
    autocompleteElement.isDebounced = true;
}

export function generateAutocompleteElementId() {
    return `autocomplete-${Math.floor(Math.random() * 100000)}`
}