import customAxios from "./axios";
import {
    WAREHOUSE_V1
} from "../constants/api";

export function getWarehouses(params) {
    return customAxios.get(WAREHOUSE_V1, {params});
}

export function createWarehouse(data) {
    return customAxios.post(WAREHOUSE_V1, data);
}

export function updateWarehouse(id, data) {
    return customAxios.put(WAREHOUSE_V1 + '/' + id, data);
}

export function updateWarehouseSampleReceiver(id, data) {
    return customAxios.put(WAREHOUSE_V1 + '/set-sample-receiver/' + id, data);
}