import customAxios from "./axios";
import {
    MANPOWER_DATABASE_EMPLOYMENT,
    WAREHOUSE_WORKPLACE_DEPARTMENT_ID,
    WORKPLACE_DEPARTMENT,
    WORKPLACE_DEPARTMENT_RELATION
} from "../constants/api";

export const getAllWorkplaceDepartment = ()=>{
    return customAxios.get(WORKPLACE_DEPARTMENT);
}

export const getWorkplaceDepartmentRelationByType = (workplaceDepartmentType)=>{
    return customAxios.get(WORKPLACE_DEPARTMENT_RELATION(workplaceDepartmentType));
}

export const updateWorkplaceDepartments = (request)=>{
    return customAxios.put(WORKPLACE_DEPARTMENT,request);
}

export const updateWorkpaceDepartmentRelations = (workplaceDepartmentId, relations) => {
    return customAxios.patch(WAREHOUSE_WORKPLACE_DEPARTMENT_ID(workplaceDepartmentId),relations);
}