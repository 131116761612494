import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

const _ = require('lodash');

const ORDER_STATUSES = {
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
}

const getOrderStatusStyle = (orderStatus) => {
  let style = '';
  if (orderStatus === ORDER_STATUSES.COMPLETED) {
    style = styles.successBadge;
  } else if (orderStatus === ORDER_STATUSES.FAILED) {
    style = styles.failedBadge;
  } else if (orderStatus == null) {
    style = styles.notRunBadge;
  }

  return style;
}

const getOrderStatusDisplay = (orderStatus) => {
  let text = '';
  if (orderStatus === ORDER_STATUSES.COMPLETED) {
    text = 'BERHASIL'
  } else if (orderStatus === ORDER_STATUSES.FAILED) {
    text = 'GAGAL'
  } else if (orderStatus == null) {
    text = 'BELUM ADA'
  }

  return text;
}

export const SHIPMENT_TABLE_COLUMNS = ({
  setSelectedShipmentId,
  setSelectedShipmentName,
}) => {
  return [
    {
      Header: "EKSEKUSI TERAKHIR",
      accessor: "lastExecution",
      // minWid: '15%',
      Cell: function WarehouseTagCell({ row }) {
        const [ timeElapsed, setTimeElapsed ] = useState(null);

        useEffect(() => {
            const interval = setInterval(updateTimeElapsed, 1000)
      
            return () => clearInterval(interval);
        }, [])

        useEffect(() => {
          updateTimeElapsed();
      }, [])
      
        const updateTimeElapsed = () => {
          const latestOrderUpdatedAt = moment(row.original?.latestOrderUpdatedAt)

          const dayDifference = moment().diff(latestOrderUpdatedAt, 'days');
        
          if (dayDifference > 0) {
            return setTimeElapsed(dayDifference + ' hari yang lalu'); 
          }
        
          const hourDifference = moment().diff(latestOrderUpdatedAt, 'hours');
        
          if (hourDifference > 0) {
            return setTimeElapsed(hourDifference + ' jam yang lalu');
          }
        
          const minuteDifference = moment().diff(latestOrderUpdatedAt, 'minutes');
        
          if (minuteDifference > 0) {
            return setTimeElapsed(minuteDifference + ' menit yang lalu');
          }
        
          const secondDifference = moment().diff(latestOrderUpdatedAt, 'seconds');
        
          if (secondDifference > 0) {
            return setTimeElapsed(secondDifference + ' detik yang lalu');
          }
        }

        return <Col>
            <Row className='row-md-auto'>
                <div className={getOrderStatusStyle(row.original?.latestOrderStatus)}>
                  {
                    getOrderStatusDisplay(row.original?.latestOrderStatus)
                  }
                </div>
            </Row>
            {
              row.original?.latestOrderUpdatedAt != null && 
              <Row>
                  <div className={styles.statusSubtext}> { timeElapsed } </div>
              </Row>
            }
        </Col>;
      },
    },
    {
      Header: "SHIPMENT",
      accessor: "shipment",
      Cell: function WarehouseTagCell({ row }) {
        return <div style={{textAlign: 'left'}}>
          <div className={styles.linkText} onClick={(target) => {
            setSelectedShipmentId(row.original?.id);
            setSelectedShipmentName(row.original?.name);
          }}>{row.original?.name}</div>
        </div>;
      },
    },
    {
      Header: "KATEGORI",
      accessor: "kategori",
      // minWid: '15%',
      Cell: function WarehouseTagCell({ row }) {
        return <div style={{textAlign: 'left'}}>
          {row.original?.warehouseTagGroup}
        </div>;
      },
    },
    {
      Header: "WAREHOUSE ASAL",
      accessor: "warehouseSource",
      // minWid: '15%',
      Cell: function WarehouseTagCell({ row }) {
        return <div style={{textAlign: 'left'}}>
          {row.original?.warehouseSource}
        </div>;
      },
    },
    {
      Header: "UPDATE TERAKHIR",
      accessor: "updateTerakhir",
      // minWid: '15%',
      Cell: function WarehouseTagCell({ row }) {
        return <div style={{textAlign: 'left'}}>
          {row.original?.latestOrderUpdatedAt != null ? moment(row.original?.latestOrderUpdatedAt).format('DD/MM/YYYY') + ' pada ' + moment(row.original?.latestOrderUpdatedAt).format('HH:mm') :  '-'}
        </div>;
      },
    },
  ];
}
