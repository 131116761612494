import React from 'react'
import {Modal, Spinner} from "react-bootstrap";

class ConfirmDeleteModal extends React.PureComponent {
    render(){
        return <Modal show={this.props.show}>
            <Modal.Header>
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='mb-2'>{this.props.message}</div>
            </Modal.Body>
            <Modal.Footer>
                <div className={'d-flex justify-content-between'} style={{width:'100%'}}>
                    <button className='btn btn-primary mr-1' onClick={this.props.onCancel}>Cancel</button>
                    <button className='btn btn-danger' onClick={this.props.onConfirm} disabled={this.props.isSubmitting}>{
                        this.props.isSubmitting ?
                            <Spinner animation={"border"} size={"sm"} /> :
                            "Confirm"
                    }
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    }
}

export default ConfirmDeleteModal