import React from "react";
import {Field, Form} from "formik";
import DatePicker from "react-datepicker";
import {Spinner} from "react-bootstrap";
import * as deliveryServiceType from '../../../constants/deliveryServiceType';

class QualitySurveyForm extends React.PureComponent {

    DELIVERY_SERVICE_TYPE_OPTIONS = [
        {value: '', label: 'All'},
        {value: deliveryServiceType.NEXT_DAY_DELIVERY, label: 'Next Day Delivery'},
        {value: deliveryServiceType.SAME_DAY_DELIVERY, label: 'Same Day Delivery'},
    ]

    render() {
        const {values, setFieldValue, isSubmitting} = this.props;
        return <Form>
                <div className='form-group'>
                    <div>Delivery Date</div>
                    <DatePicker
                        selected={values.deliveryDate}
                        onChange={date => {setFieldValue('deliveryDate',date)}}
                        className='form-control'
                        placeholderText='dd-mm-yyyy'
                        dateFormat='dd-MM-yyyy'
                    />
                </div>
                <div className='form-group'>
                    <div>Delivery Service Type</div>
                    <div>
                        <Field as="select" name="deliveryServiceType">
                            {this.DELIVERY_SERVICE_TYPE_OPTIONS.map( dt => <option key={dt.value} value={dt.value}>{dt.label}</option>)}
                        </Field>
                    </div>
                </div>
                <div className='d-flex flex-row ml-3'>
                    <button type='submit' className='btn btn-primary align-self-center' disabled={isSubmitting}>
                        {isSubmitting && <Spinner animation="border" variant="light" size="sm" />}
                        Blast
                    </button>
                </div>
        </Form>
    }
}

export default QualitySurveyForm