import React from 'react'
import { connect } from 'react-redux'
import styles from './Maps.module.scss'
import PropTypes from 'prop-types'
import { SAME_DAY_DELIVERY } from '../../constants/deliveryServiceType'
import { addDebounceToAutocompleteElement, generateAutocompleteElementId } from '../../utils/GoogleMaps'
import { sortByKey } from '../../utils/ArrayUtils';

const determineInitialLocation = async ({ fallbackLatlng }) => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                resolve({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
            }, (e) => {
                resolve(fallbackLatlng)
                console.log('Client is not allowing location access ')
            })
        } else {
            resolve(fallbackLatlng)
            console.log('Browser does not support geolocation')
        }
    })
}

class MapsPoint extends React.PureComponent {
    static RANDOM_ID_COUNTER = 0;

    static getRandomId() {
        this.RANDOM_ID_COUNTER++;
        return `map-selector-${this.RANDOM_ID_COUNTER}`
    }

    autocomplete = null;
    autocompleteElementId = generateAutocompleteElementId();
    existingPolygons = [];

    state = {
        isLoading: true,
        address: '',
        search: '',
        tempPolygon: [],
    }

    componentDidMount() {
        if (this.props.mapReady) {
            this.loadMaps();
        }
    }

    //https://stackoverflow.com/questions/50692160/react-componentwillreceiveprops-alternative
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.mapReady && !prevProps.mapReady) {
            this.loadMaps();
        }

        if (JSON.stringify(this.props.previewShippingPoint) != JSON.stringify(prevProps.previewShippingPoint)) {
            this.loadMaps();
        }

        if (JSON.stringify(this.props.previewAgent) != JSON.stringify(prevProps.previewAgent)) {
            this.loadMaps();
        }
    }

    convertCoordinatesToLatLong = (coordinates) => {
        if (!coordinates) { return []; }
        return coordinates.map(p => ({ lat: p[1], lng: p[0] }));
    }

    loadMaps = async () => {
        const { id, zoom, fallbackLatlng, streetViewControl, mapTypeControl, fullscreenControl, lastPreview } = this.props
        const google = window.google;

        let initialLatlng;

        if (lastPreview !== '') {
            try {
                const deliveryAreaCoordinates = this.convertCoordinatesToLatLong(lastPreview.value.coordinates[0][0]);
                initialLatlng = deliveryAreaCoordinates[0];
            } catch (e) {
                initialLatlng = await determineInitialLocation({ fallbackLatlng });
            }
        } else {
            initialLatlng = await determineInitialLocation({ fallbackLatlng });
        }

        if (!this.map) {
            this.map = new google.maps.Map(document.getElementById(id), {
                zoom, center: initialLatlng, mapId: 'd37a665b83c8be30',
                streetViewControl, mapTypeControl, fullscreenControl
            });
    
            this.marker = new google.maps.Marker({
                position: initialLatlng,
                map: this.map,
            });

            this.map.addListener('center_changed', () => {
                const pos = this.map.getCenter()
                const latLng = { lat: pos.lat(), lng: pos.lng() }
                this.marker.setPosition(latLng)
                this.props.onChange(pos.lat(), pos.lng())
            });
    
            this.map.addListener('dragend', async () => {
                const pos = this.map.getCenter()
                const latlng = { lat: pos.lat(), lng: pos.lng() }
                this.props.onChange(pos.lat(), pos.lng())
            });
        } else {
            this.map.setCenter(initialLatlng)
            this.clearExistingPolygons()
        }

        if (this.props.previewShippingPoint.length > 0) {
            const sortedPreviewSp = this.props.previewShippingPoint;
            sortByKey(sortedPreviewSp, 'geometrySize');

            sortedPreviewSp.forEach(sp => {
                if (!sp.value) { return; }
                sp.value.coordinates.forEach(mp => {
                    mp.forEach(p => {
                        let polygonInitialPath = this.convertCoordinatesToLatLong(p);
                        let polygonColor = sp.deliveryServiceType === SAME_DAY_DELIVERY ? '#FF9900' : '#FF0000';
                        this.polygon = new google.maps.Polygon({
                            paths: polygonInitialPath,
                            strokeColor: polygonColor,
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: polygonColor,
                            fillOpacity: 0.35,
                            editable: false,
                            draggable: false,
                        });

                        const infowindow = new google.maps.InfoWindow({
                            content: `Polygon Shipping Point ${sp.placeholder}`,
                        });

                        google.maps.event.addListener(this.polygon, 'click', (event) => {
                            infowindow.setPosition(event.latLng);
                            infowindow.open(this.map);
                        })

                        this.polygon.setMap(this.map);

                        this.existingPolygons.push(this.polygon);
                    })
                })
            });
        }

        if (this.props.previewAgent.length > 0) {
            this.props.previewAgent.forEach(sp => {
                if (sp.regular) {
                    sp.regular.coordinates.forEach(mp => {
                        mp.forEach(p => {
                            let polygonInitialPath = this.convertCoordinatesToLatLong(p);
                            this.polygon = new google.maps.Polygon({
                                paths: polygonInitialPath,
                                strokeColor: "#0000FF",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: "#0000FF",
                                fillOpacity: 0.35,
                                editable: false,
                                draggable: false,
                            });
                            const infowindow = infowindow = new google.maps.InfoWindow({
                                content: `${sp.customHtml ? sp.placeholder : 'Polygon Agent (Regular) ' + sp.placeholder}`,
                            });

                            google.maps.event.addListener(this.polygon, 'click', (event) => {
                                infowindow.setPosition(event.latLng);
                                infowindow.open(this.map);
                            });

                            this.polygon.setMap(this.map);

                            this.existingPolygons.push(this.polygon);
                        })
                    });
                }

                if (sp.exclusive) {
                    sp.exclusive.coordinates.forEach(mp => {
                        mp.forEach(p => {
                            let polygonInitialPath = this.convertCoordinatesToLatLong(p);
                            this.polygon = new google.maps.Polygon({
                                paths: polygonInitialPath,
                                strokeColor: "#4169E1",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: "#4169E1",
                                fillOpacity: 0.35,
                                editable: false,
                                draggable: false,
                            });
                            const infowindow = new google.maps.InfoWindow({
                                content: `Polygon Agent (VIP) ${sp.placeholder}`,
                            });

                            google.maps.event.addListener(this.polygon, 'click', (event) => {
                                infowindow.setPosition(event.latLng);
                                infowindow.open(this.map);
                            })

                            this.polygon.setMap(this.map);

                            this.existingPolygons.push(this.polygon);
                        })
                    });
                }
            });
        }

        this.props.onLoad({ latlng: initialLatlng })
        this.setState({
            isLoading: false
        });

        if (!this.autocomplete) {
            const autocompleteElement = document.getElementById(this.autocompleteElementId);
            addDebounceToAutocompleteElement(autocompleteElement);
            this.autocomplete = new google.maps.places.Autocomplete(autocompleteElement, {
                fields: ['geometry']
            });
            this.autocomplete.bindTo("bounds", this.map);
            this.autocomplete.addListener('place_changed', () => {
                let place = this.autocomplete.getPlace();
    
                if (!place.geometry) {
                    // User did not select a prediction
                    alert("No details available for input " + place.name);
                    return;
                }
    
                if (place.geometry.viewport) {
                    this.map.fitBounds(place.geometry.viewport);
                    this.map.setZoom(15);
                } else {
                    this.map.setCenter(place.geometry.location);
                    this.map.setZoom(15);
                }
    
                this.props.onChange(place.geometry.location.lat(), place.geometry.location.lng());
            });
        }
    };

    clearExistingPolygons = () => {
        if (this.existingPolygons) {
            for(const item of this.existingPolygons) {
                item.setMap(null)
            }
        }
        this.existingPolygons = [];
    }

    render() {
        const mapStyle = (!this.state.isLoading) ? styles.map : styles.mapHidden;

        return <div style={this.props.style} className={'p-1'}>
            <div className={`${styles['search-div']} ${this.props.hideSearchBar === true?styles['hideComponent']:''}`}>
                <input id={this.autocompleteElementId} className={`${styles['search-box']} ${styles['search-input']} md-12`}
                    onChange={e => this.setState({ search: e.target.value })}
                    aria-describedby="basic-addon1" placeholder="Cari Lokasi" />
                <div className={`${styles['search-btn']}`} />
            </div>
            <div className={`${styles.outerDivMapPoint}`}>
                <div id={this.props.id} className={mapStyle} />
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        mapReady: state.gmaps.mapReady
    }
}

const LatLngPropTypes = PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
})

MapsPoint.propTypes = {
    //wrapper for map selector
    className: PropTypes.string,
    //initial location of marker
    initLatLng: LatLngPropTypes,
    //callback when map is changed (after user ends dragging)
    onChange: PropTypes.func,
    //callback when map is loaded and user location is determined (or default fallback if user denies location access)
    onLoad: PropTypes.func,
    //initial zoom value of map
    zoom: PropTypes.number,
    //fallback location if user denies location access
    fallbackLatlng: LatLngPropTypes,
    //show maptype control for google maps (type is satellite, terrain, street)
    mapTypeControl: PropTypes.bool,
    //show streetview option for googlemaps
    streetViewControl: PropTypes.bool,
    //enable fullscreen button
    fullscreenControl: PropTypes.bool
}

MapsPoint.defaultProps = {
    id: MapsPoint.getRandomId(),
    zoom: 14,
    //Jakarta Barat, pertigaan jalan panjang yang ke arah meruya ilir
    fallbackLatlng: {
        lat: -6.1958759,
        lng: 106.7541634
    },
    onChange: () => { console.error('Please define onChange') },
    onLoad: () => { console.error('Please define onLoad') },
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
}

export default connect(mapStateToProps)(MapsPoint)