import React, { useState } from "react";
import JsonBodyResponse from "../../components/JsonBodyResponse";
import JsonBodyForm from "../../components/JsonBodyForm";
import * as productTagApis from "../../apis/productTags";
import * as productApis from "../../apis/products";
import * as frontendSubcategoryApis from "../../apis/frontendSubcategories";
import {displayAlert} from "../../redux/actions/notif";
import {connect} from 'react-redux'
import * as s from "../../constants/scope";
import PageAuthCheck from "../../components/AuthCheck/PageAuthCheck";
import styles from './NewProductTags.module.css'
import { Button, CloseButton, Col, Row, Spinner } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";

const ProductTagTable = ({isSubmitFailed, productIds, addRow, products, selectedOptions, handleChange, removeProductItem}) => {

    const isOptionDisabled = (option) => {
        return productIds.some(obj => Object.values(obj).includes(option.value));
      };

    const loadOptions = (searchValue, callback) => {
        const filteredOptions = products.filter((option) =>
            option.label.toLowerCase().includes(searchValue.toLowerCase())
        )
        console.log("loadOptions", searchValue, filteredOptions)
        callback(filteredOptions)
    }

    return <div>
        <div className={`${styles.row} align-items-center`}>
                <div className={styles.tableHead}>Product</div>
                <div className={styles.tableHead} style={{width:"20%"}}>Priority</div>
                <Plus className={styles.plusButton} size={30} onClick={addRow}/>
        </div>
        { productIds.map((row,index) => {
            return <div className={styles.row} key={index}>
                    <div className={`${styles.tableContent} ${isSubmitFailed && row.id == -1 ? styles.errorField : ""}`}>
                        <AsyncSelect
                            value={row}
                            onChange={(e, a) => {
                                handleChange(a.action === "clear" ? -1 : e.value, index, "id") 
                                handleChange(a.action === "clear" ? "Select..." : e.label, index, "label") 
                            }}
                            placeholder="Select"
                            loadOptions={loadOptions}
                            isMulti={false}
                            noOptionsMessage={() => "Type to Search"}
                            isSearchable
                            isClearable
                            isOptionDisabled={isOptionDisabled}
                        />
                    </div>
                    <div className={`${styles.tableContent} `} style={{width:"20%"}}>
                        <input
                            className={`${styles.inputCell} ${row.priority == "" || row.priority > 10000 ? styles.errorField : ""}`}
                            type="number"
                            inputMode="numeric"
                            value={row.priority}
                            onChange={e => handleChange(e.target.value != "" ? parseInt(e.target.value) : e.target.value, index, "priority")}
                            placeholder="Product Priority"
                        />
                        <p style={{fontSize:12, textAlign: "left", color: "red"}} hidden={row.priority <= 10000}>Priority number should be 1 - 10000</p>
                        <p style={{fontSize:12, textAlign: "left", color: "red"}} hidden={row.priority != "" }>Priority Can't be Empty</p>
                </div>

                {productIds.length > 1 && <CloseButton style={{padding: 8}} onClick={(e) => removeProductItem(index)}/>}
            </div>
            })
        }
        
    </div>

}

export default connect()(ProductTagTable);