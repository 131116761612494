export const LANDING_PAGE_STATUS_CONSTANT = [
    { value: 'SCHEDULED', label: 'SCHEDULED' },
    { value: 'ONGOING_PUBLISHED', label: 'ONGOING - PUBLISHED' },
    { value: 'ONGOING_UNPUBLISHED', label: 'ONGOING - UNPUBLISHED' },
    { value: 'ENDED', label: 'ENDED' }
];

export const ID_COLUMN = "ID";
export const TITLE_COLUMN = "Landing Page Title";
export const SLUGS_COLUMN = "Slugs";
export const URL_COLUMN = "Landing Page Url";
export const PERIOD_COLUMN = "Period";
export const LAST_MODIFIED_BY_COLUMN = "Last Modified By";
export const STATUS_COLUMN = "Status";
export const ACTIONS_COLUMN = "Actions";