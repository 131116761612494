import customAxios from "./axios";
import {
    FREEBIE_PRODUCT_V1,
    FREEBIE_PRODUCT_V1_DELETE,
    FREEBIE_PRODUCT_V1_GET_BY_CODE
} from "../constants/api";

export function createOrUpdateFreebieProducts(data) {
    return customAxios.post(FREEBIE_PRODUCT_V1, data);
}

export function deleteFreebieProduct(id) {
    return customAxios.delete(FREEBIE_PRODUCT_V1_DELETE(id));
}

export function getFreebieProductsByCode(code) {
    return customAxios.get(FREEBIE_PRODUCT_V1_GET_BY_CODE(code));
}