import React from 'react';
import {Collapse, Container, Row, Col} from "react-bootstrap";
import PropTypes from "prop-types";
import './PageWithSideNavbar.module.css'

class PageWithSideNavbar extends React.Component {
    renderBody() {
        const {isTopAlignmentContent, isHideOverflowX} = this.props;
        return (
            <>
                <Container fluid style={{height: '100%'}}>
                    <Row style={{height: '100%'}}>
                        <Col xs={"auto"} id="sidebar-wrapper" className='navbar_picking' style={{maxWidth: '20%'}}>
                            {this.props.navbar}
                        </Col>
                        <Col xs={"auto"} id='page_content_wrapper' className={`d-flex ${!!isTopAlignmentContent ? '': 'align-items-center'} justify-content-center`} style={{ flex: 1, overflowX: isHideOverflowX ? 'auto' : 'scroll'}}>
                            {this.props.content}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
    render() {
        return this.renderBody()
    }
}

export default PageWithSideNavbar;

PageWithSideNavbar.propTypes = {
    navbar: PropTypes.any,
    content: PropTypes.any,
}
