import fileDownload from "js-file-download";
import {RECIPE_ITEM_PRODUCT_V1_GET, RECIPE_V1, RECIPE_V1_CURATED, RECIPE_V1_DELETE, RECIPE_V1_DETAIL, RECIPE_V1_ID, RECIPE_V1_SUGGESTIONS, RECIPE_V1_SUGGESTIONS_DOWNLOAD, RECIPE_MEDIAS_V1_GET, RECIPE_STEPS_V1_ID, RECIPE_STEPS_BULK_ADD_V1} from "../constants/api";
import customAxios from "./axios";

export function getAllRecipes({params}){
    return customAxios.get(RECIPE_V1, {params});
}

export function getRecipeById(id) {
    return customAxios.get(RECIPE_V1_ID(id))
}

export function createOrUpdateRecipe(body){
    return customAxios.post(RECIPE_V1, body)
}

export function softDeleteRecipe(id){
    return customAxios.delete(RECIPE_V1_DELETE(id))
}

export function getItemProductsByRecipeId(id){
    return customAxios.get(RECIPE_ITEM_PRODUCT_V1_GET(id))
}

export function getCuratedRecipes(){
    return customAxios.get(RECIPE_V1_CURATED);
}

export function updateCuratedRecipes(body){
    return customAxios.put(RECIPE_V1_CURATED, body);
}

export function getRecipeDetailSegariAssets(){
    return customAxios.get(RECIPE_V1_DETAIL);
}

export function updateRecipeDetailSegariAsset(assetType, data){
    return customAxios.put(RECIPE_V1_DETAIL + `/${assetType}`, data);
}

export function getAllRecipeSuggestions({params}) {
    return customAxios.get(RECIPE_V1_SUGGESTIONS, {params});
}

export function download({params}) {
    return customAxios.get(RECIPE_V1_SUGGESTIONS_DOWNLOAD, {params})
    .then((response) => {
        fileDownload(response.data, `RecipeSuggestions-${new Date().toLocaleString()}.csv`)
    });
}

export function getMediasByRecipeId(id){
    return customAxios.get(RECIPE_MEDIAS_V1_GET(id))
}

export function createOrUpdateRecipeStepsByRecipeId(id, body){
    return customAxios.post(RECIPE_STEPS_V1_ID(id), body)
}

export function getStepsByRecipeId(id){
    return customAxios.get(RECIPE_STEPS_V1_ID(id))
}

export function bulkAddRecipeSteps({data}) {
    return customAxios.post(RECIPE_STEPS_BULK_ADD_V1, data)
}

