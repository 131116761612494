import React from 'react';
import DatePicker from "react-datepicker";
import Typeahead from "react-bootstrap-typeahead/lib/components/AsyncTypeahead";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Select from "react-select";
import moment from 'moment';
import { Button, InputGroup, Modal } from 'react-bootstrap';
import { dateTimeWithoutTimezone, REGEX_NEGATIVE_NUM, REGEX_NUM } from '../../utils/Formatter';

import * as apiProducts from "../../apis/products";
import * as negativePriceApi from '../../apis/negativePrice';
import { NEGATIVE_PRICE_CONSTRAINT_TYPE } from '../../constants/specialPricePromo';
import * as apiPromoLabel from "../../apis/promoLabels";
import {mapPromoLabelOptions} from "../price_group_products/CreatePriceGroupProductPage/utils";

class ModalCreateUpdateNegativePrices extends React.Component {
    render() {
        const { idToEditNegativePrice, show, displayAlert, user, closeModalCreateUpdateAndRefreshTable} = this.props;
        return <Modal show={show} size="xl" onHide={closeModalCreateUpdateAndRefreshTable}>
            <Title isCreate={!idToEditNegativePrice} onClose={closeModalCreateUpdateAndRefreshTable} />
            <Modal.Body>
                <Body displayAlert={displayAlert}
                    idToEditNegativePrice={idToEditNegativePrice}
                    onSuccessSaveData={closeModalCreateUpdateAndRefreshTable} />
            </Modal.Body>
        </Modal>
    }
}

function Title(props) {
    return <Modal.Header className="d-flex justify-content-between align-items-center">
        <h1 className="title-text">
            {props.isCreate ? "Create Harga Negatif" : "Update Harga Negatif"}
        </h1>
        <button onClick={props.onClose} className="btn btn-danger">x</button>
    </Modal.Header>;
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isCreate: true,
            categories: [],
            products: [],
            promolabels:[],
            isSubmitting: false,
            categoryTypeAheadFocused: false,
            minPurchaseFieldFocused: false,
            id: "",
            name: "",
            activeStartAt: "",
            activeEndAt: "",
            isActive: "",
            minPurchase: "",
            isForNewCustomerOnly: "",
            isNonMixableWithFreebies: false,
            isNonMixableWithVoucher: false,
            isNonMixableWithHargaGila: false,
            unableToCheckoutMoreThanOnePromo: false,
            promoLabel: null,
            negativePriceProducts: [],
            negativePriceProductIds: [], // for update
            minPurchaseCategories: [],
            isFromTalonOne: false,
        }
    }

    componentDidMount = async () => {
        try {
            const categoriesResponse = apiProducts.getAllCategories();
            const DEFAULT_PAGE =  {sort: 'name', size: 10_000};
            const productResponse = apiProducts.getProductDropdown({params: DEFAULT_PAGE});
            const negativePriceResponse = this.props.idToEditNegativePrice !== null ? negativePriceApi.getNegativePriceDetail(this.props.idToEditNegativePrice) : null;
            const products = (await productResponse).data.data.data;
            const categories = (await categoriesResponse).data.data;
            const negativePrice = this.props.idToEditNegativePrice !== null ? (await negativePriceResponse).data.data : null;
            const promoLabelResponse = await apiPromoLabel.getAllPromoLabels({
                params:{
                    isActive : true,
                    isOnSpp : true
                }
            });
            let promoLabels = promoLabelResponse.data.data;

            this.mapFormValuesToStates(negativePrice);

            this.setState({
                products: products,
                categories: categories,
                promoLabels:promoLabels,
            });
        } catch (e) {
            this.setState({
                isLoading: false,
                isError: true
            })
            console.error(e);
        }
    }

    mapFormValuesToStates = (values) => {
        if (!values) {
            this.mapDefaultValues()
            return;
        }

        this.setState({
            isCreate: false,
            id: values.id,
            name: values.name,
            activeStartAt: dateTimeWithoutTimezone(values.activeStartAt),
            activeEndAt: dateTimeWithoutTimezone(values.activeEndAt),
            isActive: values.active.toString(),
            minPurchase: values.constraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.MINIMUM_PURCHASE] ?? "",
            isForNewCustomerOnly: (values.constraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ONLY_FOR_NEW_USER] ?? "false").toString(),
            isNonMixableWithVoucher: values.isNonMixableWithVoucher ?? false,
            isNonMixableWithFreebies: values.isNonMixableWithFreebies ?? false,
            isNonMixableWithHargaGila: values.isNonMixableWithHargaGila ?? false,
            unableToCheckoutMoreThanOnePromo: values.unableToCheckoutMoreThanOnePromo ?? false,
            negativePriceProducts: this.extractNegativePriceProduct(values.products),
            negativePriceProductIds: [...values.products.map((value) => value.id)],
            minPurchaseCategories: [...values.minPurchaseCategories.map((value) => this.categoryTypeaheadFormat(value))],// values.minPurchaseCategories,
            isFromTalonOne: values.isFromTalonOne ?? false,
            isLoading: false,
            promoLabel: values.promoLabel != null ? this.promoLabelSelectFormat(values.promoLabel) : null
        })
    }

    mapDefaultValues = () => {
        this.setState({
            name: "",
            activeStartAt: dateTimeWithoutTimezone(new Date().toISOString()),
            activeEndAt: dateTimeWithoutTimezone(new Date().toISOString()),
            isActive: "false",
            minPurchase: "",
            isForNewCustomerOnly: "false",
            negativePriceProducts: [],
            negativePriceProductIds: [], // for update only
            minPurchaseCategories: [],
            isLoading: false,
            isFromTalonOne: false,
        })
    }

    extractNegativePriceProduct(negativePriceProducts) {
        if (!!negativePriceProducts && negativePriceProducts.length)
            return [...negativePriceProducts.map((value) => {
                return {
                    product: this.productSelectFormat(value.product),
                    price: value.price,
                    priceBefore: value.priceBefore,
                    allTimeUsageLimit: value.constraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ALL_TIME_USAGE_LIMIT] ?? "",
                    dailyUsageLimit: value.constraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.DAILY_USAGE_LIMIT] ?? "",
                    oneTimeUsage: (value.constraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ONE_TIME_USAGE] ?? "false").toString(),
                    oneTimeUsageForNPeriod: value.constraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ONE_TIME_USAGE_FOR_N_DAYS] ?? "",
                    isAbleToBuyMore: value.constraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ABLE_TO_BUY_MORE] ?? "false",
                }
            })];
        return [];
    }

    handleChange(fieldName, value) {
        this.setState({
            [fieldName]: value
        })
    }

    onChangeNegativePriceProduct = (index, object) => {
        let result = this.state.negativePriceProducts;
        let idsResult = this.state.negativePriceProductIds;
        if (object == null) {
            result.splice(index, 1);
            if (idsResult.length > index) idsResult.splice(index, 1);
        } else {
            result[index] = object;
        }

        this.setState({
            negativePriceProducts: result,
            negativePriceProductIds: idsResult
        })
    }

    addNewNegativePriceProduct = () => {
        let result = this.state.negativePriceProducts;
        result.push({
            product: {},
            price: -1000,
            priceBefore: 0,
            allTimeUsageLimit: "",
            dailyUsageLimit: "",
            oneTimeUsage: "true",
            oneTimeUsageForNPeriod: "",
            isAbleToBuyMore: false,
        });

        this.setState({
            negativePriceProducts: result
        })
    }

    async handleSubmit(e) {
        let body = {};

        if (this.state.id) {
            body.id = this.state.id;
        }

        body.name = this.state.name;
        if (!this.state.name) {
            this.props.displayAlert({ message: "Name can not be empty", type: 'error' })
            return;
        }
        body.activeStartAt = this.state.activeStartAt;
        body.activeEndAt = this.state.activeEndAt;
        body.isActive = this.state.isActive;

        if (!this.state.minPurchase) {
            this.props.displayAlert({ message: "Please specify minimum purchase value", type: 'error' })
            return;
        }
        if ((this.state.minPurchaseCategories || []).length < 1) {
            this.props.displayAlert({ message: "Please fill in categories for minimum purchase", type: 'error' })
            return
        }
        body.minPurchaseCategories = [...this.state.minPurchaseCategories.map((value) => value.id)];

        let constraints = {};
        constraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.MINIMUM_PURCHASE] = this.state.minPurchase;
        constraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ONLY_FOR_NEW_USER] = this.state.isForNewCustomerOnly;
        body.constraints = constraints;

        let negativePriceProducts = [];
        const negativePriceProductIds = this.state.negativePriceProductIds || [];
        let existsProductUnspecified = false;
        let existsProductRequiredFieldEmpty = false;
        (this.state.negativePriceProducts || []).map((value, index) => {
            let negativePriceProductData = {};
            if (negativePriceProductIds && (negativePriceProductIds.length > index)) negativePriceProductData.id = negativePriceProductIds[index];
            if (!value.product.value) existsProductUnspecified = true;
            if (!value.price || !value.priceBefore) existsProductRequiredFieldEmpty = true;
            negativePriceProductData.productSkuId = value.product.value;
            negativePriceProductData.price = value.price;
            negativePriceProductData.priceBefore = value.priceBefore;
            let productConstraints = {};
            productConstraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ONE_TIME_USAGE] = value.oneTimeUsage;
            if (value.oneTimeUsage === "false") {
                if (!!value.oneTimeUsageForNPeriod) productConstraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ONE_TIME_USAGE_FOR_N_DAYS] = value.oneTimeUsageForNPeriod;
                if (!!value.allTimeUsageLimit) productConstraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ALL_TIME_USAGE_LIMIT] = value.allTimeUsageLimit;
                if (!!value.dailyUsageLimit) productConstraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.DAILY_USAGE_LIMIT] = value.dailyUsageLimit;
            }
            productConstraints[NEGATIVE_PRICE_CONSTRAINT_TYPE.ABLE_TO_BUY_MORE] = value.isAbleToBuyMore;
            negativePriceProductData.constraints = productConstraints;
            negativePriceProducts.push(negativePriceProductData);
        })
        if (negativePriceProducts.length < 1) {
            this.props.displayAlert({ message: "You are creating an harga negatif promo, please specify the product", type: 'error' })
            return
        }
        if (existsProductUnspecified) {
            this.props.displayAlert({ message: "There is a harga negatif promo with empty product, please check again", type: 'error' })
            return
        }
        if (existsProductRequiredFieldEmpty) {
            this.props.displayAlert({ message: "There is a harga negatif product with empty price or price before, please specify price", type: 'error' })
            return
        }
        body.products = negativePriceProducts;

        body.isNonMixableWithVoucher = this.state.isNonMixableWithVoucher
        body.isNonMixableWithFreebies = this.state.isNonMixableWithFreebies
        body.isNonMixableWithHargaGila = this.state.isNonMixableWithHargaGila
        body.unableToCheckoutMoreThanOnePromo = this.state.unableToCheckoutMoreThanOnePromo
        body.isFromTalonOne = this.state.isFromTalonOne
        body.promoLabelId = this.state.promoLabel != null ? this.state.promoLabel.value: null;

        e.preventDefault();
        e.stopPropagation();

        this.setState({ isSubmitting: true, response: '' });
        try {
            const response = await negativePriceApi.createOrUpdateNegativePricePromo(body);
            this.setState({
                isSubmitting: false,
            });
            this.props.displayAlert({ message: `Berhasil ${this.state.isCreate ? "Membuat" : "Mengupdate"} harga negatif!`, type: 'success' });
            this.props.onSuccessSaveData();
        } catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error' })
        }
        this.setState({ isSubmitting: false });
    }

    categoryTypeaheadFormat = (category) => {
        return {
            id: category.id,
            label: `${category.id} - ${category.name}`,
            data: {...category}
        }
    }

    productTypeaheadFormat = (product) => {
        return {
            productId: product.id,
            productSkuId: product.skuId,
            name: product.name,
            label: `${product.skuId} - ${product.name} - ${product.sellingUnit}`,
            data: {...product}
        }
    }

    productSelectFormat = (product) => {
        return {
            value: product.skuId,
            label: `${product.skuId} - ${product.name} - ${product.sellingUnit}`
        }
    }

    promoLabelSelectFormat = (promoLabel) => {
        return {
            value: promoLabel.id,
            label: promoLabel.name,
        }
    }

    render() {
        const categoryOptions = [...(this.state.categories || []).map(this.categoryTypeaheadFormat)];
        const promoLabels = this.state.promoLabels;
        const {promoLabelOpts} = mapPromoLabelOptions({promoLabels});
        if (this.state.isLoading) return <div>Loading data please wait ...</div>
        if (this.state.isError) return <div>THERE IS SOME ERROR. PLEASE CLOSE THIS POPUP AND TRY TO LOAD IT AGAIN</div>
        if (this.state.isSubmitting) return <div>{this.state.isCreate ? "Creating Harga Negatif" : "Updating Harga Negatif"} please wait ...</div>

        return <div className="body-div" style={{ fontSize: '12px' }}>
            { !this.state.isCreate ? <div className="mb-5">
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Harga Negatif ID:</Form.Label>
                    <Col sm={'10'}>
                        <input name={"id"} value={this.state.id} className={'form-control'} readOnly/>
                        <Form.Text className="text-muted">ID is harga negatif id in database, you can not change this value.</Form.Text>
                    </Col>
                </Form.Group>
            </div> : <></> }
            <div className="mb-5">
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Campaign/Promo Name:</Form.Label>
                    <Col sm={'10'}>
                        <Form.Control className="form-ctr" placeholder={""} value={this.state.name}
                            onChange={e => this.handleChange("name", e.target.value)} />
                        <Form.Text className="text-muted">Name must not be empty.</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Period:</Form.Label>
                    <Col sm={'4'}>
                        <DatePicker
                            selected={(this.state.activeStartAt && moment(this.state.activeStartAt).toDate())}
                            onChange={date => this.handleChange("activeStartAt", moment(date).format('YYYY-MM-DDTHH:mm:ss'))}
                            className='form-control'
                            showTimeSelect
                            placeholderText='Datetime start'
                            timeFormat='HH:mm:ss'
                            dateFormat='dd-MM-yyyy HH:mm:ss'
                        />
                        <Form.Text className="text-muted">"Start at" datetime.</Form.Text>
                    </Col>
                    <Form.Label column sm={'2'}>Sampai</Form.Label>
                    <Col sm={'4'}>
                        <DatePicker
                            selected={(this.state.activeEndAt && moment(this.state.activeEndAt).toDate())}
                            onChange={date => this.handleChange("activeEndAt", moment(date).format('YYYY-MM-DDTHH:mm:ss'))}
                            className='form-control'
                            showTimeSelect
                            placeholderText='Datetime start'
                            timeFormat='HH:mm:ss'
                            dateFormat='dd-MM-yyyy HH:mm:ss'
                        />
                        <Form.Text className="text-muted">"End at" datetime.</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Status:</Form.Label>
                    <Col sm={'10'}>
                        <Form.Control as="select" value={this.state.isActive} custom
                            onChange={e => this.handleChange("isActive", e.target.value)}>
                            <option value={"true"}>Active</option>
                            <option value={"false"}>Inactive</option>
                        </Form.Control>
                        <Form.Text className="text-muted">Status must not be empty.</Form.Text>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Promo Label:</Form.Label>
                    <Col sm={'10'}>
                        <Select
                            onChange={(current, action) => this.handleChange("promoLabel", current)}
                            value={this.state.promoLabel}
                            placeholder={'Select promo label'}
                            options={promoLabelOpts}
                            isClearable={true}
                            isMulti={false}
                        />
                    </Col>
                </Form.Group>
            </div>
            <div className="mb-5">
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'12'}>Products:</Form.Label>
                </Form.Group>
                <Form.Text className="text-muted">
                    Don't forget to add buffer of Harga Negatif available quantity to product limiter
                </Form.Text>
                { (this.state.negativePriceProducts.map((value, index) => <NegativePriceProductField 
                    products={this.state.products}
                    item={value}
                    index={index}
                    productSelectFormat={this.productSelectFormat}
                    onChangeNegativePriceProduct={this.onChangeNegativePriceProduct}
                />)) }
                <button className='btn btn-primary mt-2' type="button" onClick={this.addNewNegativePriceProduct}>
                    Add New Harga Negatif Product
                </button>
            </div>
            <div className="mb-5">
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm='11'>Category Constraint:</Form.Label>
                </Form.Group>
                <Form.Text className="text-muted">
                    User allowed to buy Harga Negatif product if total shopping cart price of items that match specific category pass it's minimum purchase value
                </Form.Text>
                <Form.Group as={Row} className="form-prop">
                    <Col sm={'8'}> Categories </Col>
                    <Col sm={'4'}> Min. Purchase (don't let it empty) </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Col sm={'8'}>
                        <Typeahead
                            id="min-purchase-categories"
                            maxResults={20}
                            onChange={(selected => {
                                let categories = [];
                                selected.forEach((category) => {
                                    categories.push(category);
                                });
                                this.setState({ minPurchaseCategories: categories });
                            })}
                            options={categoryOptions}
                            multiple
                            selected={this.state.minPurchaseCategories}
                            onFocus={() => this.setState({categoryTypeAheadFocused: true})}
                            onBlur={() => this.setState({categoryTypeAheadFocused: false})}
                            open={this.state.categoryTypeAheadFocused}
                        />
                    </Col>
                    <Col sm={'4'}>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>
                                    <span>Rp.</span>
                                </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control className="form-ctr" placeholder={`Minimum purchase`}
                                value={this.state.minPurchase} as="input"
                                onChange={ e => REGEX_NUM.test(e.target.value) ? 
                                    this.setState({ minPurchase: e.target.value }) : 
                                    null 
                                }
                                onFocus={() => this.setState({ minPurchaseFieldFocused: true })}
                                onBlur={() => this.setState({ minPurchaseFieldFocused: false })}
                                open={this.state.minPurchaseFieldFocused}
                            />
                        </InputGroup>
                    </Col>
                </Form.Group>
            </div>
            <div className="mb-5">
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Only for New User:</Form.Label>        
                    <Col sm={'10'}>
                        <Form.Control as="select" value={this.state.isForNewCustomerOnly} custom
                            onChange={e => this.handleChange("isForNewCustomerOnly", e.target.value)}>
                            <option value={"true"}>Yes</option>
                            <option value={"false"}>No</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
            </div>
            <div className="mb-5">
            <Form.Group as={Row} className="form-prop">
                    <Col sm={'12'}>
                        <div className="form-ctr">
                            <Form.Check
                                type="switch"
                                id="switch-non-mixable-with-voucher"
                                style={{ margin: "auto" }}
                                label={"Non-mixable with voucher"}
                                checked={this.state.isNonMixableWithVoucher}
                                onChange={e => this.handleChange("isNonMixableWithVoucher", e.target.checked)} />
                        </div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Col sm={'12'}>
                        <div className="form-ctr">
                            <Form.Check
                                type="switch"
                                id="switch-non-mixable-with-freebie"
                                style={{ margin: "auto" }}
                                label={"Non-mixable with freebie"}
                                checked={this.state.isNonMixableWithFreebies}
                                onChange={e => this.handleChange("isNonMixableWithFreebies", e.target.checked)} />
                        </div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Col sm={'12'}>
                        <div className="form-ctr">
                            <Form.Check
                                type="switch"
                                id="switch-non-mixable-with-harga-gila"
                                style={{ margin: "auto" }}
                                label={"Non-mixable with harga gila"}
                                checked={this.state.isNonMixableWithHargaGila}
                                onChange={e => this.handleChange("isNonMixableWithHargaGila", e.target.checked)} />
                        </div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Col sm={'12'}>
                        <div className="form-ctr">
                            <Form.Check
                                type="switch"
                                id="switch-unable-to-checkout-more-than-one-promo"
                                style={{ margin: "auto" }}
                                label={"Unable to checkout more than 1 Harga Negatif"}
                                checked={this.state.unableToCheckoutMoreThanOnePromo}
                                onChange={e => this.handleChange("unableToCheckoutMoreThanOnePromo", e.target.checked)} />
                        </div>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Col sm={'12'}>
                        <div className="form-ctr">
                            <Form.Check
                                type="switch"
                                id="switch-from-talon-one"
                                style={{ margin: "auto" }}
                                label="Use Talon One as Harga Negatif Condition"
                                checked={this.state.isFromTalonOne}
                                onChange={e => this.handleChange("isFromTalonOne", e.target.checked)}
                            />
                        </div>
                    </Col>
                </Form.Group>
            </div>
            <div className="">
                <Button onClick={(e) => { this.handleSubmit(e) }} variant="primary">
                    { this.state.isCreate ? "Save" : "Update" }
                </Button>
            </div>
        </div>
    }
}

class NegativePriceProductField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectProductFocused: false,
            priceFocused: false,
            priceBeforeFocused: false,
            limitFocused: false,
            oneTimeUsageFocused: false,
            oneTimeUsageForNPeriodFocused: false,
            allTimeUsageLimitFocused: false,
            dailyTimeUsageLimitFocused: false,
            isBuyMoreFocused: false,
        }
    }

    handleChange = (field, value, fieldFocus) => {
        const { item, index, onChangeNegativePriceProduct } = this.props;
        let newItem = {...item, [field]: value}

        onChangeNegativePriceProduct(index, newItem);
        this.forceChangeUpdate(fieldFocus);
    }

    onDeleteProduct = () => {
        const { index, onChangeNegativePriceProduct } = this.props;

        onChangeNegativePriceProduct(index, null);
    }

    forceChangeUpdate = (targetFieldFocus) => {
        // force change to update the list (by loose the focus) then focus again
        this.setState({
            [targetFieldFocus]: false
        })
        setTimeout(() => {
            this.setState({ [targetFieldFocus]: true });
        }, 100);
    }

    render() {
        const { products, item, productSelectFormat} = this.props;
        const productNegativePriceOptions = [...products.map(productSelectFormat)];
        return <Row className='mt-4'>
            <Col sm={'11'} >
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>SKU Name:</Form.Label>        
                    <Col sm={'10'}>
                        <Select
                            onChange={(current, action) => {
                                if (!current) {
                                    this.handleChange("product", '', "selectProductFocused");
                                    return;
                                }
                                if (!Array.isArray(current)) {
                                    this.handleChange("product", current, "selectProductFocused");
                                    return;
                                } else {
                                    let currentList = [];
                                    current.forEach(c => currentList.push(c));
                                    this.handleChange("product", currentList, "selectProductFocused");
                                    return;
                                }
                            }}
                            value={item.product}
                            placeholder={'Select product'}
                            options={productNegativePriceOptions}
                            isMulti={false}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Price per Product:</Form.Label>        
                    <Col sm={'10'}>
                        <InputGroup>
                            <Form.Control className="form-ctr" placeholder={`Price per product`}
                                value={item.price} as="input"
                                onChange={ e => REGEX_NEGATIVE_NUM.test(e.target.value) ? 
                                    this.handleChange("price", e.target.value, "priceFocused") : 
                                    null 
                                }
                                onFocus={() => this.setState({ priceFocused: true })}
                                onBlur={() => this.setState({ priceFocused: false })}
                                open={this.state.priceFocused}
                            />
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Price Before per Product:</Form.Label>    
                    <Col sm={'10'}>
                        <InputGroup>
                            <Form.Control className="form-ctr" placeholder={`Price before per product`}
                                value={item.priceBefore} as="input"
                                onChange={ e => REGEX_NUM.test(e.target.value) ? 
                                    this.handleChange("priceBefore", e.target.value, "priceBeforeFocused") : 
                                    null 
                                }
                                onFocus={() => this.setState({ priceBeforeFocused: true })}
                                onBlur={() => this.setState({ priceBeforeFocused: false })}
                                open={this.state.priceBeforeFocused}
                            />
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>1 Claim for the Whole Period:</Form.Label>        
                    <Col sm={'10'}>
                        <Form.Control as="select" value={item.oneTimeUsage} custom
                            onChange={e => this.handleChange("oneTimeUsage", e.target.value, "oneTimeUsageFocused")}>
                            <option value={"true"}>Yes</option>
                            <option value={"false"}>No</option>
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>1 Claim for Specific Period:</Form.Label>        
                    {item.oneTimeUsage === "false" && !item.allTimeUsageLimit && !item.dailyUsageLimit ? <Col sm={'10'}>
                        <InputGroup>
                            <Form.Control className="form-ctr" placeholder={`n`} value={item.oneTimeUsageForNPeriod} as="input"
                                onChange={ e => REGEX_NUM.test(e.target.value) ? this.handleChange("oneTimeUsageForNPeriod", e.target.value, "oneTimeUsageForNPeriodFocused") : null }
                            />
                            <InputGroup.Text id="basic-addon2">hari</InputGroup.Text>
                        </InputGroup>
                        <Form.Text className="text-muted">
                            Customer dapat mengklaim promo 1 kali dalam n hari
                        </Form.Text>
                    </Col> : <Col sm={'10'}>
                        <Form.Label column sm={'12'}>Hanya bisa menggunakan 1 pengaturan Jumlah Klaim, bila ingin menggunakan pengaturan ini, isi <b>'1 Claim for the Whole Period' No</b> dan <b>kosongkan pengaturan lain</b></Form.Label>
                    </Col>}
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Maximal Claim for the Entire Period:</Form.Label>
                    {item.oneTimeUsage === "false" && !item.oneTimeUsageForNPeriod && !item.dailyUsageLimit ? <Col sm={'10'}>
                        <InputGroup>
                            <Form.Control className="form-ctr" placeholder={`n`} value={item.allTimeUsageLimit} as="input"
                                onChange={ e => REGEX_NUM.test(e.target.value) ? this.handleChange("allTimeUsageLimit", e.target.value, "allTimeUsageLimitFocused") : null }
                            />
                            <InputGroup.Text id="basic-addon2">kali</InputGroup.Text>
                        </InputGroup>
                        <Form.Text className="text-muted">
                            Selama periode aktif maksimal promo dikalim sebanyak n kali
                        </Form.Text>
                    </Col> : <Col sm={'10'}>
                        <Form.Label column sm={'12'}>Hanya bisa menggunakan 1 pengaturan Jumlah Klaim, bila ingin menggunakan pengaturan ini, isi <b>'1 Claim for the Whole Period' No</b> dan <b>kosongkan pengaturan lain</b></Form.Label>
                    </Col>}
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>Maximal Claim for the Entire Day:</Form.Label>
                    {item.oneTimeUsage === "false" && !item.oneTimeUsageForNPeriod && !item.allTimeUsageLimit ? <Col sm={'10'}>
                        <InputGroup>
                            <Form.Control className="form-ctr" placeholder={`n`} value={item.dailyUsageLimit} as="input"
                                onChange={ e => REGEX_NUM.test(e.target.value) ? this.handleChange("dailyUsageLimit", e.target.value, "dailyUsageLimitFocused") : null }
                            />
                            <InputGroup.Text id="basic-addon2">kali</InputGroup.Text>
                        </InputGroup>
                        <Form.Text className="text-muted">
                            Selama periode aktif, setiap harinya promo maksimal dikalim sebanyak n kali
                        </Form.Text>
                    </Col> : <Col sm={'10'}>
                        <Form.Label column sm={'12'}>Hanya bisa menggunakan 1 pengaturan Jumlah Klaim, bila ingin menggunakan pengaturan ini, isi <b>'1 Claim for the Whole Period' No</b> dan <b>kosongkan pengaturan lain</b></Form.Label>
                    </Col>}
                </Form.Group>
                <Form.Group as={Row} className="form-prop">
                    <Form.Label column sm={'2'}>User able to buy more than max purchase?</Form.Label>
                    <Col sm={'10'}>
                        <InputGroup>
                            <Form.Control as="select" value={item.isAbleToBuyMore}custom
                                onChange={e => this.handleChange("isAbleToBuyMore", e.target.value, "isBuyMoreFocused")}>
                                <option value={"true"}>Yes</option>
                                <option value={"false"}>No</option>
                            </Form.Control>
                        </InputGroup>
                    </Col>
                </Form.Group>
            </Col>
            <Col sm={'1'}>
                <button onClick={this.onDeleteProduct} className="btn btn-danger">x</button>
            </Col>
        </Row>
    }
}

export default ModalCreateUpdateNegativePrices;