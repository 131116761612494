import React from 'react';
import styles from "./NavTab.module.scss";

export const NAV_ITEM_IDS = {
    RUNS: 'runs',
    CONFIGURATION: 'pengaturan',
    SCHEDULE: 'schedule',
}

const ShipmentDetailNavTab = ({ activeNavItemId, setActiveNavItemId }) => {
    return (
        <>
            <ul className={`${styles.nav} ${styles.navTabs}`}>
                <NavItem id={NAV_ITEM_IDS.RUNS} activeNavItemId={activeNavItemId} setActiveNavItemId={setActiveNavItemId}>Runs</NavItem>
                <NavItem id={NAV_ITEM_IDS.CONFIGURATION} activeNavItemId={activeNavItemId} setActiveNavItemId={setActiveNavItemId}>Pengaturan</NavItem>
            </ul>
        </>
    )
}

const NavItem = ({ id, activeNavItemId, setActiveNavItemId, children}) => {
    const activeStyle = id === activeNavItemId ? styles.active : "";

    return (
        <li className={`${styles.navItem}`} onClick={(target) => setActiveNavItemId(id)}>
            <div className={`${styles.navLink} ${activeStyle}`}>
                {children}
            </div>
        </li>
    )
}

export default ShipmentDetailNavTab