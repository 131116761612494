import React from "react";
import Input from "../../Components/Input";

const GeneralInformationSection = (props) => {
    const {employeeId, name, email, contractType, contractTypeOptions, homeOfficeId, directoryId, directoryOptions, warehouseOptions} = props;
    const {setEmployeeId, setName, setEmail, setContractType, setHomeOfficeId, setDirectoryId} = props;
    const {errors} = props;

    const directorySelection = [];
    directorySelection.push({value: null, label: "Pilih directory"});
    directoryOptions.forEach(directoryOption => {
        directorySelection.push({value: directoryOption.id, label: directoryOption.name});
    });

    const warehouseSelection = [];
    warehouseSelection.push({value: "HO", label: "Head Office"});
    warehouseOptions.forEach(warehouseOption => {
        warehouseSelection.push({value: warehouseOption.id, label: warehouseOption.name});
    });

    return(
        <>
            <div className={"d-flex mb-3"}>
                <Input text={"Nomor Induk Karyawan"} value={employeeId} handleInput={setEmployeeId} error={errors ? errors.employeeId : null}/>
                <Input text={"Nama Lengkap"} value={name} handleInput={setName} id={"name"} error={errors ? errors.name : null}/>
                <Input text={"Email"} value={email} handleInput={setEmail}
                       id={"email"} footnote={"Disarankan Mengunakan Email Segari"} error={errors ? errors.email : null}/>
            </div>
            <div className={"d-flex mb-2"}>
                <Input text={"Kontrak Kerja"} type={"select"} value={contractType}
                       handleInput={setContractType} options={contractTypeOptions} error={errors ? errors.contractType : null}/>
                <Input text={"Home Office"} type={"typeahead"} value={homeOfficeId} id={'home-office'}
                       handleInput={setHomeOfficeId} options={warehouseSelection} error={errors ? errors.homeOfficeId : null}/>
                <Input text={"Directory"} type={"typeahead"} value={directoryId} id={'directory'}
                       handleInput={setDirectoryId} options={directorySelection} error={errors ? errors.directoryId : null}/>
            </div>
        </>
    );
}

export default GeneralInformationSection;