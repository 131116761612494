import customAxios from "./axios";
import {
    UNIT_OF_MEASURES_V1_URL,
    UNIT_OF_MEASURES_PRODUCT_SUGGESTION_V1,
    UNIT_OF_MEASURES_PURCHASING_PRODUCT_SUGGESTION_V1,
    UNIT_OF_MEASURES_ALL_SUGGESTIONS_V1,
    UNIT_OF_MEASURES_DISTINCT_V1,
    BULK_UNIT_OF_MEASURES_V1_URL,
    DELETE_ITEM_UOM_V1
} from "../constants/api";

export function getUnitOfMeasures() {
    return customAxios.get(UNIT_OF_MEASURES_V1_URL);
}

export function createUnitOfMeasure(data) {
    return customAxios.post(UNIT_OF_MEASURES_V1_URL, data);
}

export function createBulkUnitOfMeasure({data}) {
    return customAxios.post(BULK_UNIT_OF_MEASURES_V1_URL, data);
}

export function getDistinctUnitOfMeasures() {
    return customAxios.get(UNIT_OF_MEASURES_DISTINCT_V1);
}

export function getAllItemUoM() {
    return customAxios.get(UNIT_OF_MEASURES_ALL_SUGGESTIONS_V1);
}

export function getProductSuggestions({id}) {
    return customAxios.get(UNIT_OF_MEASURES_PRODUCT_SUGGESTION_V1({id}));
}

export function getPurchasingProductSuggestions({id}) {
    return customAxios.get(UNIT_OF_MEASURES_PURCHASING_PRODUCT_SUGGESTION_V1({id}));
}

export function deleteItemUoM({id}) {
    return customAxios.delete(DELETE_ITEM_UOM_V1({id}));
}