const getValueFromOptions = (value, options) => {
  if (value == null) return null;
  return options.find((i) => i.value == value);
};

const getValueFromOptionsMulti = (value, options) => {
  if (value == null) return null;
  return options.filter((opt) => value.find((id) => id == opt.value));
};

export const useReactSelectForm = () => {
    return [
        getValueFromOptions,
        getValueFromOptionsMulti
    ]
};