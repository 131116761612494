import moment from 'moment';
import _ from 'lodash';

export function formatNegativePrice(price) {
    if (price >= 0) return rupiah(price);
    return `-${rupiah(price*-1)}`
}

export function formatThousandWithSuffix(amount, suffix) {
    if (!amount) return "";
    let amountString = amount.toString();
    if (!amountString) return "";
    if (amountString.length < 4) return amountString;
    return amountString.substring(0,amountString.length-3)+suffix;
}

export function numWithCommaDelimiter(floatString) {
    if (!floatString) {
        return floatString;
    }

    return parseFloat(floatString).toLocaleString();
}

export function toPercentage(value, fractionDigits = 0) {
    return `${(value * 100).toFixed(fractionDigits)}%`
}

export function rupiah(floatString) {
    return "Rp " + parseFloat(floatString).toLocaleString('id-ID');
}

export function rupiahNoPrefix(floatString) {
    return parseFloat(floatString).toLocaleString('id-ID');
}

export function rupiahFormat(floatString, fractionDigits = 2) {
    return rupiah(toFixed(floatString, fractionDigits));
}

export function toFixed(value, fractionDigits = 2) {
    if (!value) return 0;
    return Number(value).toFixed(fractionDigits);
}

export function dateTimeWithoutTimezone(dateWithTimezone) {
    if (!dateWithTimezone) return dateWithTimezone;
    return moment(dateWithTimezone).format('YYYY-MM-DDTHH:mm:ss');
}

export function splitStringAndClean(value, delimiter, patternToClean) {
    if (!value) return [];
    return value.split(delimiter).map(item => item.replace(patternToClean, ''));
}

export function combineArrayStringWithDelimiter(arr, delimiter = ", ") {
    let result = "";
    let prefix = "";
    (arr || []).forEach(element => {
        result = `${result}${prefix}${element}`;
        prefix = delimiter;
    });

    return result;
}

export function titleCase(words, delimiter = ' ') {
    if (!words) return words;

    return words.split(delimiter).map(word => word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()).join(' ');
}

export const REGEX_NUM = new RegExp('^\\d*$');

export const REGEX_NEGATIVE_NUM = new RegExp('^-\\d*$');

export function safelySubtractFloat(a, b) {
    const splittedA = a.toString().split(".");
    let a_max = 0;
    if (splittedA.length === 2) {
        a_max = splittedA[1].length;
    }

    const splittedB = b.toString().split(".");
    let b_max = 0;
    if (splittedB.length === 2) {
        b_max = splittedB[1].length;
    }

    // Max number of comma
    let max_len = a_max > b_max ? a_max : b_max;

    return Number((a - b).toFixed(max_len));
}

export function productTagLastEditorFormat (data) {
    const lastEditorNameOrPhone = data.lastEditorUsername ? data.lastEditorUsername : data.lastEditorPhone ? data.lastEditorPhone : "n/a"
    const lastEditorEmail = ` (${data.lastEditorEmail ? data.lastEditorEmail : "n/a" })`
    
    return lastEditorNameOrPhone + lastEditorEmail
}

export const generateLowerAndSnakeCase = (text) => {
    if (!text) return '';
    return text.split(" ").join("_").toLowerCase();
}

export const isValidAlphabetLowerAndSnakeCase = (id) => {
    const lowerCaseAndSnakeCasePattern = /^[a-z]+(_[a-z]+)*$/g;
    return id.match(lowerCaseAndSnakeCasePattern) !== null;
}

export function lastEditorFormat(username, phone, email) {
    let lastEditor = "";
    lastEditor += (null != username) ? `${username}` : `${phone}`;
    lastEditor += (null != email) ? ` (${email})` : '';
    return lastEditor;
}

export const capitalizeFirstLetter = (text) => {
    if (!text) return ''
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export const generateTitleCase = (text) => {
    if (!text) return ''
    const words =  text.split("_")
    return words.map(word => capitalizeFirstLetter(word)).join(" ")
}

export const getValueOrNull = (value) => {
    if (!!value) return value;
    return null;
}

export const formatDateString = (dateString, format) => {
    const momentDate = moment(dateString);
    return momentDate.isValid() ? momentDate.format(format) : "-";
};

export const convertEmptyToNull = (value) => {
    return _.isEmpty(value) || _.isUndefined(value) ? null : value;
};

export const safeJoin = (array, delimiter = ',') => {
    return Array.isArray(array) && array.length > 0 ? array.join(delimiter) : null;
}

export const getValueOrDefault = (value, defaultValue = '-') => {
    return value ?? defaultValue;
}

export const formatNumber = (number, locale = 'id-ID') => {
    return new Intl.NumberFormat(locale).format(number);
}