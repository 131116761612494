import customAxios from "./axios";
import {
    BANNERS_CHANGE_VISIBILITY_V1,
    BANNERS_V1_URL,
    INTERNAL_TOOLS_SETTINGS,
    MANAGE_REFERRAL_DATA_VOUCHER_DELIVERY, PRODUCT_RECOMMENDATION_CAROUSEL_LOGS_V1,
    REFEREE_CONFIGS_VALUES,
    REFERRAL_BANNER,
    REFERRAL_BANNERS, REFERRAL_DATA_LOGS, REFERRAL_DISBURSE_VOUCHER,
    REFERRAL_PROGRAM_BANNER,
    REFERRAL_PROGRAM_BANNER_CHANGE_STATUS,
    REFERRAL_PROGRAM_BANNERS, REFERRAL_SHOW_DATA,
    REFERRAL_VALIDATE_DATA,
    REFERRER_CONFIGS_VALUES
} from "../constants/api";
import axios from "./axios";

export function getRefereeValues() {
    return customAxios.get(REFEREE_CONFIGS_VALUES);
}

export function getReferrerValues() {
    return customAxios.get(REFERRER_CONFIGS_VALUES);
}

export function updateRefereeValues({data}) {
    return customAxios.put(REFEREE_CONFIGS_VALUES, data);
}

export function updateReferrerValues({data}) {
    return customAxios.put(REFERRER_CONFIGS_VALUES, data);
}

export function getAllReferralBanners() {
    return customAxios.get(REFERRAL_BANNERS);
}

export function getReferralBannerById({id}) {
    return customAxios.get(REFERRAL_BANNER({id}));
}

export function updateReferralBanner({banner}) {
    return customAxios.put(REFERRAL_BANNERS, banner);
}

export function deleteReferralBanner({id}) {
    return customAxios.delete(REFERRAL_BANNER({id}));
}

export function getAllProgramBanners({params}) {
    return customAxios.get(REFERRAL_PROGRAM_BANNERS, {params});
}

export function getProgramBannerById({id}) {
    return customAxios.get(REFERRAL_PROGRAM_BANNER({id}));
}

export function createOrUpdateProgramBanner({banner}) {
    return customAxios.put(REFERRAL_PROGRAM_BANNERS, banner);
}

export function deleteProgramBanner({id}) {
    return customAxios.delete(REFERRAL_PROGRAM_BANNER({id}));
}

export function changeStatus({data}) {
    return axios.put(REFERRAL_PROGRAM_BANNER_CHANGE_STATUS, data);
}

export function validateData(data) {
    return customAxios.post(REFERRAL_VALIDATE_DATA, data);
}

export function disburseVoucher(data) {
    return customAxios.post(REFERRAL_DISBURSE_VOUCHER, data);
}

export function getLogs(params) {
    return customAxios.get(REFERRAL_DATA_LOGS, {params});
}

export function showData({data}) {
    return customAxios.post(REFERRAL_SHOW_DATA, data);
}