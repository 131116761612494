import React from 'react'
import {bindActionCreators} from "redux";
import {displayAlert} from "../../../redux/actions/notif";
import {connect} from "react-redux";
import MasterDetail from "../../../components/MasterDetail";
import moment from "moment";
import {Formik} from "formik";
import QualitySurveyForm from "./QualitySurveyForm";
import * as apiInvoice from '../../../apis/invoices'
import PageAuthCheck from "../../../components/AuthCheck/PageAuthCheck";
import {WHATSAPP_TEMPLATE_CONFIGURE} from "../../../constants/scope";
import * as deliveryServiceType from '../../../constants/deliveryServiceType';

class QualitySurveyPage extends React.Component {
    state = {
        deliveryDate: ''
    }

    onSubmitSearch = async (values, {setSubmitting}) => {
        const deliveryDate = moment(values.deliveryDate).format('YYYY-MM-DD')
        setSubmitting(true)
        try {
            const response = await apiInvoice.qualitySurvey({deliveryDate, deliveryServiceType: values.deliveryServiceType})
            this.props.displayAlert({message: response.data.message,type:'success'})
        } catch (e) {
            console.error(e)
            this.props.displayAlert({message:e.message,type:'error'})
        }
        setSubmitting(false)
    }

    renderNavbar = () => {
        return <h5>Quality Survey Blaster</h5>
    }

    renderMaster = () => {
        return <div>
            <Formik
                initialValues={{
                    deliveryDate: moment().add(-1,'days').toDate(),
                    deliveryServiceType: deliveryServiceType.NEXT_DAY_DELIVERY
                }}
                onSubmit={this.onSubmitSearch}
            >
                {(formikProps) => <QualitySurveyForm {...formikProps}/>}
            </Formik>
        </div>
    }

    renderDetail = () => {
        return <div/>
    }

    render(){
        return <PageAuthCheck allowedScopes={[WHATSAPP_TEMPLATE_CONFIGURE]}>
        <MasterDetail
            navbar={this.renderNavbar()}
            master={this.renderMaster()}
            detail={this.renderDetail()}
        />
        </PageAuthCheck>
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({message,type}) => displayAlert({message,type})
    },dispatch)
}

export default connect(mapStateToProps,mapDispatchToProps)(QualitySurveyPage)