export const SPECIAL_PRICE_PROMO_TYPE = {
    NEGATIVE_PRICE: "NEGATIVE_PRICE",
    HARGA_GILA: "HARGA_GILA"
}

export const NEGATIVE_PRICE_CONSTRAINT_TYPE = {
    ONLY_FOR_NEW_USER: "ONLY_FOR_NEW_USER",
    MINIMUM_PURCHASE: "MINIMUM_PURCHASE",
    ONE_TIME_USAGE: "ONE_TIME_USAGE",
    ONE_TIME_USAGE_FOR_N_DAYS: "ONE_TIME_USAGE_FOR_N_DAYS",
    DAILY_USAGE_LIMIT: "DAILY_USAGE_LIMIT",
    ALL_TIME_USAGE_LIMIT: "ALL_TIME_USAGE_LIMIT",
    ABLE_TO_BUY_MORE: "ABLE_TO_BUY_MORE",
}

export const HARGA_GILA_CONSTRAINT_TYPE = {
    ONLY_FOR_NEW_USER: "ONLY_FOR_NEW_USER",
    MINIMUM_PURCHASE: "MINIMUM_PURCHASE",
    ABLE_TO_BUY_MORE: "ABLE_TO_BUY_MORE",
    USER_ALL_TIME_USAGE_LIMIT: "USER_ALL_TIME_USAGE_LIMIT",
    USER_MONTHLY_USAGE_LIMIT: "USER_MONTHLY_USAGE_LIMIT",
    USER_WEEKLY_USAGE_LIMIT: "USER_WEEKLY_USAGE_LIMIT",
    USER_DAILY_USAGE_LIMIT: "USER_DAILY_USAGE_LIMIT",
    GLOBAL_ALL_TIME_USAGE_LIMIT: "GLOBAL_ALL_TIME_USAGE_LIMIT",
    GLOBAL_MONTHLY_USAGE_LIMIT: "GLOBAL_MONTHLY_USAGE_LIMIT",
    GLOBAL_WEEKLY_USAGE_LIMIT: "GLOBAL_WEEKLY_USAGE_LIMIT",
    GLOBAL_DAILY_USAGE_LIMIT: "GLOBAL_DAILY_USAGE_LIMIT",
}