import customAxios from "./axios";
import {
    SHIPPING_POINTS_V1,
    SHIPPING_POINTS_GEO_MEASUREMENT_V1,
    SHIPPING_POINTS_COVERING,
    SHIPPING_POINTS_GEOFENCING,
    SHIPPING_POINTS_SDD_SP,
    SHIPPING_POINTS_SDD_TRIAL_SP,
    SHIPPING_POINTS_ACTIVE,
    SHIPPING_POINTS_SDD_TRIAL_SP_CONFIG,
    SHIPPING_POINTS_ACTIVE_NDD,
    SHIPPING_POINTS_ACTIVE_NDD_SCHEDULED,
    SHIPPING_POINTS_ACTIVE_SDD,
    SHIPPING_POINTS_ACTIVE_SDD_SCHEDULED,
    SHIPPING_POINTS_ACTIVE_SDD_PRIORITY,
    SHIPPING_POINTS_ACTIVE_GEO_MEASUREMENT,
    SHIPPING_POINTS_SDD_UNFILLED_AUTO_ASSIGN_CONFIG,
    SHIPPING_POINTS_SDD_OTHER_CONTACTS,
    SHIPPING_POINTS_ACTIVE_EXTENSION,
    SHIPPING_POINTS_GOMART_MANUAL
} from "../constants/api";

export function getShippingPoints() {
    return customAxios.get(SHIPPING_POINTS_V1);
}

export function getShippingPointsWithGeometryMeasurement() {
    return customAxios.get(SHIPPING_POINTS_GEO_MEASUREMENT_V1);
}

export function getAllActiveShippingPoints() {
    return customAxios.get(SHIPPING_POINTS_ACTIVE);
}

export function getAllActiveShippingPointsWithGeometryMeasurement() {
    return customAxios.get(SHIPPING_POINTS_ACTIVE_GEO_MEASUREMENT);
}

export function getAllActiveNddShippingPoints() {
    return customAxios.get(SHIPPING_POINTS_ACTIVE_NDD);
}

export function getAllActiveNddScheduledShippingPoints() {
    return customAxios.get(SHIPPING_POINTS_ACTIVE_NDD_SCHEDULED);
}

export function getAllActiveSddShippingPoints() {
    return customAxios.get(SHIPPING_POINTS_ACTIVE_SDD);
}

export function getAllActiveSddScheduledShippingPoints() {
    return customAxios.get(SHIPPING_POINTS_ACTIVE_SDD_SCHEDULED);
}

export function getAllActiveSddPriorityShippingPoints() {
    return customAxios.get(SHIPPING_POINTS_ACTIVE_SDD_PRIORITY);
}

export function getAllActiveExtensionShippingPoints() {
    return customAxios.get(SHIPPING_POINTS_ACTIVE_EXTENSION);
}

export function updateShippingPoint(data) {
    return customAxios.put(SHIPPING_POINTS_V1, data)
}

export function createShippingPoint(data) {
    return customAxios.post(SHIPPING_POINTS_V1, data)
}

export function getShippingPointsCovering({ ...params }) {
    return customAxios.get(SHIPPING_POINTS_COVERING, { params: params })
}

export function getShippingPointsGeofencing({ ...params }) {
    return customAxios.get(SHIPPING_POINTS_GEOFENCING, { params: params })
}

export function getSDDOtherContacts() {
    return customAxios.get(SHIPPING_POINTS_SDD_OTHER_CONTACTS)
}

export function getSddShippingPoints() {
    return customAxios.get(SHIPPING_POINTS_SDD_SP);
}

export function getSddSpTrial() {
    return customAxios.get(SHIPPING_POINTS_SDD_TRIAL_SP);
}

export function getSddSpTrialConfig() {
    return customAxios.get(SHIPPING_POINTS_SDD_TRIAL_SP_CONFIG);
}

export function getSDDSPWithUnfilledAutoAssignConfig() {
    return customAxios.get(SHIPPING_POINTS_SDD_UNFILLED_AUTO_ASSIGN_CONFIG);
}

export function getGomartManualExternalChannelShippingPointIds() {
    return customAxios.get(SHIPPING_POINTS_GOMART_MANUAL);
}