export const AGENT_PRICE_GROUPS_DEFAULT_LIMIT = 10_000;
export const AGENT_SALES_PICS_DEFAULT_LIMIT = 20_000;
export const AGENT_TYPES_DEFAULT_LIMIT = 10_000;
export const AGENTS_DEFAULT_LIMIT = 40_000;

export const ORDERS_DEFAULT_LIMIT = 24_000;

export const PRODUCTS_DEFAULT_LIMIT = 100_000;


