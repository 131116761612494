import React from 'react';
import * as s from "../../constants/scope";
import MasterDetail from "../../components/MasterDetail/MasterDetail";
import PageAuthCheck from '../../components/AuthCheck/PageAuthCheck';
import { bindActionCreators } from "redux";
import { displayAlert } from "../../redux/actions/notif";
import { connect } from "react-redux";

import { Formik, Form, Field } from 'formik';
import styles from './CategoryDisplayGroupPage.module.css';
import * as apiFrontendCategory from '../../apis/frontendCategories';
import * as apiFrontendCategoryGroup from '../../apis/frontendCategoryGroups'
import * as frontendCategoryGroupPageType from '../../constants/frontendCategoryGroupPageType';
import _ from "lodash";
import ExitConfirmModal from './ExitConfirmModal';
import GuidelineModal from './GuidelineModal';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CDGDragNDropItem from './CDGDragNDropItem';
import { Spinner } from 'react-bootstrap';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};


const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? "lightgreen" : "white",

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "white",
    width: 400
});

class CreateCDGPageDirectoryCLP extends React.Component {
    state = {
        groupName: null,
        groupId: null,
        defaultFcOptions: null,
        defaultBackupFcOptions: null,
        frontendCategoryOptions: null,
        backupFrontendCategoryOptions: null,
        removedFCOptions: [],
        isFormResetting: false,
        isThematicActive: false,
        nonThematicData: [],
        isSubmitting: false,
        pageType: this.props.location.state.pageType,
        showCloseModal: false,
        showGuidelineModal: false
    }

    componentDidMount = async () => {
        document.title = "Category Display Group";
        this.setState({
            isFormResetting: false
        });
        await this.loadFcOptionData();
        this.loadAndParseToInitialData();
    }

    loadAndParseToInitialData = () => {
        let fcg = this.props.location.state.data;
        
        this.setState({
            isThematicActive: fcg.thematicActive,
            groupId: fcg.id,
            groupName: fcg.name,
        })
        let fcgItem = this.props.location.state.fcData;
        let fcInitialOptions = fcgItem.map( (item, idx) =>{
            let obj = {
                priority: item.priority,
                id: idx+1,
                data:{
                    category: item.frontendCategory.id,
                    backupCategory: item.backupFrontendCategory?.id
                },
                type: item.type,
                isBackupMandatory: item.frontendCategory.isBackupMandatory,
            }
            return obj;
        });
        this.setState({
            defaultFcOptions: fcInitialOptions
        }, () => this.initialFcOptions(fcInitialOptions))
    }

    initialFcOptions = (fcInitialOptions) => {
        let currentOptions = _.cloneDeep(this.state.frontendCategoryOptions);
        let selectedFcOptions= [];

        let newFcOptions = currentOptions.filter( ele => {
            let found = fcInitialOptions.find(item => {
                return item.data.category === ele.value;
            });
            if (found) {
                ele.isDisabled = true;
                let obj = {
                    fieldName: "categoryOptions_" + found.id,
                    option: ele
                }
                selectedFcOptions.push(obj);
            }
            return ele
        })
        this.setState({
            frontendCategoryOptions: newFcOptions,
            removedFCOptions: selectedFcOptions
        })
    }

    loadFcOptionData = async () => {
        //load data from api and generate dropdown
        try {
            let frontendCategories = await apiFrontendCategory.getAll();
            let fcOptions = this.parseToFcOptions(frontendCategories);
            this.setState({
                frontendCategoryOptions: fcOptions,
                backupFrontendCategoryOptions: fcOptions,
            })
        } catch (error) {
            console.log("Error : ", error);
        }
    }
    
    parseToFcOptions = (data) => {
        let options = data.data.data.map( item => {
            let obj = {
                value : item.id,
                label : item.name,
                isBackupMandatory : item.isBackupMandatory,
                isDisabled: false
            }
            return obj;
        });
        return options;
    }
    
    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            this.state.defaultFcOptions,
            result.source.index,
            result.destination.index
        ).map((item, index) =>
        ({
            ...item, priority: index + 1
        })
        )

        this.setState({
            defaultFcOptions: items
        });

    }

    onThematicActivated = async (checked, values) => {
    
        const thematicItem = [
            {
                id: 101,
                data: {},
                isBackupMandatory: false,
                type: "thematic"
            },
            {
                id: 102,
                data: {},
                isBackupMandatory: false,
                type: "thematic"
            },
            {
                id: 103,
                data: {},
                isBackupMandatory: false,
                type: "thematic"
            },
            {
                id: 104,
                data: {},
                isBackupMandatory: false,
                type: "thematic"
            },
            {
                priority: 105,
                id: 105,
                data: {},
                isBackupMandatory: false,
                type: "thematic"
            },
        ]
        this.setState({
            isThematicActive: checked
        })

        if(checked) {
            this.setState({
               // nonThematicData: this.state.defaultFcOptions,
                defaultFcOptions: [
                    ...this.state.defaultFcOptions,
                    ...thematicItem
                ]    
            })
        } else {
            const thematicValue = []
            const nonThematicData = this.state.defaultFcOptions.slice(0, this.state.defaultFcOptions.length-5)
            this.state.defaultFcOptions.slice(this.state.defaultFcOptions.length-5, this.state.defaultFcOptions.length).map((item) => {
                let value = this.selectValueFromValues(values, item.id)
                thematicValue.push(value)
                this.deleteFromValues(values, item.id)    
            })

            this.onDeactivateThematicCategory(thematicValue)

            this.setState({
                defaultFcOptions: nonThematicData
            })
        }
    }

    onDeactivateThematicCategory = (items) => {
        //restore removed options
        let options = _.cloneDeep(this.state.frontendCategoryOptions);
        options = this.enableOptions(items)

        this.setState({
            frontendCategoryOptions: options
        })
    }

    deleteFromValues = (values, id) => {
        let key = "categoryOptions_" + id;
        if (values[key]) delete values[key]
        return values;
    }

    selectValueFromValues = (values, id) => {
        return values["categoryOptions_" + id];
    }

    assignValue = (data, index, value, isBackup) => { //assign formik value to array
        data.forEach(item => {
            if (item.id === index) {
                if (!isBackup) item.data.category = value;
                else item.data.backupCategory = value;
            }
        });
    }

    checkIsBackupMandatory = async (value, id) => {
        let options = _.cloneDeep(this.state.defaultFcOptions);
        let isBackupMandatory = false;

        this.state.frontendCategoryOptions.forEach(ele => {
            if (ele.value === value) isBackupMandatory = ele.isBackupMandatory;
        })

        options.forEach(ele => {
            if (ele.id === id){
                ele.isBackupMandatory = isBackupMandatory;
            }
            return ele;
        });

        this.setState({
            defaultFcOptions: options
        });
    }

    disableOptions = async (itemValue,fieldName) => {
        let options = _.cloneDeep(this.state.frontendCategoryOptions);
        let isAlreadyDisable = this.findInRemovedFCOptionsState(fieldName, itemValue);
        if(isAlreadyDisable){
            options = this.enableOptions([isAlreadyDisable.option.value])
        }
        options.forEach(ele => {
            if (ele.value === itemValue){
                ele.isDisabled = true;
                let obj= {
                    fieldName: fieldName,
                    option: ele
                }
                this.addToRemovedFCOptionsState(obj);
            }
            return ele;
        });

        this.setState({
            frontendCategoryOptions: options
        });
    }

    enableOptions = (itemValue) => {
        let options = _.cloneDeep(this.state.frontendCategoryOptions);
        let updatedOptions = options.map(ele => {
            if (ele.value == itemValue[0] || 
                ele.value == itemValue[1] ||
                ele.value == itemValue[2] ||
                ele.value == itemValue[3] ||
                ele.value == itemValue[4]) 
            {
                ele.isDisabled = false;
                this.removeFromRemovedFCOptionsState(ele.value);
            }
            return ele;
        })
   
        return updatedOptions;
    }

    findInRemovedFCOptionsState = (fieldName, itemValue) => {
        let removed = _.cloneDeep(this.state.removedFCOptions);
        let result; 
        removed.forEach(ele => {
            if(ele.fieldName === fieldName && ele.option.value !== itemValue) result = ele;
        })
        
        return result;
    }

    removeFromRemovedFCOptionsState = (item) => {
        let removed = _.cloneDeep(this.state.removedFCOptions);
        removed.filter(ele => {
            return ele.option.value !== item;
        })
        this.setState({ removedFCOptions: removed }); 
    }

    addToRemovedFCOptionsState = (item) => {
        let removed = _.cloneDeep(this.state.removedFCOptions);
        removed.push(item);
        this.setState({
            removedFCOptions: removed
        });
    }

    convertToEditBody = (data) => {
        let nullCheck;
        let frontendCategories = data.map((item, index) => {
            if(item.data.category == null){
                nullCheck = true;
            }
            let obj = {
                priority: item.priority,
                frontendCategoryId: item.data.category,
                backupFrontendCategoryId: item.data.backupCategory,
                type: item.type 
            }
            return obj;
        })
        if (nullCheck) return null;
        else return frontendCategories;
    }


    onSubmit = async (values) => {
        this.setState({
            isSubmitting: true
        })
        let data = _.cloneDeep(this.state.defaultFcOptions);
        for (let key of Object.keys(values)) {
            if (key.startsWith("category")) this.assignValue(data, parseInt(key.split("_")[1]), values[key], false);
            else this.assignValue(data, parseInt(key.split("_")[1]), values[key], true);
        }
        let groupId = this.state.groupId

        let frontendCategories = this.convertToEditBody(data)
        if (frontendCategories){
            let body = {
                frontendCategories: frontendCategories,
                thematicActive: this.state.isThematicActive,
                pageType: this.state.pageType
            }
            try {
                await apiFrontendCategoryGroup.update(groupId, body);
                this.props.displayAlert({message : 'Success Update Frontend Category Group', type: 'success'})
                this.props.history.push("/category-display-group");
            } catch (error) {
                console.log("error :", error)
            }
        }
        else {
            this.props.displayAlert({message : 'Please complete the setup before submitting', type: 'error'})
        }

        this.setState({
            isSubmitting: false
        })

    }

    showCloseModal = () => {
        this.setState({
            showCloseModal: true
        });
    }

    onClickConfirmExit = () => {
        this.props.history.push("/category-display-group");
    }

    onClickCancelExit = () => {
        this.setState({
            showCloseModal: false
        });
    }

    showGuidelineModal = () => {
        this.setState({
            showGuidelineModal: true
        });
    }
    
    closeGuidelineModal = () => {
        this.setState({
            showGuidelineModal: false
        });
    }

    renderNavbar = () => {
        let backToListPageTitle = "<<< Category Display Group Lists"
        let pageTitle = "";
        if (this.state.pageType == frontendCategoryGroupPageType.CATEGORY_LANDING_PAGE) pageTitle = "Update Category Landing Page Sorting";
        if (this.state.pageType == frontendCategoryGroupPageType.CATEGORY_DIRECTORY_CLP) pageTitle = "Update Category Directory CLP Sorting";
        return <div>
            <h1 className='title-text'> {pageTitle} </h1>
            <button type='button' className='btn btn-link' onClick={this.showCloseModal}>
                {backToListPageTitle}
            </button>
        </div>
    }


    dndOptionsinitialValues = () => {
        let defaultFcOption = _.cloneDeep(this.state.defaultFcOptions);
        let obj = {};
        if(defaultFcOption){
            defaultFcOption.forEach(item => {
                let key = "categoryOptions_" + item.id
                obj[key] = item.data.category
                if (item.isBackupMandatory) {
                    key = "backupOptions_" + item.id;
                    obj[key] = item.data.backupCategory;
                }
            })
        }
        obj["groupName"] = this.state.groupName;
        return obj
    }
    
    

    renderForm = () => {
        let initialValues = this.dndOptionsinitialValues();   
        return <div className={styles.create_form_style}>
            <Formik
                initialValues={initialValues? initialValues : null}
                onSubmit={this.onSubmit}
            >
                {
                    (formikProps) => <CreateForm
                        isSubmitting={this.state.isSubmitting}
                        formikProps={formikProps}
                        data={this.state.defaultFcOptions}
                        groupName={this.state.groupName}
                        onDragEnd={this.onDragEnd}
                        initialValues={initialValues? initialValues : null}
                        onSubmit={this.onSubmit}
                        onThematicActivated={this.onThematicActivated}
                        frontendCategoryOptions={this.state.frontendCategoryOptions}
                        backupFrontendCategoryOptions={this.state.backupFrontendCategoryOptions}
                        isThematicActive={this.state.isThematicActive}
                        showGuidelineModal={this.showGuidelineModal}
                    />
                }
            </Formik>
        </div>
    }

    render() {
        return (
            <PageAuthCheck allowedScopes={[s.FRONTEND_CATEGORY_GROUP_UPDATE]}>
                <MasterDetail
                    navbar={this.renderNavbar()}
                    master={this.renderForm()}
                />

                <ExitConfirmModal
                    title="Exit Confirmation"
                    show={this.state.showCloseModal}
                    message={"Changes you made may not be saved."}
                    onCancel={this.onClickCancelExit}
                    onConfirm={this.onClickConfirmExit}
                />
                <GuidelineModal
                    show={this.state.showGuidelineModal}
                    onClose={this.closeGuidelineModal}
                />
            </PageAuthCheck>
        )
    }

}

class CreateForm extends React.Component {
    showText = () => {
        if (this.props.isSubmitting) return ""
        else return "Submit"
    }

    render() {

        return (
                <Form className='my-5 ml-5' id="create-form">
                    <div className='row'>
                        <div className={`${styles.nopadding}`}>
                            Display Group <br/> Name
                        </div>
                        <div className={`col-11 `}>
                            <div className='form-group'>
                                <Field name='groupName' type="text"
                                    className={`form-control`}
                                    value={this.props.groupName} disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mt-2'>
                        <button type='button' className={`btn btn-link ${styles.button_style}`} onClick={this.props.showGuidelineModal}>
                            Guideline
                        </button>
                    </div>
                    <DragDropContext onDragEnd={this.props.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {this.props.data? this.props.data.map((item, index) => {
                                            item.priority = index + 1;
                                            return (<div>
                                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div className={`${styles.row_form} row`}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            {this.props.frontendCategoryOptions? (<div className="row w-100">
                                                                <div className={`block ${styles.row_mid_form}`}>
                                                                    <CDGDragNDropItem
                                                                        {...this.props.formikProps}
                                                                        {...provided}
                                                                        allData={this.props.data}
                                                                        data={item}
                                                                        isThematicActive={this.props.isThematicActive}
                                                                        optionData={this.props.frontendCategoryOptions}
                                                                        initialValues={this.props.initialValues}
                                                                        isBackup={false}
                                                                        hidePlusAndDeleteButton={true}
                                                                    />
                                                                </div>
                                                            </div>) : <div>Loading...</div>}
                                                        </div>
                                                    )}
                                                </Draggable>
                                                
                                                </div>
                                            )
                                        }): () =>{
                                            return (
                                                <div>Loading...</div>
                                            )
                                        }
                                        }
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        <div className={`row mt-5 ${styles.back_next_button_position}`}>
                            <div className={`col-1 ${styles.nopadding}`}>
                                <button className={`btn btn-primary ${styles.submit_button_style}`} disabled={this.props.isSubmitting}> 
                                    {this.props.isSubmitting && <Spinner animation="border" size="sm" role="status"/>}
                                    {this.showText()}
                                </button>
                            </div>
                        </div>
                </Form>
        )
    }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({ message, type }) => displayAlert({ message, type })
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCDGPageDirectoryCLP)

