import React from "react";

class SelectFormik extends React.Component {
    makeOption = option => {
        if(option.header){
            return (
                <option disabled value="">{option.label}</option>
            )
        }
        return (
            <option key={option.value} value={option.value}>
                {option.label}
            </option>
        );
    };

    handleChange = event => {
        this.props.onChange(event);
        event.preventDefault();
    };

    render() {
        const { name, options, className, value, disabled } = this.props;
        return (
            <div>
                <select
                    className={className}
                    name={name}
                    onChange={this.handleChange}
                    value={value}
                    disabled={disabled}
                >
                    {options.map(this.makeOption)}
                </select>
            </div>
        );
    }
}

export default SelectFormik;
