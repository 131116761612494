import React from 'react';
import {Typeahead} from "react-bootstrap-typeahead";
import "./Filter.css";
import {Form, Spinner} from "react-bootstrap";
import {DateRangePicker} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import { useRef } from 'react';
import moment from "moment";

const ProductListFilter = props => {
    const {filterValues, onFilterChange, filterOptions, onSearchHandler, onFilterReset, isFetching, type} = props;

    const productNameFilterRef = useRef(null);

    const onSearchButtonClickHandler = () => {
        const {products, universalBarcodes, categories, labels, productTags, status, itemStates, saleStates, dateRange, users} = filterValues;
        const nullOrEmpty = val => val === null || val === undefined || val.length === 0;
        const determineUniversalBarcodeParam = () => {
            if (universalBarcodes === null || universalBarcodes === undefined) return null
            const [val] = universalBarcodes
            if(val === 'ADA') return true;
            if(val === 'TIDAK ADA') return false;
            return null;
        }
        const determineStatusParam = () => {
            if (status === null || status === undefined) return null;
            const [val] = status;
            if (val === 'ACTIVE') return true;
            if (val === 'INACTIVE') return false;
            return null;
        }
        const determineDraftStatus = () => {
            if (!status || status[0]?.value === null) {
                return ({
                    isApproved: null,
                    isDeleted: null
                });
            }
            const [val] = status;
            if (!val) {
                return ({
                    isApproved: null,
                    isDeleted: null
                });
            }
            if(typeof val.value === "boolean"){
                return {
                    isApproved: val.value,
                    isDeleted: val.value ? null : false
                };
            }
            return {
                isApproved: null,
                isDeleted: true
            };
        }
        const determineIsInPriceGroup = () => {
            if (saleStates === null || saleStates === undefined) return null;
            const [val] = saleStates;
            if (val === 'ADA DI PRICE GROUP, ADA DI POL' || val === 'ADA DI PRICE GROUP, TIDAK ADA DI POL') return true;
            if (val === 'TIDAK ADA DI PRICE GROUP, ADA DI POL' || val === 'TIDAK ADA DI PRICE GROUP, TIDAK ADA DI POL') return false;
            return null;
        }
        const determineIsInPOL = () => {
            if (saleStates === null || saleStates === undefined) return null;
            const [val] = saleStates;
            if (val === 'ADA DI PRICE GROUP, ADA DI POL' || val === 'TIDAK ADA DI PRICE GROUP, ADA DI POL') return true;
            if (val === 'ADA DI PRICE GROUP, TIDAK ADA DI POL' || val === 'TIDAK ADA DI PRICE GROUP, TIDAK ADA DI POL') return false;
            return null;
        }
        const getDateRange = () => {
            if (dateRange === null || dateRange === undefined || dateRange.length === 0) return null;
            return {
                startDate: dateRange[0].toISOString(),
                endDate: dateRange[1].toISOString()
            };
        }

        let params = {
            skuIds: nullOrEmpty(products) ? null : products.map(p => p.skuId).join(','),
            productName: productNameFilterRef.current.value,
            hasUniversalBarcode: determineUniversalBarcodeParam(),
            categoryIds: nullOrEmpty(categories) ? null : categories.map(c => c.id).join(','),
            labels: nullOrEmpty(labels) ? null : labels.join(','),
            productTagIds: nullOrEmpty(productTags) ? null : productTags.map(pt => pt.id).join(','),
            isActive: determineStatusParam(),
            states: nullOrEmpty(itemStates) ? null : itemStates.join(','),
            isInPriceGroup: determineIsInPriceGroup(),
            isInPOL: determineIsInPOL(),
            createdAtStart: getDateRange() ? moment(getDateRange()?.startDate).utc(false).toISOString(false) : null,
            createdAtEnd: getDateRange() ? moment(getDateRange()?.endDate).utc(false).toISOString(false) : null,
            userIds: nullOrEmpty(users) ? null : users.map( u => u.userId).join(','),
        }

        if (type === "DRAFT") {
            const {isApproved, isDeleted} = determineDraftStatus();
                params = {
                    ...params,
                    isApproved,
                    isDeleted
                }

        }
        onSearchHandler(params);
    }

    const getProductDropdownTypeaheadOption = () => {
        return filterOptions.products?.map(p => ({
            ...p,
            label: `${p.skuId} - ${p.name} - ${p.sellingUnit}`
        })) ?? [];
    }

    const getProductDropdownValues = () => {
        if (type === 'DRAFT') {
            return filterOptions.productDrafts?.map(p => ({
                ...p,
                label: `${p.skuId} - ${p.name} - ${p.sellingUnit}`
            })) ?? [];
        } else {
            return filterOptions.products?.map(p => ({
                ...p,
                label: `${p.skuId} - ${p.name} - ${p.sellingUnit}`
            })) ?? [];
        }
    }

    const getCategoriesTypeaheadOption = () => {
        return filterOptions.categories?.map(c => ({
            ...c,
            label: c.name
        })) ?? [];
    }

    const getProductTagsTypeaheadOption = () => {
        return filterOptions.productTags?.map(pt => ({
            ...pt,
            label: pt.name
        })) ?? [];
    }

    const getUsersTypeAheadOption = () => {
        return filterOptions.users?.map(
            user =>
                ({
                    ...user,
                    label: `${user.userId} - ${user.name ? user.name : user.username ? user.username : '-'}`
                })) ?? [];
    }

    return <div className='ml-3'>
        <div className='row'>
            <div className='col-4'>
                <Typeahead
                    multiple
                    id='product-filter'
                    placeholder='Cari Produk Spesifik'
                    options={getProductDropdownValues()}
                    name='products'
                    selected={filterValues.products ?? []}
                    onChange={val => onFilterChange('products', val)}
                />
            </div>
            <div className='col-4'>
                <Form.Control
                    ref={productNameFilterRef}
                    type="text"
                    placeholder='Cari Nama Tertentu'
                />
            </div>
            <div className='col-4'>
                <Typeahead  
                    id='universal-barcode'
                    placeholder='Universal Barcode'
                    options={filterOptions.universalBarcodes ?? []}
                    name='barcodes'
                    selected={filterValues.universalBarcodes}
                    onChange={val => onFilterChange('universalBarcodes', val)} />
            </div>
        </div>
        <div className='row'>
            <div className='col-4'>
                <Typeahead
                    multiple
                    id='category-filter'
                    placeholder='Kategori'
                    options={getCategoriesTypeaheadOption()}
                    name='categoryIds'
                    selected={filterValues.categories ?? []}
                    onChange={val => onFilterChange('categories', val)}
                />
            </div>
            <div className='col-4'>
                <Typeahead
                    multiple
                    id='product-tag-filter'
                    placeholder='Product Tag'
                    options={getProductTagsTypeaheadOption()}
                    name='productTagIds'
                    selected={filterValues.productTags ?? []}
                    onChange={val => onFilterChange('productTags', val)}
                />
            </div>
            <div className='col-4'>
                <DateRangePicker
                     showOneCalendar
                     placeholder='Waktu Dibuat'
                     defaultValue={null}
                     onChange={(dateRange) => onFilterChange('dateRange', dateRange)}
                     value={filterValues.dateRange}
                     format={"yyyy-MM-dd HH:mm:ss"}
                />
            </div>
        </div>
        <div className='row'>
            <div className='col-4'>
                <Typeahead
                    id='status-filter'
                    placeholder='Status'
                    options={type !== "DRAFT" ? filterOptions.status ?? [] : filterOptions.approvalStatus }
                    name='status'
                    selected={filterValues.status}
                    onChange={val => onFilterChange('status', val)}
                />
            </div>
            {type === "DRAFT" ? <></> :
                <div className='col-4'>
                    <Typeahead
                        multiple
                        id='label-filter'
                        placeholder='Label'
                        options={filterOptions.labels ?? []}
                        name='labelIds'
                        selected={filterValues.labels}
                        onChange={val => onFilterChange('labels', val)}
                    />
                </div>
            }

            <div className='col-4'>
                <Typeahead
                    multiple
                    id='state-filter'
                    placeholder='Item State'
                    options={filterOptions.itemStates ?? []}
                    name='states'
                    selected={filterValues.itemStates}
                    onChange={val => onFilterChange('itemStates', val)}
                />
            </div>
            {
                type === "DRAFT" ?
                    <div className='col-4'>
                        <Typeahead
                            multiple
                            id='user-filter'
                            placeholder='Requestor'
                            options={getUsersTypeAheadOption()}
                            name='users'
                            selected={filterValues.users ?? []}
                            onChange={val => onFilterChange('users', val)}
                        />
                    </div> : <></>
            }
        </div>
        <div className='row'>
            {type !== 'DRAFT' ?
                (<div className='col-4'>
                    <Typeahead
                        id='sale-state-filter'
                        placeholder='State Penjualan'
                        options={filterOptions.saleStates ?? []}
                        name='sale-states'
                        selected={filterValues.saleStates}
                        onChange={val => onFilterChange('saleStates', val)}
                    />
                </div>) : <></>}
            <div className='col-1'>
                <button className='search-button c-button' disabled={isFetching} onClick={onSearchButtonClickHandler}>
                    {isFetching && <Spinner animation="border" variant="dark"
                                            size="sm"/>}
                    Cari
                </button>
            </div>
            <div className='col-1'>
                <button className='reset-button c-button' disabled={isFetching} onClick={onFilterReset}>
                    {isFetching && <Spinner animation="border" variant="dark"
                                            size="sm"/>}
                    Reset
                </button>
            </div>
        </div>
    </div>
}

export default ProductListFilter;