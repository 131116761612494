import customAxios from "./axios";
import {
    FRONTEND_CATEGORY_SORTINGS_V1,
} from "../constants/api";

export function search(params) {
    return customAxios.get(FRONTEND_CATEGORY_SORTINGS_V1, {params})
}

export function searchIntools(params) {
    return customAxios.get(FRONTEND_CATEGORY_SORTINGS_V1 + '/' + 'category-intools', {params})
}

export function updateBulk(data) {
    return customAxios.put(FRONTEND_CATEGORY_SORTINGS_V1, data)
}

export function create(data){
    return customAxios.post(FRONTEND_CATEGORY_SORTINGS_V1, data)
}

export function updateSingle(id, data){
    return customAxios.put(FRONTEND_CATEGORY_SORTINGS_V1 + '/' + id, data)
}