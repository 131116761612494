import React from "react";
import { Button, Row } from "react-bootstrap";
import { Field, Form, Formik } from 'formik';
import styles from './EditBannerModal.module.css';
import { Modal, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { displayAlert } from '../../redux/actions/notif';
import * as apiReferral from "../../apis/referral";

const jpgMaxSizeUploadError = 'JPG-MAX-SIZE-UPLOAD-ERROR';
const webpMaxSizeUploadError = 'WEBP-MAX-SIZE-UPLOAD-ERROR';

class EditBannerModal extends React.PureComponent {
    render(){
        const{ show, onClose } = this.props;
        let title = 'Edit Banner';
        return <Modal size="lg" centered show={show} onHide={onClose} dialogClassName={styles.modal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    <p>{title}</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Body
                    {...this.props}
                    displayAlert={this.props.displayAlert}
                    onHide={this.props.onClose}
                />
            </Modal.Body>
        </Modal>
    }
}

class Body extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            bannerId: this.props.bannerId,
            banner: null,
            isSubmitting: false,
            isErrorJpgImageUrl: false,
            isErrorWebpImageUrl: false
        }
    }

    componentDidMount = async () => {
        this.setState({
            isLoading: true
        });

        try{
            const { bannerId } = this.props;
            
            const responseBanner = (await apiReferral.getReferralBannerById({id: bannerId}));
            this.setState({
                banner: responseBanner.data.data
            });
        } catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error'});
        }

        this.setState({
            isLoading: false
        });
    }

    handleSubmit = async (values) => {
        this.setState({
            isSubmitting: true
        })
        let data = this.mapItemToRequestBody(values);
        try {
            await apiReferral.updateReferralBanner({ banner: data});
            this.props.displayAlert({ message: 'Success', type: 'success'});
            this.props.onClose();
        } catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error'});
            const errorCode = e.code;
            if (errorCode === jpgMaxSizeUploadError) this.setState({
                isErrorJpgImageUrl: true
            });
            if (errorCode === webpMaxSizeUploadError) this.setState({
                isErrorWebpImageUrl: true
            });
        }
        this.setState({
            isSubmitting: false
        })
    }

    mapItemToRequestBody = (item) => {
        let result = {
            webpUrl: item.imageWebp,
            imgUrl: item.imageJpg,
            id: item.id
        }

        return result;
    }

    getInitialValues = () => {
        return {
            id: this.state.banner?.id ?? '',
            imageJpg: this.state.banner?.imgUrl??'',
            imageWebp: this.state.banner?.webpUrl??'',
            currentImageJpg: this.state.banner?.imgUrl??'',
            currentImageWebp: this.state.banner?.webpUrl??'',
        }
    }

    render(){
        const {isSubmitting} = this.state;
        return <div className="body-div">
            <Formik
                initialValues={this.getInitialValues()}
                enableReinitialize={true}
                onSubmit={this.handleSubmit}
            >
                {({values}) => 
                    <Form>
                        <Row className='form-group'>
                            <div className='col-2'>Name</div>
                            <div className='col-10'>
                                <Field name='id' className={'form-control'} type='text' disabled={true}/>
                            </div>
                        </Row>

                        <Row className='form-group'>
                            <div className='col-2'>
                                <div>Image (.jpg)</div>
                                <small className={ this.state.isErrorJpgImageUrl && 'text-danger' }>max size limit 128 KB</small>
                            </div>
                            <div className='col-2'>
                                <a target="_blank"
                                    href={values.currentImageJpg}>
                                    <Button className="btn btn-primary btn-md" disabled={values.currentImageJpg == ''}>View Current</Button>
                                </a>
                            </div>
                            <div className='col-8'>
                                <Field name='imageJpg' className={ this.state.isErrorJpgImageUrl ? 'form-control is-invalid' : 'form-control'} type='text'/>
                            </div>
                        </Row>

                        <Row className='form-group'>
                            <div className='col-2'>
                                <div>Image (.webp)</div>
                                <small className={ this.state.isErrorWebpImageUrl && 'text-danger' }>max size limit 128 KB</small>
                            </div>
                            <div className='col-2'>
                                <a target="_blank"
                                    href={values.currentImageWebp}>
                                    <Button className="btn btn-primary btn-md" disabled={values.currentImageWebp == ''}>View Current</Button>
                                </a>
                            </div>
                            <div className='col-8'>
                                <Field name='imageWebp' className={ this.state.isErrorWebpImageUrl ? 'form-control is-invalid' : 'form-control'} type='text'/>
                            </div>
                        </Row>

                        <Row className='form-group'>
                            <div className='col-3'>
                                <a target="_blank"
                                    href="https://docs.google.com/document/d/1aNvg9MX1fDUTHgycP1WQT0s65O7A_i_dZbDX8uzy7QY/edit?usp=sharing">
                                    How to upload an image ?
                                </a>
                            </div>
                        </Row>

                        <Row className="form-group">
                            <div className='col text-center'>
                                <Button type="submit" className="btn btn-primary btn-md" disabled={isSubmitting}>{isSubmitting && 
                                <Spinner className="mx-1" animation="border" variant="light" size="sm"/>}Simpan</Button>
                            </div>
                        </Row>
                    </Form>
                }
            </Formik>
        </div> 
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({ message, type }) => displayAlert({ message, type })
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBannerModal)