import React, { useEffect, useState } from 'react';
import Table from "react-bootstrap/Table";
import ContentWithSideContent from "../Component/ContentWithSideContent";
import * as workplaceDepartmentApi from "../../../../apis/workplaceDepartment";
import { Modal } from "react-bootstrap";

const RoleList = (props) => {
    const { workplaceDepartments, warehouses, displayAlert } = props;
    const [warehouseMap, setWarehouseMap] = useState(new Map());
    const [wdTypeToWorkplaceDepartment, setWdTypeToWorkplaceDepartment] = useState(new Map());

    const [willInitializePage, setWillInitializePage] = useState(true);
    const [showConfigurationTable, setShowConfigurationTable] = useState(false);
    const [showRelationmodal, setShowRelationModal] = useState(false);

    const [selectedWdType, setSelectedWdType] = useState("");
    const [selectedWorkplaceDepartmentId, setSelectedWorkplaceDepartmentId] = useState(-1);

    const [relationFormData, setRelationFormData] = useState(new Map());
    const [workplaceDepartmentFormData, setWorkplaceDepartmentFormData] = useState(new Map());
    const [relationWarehouseIds, setRelationWarehouseIds] = useState(new Map());

    useEffect(() => {   
        const initializePage = () => {
            if (workplaceDepartments.length > 0 && warehouses.length > 0) {
                buildWorkplaceDepartmentMap();
                buildWarehouseMap();
                setWillInitializePage(false);
            }
        }

        const buildWorkplaceDepartmentMap = () => {
            let newMap = new Map();

            workplaceDepartments.forEach((wd) => {
                let workplaceDepartmentValues = newMap.get(wd.type);
                if (!workplaceDepartmentValues || workplaceDepartmentValues.length === 0) {
                    workplaceDepartmentValues = [];
                }
                workplaceDepartmentValues.push(wd);
                newMap.set(wd.type, workplaceDepartmentValues);
            });
            setWdTypeToWorkplaceDepartment(newMap);
        }

        const buildWarehouseMap = () => {
            const newMap = new Map();
            warehouses.forEach(
                (wh) => {
                    newMap.set(wh.id, wh);
                }
            );
            setWarehouseMap(newMap);
        }

        if (willInitializePage === true) {
            initializePage();
        }
    });

    const GenerateMain = () => {
        return (
            <div className={"d-flex justify-content-center w-75"}>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>List</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            wdTypeToWorkplaceDepartment && wdTypeToWorkplaceDepartment.size > 0 ?
                                generateWorkplaceDepartmentTable() : <></>
                        }
                    </tbody>
                </Table>
            </div>
        );
    }

    const generateWorkplaceDepartmentTable = () => {
        let tableContents = [];
        wdTypeToWorkplaceDepartment.forEach((value, key, map) => {
            let workplaceDepartmentNames = value.map(
                (wd) => {
                    return wd.value;
                }
            );
            let content =
                <tr key={`${key}#show`}>
                    <td>{key}</td>
                    <td>{workplaceDepartmentNames ? workplaceDepartmentNames.join(", ") : "-"}</td>
                    <td className={"hover-cursor"} onClick={() => { configurationTableTriggerEvent(key) }}>
                        <div>Configure</div>
                    </td>
                </tr>
            tableContents.push(content);
        });
        return tableContents;
    }

    const configurationTableTriggerEvent = async (key) => {
        setShowConfigurationTable(true);
        const relationResponse = await workplaceDepartmentApi.getWorkplaceDepartmentRelationByType(key);
        buildRelationMap(relationResponse.data.data);
        buildFormData(key);
        setSelectedWdType(key);
    }

    const buildRelationMap = (relations) => {
        let newMap = new Map();
        relations.forEach((relation) => {
            let wdId = relation.workplaceDepartmentId;
            let values = newMap.get(wdId);
            if (!values) {
                values = new Map();
            }
            values.set(relation.warehouseId, relation.warehouseId);
            newMap.set(wdId, values);
        });
        setRelationFormData(newMap);
    }

    const buildFormData = (type) => {
        let newMap = new Map();
        const workplaceDepartments = [...wdTypeToWorkplaceDepartment.get(type)]
        workplaceDepartments.forEach(
            (wd) => {
                newMap.set(wd.id, {...wd});
            }
        );
        setWorkplaceDepartmentFormData(newMap);
    }

    const GenerateSide = () => {
        return (
            <div>
                {showRelationmodal ? 
                    <WarehouseList/> : 
                    <ConfigurationTable 
                        workplaceDepartmentFormData={workplaceDepartmentFormData}
                        selectedWdType={selectedWdType} 
                        onChangeWorkplaceDepartmentName={onChangeWorkplaceDepartmentName} 
                        onClickStatusCheckbox={onClickStatusCheckbox} 
                        generateRelationData={generateRelationData} 
                        onClickConfigureRelationButton={onClickConfigureRelationButton}
                        addNewWorkplaceDepartment={addNewWorkplaceDepartment}
                        cancelChange={cancelChange}
                        submitUpdateWorkplaceDepartments={submitUpdateWorkplaceDepartments}
                    />}
            </div>
        );
    }

    const onChangeWorkplaceDepartmentName = (key, value) => {
        let newMap = new Map(workplaceDepartmentFormData);
        let workplaceDepartment = newMap.get(key);
        workplaceDepartment.value = value.target.value;
        newMap.set(key, workplaceDepartment);
        setWorkplaceDepartmentFormData(newMap);
    }

    const addNewWorkplaceDepartment = () => {
        let newMap = new Map(workplaceDepartmentFormData);
        let workplaceDepartment = {
            id: null,
            type: selectedWdType,
            value: "",
            active: false
        }
        newMap.set(null, workplaceDepartment);
        setWorkplaceDepartmentFormData(newMap);
    }

    const generateRelationData = (key) => {
        let relations = relationFormData.get(key);
        let warehouseNameDisplay = "";
        if (relations && relations.size > 0) {
            relations.forEach((relation) => {
                const warehouse = warehouseMap.get(relation);
                warehouseNameDisplay += `${warehouse.name}, `;
            });
            warehouseNameDisplay = warehouseNameDisplay.substring(0, warehouseNameDisplay.length - 2);
        } else {
            warehouseNameDisplay = "-";
        }
        return warehouseNameDisplay;
    }

    const onClickStatusCheckbox = (key) => {
        let newMap = new Map(workplaceDepartmentFormData);
        let workplaceDepartment = newMap.get(key);
        let active = workplaceDepartment.active;
        workplaceDepartment.active = !active;
        newMap.set(key, workplaceDepartment);
        setWorkplaceDepartmentFormData(newMap);
    }

    const cancelChange = () => {
        buildFormData(selectedWdType);
    }

    const submitUpdateWorkplaceDepartments = async () => {
        let requestDatas = {
            workplaceDepartments: [],
        };
        workplaceDepartmentFormData.forEach((value, key) => {
            let workplaceDepartment = value;
            requestDatas.workplaceDepartments.push({ ...workplaceDepartment });
        })

        try {
            let response = await workplaceDepartmentApi.updateWorkplaceDepartments({
                workplaceDepartments: requestDatas.workplaceDepartments,
            });
            if (response.status === 200) {
                displayAlert({ message: `Update Success`, type: 'success' });

                response.data.data.forEach(data => {
                    let found = false;

                    wdTypeToWorkplaceDepartment.get(selectedWdType).forEach(workplaceDepartment => {
                        if (workplaceDepartment.id == data.id) {
                            found = true;
                            workplaceDepartment = data;
                        }
                    })

                    if (!found) {
                        wdTypeToWorkplaceDepartment.get(selectedWdType).push(data);
                    }
                });

                await configurationTableTriggerEvent(selectedWdType);

                let newMap = new Map();

                response.data.data.forEach(data => {
                    newMap.set(data.id, data);
                })

                setWorkplaceDepartmentFormData(newMap);
            }
        } catch (e) {
            displayAlert({ message: `${e.message}`, type: 'error' });
        }
    }

    const onClickConfigureRelationButton = (id) => {
        setShowRelationModal(true);
        setSelectedWorkplaceDepartmentId(id);
        const warehouseIdRelation = relationFormData.get(id);
        setRelationWarehouseIds(new Map(warehouseIdRelation));
    }

    const WarehouseList = () => {
        let datas = [];
        warehouses.sort((a, b) => a.id - b.id)
            .forEach(
                (wh) => {
                    let data =
                        <div key={wh.id} className={"d-flex"}>
                            <input type={"checkbox"} className={""} role={"switch"} checked={isWarehouseChecked(wh.id)} onClick={() => { toggleWarehouseCheckbox(wh.id) }} />
                            <div className={"w-75 ml-2"}>{`${wh.id} - ${wh.name}`}</div>
                        </div>
                    datas.push(data);
                });

        return (
            <div className={"w-100 overflow-auto"}>
                <div className={"border h-25 mb-2"}>
                    <div className={"btn btn-danger mr-2"} onClick={() => { cancelEditedRelations() }}>
                        Cancel
                    </div>
                    <div className={"btn btn-primary"} onClick={() => { saveEditedRelations() }}>
                        Save
                    </div>
                </div>
                {datas}
            </div>
        );
    }

    const isWarehouseChecked = (id) => {
        let relations = relationWarehouseIds.get(id);
        if (relations) {
            return true;
        }
        return false;
    }

    const toggleWarehouseCheckbox = (whId) => {
        let warehouseRelations = new Map(relationWarehouseIds);
        let warehouse = warehouseRelations.get(whId);
        if (!warehouse) {
            warehouseRelations.set(whId, whId);
        } else if (warehouse) {
            warehouseRelations.delete(whId);
        }
        setRelationWarehouseIds(warehouseRelations);
    }

    const saveEditedRelations = async () => {
        let relations = [];
        relationWarehouseIds.forEach((value, key) => {
            relations.push(value);
        });

        let response = null;
        try {
            response = await workplaceDepartmentApi.updateWorkpaceDepartmentRelations(selectedWorkplaceDepartmentId, relations);
            if (response.status === 200) {
                syncRelationData(response.data.data);
                displayAlert({ message: `Success Update Relation Data`, type: "success" });
                setShowRelationModal(false);
            }
        } catch (e) {
            displayAlert({ message: `${e.message}`, type: "error" })
        }

    }

    const cancelEditedRelations = () => {
        setShowRelationModal(false);
        setRelationWarehouseIds(new Map());
    }

    const hideSideContent = () => {
        setShowConfigurationTable(false);
        setShowRelationModal(false);
    }

    const syncRelationData = (response) => {
        let relationMap = new Map(relationFormData);
        let warehouseMap = new Map();
        response.forEach((data) => {
            warehouseMap.set(data, data);
        })
        relationMap.set(selectedWorkplaceDepartmentId, warehouseMap);
        setRelationFormData(relationMap);
        setRelationWarehouseIds(warehouseMap);
    }
    return (
        <ContentWithSideContent Main={<GenerateMain/>} Side={<GenerateSide/>} show={showConfigurationTable} hideSideContent={hideSideContent} />
    );
}

const ConfigurationTable = (props) => {
    const {workplaceDepartmentFormData, selectedWdType, onChangeWorkplaceDepartmentName, onClickStatusCheckbox, generateRelationData, onClickConfigureRelationButton, addNewWorkplaceDepartment, cancelChange, submitUpdateWorkplaceDepartments} = props;

    let hasNewRecord = false;

    let workplaceDepartments = [];
    workplaceDepartmentFormData.forEach(
        (value) => {
            workplaceDepartments.push(value);
            if (value.id == null) {
                hasNewRecord = true;
            }
        }
    );


    return (
        <Table className={""} striped bordered>
            <thead>
                <tr>
                    <th colSpan={4}>
                        Configure {selectedWdType}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>List</td>
                    <td>Status</td>
                    <td>Warehouse</td>
                    <td>Action</td>
                </tr>
                {
                    workplaceDepartments.map(
                        (workplaceDepartment) => {
                            return (
                                <tr>
                                    <td>
                                        <input className={"form-control"} value={workplaceDepartment.value} onChange={
                                            (value) => {
                                                onChangeWorkplaceDepartmentName(workplaceDepartment.id, value);
                                            }
                                        } id={`${workplaceDepartment.id}`} autoFocus />
                                    </td>
                                    <td>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input form-switch" type="checkbox" role="switch" checked={workplaceDepartment.active ? workplaceDepartment.active : false} onChange={() => { onClickStatusCheckbox(workplaceDepartment.id) }} />
                                        </div>
                                    </td>
                                    <td>{generateRelationData(workplaceDepartment.id)}</td>
                                    <td><button onClick={() => { onClickConfigureRelationButton(workplaceDepartment.id) }} disabled={workplaceDepartment.id == null}>Configure</button></td>
                                </tr>
                            );
                        }
                    )
                }
                <tr>
                    <td colSpan={2} 
                        onClick={() => { !hasNewRecord ? addNewWorkplaceDepartment() : cancelChange() }} role='button'
                    >
                        {!hasNewRecord ? 'Add' : 'Cancel'}
                    </td>
                    <td colSpan={2} onClick={() => { submitUpdateWorkplaceDepartments() }} role='button'>Save</td>
                </tr>
            </tbody>
        </Table>
    );
}
export default RoleList;