import AuthCheck from "./AuthCheck";
import React from "react";
import PropTypes from "prop-types";

/**
 * Use this to show/hide menu from a user
 * Behaviour:
 * - cached (get data from redux)
 * - no message (hide the children if user is not authorized
 * @param props
 * @returns {*}
 * @constructor
 */
const MenuAuthCheck = (props) => {
    const {children, ...rest} = props
    return <AuthCheck cached noMessage {...rest}>
        {children}
    </AuthCheck>
}

export default MenuAuthCheck

MenuAuthCheck.propTypes = {
    allowedRoles: PropTypes.array,
    allowedScopes: PropTypes.array,
}