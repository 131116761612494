import React, {memo, useEffect, useState} from "react";
import PurchaseOrderCard from "./PurchaseOrderCard";
import InternalShipmentCard from "./InternalShipmentCard";
import ItemCard from "./ItemCard";
import ItemTable from "./ItemTable";
import {Form, Formik, useFormikContext} from "formik";
import {TOP} from "../../../../constants/termsOfPayment";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {displayAlert} from "../../../../redux/actions/notif";
import * as utils from "../../utils/utils";
import * as apiPurchaseOrder from '../../../../apis/purchaseOrders';
import Button from "react-bootstrap/Button";
import * as apiInternalShipment from "../../../../apis/internalShipments";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {PRODUCT} from "../../../../constants/itemType";
import {calculatePredictedQuantity, setUOMSuggestion} from "../../utils/utils";
import {PURCHASE_ORDER_STATUS, PURCHASE_ORDER_TYPE} from "../../../../constants/purchaseOrder";
import {UPDATE_PURCHASE_ORDER} from "../../../../constants/scope";
import {ADMIN} from "../../../../constants/role";


function SinglePOCreateOrUpdate(props) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const { id, products, purchasingProducts, onEditSubmit, suppliers, warehouses, onApprove } = props ?? useParams();
    const user = useSelector(state => state.auth.user);

    const [initialValues, setInitialValues] = useState({
        purchaseOrder: {
            otherCost: 0,
        },
        internalShipment: {
            warehouseId: '',
            shipmentMethod: '',
            vehicleNumbers: '',
            address: ''
        },
        items: {},
        isUpdate: false,
        readOnly: false,
    });

    useEffect(() => {
        const prefixTitle = id ? `Detail Purchase Order ${id}` : "Create Purchase Order";
        document.title = `${prefixTitle} - Segari Internal`;

        async function handleResponse (response) {
            let purchaseOrderData = response.purchaseOrder.data.data;
            const internalShipmentData = response.internalShipment.data.data;
            const internalShipment = Array.isArray(internalShipmentData) && internalShipmentData.length > 0
                ?
                {
                    ...internalShipmentData[0],
                    warehouseId: internalShipmentData[0].warehouse.id,
                    expectedArrivalAt: internalShipmentData[0].expectedArrivalAt
                        ? moment(internalShipmentData[0].expectedArrivalAt).toDate()
                        : null,
                }
                : null;
            const purchaseOrderItemData = purchaseOrderData.purchaseOrderItem ?? [];

            const itemList = await Promise.all(purchaseOrderItemData.map(async (e) => {
                const exists = e.itemType === PRODUCT
                    ? products.find(ex => ex.skuId === e.sku)
                    : purchasingProducts.find(ex => ex.purchasingSku === e.sku);

                if (!exists) return null;
                const key = `${e.itemType}|${exists.id}`;

                let value = {
                    sku: e.sku,
                    name: e.name,
                    itemType: e.itemType,
                    unit: e.unit,
                    pricePerUnit: e.pricePerUnit,
                    quantity: e.customPurchasingUnit ? null : e.quantity,
                    predictedQuantity: e.customPurchasingUnit ? e.quantity : null,
                    isCustom: e.customPurchasingUnit,
                    customQuantity: e.customQuantity > 0 ? e.customQuantity : null,
                    customPurchasingUnit: e.customPurchasingUnit,
                    isGramationPerQuantityActive: e.gramationPerQuantity != null,
                    gramationPerQuantity: e.gramationPerQuantity,
                    key
                }
                value = calculatePredictedQuantity(await setUOMSuggestion(value));
                return value;
            }));

            const items = itemList.reduce((prev, cur) => {
                if(!cur) return prev;
                return {
                    ...prev,
                    [cur.key]: cur,
                }
            }, {});

            const userIsAdmin = !!user.roles.find(e => e.id === ADMIN);
            const userCanUpdate = !!user.scopes.find(e => e.id === UPDATE_PURCHASE_ORDER)
            const readOnly = purchaseOrderData.isLocked || purchaseOrderData.status === PURCHASE_ORDER_STATUS.CANCELLED 
                || purchaseOrderData.status === PURCHASE_ORDER_STATUS.CLOSED || (!userIsAdmin && !userCanUpdate);

            let purchaseOrder = {
                ...purchaseOrderData,
                otherCost: purchaseOrderData.otherCost ?? 0,
                isUseVat: purchaseOrderData.useVat,
                supplierId: purchaseOrderData.supplier?.id,
            };
            
            if (purchaseOrder.termsOfPayment?.includes(TOP)) {
                purchaseOrder.TOPDays = purchaseOrder.termsOfPayment.split('_')?.[1];
                purchaseOrder.termsOfPayment = TOP;
            }

            setInitialValues({
                purchaseOrder,
                internalShipment,
                items,
                isUpdate: true,
                readOnly: readOnly,
            });
        }

        const fetch = async ({id}) => {
            try {
                const purchaseOrderPromise = apiPurchaseOrder.getPO({id});
                const internalShipmentPromise = apiInternalShipment.getInternalShipment({id})

                const [
                    purchaseOrderResponse,
                    internalShipmentResponse,
                ] = await Promise.all([
                    purchaseOrderPromise,
                    internalShipmentPromise,
                ]);

                await handleResponse({
                    purchaseOrder: purchaseOrderResponse,
                    internalShipment: internalShipmentResponse,
                })

            } catch (e) {
                dispatch(displayAlert({message: e.message, type: 'error'}));
            }
        }

        if (!!id){
            fetch({id}).then();
        }
    }, [id, user]);

    const onSubmit = async (values) => {
        try{
            const purchaseOrder = purchaseOrderPreparationData(values.purchaseOrder);
            const internalShipment = internalShipmentPreparationData(values.internalShipment);
            const items = itemPreparationData(values.items, values.purchaseOrder);
            const data = {purchaseOrder, internalShipment, items};
            const res = !!id
                ? await apiPurchaseOrder.updateV2({id, data})
                : await apiPurchaseOrder.createV2({ data });
            if (res?.data) {
                const message = values.isUpdate
                    ? `Success update Purchase Order! [${values.purchaseOrder.poNumber}]`
                    : `Success create Purchase Order! [PO Number from Supplier ${purchaseOrder.supplierId}]`
                dispatch(displayAlert({
                    message,
                    type: 'success'
                }))
                const delay = new Promise(resolve => setTimeout(resolve, 1000));
                await delay;
                // onEditSubmit when component loaded with props
                if (!props || Object.keys(props).length > 0) {
                    return onEditSubmit();
                }
                return history?.push(`/purchase-orders/list${location.search}`);
            } else {
                dispatch(displayAlert({ message: res?.message, type: 'error' }))
            }
        } catch (e) {
            dispatch(displayAlert({ message: e.message, type: 'error' }));
        }
    }

    return <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
    >
        <div>
            <Form>
                <PurchaseOrderCard suppliers={suppliers}/>
                <InternalShipmentCard warehouses={warehouses}/>
                <ItemCard products={products}/>
                <ItemTable/>
                <ApproveButton {...props} color={'#9A66EF'}/>
                <SubmitButton/>
            </Form>
        </div>
    </Formik>
}

const ApproveButton = (props) => {
    const { values } = useFormikContext();
    const { readOnly, isUpdate, purchaseOrder } = values;
    
    const { color } = props;

    return !readOnly && isUpdate && purchaseOrder.status === 'PENDING' ?
        <div>
            <Button
                style={{backgroundColor:color, borderColor:color, marginLeft:10}}
                className="float-right"
                onClick={() => approvePO(props)}
            >
                Approve
            </Button>
        </div> : <div />
}

const approvePO = async (props) => {
    const { id, user, onApprove, onEditSubmit } = props;
    let data = {
        status: 'APPROVED',
        id: id,
        userId: user.id,
    }
    const isSuccess = await onApprove(data);
    if (isSuccess) {
        return onEditSubmit()
    }
}

const SubmitButton = (props) => {
    const {values} = useFormikContext();
    const {readOnly, isUpdate} = values;

    return readOnly
        ? <div/>
        : <div>
            <Button
                className="float-right"
                type='submit'
                variant='success'
            >
                {isUpdate ? "Simpan" : "Buat PO"}
            </Button>
        </div>
}

const purchaseOrderPreparationData = (value) => {
    if (!value.domainType) {
        throw new Error('Fulfill For belum dipilih');
    }
    if (!value.supplierId) {
        throw new Error('Supplier Purchase Order belum dipilih');
    }
    if (value.termsOfPayment === TOP && isNaN(parseInt(value.TOPDays))) {
        throw new Error('Jumlah hari untuk pilihan Terms Of Payment harus di isi oleh angka');
    }
    if (!!value.otherCost && isNaN(parseInt(value.otherCost))){
        throw new Error('Isi Other Cost harus kosong / sebuah angka');
    }
    
    if (!value.purchaseOrderType){
        throw new Error(`Harap mengisi 'Purchase Order Type'`);
    }

    if(TOP !== value.termsOfPayment) {
        value.TOPDays = "";
    }

    return {
        domainType: value.domainType,
        supplierId: value.supplierId,
        isUseVat: value.isUseVat,
        otherCost: value.otherCost,
        notes: value.notes,
        termsOfPayment: value.termsOfPayment === TOP ? 'TOP_' + value.TOPDays.toString() : value.termsOfPayment,
        TOPDays: value.TOPDays,
        purchaseOrderType: value.purchaseOrderType,
    }
}

const internalShipmentPreparationData = (value) => {
    const ISO_DATE_TIME = 'YYYY-MM-DDTHH:mm';
    const {warehouseId, shipmentMethod, vehicleNumbers, address, expectedArrivalAt} = value;
    if (!warehouseId) throw new Error('Warehouse Internal Shipment Harus Diplih');
    if (!address) throw new Error('Alamat Warehouse Internal Shipment Harus Diisi');
    if (!expectedArrivalAt) throw new Error('ETA Internal Shipment Harus Diisi');

    return {
        warehouseId,
        shipmentMethod,
        vehicleNumbers,
        address,
        expectedArrivalAt: moment(expectedArrivalAt).format(ISO_DATE_TIME),
    }
}

const itemPreparationData = (values, po) => {
    if(!values || utils.isEmpty(values)) {
        throw new Error('Items tidak boleh kosong');
    }
    return Object.entries(values).map(([, value]) => {
        if (value.isCustom) {
            if(isNaN(parseFloat(value.predictedQuantity)) || parseFloat(value.predictedQuantity) <= 0){
                throw new Error(`Predicted Qty (custom) ${value.name} harus diisi angka dan diatas 0`);
            }

            if(isNaN(parseFloat(value.customQuantity)) || parseFloat(value.customQuantity) <= 0){
                throw new Error(`Qty (custom) ${value.name} harus diisi angka dan diatas 0`);
            }

            if (!value.customPurchasingUnit || !/^.*[a-zA-Z].*$/.test(value.customPurchasingUnit)) {
                throw new Error(`Unit (custom) ${value.name} harus diisi dengan minimal 1 karakter alfabet`);
            }
        } else {
            if(isNaN(parseFloat(value.quantity)) || parseFloat(value.quantity) <= 0){
                throw new Error(`Qty ${value.name} harus diisi angka dan diatas 0`);
            }
        }

        if (isNaN(parseFloat(value.pricePerUnit))) {
            throw new Error(`Price per unit ${value.name} harus diisi angka`);
        }
        if (po.purchaseOrderType === PURCHASE_ORDER_TYPE.FREEBIES) {
            if (parseFloat(value.pricePerUnit) !== 0){
                throw new Error(`'Price per Unit' ${value.name} harus 0 untuk Purchase Order Type 'Freebies'`);
            }
        } else if (po.purchaseOrderType === PURCHASE_ORDER_TYPE.REGULAR) {
            if (parseFloat(value.pricePerUnit) <= 0){
                throw new Error(`'Price per Unit' ${value.name} harus diatas 0 untuk Purchase Order Type 'Regular'`);
            }
        }

        return {
            sku: value.sku,
            quantity: value.isCustom ? value.predictedQuantity : value.quantity,
            customQuantity: value.isCustom ? value.customQuantity : null,
            customPurchasingUnit: value.isCustom ? value.customPurchasingUnit : null,
            itemType: value.itemType,
            pricePerUnit: value.pricePerUnit,
            gramationPerQuantity: value.gramationPerQuantity,
            isGramationPerQuantityActive: value.isGramationPerQuantityActive
        }
    });
}

export default memo(SinglePOCreateOrUpdate);