import React from 'react';
import { BsBox } from "react-icons/bs";
import { TfiPackage } from 'react-icons/tfi';
import { ReactComponent as ShipmentLogo } from './Assets/shipmentLogo.svg';

const ACTIVE_LINKS = {
    CATEGORIES: "/categories/categories",
    SHIPMENT: "/categories/shipment",
}

const LINKS_WITH_SUBHEADERS = [
    {
        label: "",
        links: [{ icon: <BsBox size={24}/>, link: ACTIVE_LINKS.CATEGORIES, label: 'Categories' }, { icon:<ShipmentLogo />, link: ACTIVE_LINKS.SHIPMENT, label: 'Shipment' }]
    },
]

export { ACTIVE_LINKS, LINKS_WITH_SUBHEADERS };