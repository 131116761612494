import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import styles from './MasterDetail.module.css'

class MasterDetail extends React.Component {
    render() {
      
        const { navbar, master, detail, showDetail, isSubmitting, responsive, noHeight } = this.props

        return <div className={`${styles.wrapper} ${!noHeight && styles.height} ${responsive && styles.responsive}`}>
            <div className={styles.navbar}>
                {navbar}
            </div>
            <div className={`${styles.mdWrapper} ${responsive && styles.responsive}`}>
                {isSubmitting ?
                    <React.Fragment>
                        <div className={styles.loadingIndicator}>
                            <Spinner animation="border" size="lg" /><span className="ml-2">Please wait...</span>
                        </div>
                    </React.Fragment> :
                    <React.Fragment>
                        <div className={styles.master}>
                            {master}
                        </div>
                        {showDetail && <div className={styles.detail}>
                            {detail}
                        </div>}
                    </React.Fragment>
                }
            </div>
        </div>
    }
}

MasterDetail.defaultProps = {
    responsive: false
}

export default MasterDetail