import React from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import { useTable, useFlexLayout } from "react-table";
import styles from "./CustomTable.module.scss";

const ListTitle = ({ title }) => {
  return (
    <Container fluid className="mt-3">
      <Row>
        <h4 className="text-bold">
          <u>{title}</u>
        </h4>
      </Row>
    </Container>
  );
};

const defaultColumn = {
  width: "auto",
  minWidth: 150,
  maxWidth: 200,
};

/**
 * Returns CustomTable from react-table
 *
 * @param {React.Ref} tableRef - The columns and accesor of table.
 * @param {Array} columns - The columns and accesor of table.
 * @param {Array} data - Data to be displayed, the key must be the same as the accessor.
 * @param {String} title - The title of the table
 * @param {number} height - The minimum height of table.
 * @return {React.Component}
 */

const CustomTable = ({ tableRef, columns, data, title, height = '600px' , isLoading = false}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
      defaultColumn,
      useFlexLayout,
    });

  return (
    <>
      {title && <ListTitle title={title} />}
      <div
        className={"table table-responsive"}
        style={{ overflowX: "scroll", maxHeight: height }}
      >
        <table
          ref={tableRef}
          className={styles.tableWrapper}
          style={{ width: "100%" }}
          {...getTableProps()}
        >
          <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                      },
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {isLoading 
            ? <SpinnerLoading columns={columns}/>  
            : rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
            })
          }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CustomTable;
function SpinnerLoading({columns}) {
  return <tr>
    <td colSpan={columns.length} style={{ textAlign: 'center', padding: '20px', height:'200px' }}>
      <Spinner animation="grow" role="status"/>
    </td>
  </tr>;
}

