import React from "react";


const InputLabel = (props)=>{
    let fontSize = getWidth(props.fontSize);
    return(
        <div>
            <label style={{fontSize: `${fontSize}`, margin:'2px'}}>
                {props.text}
            </label>
        </div>
    );
}

const getWidth = (width) =>{
    if(width) return `${width}em`;
    return '1em';
}


export default InputLabel;