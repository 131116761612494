export const DIVISION = "DIVISION";
export const TIER = "TIER";
export const ROLE = "ROLE";
export const DEPARTMENT = "DEPARTMENT";
export const TEAM = "TEAM";
export const POSITION = "POSITION";
export const RESIGN_REASON = "RESIGN_REASON";








