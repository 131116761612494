import React, {useEffect, useState} from "react";
import {displayAlert} from "../../../redux/actions/notif";
import {useDispatch} from "react-redux";
import PageAuthCheck from "../../../components/AuthCheck/PageAuthCheck";
import {CREATE_PURCHASE_ORDER_METHOD} from "../../../constants/purchaseOrder";
import MethodCard from "./SinglePO/MethodCard";
import {useParams} from "react-router-dom";
import SinglePOCreateOrUpdate from "./SinglePO/SinglePOCreateOrUpdate";
import * as apiPurchaseOrder from "../../../apis/purchaseOrders";
import {CREATE_PURCHASE_ORDER, LIST_PURCHASE_ORDER, UPDATE_PURCHASE_ORDER} from "../../../constants/scope";
import MultiplePOCreate from "./MultiplePO/MultiplePOCreate";

function CreateOrUpdatePurchaseOrderPage(props){
    const [createMethod, setCreateMethod] = useState(CREATE_PURCHASE_ORDER_METHOD.SINGLE);
    const [purchaseOrder, setPurchaseOrder] = useState(null);
    const { id } = useParams();
    const dispatch = useDispatch();

    const title = !!purchaseOrder
        ? `Details of Purchase Order – [${purchaseOrder.poNumber}]`
        : "Create Purchase Order";

    useEffect(() => {
        const prefixTitle = id ? `Detail Purchase Order ${id}` : "Create Purchase Order";
        document.title = `${prefixTitle} - Segari Internal`;

        function handleResponse(response) {
            setPurchaseOrder(response.purchaseOrder.data.data);
        }

        const fetch = async ({id}) => {
            try {
                const purchaseOrderPromise = apiPurchaseOrder.getPO({id});
                const [purchaseOrderResponse] = await Promise.all([
                    purchaseOrderPromise,
                ])

                handleResponse({
                    purchaseOrder: purchaseOrderResponse,
                })

            } catch (e) {
                dispatch(displayAlert({message: e.message, type: 'error'}));
            }
        }

        if (!!id){
            fetch({id}).then();
        }
    }, [id]);

    const allowedScopes = !id
        ? [CREATE_PURCHASE_ORDER]
        : [LIST_PURCHASE_ORDER, UPDATE_PURCHASE_ORDER];

    return <PageAuthCheck
        allowedScopes={allowedScopes}
    >
        <div>
            <Navbar
                title={title}
            />
            <div className="pl-3" style={{paddingBottom: '150px'}}>
                {
                    !id
                    && <MethodCard
                        createMethod={createMethod}
                        setCreateMethod={setCreateMethod}/>
                }

                {/*Single Form*/}
                {createMethod === CREATE_PURCHASE_ORDER_METHOD.SINGLE && <SinglePOCreateOrUpdate/>}
                {createMethod === CREATE_PURCHASE_ORDER_METHOD.MULTIPLE && <MultiplePOCreate />}
                
            </div>
        </div>
    </PageAuthCheck>
}


function Navbar(props) {
    return <div className="row ml-1 mb-2">
        <Title content={props.title}/>
    </div>
}

function Title(props) {
    return <div className="title-div">
        <h4>{props.content}</h4>
    </div>;
}

export default CreateOrUpdatePurchaseOrderPage;