export const sortByKey = (array, key) => {
    return array.sort(function(a, b){
        var x = a[key]; 
        var y = b[key];

        if(!x && !y){return 0}
        if(!y) return -1;
        if(!x) return 1; 

        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
}

/**
 * Perform swapping in similar manner as insertion sort would do, by moving
 * the element positioned in `startIndex` such after the routine, said element
 * can be found in `endIndex` of the `list`
 * @param {any[]} list 
 * @param {any} startIndex 0-based index
 * @param {any} endIndex 0-based index
 * @returns 
 */
export const insertionSwap = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};