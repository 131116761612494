import React from "react";
import LabelBox from "../../Components/LabelBox";
import BorderedBox from "../../Components/BorderedBox";
import UploadFileComponent from "./UploadFileComponent";

const BulkUploadContainer = (props) => {
    let bgColorStatus = "#E6E6E6";
    let statusText = "";
    let statusTextColor = "#FFFFFF";

    if(props.bulkErrors && props.bulkSuccess === false){
        bgColorStatus = "#DA0063";
        statusText = "Upload Failed";
    }else if(!props.bulkErrors && props.bulkSuccess === true){
        bgColorStatus = "#0CA789";
        statusText = "Upload Success";
    }

    return(
        <div className={"d-flex flex-column"} style={{width: "40%"}}>
            <div className={"d-flex mb-2"}>
                <LabelBox text={"Contoh File"}/>
                <BorderedBox
                    bgColor={"#FFFFFF"} borderColor={"#E6E6E6"}
                    content={<a target="_blank" href={"https://docs.google.com/spreadsheets/d/1okjBosmtWFe4Cz9tBVO7vQLnzCYHeRLSFGsbO3JEzFA/edit?usp=sharing"}>Here</a>}/>
            </div>

            <div className={"d-flex mb-2"}>
                <LabelBox text={"File Untuk Di Upload"}/>
                <UploadFileComponent isSubmitting={props.isSubmitting} deleteFile={props.deleteFile} sendBulkRequest={props.sendBulkRequest} setFile={props.setFile}/>
            </div>

            <div className={"d-flex mb-2"}>
                <LabelBox text={"Result"}/>
                <BorderedBox bgColor={bgColorStatus} content={statusText} textColor={statusTextColor}/>
            </div>

            <div className={"d-flex mb-2"}>
                <LabelBox text={"Result Feedback"}/>
                {props.bulkErrors ?
                    <BorderedBox bgColor={"#FFFFFF"} borderColor={"#E6E6E6"} content={"Click Here"}
                        action={()=>{props.setShowModal(true)}}
                    />
                    : <BorderedBox bgColor={"#E6E6E6"}/>
                }

            </div>
        </div>
    );
}

export default BulkUploadContainer;