import { Button, Modal, Spinner } from "react-bootstrap";
import { Form, Formik } from "formik";
import React from "react";
import ReactSelectForm from "../../../../components/ReactSelectForm/ReactSelectForm";
import commonStyles from "./style.module.scss";
import { getWarehouses } from '../../../../apis/warehouses';
import * as shipmentApi from '../../../../apis/shipment'
import { displayAlert } from "../../../../redux/actions/notif";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchPlanograms } from "../../../../apis/warehousePlanogram";

const _ = require('lodash');
class ShipmentDetailWarehouseGroupConfigurationModal extends React.PureComponent {
    getInitialValues = () => {
        return {
            warehouseIds: [],
            warehousePlanogramId: null,
        }
    }

    createShipmentDetailWarehouseConfig = async (values) => {
        const payload = {
            warehouseIds: values.warehouseIds,
            zoneId: values.warehousePlanogramId,
        }

        try {
            await shipmentApi.createShipmentWarehouseGroup(this.props.selectedShipmentId, payload);
        } catch (e) {
            this.props.displayAlert({ message: e.message, type: 'error'});
        }
        
        this.props.fetchShipmentWarehouseGroups();
        this.props.onClose();
    }

    render() {
        const {isShow, handleSubmit, onClose, initialValue, warehouseSourceId} = this.props

        return <Modal size="m" centered
                      show={isShow}
                      onHide={onClose}>
            <Modal.Header closeButton={true}>
                <Modal.Title> <b> Pengaturan Warehouse Group </b> </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={this.getInitialValues(initialValue)}
                    enableReinitialize={true}
                    onSubmit={this.createShipmentDetailWarehouseConfig}
                >
                    {(formikProps) => (
                        <ModalBody
                            {...this.props}
                            {...formikProps}
                            warehouseSourceId={warehouseSourceId}
                        />
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    }
}

class ModalBody extends React.Component {
    state = {
        warehouseOptions: [],
    }

    componentDidMount() {
        this.fetchWarehouseOptions();
        this.fetchWarehousePlanogramOptions();
    }

    getValueFromOption(value, option) {
        return option.filter(e => e.value === value);
    }

    fetchWarehouseOptions = async () => {
        let warehouses = [];
        try {
            warehouses = (await getWarehouses({isActive: true, sort: 'id,asc'})).data.data;
        } catch (e) {
            this.props.displayAlert({message: e.message, type: 'error'});
        }
        
        const warehouseOptions = warehouses.map(e => ({value: e.id, label: `${e.id} - ${e.name}`}));

        this.setState({
            warehouseOptions: warehouseOptions,
        });
    }

    fetchWarehousePlanogramOptions = async () => {
        let warehousesPlanograms = [];
        try {

            warehousesPlanograms = (await fetchPlanograms({warehouseIds: this.props.warehouseSourceId, fetchType: 'SPECIFIC', planogramTypes: ['ZONE'].join('&'), functionalities: ['STORAGE_FINISHED_GOODS'].join('&'), sort: 'id,asc'})).data.data;
        } catch (e) {
            this.props.displayAlert({message: e.message, type: 'error'});
        }
        
        const warehousePlanogramOptions = warehousesPlanograms.map(e => ({value: e.id, label: `${e.id} - ${e.name}`}));

        this.setState({
            warehousePlanogramOptions: warehousePlanogramOptions,
        });
    }

    getOptionFromValue = (value, options) => {
        if (value === undefined || options === undefined) return;
        return options.find((i) => i.value === value);
    }

    render() {
        const { isSubmitting, setFieldValue, onClose } = this.props;

        const createForm = () => {
            return <>
                <div className={"row"}>
                    <div className={`col`}>
                        <div className={"form-group"}>
                            <label style={{marginBottom: '4px'}}><b>Pilih Warehouse Tujuan</b></label>
                            <label style={{fontSize: '12px'}}>Warehouse yang ditampilkan hanya warehouse yang memiliki status <b>aktif</b></label>
                            <ReactSelectForm
                                isMulti={true}
                                fieldName={'warehouseIds'}
                                placeholder={'Pilih (maks. 3)...'}
                                options={this.state.warehouseOptions}
                                isClearable
                                setFieldValue={setFieldValue}/>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={`col`}>
                        <div className={"form-group"}>
                            <label style={{marginBottom: '4px'}}><b>Pilih Zona Picking</b></label>
                            <label style={{fontSize: '12px'}}>Hanya menampilkan zona dengan functionality <b>“Storage for Finished Goods”</b></label>
                            <ReactSelectForm
                                fieldName={'warehousePlanogramId'}
                                placeholder={'Pilih...'}
                                options={this.state.warehousePlanogramOptions}
                                isMulti={false}
                                isClearable
                                setFieldValue={setFieldValue}/>
                        </div>
                    </div>
                </div>
            </>
        }

        return <Form>
            {createForm()}
            <div className={'form-group d-flex justify-content-end'}>
                <Button className={`btn mr-2 ${commonStyles.btnCancel}`} disabled={isSubmitting} onClick={onClose}>
                    {isSubmitting && <Spinner animation="border" variant="light" size="sm"/>}Batal</Button>
                <Button type={'submit'} className={`btn ${commonStyles.btnOk}`} disabled={isSubmitting || _.isEmpty(this.props.values.warehouseIds) || this.props.values.warehousePlanogramId == null || this.props.values.warehouseIds.length > 3}>
                    {isSubmitting && <Spinner animation={"border"} variant={"light"} size={"sm"}/>}Simpan</Button>
            </div>
        </Form>
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        displayAlert: ({ message, type }) => displayAlert({ message, type })
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetailWarehouseGroupConfigurationModal);